import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { PrognosisService, PrognosisUpdateDto } from 'src/api';
import { usePrognosisFromId } from 'src/hooks';
import { StatusIdEnum } from 'src/types/status';

export const useConvertToDraftPrognosis = () => {
  const { data } = usePrognosisFromId();
  const { prognosisId } = useParams();
  const navigate = useNavigate();

  const pickedData: PrognosisUpdateDto = {
    title: data?.title ?? '',
    operationId: data?.operationId ?? -1,
    statusId: data?.statusId ?? StatusIdEnum.HISTORICAL,
    approveUser: data?.approveUser,
    ciTargetUuid: data?.ciTargetUuid,
    wellboreIdentifier: data?.wellboreIdentifier,
    wellpathRevision: data?.wellpathRevision,
    comment: data?.comment,
    validFromDate: data?.validFromDate,
    validToDate: data?.validToDate,
    updateUser: data?.updateUser ?? 'N/A',
  };

  return useMutation({
    mutationKey: ['DRAFT PROGNOSIS'],
    mutationFn: () => {
      return PrognosisService.putApiV1Prognoses(
        Number(prognosisId),
        undefined,
        {
          ...pickedData,
          statusId: StatusIdEnum.DRAFT,
        }
      );
    },
    onSuccess: () => {
      navigate(`/dashboard/edit-prognosis/${prognosisId}`);
    },
  });
};
