import { Button, Icon, OptionalTooltip } from '@equinor/amplify-component-lib';
import { visibility, visibility_off } from '@equinor/eds-icons';

import { useBulkUpdateFilter } from 'src/pages/EditMeasuredData/hooks/useBulkUpdateFilter';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useSaveFilter } from 'src/pages/EditMeasuredData/hooks/useSaveFilter';

export const DisableFilterButton = () => {
  const {
    selectedFilter,
    setSelectedFilter,
    selectedFilters,
    setSelectedFilters,
  } = useEditMeasuredData();
  const noFiltersSelected =
    selectedFilter === undefined && selectedFilters.length === 0;

  const { mutate: saveFilter } = useSaveFilter();
  const { mutate: bulkUpdateFilter } = useBulkUpdateFilter();

  const isDisabled = selectedFilter
    ? (selectedFilter.disabled ?? false)
    : selectedFilters.some((f) => f.disabled);

  const handleDisableClick = () => {
    if (selectedFilter) {
      saveFilter({ ...selectedFilter, disabled: !selectedFilter.disabled });
    } else {
      bulkUpdateFilter(
        selectedFilters.map((f) => {
          return { ...f, disabled: !f.disabled };
        })
      );
    }
    setSelectedFilter(undefined);
    setSelectedFilters([]);
  };

  return (
    <OptionalTooltip
      title={
        noFiltersSelected
          ? 'Please select one or more items to temporarily disable'
          : ''
      }
    >
      <Button
        disabled={noFiltersSelected}
        variant="ghost_icon"
        onClick={handleDisableClick}
      >
        {isDisabled ? (
          <Icon data={visibility_off} />
        ) : (
          <Icon data={visibility} />
        )}
      </Button>
    </OptionalTooltip>
  );
};
