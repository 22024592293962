import { FC, useMemo } from 'react';

import { arrow_drop_right } from '@equinor/eds-icons';

import { useHelplines } from '../../ManageHelpLines';
import { StyledListItem } from '../../ManageHelpLines.styles';
import { Sources } from '../../ManageHelpLines.types';
import { Count } from '../Count';

interface OptionProps {
  source: Sources;
}

export const SelectSourceOption: FC<OptionProps> = ({ source }) => {
  const {
    selectedWellbore,
    setSource,
    source: currentSource,
    selectedHelpLines,
  } = useHelplines();

  const count = useMemo(() => {
    return selectedHelpLines.filter(
      (helpLine) =>
        helpLine.id === selectedWellbore?.wellboreUuid &&
        source === helpLine.source &&
        helpLine.source === source
    ).length;
  }, [selectedHelpLines, selectedWellbore?.wellboreUuid, source]);

  const label = useMemo(() => {
    switch (source) {
      case 'GRADIENT':
        return 'Gradient';
      case 'FLOW':
        return 'Flow';
      case 'Dhpg1':
        return 'Gauge 1';
      case 'Dhpg2':
        return 'Gauge 2';
    }
  }, [source]);

  const handleOnClick = () => {
    setSource(source);
  };
  return (
    <StyledListItem
      label={label}
      onClick={handleOnClick}
      selected={currentSource === source}
      leadingContent={count > 0 ? <Count value={count} /> : undefined}
      trailingContent={arrow_drop_right}
    />
  );
};
