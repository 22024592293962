import { Checkbox, colors, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

const { shape } = tokens;

import styled from 'styled-components';

export const HeaderCell = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid ${colors.ui.background__medium.rgba};
  height: calc(100px - ${spacings.x_small});
  padding: ${spacings.small};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
`;

export const InitializeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface TableProps {
  $columns: number;
  $datum?: boolean;
  $gradient?: boolean;
  $rows: number;
  $segment?: boolean;
  $isEditing?: boolean;
}

export const Table = styled.div<TableProps>`
  display: grid;
  grid-template-columns: 275px repeat(${({ $columns }) => $columns || 0}, auto) ${({
      $isEditing,
    }) => ($isEditing ? '50px' : '')};
  grid-template-rows: auto repeat(${({ $rows }) => $rows || 0}, auto);
  overflow-x: auto;
  overflow-y: hidden;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  height: 24px;
  > span {
    padding: 0px !important;
    padding-right: ${spacings.small} !important;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

interface ColumnContainerProps {
  $columnStart: number;
  $columnSpan: number;
  $rowSpan: number;
}

export const ActionColumnContainer = styled.div<ColumnContainerProps>`
  display: grid;
  grid-column: ${({ $columnStart, $columnSpan }) =>
    `${$columnStart} / span ${$columnSpan}`};
  grid-row: ${({ $rowSpan }) => `1 / span ${$rowSpan}`};
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-left: none;
  border-bottom: none;
  border-radius: 0 ${shape.corners.borderRadius} 0 0;
`;

export const SegmentColumnContainer = styled.div<ColumnContainerProps>`
  display: grid;
  grid-column: ${({ $columnStart, $columnSpan }) =>
    `${$columnStart} / span ${$columnSpan}`};
  grid-row: ${({ $rowSpan }) => `1 / span ${$rowSpan}`};
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-bottom: none;
  border-radius: ${shape.corners.borderRadius} 0 0 0;
`;
