import { FC, ReactNode } from 'react';

import {
  AuthProvider,
  LoadingProvider,
  ReleaseNotesProvider,
  SideBarProvider,
  SnackbarProvider,
} from '@equinor/amplify-component-lib';
import { FeatureToggleProvider } from '@equinor/subsurface-app-management';
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppStateContextProvider } from './AppStateProvider';
import { FormProviderWrapper } from './FormProvider';
import { MeasuredDataProvider } from './MeasuredDataProvider';
import { OptionalAttributeProvider } from 'src/pages/EditPrognosis/providers/OptionalAttributeProvider';

interface ProvidersProps {
  children: ReactNode;
  queryClientConfig?: QueryClientConfig;
}

export const Providers: FC<ProvidersProps> = ({
  children,
  queryClientConfig,
}) => {
  const queryClient = new QueryClient(queryClientConfig ?? {});

  return (
    <AuthProvider withoutLoader>
      <QueryClientProvider client={queryClient}>
        <FeatureToggleProvider>
          <LoadingProvider>
            <ReleaseNotesProvider>
              <AppStateContextProvider>
                <SideBarProvider>
                  <SnackbarProvider>
                    <OptionalAttributeProvider>
                      <MeasuredDataProvider>
                        <FormProviderWrapper>{children}</FormProviderWrapper>
                      </MeasuredDataProvider>
                    </OptionalAttributeProvider>
                  </SnackbarProvider>
                </SideBarProvider>
              </AppStateContextProvider>
            </ReleaseNotesProvider>
          </LoadingProvider>
        </FeatureToggleProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition={'bottom-left'}
        />
      </QueryClientProvider>
    </AuthProvider>
  );
};
