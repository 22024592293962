import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { FieldSelector } from '@equinor/amplify-component-lib';

import { useApp } from '../hooks';
import { useGetUserAccess } from '../hooks/useGetUserAccess';

export const SelectField: FC = () => {
  const { setField } = useApp();
  const navigate = useNavigate();

  const onChangedField = () => {
    navigate('/dashboard');
  };
  const { fields } = useGetUserAccess();

  return (
    <FieldSelector
      setField={setField}
      fields={fields ?? []}
      onChangedField={onChangedField}
      finishedText="Taking you to the dashboard page"
    />
  );
};
