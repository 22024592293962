import { CSSProperties, Fragment } from 'react';

import {
  colors,
  isBetweenDates,
  spacings,
} from '@equinor/amplify-component-lib';
import { TickFormatter } from '@visx/axis';
import { LinePath } from '@visx/shape';
import { defaultStyles } from '@visx/tooltip';
import { NumberValue, ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale';
import { timeFormat } from '@visx/vendor/d3-time-format';

import {
  LineChartData,
  LineChartLine,
  VerticalBrushData,
} from './LineChart.types';
import { DataType } from 'src/api';
import { PressureDataSimple } from 'src/api';
import { PressureType } from 'src/api';
import {
  getDateForDaysIntoPast,
  getLineChartLineColor,
  getNameFromMeasurement,
  roundTo,
} from 'src/utils';

export const getDate = (d: LineChartData) => {
  return new Date(d.date?.toDateString());
};
export const getValue = (d: LineChartData) => {
  return d.value;
};
export const getHeatMapValue = (d: VerticalBrushData) => d.value;
export const getHeatMapCount = (d: VerticalBrushData) => d.count;

export const formatDate: TickFormatter<NumberValue> = (value: NumberValue) => {
  return timeFormat('%d.%m.%y')(new Date(value.valueOf()));
};

export const getDataTypeShortName = (dataType: DataType) => {
  switch (dataType) {
    case DataType.CUMULATIVE_INJECTION:
    case DataType.CUMULATIVE_PRODUCTION:
    case DataType.CUMULATIVE_VOIDAGE:
      return 'Cumul.';
    case DataType.INSTANT_INJECTION:
    case DataType.INSTANT_PRODUCTION:
    case DataType.INSTANT_VOIDAGE:
      return 'Inst.';
    case DataType.ON_STREAM_HOURS:
      return 'OnStHr';
    case DataType.FINAL:
      return 'QC';
    case DataType.GRADIENT:
      return 'Grad.';
    case DataType.DEPTH_CORRECTED:
      return 'Dep. cor.';
    case DataType.RAW:
      return 'Raw';
    case DataType.PRODUCTIVITY_INDEX_INTERPOLATED:
      return 'PI';
    case DataType.PRODUCTIVITY_INDEX:
      return 'PI Meas.';
    default:
      return undefined;
  }
};

export const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: colors.ui.background__overlay.hex + 'cc',
  display: 'grid',
  gridTemplateColumns: 'auto 2fr 1fr',
  gap: spacings.x_small,
  width: 'fit-content',
} as CSSProperties;

export const getDefaultBrushRange = (dataLength: number) => {
  if (dataLength < 30)
    return [getDateForDaysIntoPast(7), getDateForDaysIntoPast(1)] as [
      Date,
      Date,
    ];

  return [
    getDateForDaysIntoPast(Math.round(dataLength / 3)),
    getDateForDaysIntoPast(1),
  ] as [Date, Date];
};

export const getDefinedLineChartData = (line: LineChartData) => {
  return line.value !== 0;
};
export const getDefinedRegularLineChartData = (dataPoint: LineChartData) => {
  return dataPoint.value !== 0 && !dataPoint.isInterpolated;
};

export const createChartData = (
  wellboreData: PressureDataSimple[],
  range?: [Date, Date]
): LineChartLine => {
  let data = wellboreData;

  if (range !== undefined) {
    data = data.filter((data) =>
      isBetweenDates(new Date(data.prodDay ?? ''), range)
    );
  }

  return {
    color: getLineChartLineColor(wellboreData[0]),
    name: getNameFromMeasurement(wellboreData[0]),
    data: data.map((d) => {
      return {
        value: d.pressureFinal ?? 0,
        date: new Date(d.prodDay ?? ''),
        isInterpolated:
          d.pressureType === PressureType.INTERPOLATED ||
          d.pressureType === PressureType.CORRECTED,
        isManual: d.pressureType === PressureType.CORRECTED,
      };
    }),
  };
};

export const getLineChartLimitsForVertBrush = (
  wellbores: LineChartLine[],
  helpLines?: LineChartLine[]
) => {
  const relevantHelpLines = helpLines
    ? helpLines?.filter((helpline) => helpline.rightAxisType === undefined)
    : undefined;

  const flatValues = wellbores.flatMap((data) => data.data.map((d) => d.value));

  const filteredFlatValues = flatValues.filter((value) => value > 0);
  const flatValuesWithHelpLine = [...filteredFlatValues];

  if (relevantHelpLines) {
    const helpLineValues = relevantHelpLines.flatMap((helpline) => {
      if (helpline.data) {
        return helpline.data.map((d) => d.value ?? 0);
      }
      return [];
    });
    flatValuesWithHelpLine.push(...helpLineValues);
  }

  const minValue = Math.min(...filteredFlatValues);
  const maxValue = Math.max(...filteredFlatValues);

  const paddingValue = Math.max((maxValue - minValue) * 0.1, 0.2);

  const value = roundTo(minValue - paddingValue, 1) ?? 0;
  const minValuePadded = value >= 0 ? value : 0;

  const maxValuePadded = roundTo(maxValue + paddingValue, 1) ?? 0;

  const minFloorValue = Math.floor(
    Math.min(...flatValuesWithHelpLine) - paddingValue
  );
  const minValuePaddedFloor = minFloorValue >= 0 ? minFloorValue : 0;

  const maxValuePaddedCeil = Math.max(...flatValuesWithHelpLine) + paddingValue;

  return {
    flatValues,
    minValuePadded,
    maxValuePadded,
    minValuePaddedFloor,
    maxValuePaddedCeil,
  };
};

export const renderLeftAxisLines = (
  leftAxisLines: LineChartLine[],
  dateScale: ScaleTime<number, number, never>,
  valueScale: ScaleLinear<number, number, never>,
  isBrush?: boolean
) => {
  return leftAxisLines.map((wellbore) => {
    const regularDataLinePath = (
      <LinePath
        key={`${wellbore.data.at(0)?.date.toDateString()}-${
          wellbore.data.at(0)?.value
        }-${wellbore.color}-${isBrush}`}
        data={wellbore.data}
        x={(d) => dateScale(getDate(d))}
        y={(d) => valueScale(getValue(d))}
        defined={getDefinedRegularLineChartData}
        stroke={wellbore.color}
        strokeWidth={2}
        strokeOpacity={2}
      />
    );

    const interpolatedData = wellbore.data.filter((dataPoint, index) => {
      const prevDataPoint = wellbore.data.at(index > 0 ? index - 1 : index);
      const nextDataPoint = wellbore.data.at(index + 1);

      return (
        dataPoint.isInterpolated ||
        prevDataPoint !== undefined ||
        nextDataPoint !== undefined
      );
    });

    if (interpolatedData.length > 0) {
      const interpolatedDataLinePath = (
        <LinePath
          key={`${interpolatedData.at(0)?.date.toDateString()}-${
            interpolatedData.at(0)?.value
          }-${wellbore.color}-${interpolatedData.at(0)?.isInterpolated}-${isBrush}`}
          data={interpolatedData}
          x={(d) => dateScale(getDate(d))}
          y={(d) => valueScale(getValue(d))}
          defined={getDefinedLineChartData}
          stroke={wellbore.color}
          strokeWidth={2}
          strokeOpacity={2}
          strokeDasharray={isBrush ? '3,2' : '4,5'}
        />
      );
      return (
        <Fragment
          key={`${wellbore.data.at(0)?.date.toDateString()}-${
            wellbore.data.at(0)?.value
          }-${wellbore.color}-${isBrush}`}
        >
          {regularDataLinePath}
          {interpolatedDataLinePath}
        </Fragment>
      );
    }
    return (
      <Fragment
        key={`${wellbore.data.at(0)?.date.toDateString()}-${
          wellbore.data.at(0)?.value
        }-${wellbore.color}-${isBrush}`}
      >
        {regularDataLinePath}
      </Fragment>
    );
  });
};
