import { FC, useEffect, useRef, useState } from 'react';

import { MapViewState, WebMercatorViewport } from '@deck.gl/core';
import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const Container = styled.div`
  width: fit-content;
  background: ${colors.ui.background__light.rgba};
  padding: ${spacings.xx_small} ${spacings.small};
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

const Bar = styled.div`
  margin-top: 2px;
  height: ${spacings.x_small};
  border-left: 2px solid ${colors.text.static_icons__secondary.rgba};
  border-right: 2px solid ${colors.text.static_icons__secondary.rgba};
  border-bottom: 2px solid ${colors.text.static_icons__secondary.rgba};
  transition: all 1s;
`;

const Text = styled(Typography)`
  font-weight: 500;
`;

const MAX_WIDTH = 200;

const getRoundNum = (num: number) => {
  const pow10 = Math.pow(10, (Math.floor(num) + '').length - 1);
  let d = num / pow10;

  d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

  return pow10 * d;
};

interface ScaleProps {
  viewport: MapViewState;
}

export const Scale: FC<ScaleProps> = ({ viewport }) => {
  const [label, setLabel] = useState<string>('');
  const [ratio, setRatio] = useState<number>(0);
  const previousZoom = useRef<number>(-1);

  useEffect(() => {
    if (viewport.zoom === previousZoom.current) return;

    previousZoom.current = viewport.zoom;
    const view = new WebMercatorViewport(viewport);
    const y = view.y;

    const { metersPerUnit } = view.getDistanceScales();
    const left = view.unproject([0, y]);
    const right = view.unproject([MAX_WIDTH, y]);

    const maxMeters = (right[0] - left[0]) * metersPerUnit[0];

    const currentMeters = getRoundNum(maxMeters);
    if (currentMeters / maxMeters !== 0) {
      setRatio(currentMeters / maxMeters);
      setLabel(
        `${currentMeters <= 1000 ? currentMeters : currentMeters / 1000} ${
          currentMeters > 1000 ? 'km' : 'm'
        }`
      );
    }
  }, [viewport]);

  return (
    <Container>
      <Text variant="meta" color={colors.text.static_icons__default.rgba}>
        {label}
      </Text>
      <Bar
        style={{ width: `calc(${Math.round(MAX_WIDTH * ratio) + 'px'} - 4px)` }}
      />
    </Container>
  );
};
