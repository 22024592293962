import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Divider,
  Search,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { Column } from '../../components/Column';
import { SelectWellboreOption } from './SelectWellboreOption';

import { useMeasuredData } from 'hooks/index';
import { useMeasuredDataWellbore } from 'hooks/useMeasuredDataWellbore';
import styled from 'styled-components';

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1rem;
`;

const StyledSectionLabel = styled(Typography)`
  margin-left: ${spacings.medium};
  margin-top: ${spacings.medium};
`;

export const SelectWellbore = () => {
  const { wellId } = useParams();

  const { wellbore: currentEditWellbore } = useMeasuredDataWellbore(wellId);
  const { wellbores } = useMeasuredData();
  const [search, setSearch] = useState('');

  return (
    <Column title="Wellbores">
      <SearchWrapper>
        <Search
          placeholder="Search for well"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SearchWrapper>
      <StyledSectionLabel group="paragraph" variant="overline">
        CURRENT EDIT:
      </StyledSectionLabel>
      <SelectWellboreOption wellbore={currentEditWellbore} />
      <Divider variant="small" size="1" color="medium" />
      <StyledSectionLabel group="paragraph" variant="overline">
        OTHER:
      </StyledSectionLabel>
      {wellbores
        .filter((w) => w.wellboreUuid !== currentEditWellbore?.wellboreUuid)
        .filter((w) =>
          w.wellName?.toLowerCase()?.includes(search.toLowerCase())
        )
        .map((wellbore) => (
          <SelectWellboreOption
            key={wellbore.wellboreUuid}
            wellbore={wellbore}
          />
        ))}
    </Column>
  );
};
