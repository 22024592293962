import { useCallback, useEffect } from 'react';

import { PickingInfo } from '@deck.gl/core';

import { useMeasureArea } from './useMeasureArea';
import { useMeasuredData } from 'src/hooks/useMeasuredData';
import { Interact } from 'src/pages/MapView/components/Map/hooks/useDeckGlMapProps/useMeasureArea.types';
import { MapTool } from 'src/pages/MapView/components/MapSettings/MapTools';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { DataSet, MapBackground } from 'src/pages/MapView/MapView.types';
import {
  getClosestDatapointValue,
  isInSelectedArea,
} from 'src/pages/MapView/MapView.utils';
import { Measurement } from 'src/types';
import { getNameFromMeasurement } from 'src/utils';

import { debounce } from 'lodash';

export function useMapMouseInteractions() {
  const { selectedMeasurements, setSelectedMeasurements } = useMeasuredData();

  const {
    selectionAreaStart,
    setSelectionAreaEnd,
    isDraggingMeasureAreaPoint,
    hoverPoint,
    setHoverPoint,
    mapTool,
    filteredPressurePoints,
    backgroundLayer,
  } = useMapOptions();

  const {
    measureAreaShapes,
    resetMeasureAreaShapes,
    updateMeasureAreaLayer,
    getDraggingPoint,
  } = useMeasureArea();

  const handleOnClick = (info: PickingInfo) => {
    if (
      mapTool === MapTool.MEASURE_AREA &&
      info.coordinate &&
      !isDraggingMeasureAreaPoint.current
    ) {
      updateMeasureAreaLayer(Interact.CLICK, info);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetHoverPoint = useCallback(
    debounce((info: PickingInfo | undefined) => {
      const { coordinate, layer } = info!;
      if (!coordinate) return undefined;

      if (backgroundLayer === MapBackground.INTERPOLATED) {
        setHoverPoint([
          ...coordinate,
          getClosestDatapointValue(layer?.state?.data as DataSet, coordinate) ??
            NaN,
        ]);
      } else {
        setHoverPoint(coordinate);
      }
    }, 400),
    [setHoverPoint, backgroundLayer]
  );

  const handleOnHover = useCallback(
    (info: PickingInfo) => {
      if (
        mapTool === MapTool.MEASURE_AREA &&
        info.coordinate &&
        !isDraggingMeasureAreaPoint.current
      ) {
        updateMeasureAreaLayer(Interact.HOVER, info);
      }
      debouncedSetHoverPoint(info);
    },
    [
      debouncedSetHoverPoint,
      isDraggingMeasureAreaPoint,
      mapTool,
      updateMeasureAreaLayer,
    ]
  );

  const handleOnDragStart = (info: PickingInfo) => {
    if (mapTool === MapTool.SELECT_AREA) {
      selectionAreaStart.current = info.coordinate;
    }
    if (mapTool === MapTool.MEASURE_AREA) {
      isDraggingMeasureAreaPoint.current = getDraggingPoint(info);
    }
  };

  const handleOnDrag = (info: PickingInfo) => {
    if (mapTool === MapTool.SELECT_AREA) {
      setSelectionAreaEnd(info.coordinate);
    } else if (
      mapTool === MapTool.MEASURE_AREA &&
      isDraggingMeasureAreaPoint.current
    ) {
      updateMeasureAreaLayer(
        Interact.DRAGGING,
        info,
        isDraggingMeasureAreaPoint.current
      );
    }
  };
  const handleOnDragEnd = (info: PickingInfo) => {
    if (mapTool === MapTool.MEASURE_AREA) {
      isDraggingMeasureAreaPoint.current = undefined;
      updateMeasureAreaLayer(Interact.HOVER, info);
    }
    if (mapTool === MapTool.SELECT_AREA) {
      const updatedMeasurements: Measurement[] = selectedMeasurements;
      filteredPressurePoints.forEach((pressurePoint) => {
        if (
          !pressurePoint.measurementPosition?.coordinates ||
          !selectionAreaStart.current ||
          !info.coordinate ||
          !pressurePoint.wellboreName ||
          !pressurePoint.wellboreUuid ||
          pressurePoint.dhpg === undefined
        )
          return;

        if (
          isInSelectedArea(
            pressurePoint.measurementPosition?.coordinates,
            selectionAreaStart.current,
            info.coordinate
          ) &&
          !selectedMeasurements.some(
            (measurement) =>
              measurement.wellboreUuid === pressurePoint.wellboreUuid &&
              measurement.dhpg === pressurePoint.dhpg
          )
        ) {
          updatedMeasurements.push({
            value: `${pressurePoint.wellboreUuid}:${pressurePoint.dhpg}`,
            label: getNameFromMeasurement(pressurePoint),
            dhpg: pressurePoint.dhpg,
            wellboreUuid: pressurePoint.wellboreUuid,
          });
        }
      });
      if (updatedMeasurements.length > 0) {
        setSelectedMeasurements([...updatedMeasurements]);
      }
      selectionAreaStart.current = undefined;
      setSelectionAreaEnd(undefined);
    }
  };

  useEffect(() => {
    if (mapTool !== MapTool.MEASURE_AREA) {
      resetMeasureAreaShapes();
    }
  }, [resetMeasureAreaShapes, mapTool]);

  return {
    hoverPoint,
    handleOnHover,
    measureAreaShapes,
    resetMeasureAreaShapes,
    handleOnClick,
    handleOnDrag,
    handleOnDragEnd,
    handleOnDragStart,
  };
}
