import { useQuery } from '@tanstack/react-query';

import { PrognosisDetailService } from 'src/api';
import { RESERVOIR_ZONE_DETAILS_KEY } from 'src/constants/queryKeys';

export const useGetReservoirZoneDetails = (reservoirZone: string) => {
  return useQuery({
    queryKey: [RESERVOIR_ZONE_DETAILS_KEY, reservoirZone],
    queryFn: () =>
      PrognosisDetailService.getApiV1PrognosesDetailsReservoirZone(
        reservoirZone
      ),
    staleTime: 1000 * 60 * 5,
    enabled: !!reservoirZone,
  });
};
