import { FC, ReactNode } from 'react';

import {
  Button,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { arrow_back } from '@equinor/eds-icons';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
  padding-bottom: ${spacings.large};
`;

const Breadcrumbs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleAndChildren = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:first-child {
    flex-grow: 10;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  gap: ${spacings.small};
  align-items: center;
`;

interface LinePlotHeaderProps {
  title: string;
  backText?: string;
  handleOnBackClick?: () => void;
  children?: ReactNode;
}

export const LinePlotHeader: FC<LinePlotHeaderProps> = ({
  title,
  backText,
  handleOnBackClick,
  children,
}) => {
  // TODO: Implement breadcrumbs
  return (
    <Wrapper>
      {backText && handleOnBackClick ? (
        <Breadcrumbs>
          <Button variant="ghost" onClick={handleOnBackClick}>
            <Icon data={arrow_back} /> {backText}
          </Button>
        </Breadcrumbs>
      ) : null}
      <TitleAndChildren>
        <Typography variant="h1_bold">{title}</Typography>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </TitleAndChildren>
    </Wrapper>
  );
};
