import { FC, Fragment, MouseEvent, useCallback, useMemo } from 'react';

import { ScaleLinear } from '@visx/vendor/d3-scale';

import {
  CIRCLE_RADIUS,
  STROKE_WIDTH,
  TRANSITION,
} from 'components/Chart/Chart.constants';
import {
  ChartDataPoint,
  ChartDataPointCategory,
} from 'components/Chart/Chart.types';
import {
  dataPointColor,
  dataPointKeyToCategory,
} from 'components/Chart/Chart.utils';

import { motion } from 'framer-motion';
import styled from 'styled-components';

const Group = styled.g`
  > circle {
    pointer-events: none;
  }
`;

interface ElementsProps {
  data: ChartDataPoint;
  pressureScale: ScaleLinear<number, number, never>;
  depthScale: ScaleLinear<number, number, never>;
  onMouseMove: (
    value: ChartDataPoint,
    event: MouseEvent<SVGRectElement>
  ) => void;
  onMouseExit: () => void;
}

export const Elements: FC<ElementsProps> = ({
  data,
  pressureScale,
  depthScale,
  onMouseMove,
  onMouseExit,
}) => {
  const handleOnMouseMove = useCallback(
    (event: MouseEvent<SVGRectElement>, currentElement: ChartDataPoint) => {
      onMouseMove(currentElement, event);
    },
    [onMouseMove]
  );

  const totalWidth = useMemo(() => {
    const x = pressureScale(data.low) - CIRCLE_RADIUS - STROKE_WIDTH * 1.5;
    return pressureScale(data.high) - x + CIRCLE_RADIUS + STROKE_WIDTH * 1.5;
  }, [data.high, data.low, pressureScale]);

  const circles = useMemo(() => {
    const initialColors = dataPointColor(dataPointKeyToCategory('initial'));
    const expectedColors = dataPointColor(dataPointKeyToCategory('expected'));

    return (
      <Fragment>
        <motion.circle
          key={'initial'}
          cx={pressureScale(data.initial ?? 0)}
          cy={depthScale(data.depth)}
          initial={{
            r: 0,
          }}
          animate={{ r: 4 }}
          transition={TRANSITION}
          {...initialColors}
        />

        <motion.circle
          key={'expected'}
          strokeWidth={4}
          cx={pressureScale(data.expected)}
          cy={depthScale(data.depth)}
          initial={{
            r: 0,
          }}
          animate={{ r: 6 }}
          strokeOpacity={0.4}
          transition={TRANSITION}
          {...expectedColors}
        />
      </Fragment>
    );
  }, [data, depthScale, pressureScale]);

  const rectangle = useMemo(() => {
    const x = pressureScale(data.low) - CIRCLE_RADIUS - STROKE_WIDTH * 1.5;
    const y = depthScale(data.depth) - CIRCLE_RADIUS - STROKE_WIDTH * 1.5;

    const rx = CIRCLE_RADIUS + STROKE_WIDTH * 2;

    const { fill } = dataPointColor(
      ChartDataPointCategory.LOW_HIGH,
      data.color
    ) as {
      stroke: string;
      fill: string;
      opacity: number;
    };

    return (
      <motion.rect
        x={x}
        y={y}
        height={12}
        width={totalWidth}
        rx={rx}
        fill={fill}
        onMouseMove={(e) => handleOnMouseMove(e, data)}
        onMouseLeave={onMouseExit}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={TRANSITION}
      />
    );
  }, [
    depthScale,
    handleOnMouseMove,
    onMouseExit,
    pressureScale,
    totalWidth,
    data,
  ]);

  return (
    <Group>
      {rectangle}
      {circles}
    </Group>
  );
};
