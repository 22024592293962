import { MouseEvent, useMemo } from 'react';

import { scaleLinear } from '@visx/scale';
import { withTooltip } from '@visx/tooltip';

import Chart from 'components/Chart/Chart';
import { MARGIN, TICK_LABEL_PROPS } from 'components/Chart/Chart.constants';
import {
  ChartDataCategory,
  ChartDataPoint,
} from 'components/Chart/Chart.types';
import {
  getDepthDomain,
  getPressureDomain,
  localPoint,
} from 'components/Chart/Chart.utils';

import { Elements } from './Elements';
import { Unit } from 'src/types';

import { useApp } from 'hooks/useApp';

interface ScalesProps {
  selected: ChartDataCategory;
  data: ChartDataPoint[];
  width: number;
  height: number;
}

export const Scales = withTooltip<ScalesProps, ChartDataPoint | undefined>(
  ({
    selected,
    data,
    width,
    height,
    showTooltip,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipData,
  }) => {
    const { unit } = useApp();
    const xMax = useMemo(() => width - MARGIN.RIGHT, [width]);
    const yMax = useMemo(() => height - MARGIN.BOTTOM, [height]);

    const depthScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax - MARGIN.BOTTOM - TICK_LABEL_PROPS.fontSize, 0],
          domain: getDepthDomain(data),
          round: true,
        }),
      [data, yMax]
    );

    const pressureScale = useMemo(
      () =>
        scaleLinear({
          range: [0, xMax],
          domain: getPressureDomain(data),
          round: true,
        }),
      [data, xMax]
    );

    const handleOnMouseMove = (
      value: ChartDataPoint,
      event: MouseEvent<SVGRectElement>
    ) => {
      const { x, y } = localPoint(event) ?? { x: 0 };

      showTooltip({
        tooltipData: value,
        tooltipTop: y,
        tooltipLeft: x,
      });
    };

    const graphColors = data.map((d) => d.color);

    return (
      <Chart
        width={width}
        height={height}
        data={data}
        xScale={pressureScale}
        yScale={depthScale}
        yMax={yMax}
        xMax={xMax}
        tooltipLeft={tooltipLeft}
        tooltipTop={tooltipTop}
        tooltipData={tooltipData}
        xLabel={unit === Unit.BAR ? 'Pressure' : 'SG'}
        yLabel="Depth"
        graphColors={graphColors as string[]}
      >
        {data.map((dataPoint, index) => (
          <Elements
            key={`datapoint-${index}-${selected}`}
            data={dataPoint}
            pressureScale={pressureScale}
            depthScale={depthScale}
            onMouseMove={handleOnMouseMove}
            onMouseExit={hideTooltip}
          />
        ))}
      </Chart>
    );
  }
);
