import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { Tooltip as VisxTooltip } from '@visx/tooltip';

import { ITooltip } from '../Chart.types';

export const Tooltip: FC<ITooltip> = ({
  tooltipData,
  tooltipLeft,
  tooltipTop,
}) => {
  if (!tooltipData) return;

  return (
    <VisxTooltip left={tooltipLeft} top={tooltipTop}>
      {tooltipData.zone && (
        <Typography variant="chip__badge" group="ui">
          Zone: {tooltipData.zone}
        </Typography>
      )}
      {tooltipData.depth && (
        <Typography variant="chip__badge" group="ui">
          Depth: {tooltipData.depth}
        </Typography>
      )}
      {tooltipData.date && (
        <Typography variant="chip__badge" group="ui">
          Date: {tooltipData.date.toDateString()}
        </Typography>
      )}
      <Typography variant="chip__badge" group="ui">
        Initial: {tooltipData.initial}
      </Typography>
      <Typography variant="chip__badge" group="ui">
        Expected: {tooltipData.expected}
      </Typography>
      <Typography variant="chip__badge" group="ui">
        Low: {tooltipData.low}
      </Typography>
      <Typography variant="chip__badge" group="ui">
        High: {tooltipData.high}
      </Typography>
    </VisxTooltip>
  );
};
