/*
Example of get statuses
[
  {
    "id": 0,
    "name": "draft",
    "description": null
  },
  {
    "id": 1,
    "name": "submitted",
    "description": null
  },
  {
    "id": 2,
    "name": "revision_required",
    "description": null
  },
  {
    "id": 3,
    "name": "outdated",
    "description": null
  },
  {
    "id": 4,
    "name": "approved",
    "description": null
  },
  {
    "id": 5,
    "name": "historical",
    "description": null
  }
]
 */

export enum StatusIdEnum {
  DRAFT,
  SUBMITTED,
  REVISION_REQUIRED,
  OUTDATED,
  APPROVED,
  HISTORICAL,
  PREVIEW,
}

export enum StatusNameEnum {
  DRAFT = 'draft',
  SUBMITTED = 'submitted',
  REVISION_REQUIRED = 'revision_required',
  OUTDATED = 'outdated',
  APPROVED = 'approved',
  HISTORICAL = 'historical',
  PREVIEW = 'preview',
}

export const StatusNameToId: Record<StatusNameEnum, StatusIdEnum> = {
  draft: StatusIdEnum.DRAFT,
  submitted: StatusIdEnum.SUBMITTED,
  revision_required: StatusIdEnum.REVISION_REQUIRED,
  outdated: StatusIdEnum.OUTDATED,
  approved: StatusIdEnum.APPROVED,
  historical: StatusIdEnum.HISTORICAL,
  preview: StatusIdEnum.PREVIEW,
} as const;
