import { FC } from 'react';

import { Icon, spacings } from '@equinor/amplify-component-lib';

import { PrognosisDto } from 'src/api';
import { StatusIdEnum } from 'src/types/status';
import { getVariantStyles } from 'src/utils/iconChip';

import { styled } from 'styled-components';

interface ContainerProps {
  $background: string;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  border-radius: ${spacings.x_large};
  background: ${({ $background }) => $background};
  width: fit-content;
  padding: ${spacings.x_small};
`;

interface IconChipProps {
  statusId: PrognosisDto['statusId'];
  isSubCard?: boolean;
  from_date?: string | undefined | null;
}

export const IconChip: FC<IconChipProps> = ({
  statusId,
  isSubCard = false,
  from_date,
}) => {
  const { background, color, iconData } = getVariantStyles(
    statusId as StatusIdEnum,
    isSubCard,
    from_date
  );

  return (
    <Container $background={background}>
      <Icon data={iconData} size={16} color={color} />
    </Container>
  );
};
