import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { LastSubmittedPrognosisService } from 'src/api/services/LastSubmittedPrognosisService';
import { PREVIOUS_PROGNOSIS_DETAILS_KEY } from 'src/constants/queryKeys';

export const useGetPreviousPrognosisDetails = () => {
  const { prognosisId } = useParams();

  const usingId = Number(prognosisId);

  return useQuery({
    queryKey: [PREVIOUS_PROGNOSIS_DETAILS_KEY, prognosisId],
    queryFn: () =>
      LastSubmittedPrognosisService.getApiV1PrognosesDetailsLastSubmitted(
        usingId
      ),
    enabled: !Number.isNaN(usingId),
  });
};
