import { ChangeEvent, useState } from 'react';

import {
  Button,
  colors,
  Icon,
  TextField,
} from '@equinor/amplify-component-lib';
import { autorenew } from '@equinor/eds-icons';

import { roundTo } from 'src/utils';

import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
  > div {
    background-color: transparent;
  }
  input {
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    /* ::-webkit-inner-spin-button {
      display: none;
    } */
    :focus {
      ~ button > span > svg {
        fill: ${colors.interactive.focus.rgba};
      }
    }
  }
  button {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 50px;
    top: 5px;
    ::after {
      display: none;
    }
    > span > svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export interface ManualDataPressureValue {
  original: string;
  new: string;
}

interface InputCurrentProps {
  pressureFinal: ManualDataPressureValue;
  onChange: (change: string) => void;
}

export const InputCurrent = ({
  pressureFinal,
  onChange,
}: InputCurrentProps) => {
  const [value, setValue] = useState<string>(pressureFinal.new);

  const handleReset = (val: string) => {
    setValue(val);
    onChange(val);
  };

  const handleChange = (val: string) => {
    setValue(roundTo(val, 2)?.toString() ?? '');
    onChange(roundTo(val, 2)?.toString() ?? '');
  };

  return (
    <StyledInputWrapper>
      <TextField
        id="input-current"
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value)
        }
        type="number"
        step="0.1"
      />
      {value !== pressureFinal.original && (
        <Button
          variant="ghost_icon"
          onClick={() => {
            handleReset(pressureFinal.original);
          }}
        >
          <Icon data={autorenew} color="secondary"></Icon>
        </Button>
      )}
    </StyledInputWrapper>
  );
};
