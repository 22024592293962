import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { useIsMutating } from '@tanstack/react-query';

import { BorderedSection } from '../BorderedSection/BorderedSection';
import { AttributesWrapper } from './OptionalAttributes.styles';
import { PrognosisDetailDto } from 'src/api';
import { TOTAL_DEPTH_ROW_FEATURE_KEY } from 'src/constants';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { usePostDetail } from 'src/pages/EditPrognosis/hooks/usePostDetail';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { useGetAllWellbores } from 'hooks/useGetWellbores';
import { usePrognosisDetailsFromId } from 'hooks/usePrognosisDetailsFromId';

export const OptionalAttributes: FC = () => {
  const { optionalAttributes, setOptionalAttributes } = useOptionalAttributes();
  const { mutate: addPrognosisDetail } = usePostDetail();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();
  const { data } = usePrognosisDetailsFromId();
  const isMutating = useIsMutating();
  const { watch } = useFormContext<EditPrognosisFormValues>();
  const { showContent } = useFeatureToggling({
    featureKey: TOTAL_DEPTH_ROW_FEATURE_KEY,
  });
  const { data: wellboreData } = useGetAllWellbores();
  const { wellbore } = watch();

  const selectedWellboreData = wellboreData?.find(
    (item) => item.wellboreUuid === wellbore?.value
  );

  const onToggleSegments = () => {
    setOptionalAttributes((prev) => ({
      segment: !prev.segment,
      datum: prev.datum,
      totalDepthRow: prev.totalDepthRow,
    }));
  };

  const onToggleDatum = () => {
    setOptionalAttributes((prev) => ({
      segment: prev.segment,
      datum: !prev.datum,
      totalDepthRow: prev.totalDepthRow,
    }));
  };

  const onToggleTotalDepthRow = () => {
    if (isMutating) return;
    setOptionalAttributes((prev) => ({
      segment: prev.segment,
      datum: prev.datum,
      totalDepthRow: !prev.totalDepthRow,
    }));

    if (!optionalAttributes.totalDepthRow) {
      const totalDepthRowDetail: Partial<PrognosisDetailDto> = {
        reservoirZoneNickname: 'Total depth',
        lithostratSubzone: 'TEMPORARY deepest zone name', // TODO implement this when api request includes the deepest zone name
        reservoirZone: 'reservoirZone - TOTAL DEPTH',
        stratColumnIdentifier: 'stratColumnIdentifier - TOTAL DEPTH',
        referenceDepth: selectedWellboreData?.totalDepthDrillerMd ?? 0,
      };

      addPrognosisDetail({ detail: totalDepthRowDetail });
    } else {
      const detailToDelete = data?.find(
        (detail) => detail.reservoirZoneNickname === 'Total depth'
      );
      if (detailToDelete) {
        deletePrognosisDetail(detailToDelete?.id);
      }
    }
  };

  return (
    <BorderedSection title="Select the optional attributes">
      <AttributesWrapper>
        <Checkbox
          label="Segments"
          checked={optionalAttributes.segment}
          onChange={onToggleSegments}
          data-testid="segments-checkbox"
          outlined
        />
        <Checkbox
          label="Datum depth correction"
          checked={optionalAttributes.datum}
          onChange={onToggleDatum}
          data-testid="datum-checkbox"
          outlined
        />
        {showContent && (
          <Checkbox
            label="Total depth row"
            checked={optionalAttributes.totalDepthRow}
            onChange={onToggleTotalDepthRow}
            data-testid="totalDepthRow-checkbox"
            outlined
          />
        )}
      </AttributesWrapper>
    </BorderedSection>
  );
};
