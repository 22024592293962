import { ChangeEvent, FC, useEffect, useState } from 'react';

import { date, Label, usePrevious } from '@equinor/amplify-component-lib';

import {
  DisabledText,
  StyledDatePicker,
  StyledDateWrapper,
} from './CustomDatePicker.styles';
import { CUSTOM_DATE_PICKER_PATTERN } from 'src/constants';

const { formatDate } = date;

interface CustomDatePickerProps {
  handleOnBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  date: string | Date | undefined | null;
  label?: string;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
}

/**
 * @deprecated should probably swap to using EDS DatePicker
 */
export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  handleOnBlur,
  date: dateFromProps,
  label,
  minDate,
  maxDate,
  disabled,
}) => {
  const [date, setDate] = useState<Date>(new Date(dateFromProps ?? ''));
  const prevDateFromProps = usePrevious(dateFromProps);
  let min: Date | undefined = undefined;
  let max: Date | undefined = undefined;

  if (minDate) {
    min = new Date(minDate);
  }
  if (maxDate) {
    max = new Date(maxDate);
  }
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(new Date(event.target.value));
  };

  useEffect(() => {
    if (dateFromProps === prevDateFromProps) return;
    setDate(new Date(dateFromProps ?? ''));
  }, [dateFromProps, prevDateFromProps]);

  return (
    <StyledDateWrapper>
      <Label htmlFor="date-picker" label={label ?? ''} />
      {disabled && <DisabledText>Indefinite</DisabledText>}
      <StyledDatePicker
        type="date"
        value={formatDate(date, { format: 'YYYY-MM-DD' })}
        pattern={CUSTOM_DATE_PICKER_PATTERN}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        min={formatDate(min, { format: 'YYYY-MM-DD' })}
        max={formatDate(max, { format: 'YYYY-MM-DD' })}
        disabled={disabled}
      />
    </StyledDateWrapper>
  );
};
