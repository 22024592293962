/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Field } from '../models/Field';
import type { User } from '../models/User';
import type { UserAccess } from '../models/UserAccess';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
  /**
   * Get field access
   * @param fieldIdentifier
   * @returns string OK
   * @throws ApiError
   */
  public static getApiV1UserFieldAccess(
    fieldIdentifier: string
  ): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/User/FieldAccess/{fieldIdentifier}',
      path: {
        fieldIdentifier: fieldIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get user access
   * @returns UserAccess OK
   * @throws ApiError
   */
  public static getApiV1UserUserAccess(): CancelablePromise<Array<UserAccess>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/User/UserAccess',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get user fields
   * @returns Field OK
   * @throws ApiError
   */
  public static getApiV1UserUserFields(): CancelablePromise<Array<Field>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/User/UserFields',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get approvers by field
   * @param fieldIdentifier
   * @returns User OK
   * @throws ApiError
   */
  public static getApiV1UserApprovers(
    fieldIdentifier: string
  ): CancelablePromise<Array<User>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/User/Approvers/{fieldIdentifier}',
      path: {
        fieldIdentifier: fieldIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get user's full name
   * @param shortname
   * @returns string OK
   * @throws ApiError
   */
  public static getApiV1UserFullname(
    shortname: string
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/User/Fullname/{shortname}',
      path: {
        shortname: shortname,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get user's photo
   * @param shortname
   * @returns string OK
   * @throws ApiError
   */
  public static getApiV1UserPhoto(
    shortname: string
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/User/Photo/{shortname}',
      path: {
        shortname: shortname,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
