import { FC } from 'react';

import { Chip as AmplifyChip } from '@equinor/amplify-component-lib';

import { PressureType } from 'src/api';
import { PressureTypesValues } from 'src/constants';
import { WellborePurpose } from 'src/types';

import styled from 'styled-components';

interface ChipContentProps {
  $color: string;
  $backgroundColor: string;
  $borderColor?: string;
}

const Chip = styled(AmplifyChip)<ChipContentProps>`
  color: ${({ $color }) => $color} !important;
  background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
  border: ${({ $borderColor }) =>
    $borderColor ? `2px solid ${$borderColor} ` : 'none'} !important;
  outline: none;
`;

interface PressureTypeChipProps {
  type: PressureType | WellborePurpose;
}

export const PressureTypeChip: FC<PressureTypeChipProps> = ({ type }) => {
  return (
    <Chip
      $color={PressureTypesValues[type].color}
      $backgroundColor={PressureTypesValues[type].background}
      $borderColor={
        type === WellborePurpose.INJECTION
          ? PressureTypesValues[WellborePurpose.INJECTION].color
          : undefined
      }
    >
      {PressureTypesValues[type].label}
    </Chip>
  );
};
