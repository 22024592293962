import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { Table } from '@equinor/eds-core-react';

interface CellBodyProps {
  label: string | number;
}

export const CellBody: FC<CellBodyProps> = ({ label }) => {
  return (
    <Table.Cell>
      <Typography group="table" variant="cell_text">
        {label}
      </Typography>
    </Table.Cell>
  );
};
