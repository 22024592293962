import { Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const RowHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  border-bottom: 1px solid #dcdcdc;
`;

interface CellProps {
  $borderLeft?: boolean;
  $paddingRight?: boolean;
  $compact?: boolean;
}

export const Cell = styled.div<CellProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
  padding-left: 16px;
  height: ${({ $compact }) => ($compact ? '32px' : '48px')};
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  border-left: ${({ $borderLeft }) => ($borderLeft ? '1px solid #DCDCDC' : '')};
  padding-right: ${({ $paddingRight }) => ($paddingRight ? '1rem' : '')};
`;
export const TableHeader = () => {
  const Headers = [
    { label: 'Wellbore', unit: undefined, borderLeft: false },
    { label: 'Well type', unit: undefined, borderLeft: false },
    { label: 'Region', unit: undefined, borderLeft: false },
    { label: 'Formation', unit: undefined, borderLeft: false },
    { label: 'Gauge', unit: '(m)', borderLeft: true },
    { label: 'Screen/perf', unit: '(m)', borderLeft: false },
    { label: 'Gauge', unit: '(m)', borderLeft: true },
    { label: 'Screen/perf', unit: '(m)', borderLeft: false },
    { label: 'Datum', unit: '(m)', borderLeft: false },
    { label: 'Easting', unit: '(m)', borderLeft: true },
    { label: 'Northing', unit: '(m)', borderLeft: false },
    { label: 'Gradient', unit: '(bar/m)', borderLeft: false },
  ];

  return (
    <RowHeader>
      {Headers.map((item, index) => (
        <Cell
          key={index}
          $borderLeft={item.borderLeft}
          $paddingRight={
            index === Headers.length - 1 || Headers[index + 1].borderLeft
          }
        >
          <Typography group="table" variant="cell_header">
            {item.label}
          </Typography>
          {item.unit && (
            <Typography group="input" variant="label">
              {item.unit}
            </Typography>
          )}
        </Cell>
      ))}
    </RowHeader>
  );
};
