import { isBetweenDates } from '@equinor/amplify-component-lib';

import { RightAxisEnum } from '../types/editMode';
import { useLinePlot } from './useLinePlot';
import { DataType } from 'src/api';
import { HelpLine } from 'src/api';
import {
  LineChartData,
  LineChartLine,
} from 'src/components/Charts/LineChart/LineChart.types';
import { useMeasuredData } from 'src/hooks';
import { getHelpLineColor } from 'src/utils';

export const getRightAxisTypeFromHelpLineType = (helpline: HelpLine) => {
  if (
    helpline.type === DataType.CUMULATIVE_INJECTION ||
    helpline.type === DataType.CUMULATIVE_PRODUCTION
  ) {
    return RightAxisEnum.CUMULATIVE;
  }
  if (
    helpline.type === DataType.INSTANT_INJECTION ||
    helpline.type === DataType.INSTANT_PRODUCTION
  ) {
    return RightAxisEnum.INSTANT;
  }
  if (helpline.type === DataType.GRADIENT) {
    return RightAxisEnum.GRADIENT;
  }
  if (helpline.type === DataType.ON_STREAM_HOURS) {
    return RightAxisEnum.ON_STREAM_HOURS;
  }
  if (
    helpline.type === DataType.PRODUCTIVITY_INDEX_INTERPOLATED ||
    helpline.type === DataType.PRODUCTIVITY_INDEX
  ) {
    return RightAxisEnum.PRODUCTIVITY_INDEX;
  }
};

export const useLineChartHelpLines = (helplines: HelpLine[] | undefined) => {
  const { currentBrushRange } = useMeasuredData();
  const { selectedRightAxis } = useLinePlot();

  const helpLinesClone = structuredClone(helplines);
  if (!helpLinesClone) return { helpLineChartData: [] };

  const allHelplineData: LineChartLine[] = helpLinesClone
    .reverse()
    .map((helpline) => {
      return {
        color: getHelpLineColor(helpline),
        name: helpline.name,
        helplineDataType: helpline.type,
        rightAxisType: getRightAxisTypeFromHelpLineType(helpline),
        data: helpline.data
          ?.filter((data) =>
            isBetweenDates(new Date(data.prodDate ?? ''), currentBrushRange)
          )
          .map((d) => {
            return {
              value: d.value ?? 0,
              date: new Date(d.prodDate ?? ''),
            } as LineChartData;
          }),
      } as LineChartLine;
    })
    .filter((hl) => hl.data.length > 0);

  const relevantHelpLineChartData = allHelplineData.filter(
    (helpline) =>
      helpline.rightAxisType === undefined ||
      helpline.rightAxisType === selectedRightAxis
  );

  return { helpLineChartData: relevantHelpLineChartData };
};
