import { createContext, useContext, useState } from 'react';

import { Dialog } from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { useLinePlot } from '../../hooks/useLinePlot';
import { PleaseSelect } from './components/PleaseSelect';
import { SelectData } from './components/SelectData/SelectData';
import { SelectSource } from './components/SelectSource/SelectSource';
import { SelectWellbore } from './components/SelectWellbore/SelectWellbore';
import { Sources } from './ManageHelpLines.types';
import { DataType, MeasurementType, WellboreDetailed } from 'src/api';
import { MEASURED_DATA_HELPLINES_KEY } from 'src/constants';
import { HelpLineType } from 'src/types';

import styled from 'styled-components';

interface HelpLinesContextProps {
  selectedWellbore: WellboreDetailed | undefined;
  setSelectedWellbore: (wellbore: WellboreDetailed | undefined) => void;
  source: Sources | undefined;
  setSource: (source: Sources | undefined) => void;
  selectedHelpLines: HelpLineType[];
  addHelpLine: (helpLine: HelpLineType) => void;
  removeHelpLine: (helpLine: HelpLineType) => void;
  onClose: () => void;
}

export const HelpLinesContext = createContext<
  HelpLinesContextProps | undefined
>(undefined);

export const useHelplines = () => {
  const context = useContext(HelpLinesContext);
  if (context === undefined) {
    throw new Error(
      'useHelplines must be used within a HelpLinesContext.Provider'
    );
  }

  return context;
};

const StyledDialog = styled(Dialog)`
  min-width: 900px;
  width: 80vw;
  max-width: 1300px;
  height: auto;
`;

interface StyledBodyProps {
  $selected: boolean;
}

const StyledBody = styled.div<StyledBodyProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$selected ? '30% 30% 40%' : '30% 70%'};
  height: 400px;
`;

interface HelpLinesProps {
  onClose: () => void;
}

export const ManageHelpLines = ({ onClose }: HelpLinesProps) => {
  const {
    selectedHelpLines,
    setSelectedHelpLines,
    setSelectedHelpLinesForQuery,
  } = useLinePlot();
  const [selectedWellbore, setSelectedWellbore] = useState<WellboreDetailed>();
  const [source, setSource] = useState<Sources>();
  const queryClient = useQueryClient();

  const handleApplyOnClick = () => {
    // Reset the all helplines query cache. In the case you try to get the same data, but the cache for that data is removed from the result of the query already, so we want to force a refetch
    queryClient.resetQueries({
      predicate: (query) => {
        return query.queryKey.includes(MEASURED_DATA_HELPLINES_KEY);
      },
    });

    setSelectedHelpLinesForQuery(selectedHelpLines);
    onClose();
  };

  const addHelpLine = (helpLine: HelpLineType) => {
    const helpLineClone = structuredClone(helpLine);
    if (helpLineClone.dataTypes.at(0) === DataType.ON_STREAM_HOURS) {
      helpLineClone.measureType = MeasurementType.DHPG1;
    }
    setSelectedHelpLines((hl) => [...hl, helpLineClone]);
  };

  const removeHelpLine = (helpLine: HelpLineType) => {
    setSelectedHelpLines((line) =>
      line.filter(
        (hl) =>
          !(
            hl.id === helpLine.id &&
            hl.type === helpLine.type &&
            hl.dataTypes[0] === helpLine.dataTypes[0] &&
            hl.measureType === helpLine.measureType
          )
      )
    );
  };

  return (
    <HelpLinesContext.Provider
      value={{
        selectedWellbore,
        setSelectedWellbore,
        source,
        setSource,
        selectedHelpLines,
        addHelpLine,
        removeHelpLine,
        onClose: onClose,
      }}
    >
      <StyledDialog
        open
        title="Manage help lines"
        onClose={onClose}
        withBorders
        isDismissable
        actions={[
          { variant: 'ghost', text: 'Close', onClick: onClose },
          {
            variant: 'contained',
            text: 'Apply help lines',
            onClick: handleApplyOnClick,
          },
        ]}
      >
        <StyledBody $selected={selectedWellbore !== undefined}>
          <SelectWellbore />
          <SelectSource />
          <SelectData />
          <PleaseSelect />
        </StyledBody>
      </StyledDialog>
    </HelpLinesContext.Provider>
  );
};
