import { FC } from 'react';

import { Icon, Typography } from '@equinor/amplify-component-lib';
import { arrow_forward, placeholder_icon } from '@equinor/eds-icons';

import { MeasuredDataFilter } from 'src/api';
import { FilterItemWrapper } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItemWrapper';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface FilterItemAutoProps {
  filter: MeasuredDataFilter;
}

export const FilterItemAuto: FC<FilterItemAutoProps> = ({ filter }) => {
  const { setSelectedFilter } = useEditMeasuredData();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSelectedFilter(filter);
  };

  return (
    <FilterItemWrapper filter={filter} onClick={handleClick}>
      <Wrapper>
        <div>
          <Icon data={placeholder_icon} />
          <Typography>Auto interpolation</Typography>
        </div>
        <Icon data={arrow_forward} />
      </Wrapper>
    </FilterItemWrapper>
  );
};
