import { FC } from 'react';

import { Button, Icon, Tabs } from '@equinor/amplify-component-lib';
import { close, filter_list } from '@equinor/eds-icons';

import { TabRowContainer } from '../CommonTabStyles.styles';
import { useDashboard } from 'src/pages/Dashboard/hooks/useDashboard';

interface ApplicationTabsViewProps {
  usingTabList: string[];
  usingTabPanels: JSX.Element[];
  handleShowFilterSidebar: () => void;
  showFilterSidebar: boolean;
}

export const ApplicationTabsView: FC<ApplicationTabsViewProps> = ({
  usingTabList,
  usingTabPanels,
  handleShowFilterSidebar,
  showFilterSidebar,
}) => {
  const { activeTab, handleTabChange } = useDashboard();

  return (
    <Tabs activeTab={activeTab} onChange={handleTabChange}>
      <TabRowContainer>
        <Button onClick={handleShowFilterSidebar} variant="ghost">
          <Icon data={showFilterSidebar ? close : filter_list} />
          {showFilterSidebar ? 'Hide filters' : 'Show filters'}
        </Button>
        <Tabs.List>
          {usingTabList.map((item) => (
            <Tabs.Tab key={`tab-${item}`}>{item}</Tabs.Tab>
          ))}
        </Tabs.List>
      </TabRowContainer>
      <Tabs.Panels>
        {usingTabPanels.map((element, index) => (
          <Tabs.Panel key={`panel-${index}`}>{element}</Tabs.Panel>
        ))}
      </Tabs.Panels>
    </Tabs>
  );
};
