import { useContext } from 'react';

import {
  PressurePointsState,
  PressurePointsStateContext,
} from '../providers/PressurePointsProvider';

export const usePressurePoints = (): PressurePointsState => {
  const context = useContext(PressurePointsStateContext);
  if (context === undefined) {
    throw new Error('useWellsState must be used within a WellsStateProvider');
  }
  return context;
};
