import { FC } from 'react';

import {
  colors,
  formatDateTime,
  Typography,
} from '@equinor/amplify-component-lib';
import { format_quote } from '@equinor/eds-icons';

import {
  CommentContainer,
  Container,
  MessageColumn,
  StyledIcon,
} from './HistoryItem.styles';
import { PrognosisHistoryEntryDto } from 'src/api';

interface HistoryItemProps extends PrognosisHistoryEntryDto {
  index: number;
  isApproved: boolean;
}

export const HistoryItem: FC<HistoryItemProps> = ({
  index,
  message,
  comment,
  date,
  isApproved,
}) => (
  <Container data-testid={`history-item-${index}`}>
    <Typography group="table" variant="cell_numeric_monospaced">
      {formatDateTime(date)}
    </Typography>
    <MessageColumn>
      <Typography group="paragraph" variant="body_short_bold">
        {message}
      </Typography>
      {comment && !isApproved && (
        <CommentContainer>
          <Typography group="paragraph" variant="body_long">
            {comment}
          </Typography>
          <StyledIcon
            data={format_quote}
            color={colors.text.static_icons__tertiary.rgba}
          />
        </CommentContainer>
      )}
    </MessageColumn>
  </Container>
);
