import { MouseEvent, useState } from 'react';

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

interface sortStates {
  sortColumn?: string;
  sortDirection: SortDirection;
  handleSort: (event: MouseEvent<HTMLDivElement>) => void;
}

export const useSortDirection = (): sortStates => {
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.DESCENDING
  );

  const handleSort = () => {
    setSortDirection((prev) =>
      prev === SortDirection.ASCENDING
        ? SortDirection.DESCENDING
        : SortDirection.ASCENDING
    );
  };

  return {
    sortDirection: sortDirection,
    handleSort,
  };
};
