import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';

import { colors, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { elevation } = tokens;

const PaperMenu = styled.div`
  position: absolute;
  width: fit-content;
  padding: ${spacings.medium};
  background-color: ${colors.ui.background__default.rgba};
  border-radius: 4px;
  z-index: 100;
  box-shadow: ${elevation.raised};
`;

const Wrapper = styled.div`
  display: flex;
  width: 256px;
  flex-direction: column;
  gap: 24px;
`;

export interface CommonContextMenuProps {
  style: CSSProperties;
  open: boolean;
  onClose: () => void;
}

interface ContextMenuProps extends CommonContextMenuProps {
  children: ReactNode;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  children,
  style,
  open,
  onClose,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const previousOpen = useRef<boolean>(false);

  useEffect(() => {
    if (!open) {
      previousOpen.current = false;
      return;
    }

    const handler = (event: MouseEvent) => {
      const node = event.target as Node;
      if (
        wrapperRef.current !== null &&
        !wrapperRef.current.contains(node) &&
        open === previousOpen.current
      ) {
        onClose();
      } else {
        previousOpen.current = open;
      }
    };

    document.addEventListener('click', handler);

    return () => {
      if (open) {
        document.removeEventListener('click', handler);
        previousOpen.current = open;
      }
    };
  }, [open, onClose]);

  if (!open) return null;

  return (
    <PaperMenu style={style} ref={wrapperRef}>
      <Wrapper>{children}</Wrapper>
    </PaperMenu>
  );
};
