import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants/queryKeys';

export function usePrognosisDetailsFromId() {
  const { prognosisId } = useParams();

  const usingId = Number(prognosisId);

  return useQuery({
    queryKey: [PROGNOSIS_DETAIL, prognosisId],
    queryFn: () => PrognosisDetailService.getApiV1PrognosesDetails1(usingId),
    enabled: !Number.isNaN(usingId),
    retry: 2, // drafts can potentially not have prognosis details, so we want to limit the number of requests made
  });
}
