import { MouseEvent } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';

import { MeasuredDataFilter } from 'src/api';
import { FilterType } from 'src/api';
import {
  FilterSelect,
  SelectionOptions,
} from 'src/pages/EditMeasuredData/providers/EditMeasuredDataProvider';
import { roundTo } from 'src/utils';

export const selectingClickHandler = (
  e: MouseEvent<HTMLElement>,
  filter: MeasuredDataFilter,
  filters: MeasuredDataFilter[],
  selectedFilters: FilterSelect[]
) => {
  if (e.ctrlKey || e.metaKey) {
    return toggleMultiSelectingFilters(filter, selectedFilters);
  }

  if (e.shiftKey) {
    return applySelectedFiltersAcrossRange(filter, filters, selectedFilters);
  }

  return toggleSingleSelectingFilter(filter, selectedFilters);
};

export const toggleMultiSelectingFilters = (
  filter: MeasuredDataFilter,
  selectedFilters: FilterSelect[]
) => {
  const index = selectedFilters.findIndex((sc) => sc.id === filter.id);

  if (index >= 0) {
    return [
      ...selectedFilters.slice(0, index),
      ...selectedFilters.slice(index + 1),
    ];
  }

  return [
    ...selectedFilters,
    {
      ...filter,
      selectionType: SelectionOptions.CTRL,
    } as FilterSelect,
  ];
};

export const toggleSingleSelectingFilter = (
  filter: MeasuredDataFilter,
  selectedFilters: FilterSelect[]
) => {
  const index = selectedFilters.findIndex((sc) => sc.id === filter.id);

  if (index >= 0) {
    return [];
  }

  return [{ ...filter, selectionType: SelectionOptions.CLICK } as FilterSelect];
};

export const applySelectedFiltersAcrossRange = (
  filter: MeasuredDataFilter,
  filters: MeasuredDataFilter[],
  selectedFilters: FilterSelect[]
) => {
  if (selectedFilters.length === 0) {
    return toggleSingleSelectingFilter(filter, selectedFilters);
  }
  const { startIndex, endIndex } = getSelectedRangeEdges(
    filter,
    filters,
    selectedFilters
  );

  let newSelectedFilters = removePreviousRangeSelection(selectedFilters);

  for (let i = startIndex; i <= endIndex; i++) {
    newSelectedFilters = applyRangeSelectionChange(
      filters[i],
      filters,
      newSelectedFilters
    );
  }

  // This is to ensure that the anchor of the range selection is always at the end of the array
  newSelectedFilters = moveRangeAnchorToEnd(
    selectedFilters,
    newSelectedFilters
  );

  return newSelectedFilters;
};

export const getSelectedRangeEdges = (
  filter: MeasuredDataFilter,
  filters: MeasuredDataFilter[],
  selectedFilters: FilterSelect[]
) => {
  const lastSelectedFilter = selectedFilters[selectedFilters.length - 1];
  const indexA = filters.findIndex((sc) => sc.id === lastSelectedFilter.id);
  const indexB = filters.findIndex((sc) => sc.id === filter.id);

  const startIndex = Math.min(indexA, indexB);
  const endIndex = Math.max(indexA, indexB);

  return { startIndex: startIndex, endIndex: endIndex };
};

const removePreviousRangeSelection = (selectedFilters: FilterSelect[]) => {
  return selectedFilters.filter(
    (sc) => sc.selectionType !== SelectionOptions.SHIFT
  );
};

const applyRangeSelectionChange = (
  filter: MeasuredDataFilter,
  filters: MeasuredDataFilter[],
  newSelectedFilters: FilterSelect[]
) => {
  if (newSelectedFilters.some((nsf) => nsf.id === filter.id)) {
    return newSelectedFilters;
  }

  return [
    ...newSelectedFilters,
    {
      ...filter,
      selectionType: SelectionOptions.SHIFT,
    } as FilterSelect,
  ];
};

const moveRangeAnchorToEnd = (
  originalSelectedFilters: FilterSelect[],
  newSelectedFilters: FilterSelect[]
) => {
  const originalAnchorId = originalSelectedFilters.pop()?.id;

  const index = newSelectedFilters.findIndex(
    (sc) => sc.id === originalAnchorId
  );

  if (index === -1) {
    return originalSelectedFilters;
  }

  return [
    ...newSelectedFilters.slice(0, index),
    ...newSelectedFilters.slice(index + 1),
    newSelectedFilters[index],
  ];
};

export const getfilterTooltipTitle = (filter: MeasuredDataFilter) => {
  if (filter.type === FilterType.AUTO) {
    return 'Auto interpolation';
  } else if (filter.type === FilterType.RULE) {
    const fromString = formatDate(filter.rule?.fromDate, {
      format: 'DD.MM.YY',
    });
    const toString = formatDate(filter.rule?.toDate, {
      format: 'DD.MM.YY',
    });

    return `Rule: ${fromString} - ${toString}`;
  } else if (filter.changes && filter.changes?.length > 0) {
    const change = filter.changes[0];
    const date = formatDate(change.date, {
      format: 'DD.MM.YY',
    });
    const roundedOldValue = roundTo(change.from, 2);

    return `${date}: ${roundedOldValue} -> ${change.to}`;
  }
};
