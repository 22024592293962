import { FC, useCallback, useMemo, useState } from 'react';

import { WebMercatorViewport } from '@deck.gl/core';
import { Position } from '@turf/helpers';

import { MeasureAreaShape } from 'src/pages/MapView/components/Map/hooks/useDeckGlMapProps/useMeasureArea.types';
import { useMapInner } from 'src/pages/MapView/components/Map/hooks/useMapInner';
import { MapTool } from 'src/pages/MapView/components/MapSettings/MapTools';
import { Shape } from 'src/pages/MapView/components/MeasureArea/Shape';
import { ShapeMeasurements } from 'src/pages/MapView/components/MeasureArea/ShapeMeasurements';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { ShapeType } from 'src/utils/measureArea';

import styled from 'styled-components';

const MeasureAreaSvg = styled.svg`
  height: 100%;
  width: 100%;
  cursor: crosshair;
`;

export interface HighlightShape {
  type: ShapeType;
  shapeIndex: number;
}

interface MeasureAreaProps {
  shapes: MeasureAreaShape[];
}

export const MeasureArea: FC<MeasureAreaProps> = ({ shapes }) => {
  const { mapTool } = useMapOptions();
  const [highlightShape, setHighlightShape] = useState<
    HighlightShape | undefined
  >(undefined);

  const { viewport } = useMapInner();
  const typedViewport = viewport as WebMercatorViewport;

  const getPixelValues = useCallback(
    (coordinates: Position[]) => {
      const returnArray: number[][] = [];
      coordinates.forEach((coordinate) => {
        const [x, y] = typedViewport.project(coordinate);
        returnArray.push([x, y]);
      });
      return returnArray;
    },
    [typedViewport]
  );

  const allShapesPixels = useMemo(() => {
    return shapes
      .filter((shape) => shape.shape.length !== 0)
      .map((shape) => {
        return getPixelValues(shape.shape);
      });
  }, [shapes, getPixelValues]);

  if (mapTool !== MapTool.MEASURE_AREA) return null;
  return (
    <>
      <MeasureAreaSvg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        pointer-events="bounding-box"
      >
        {allShapesPixels.map((shapePixel, index) => {
          return (
            <Shape
              key={`${allShapesPixels.toString()}//${index}`}
              shapePixel={shapePixel}
              index={index}
              highlightShape={highlightShape}
              canComplete={
                shapes[index].canComplete && !shapes[index].isComplete
              }
            />
          );
        })}
      </MeasureAreaSvg>
      {shapes.length > 0 &&
        shapes.map((shape, index) => {
          return (
            <ShapeMeasurements
              key={`${shape.measurements.area}${shape.measurements.sides[0]}//${index}`}
              shape={shape}
              index={index}
              setHighlightShape={setHighlightShape}
              shapePixel={allShapesPixels[index]}
            />
          );
        })}
    </>
  );
};
