import { polygon } from '@turf/helpers';

export const TURF_INTERPOLATE_CELL_SIZE = 80;
export const TURF_INTERPOLATE_WEIGHT = 3;
export const WEIGHTS_TEXTURE_SIZE = 512;
export const HEATMAP_LAYER_RADIUS_PIXELS = 100;
export const TOP_LEFT_DATA_GRID_COORDS = [59.0012, 2.3407];
export const BOTTOM_RIGHT_DATA_GRID_COORDS = [58.6228, 2.9348];
export const LONGITUDE_RANGE = [1.9, 3.4];
export const LATITUDE_RANGE = [58.6, 59];
export const MAP_ZOOM_THRESHOLD_FOR_GROUPING_POINTS = 11.5;

// TODO: Remove this when the new color domain is set in stone
export const FABIO_BATLOW_W_HEATMAP_COLOR_DOMAIN: [number, number, number][] = [
  [255, 254, 254],
  [255, 225, 223],
  [247, 186, 171],
  [227, 169, 122],
  [195, 159, 73],
  [148, 143, 50],
  [105, 128, 64],
  [68, 115, 83],
  [37, 99, 96],
  [21, 79, 98],
  [14, 57, 95],
  [1, 25, 89],
] as const;

export const RODRIGO_HEATMAP_COLOR_DOMAIN: [number, number, number][] = [
  [255, 255, 255],
  [205, 255, 231],
  [190, 243, 218],
  [163, 226, 197],
  [139, 205, 174],
  [117, 184, 152],
  [92, 157, 126],
  [73, 131, 111],
  [37, 99, 96],
  [21, 79, 98],
  [14, 57, 95],
  [1, 26, 90],
];

export const BACKGROUND_IMAGE_OUTLINE_COORDS = [
  [2.3651027019345046, 58.92712885012805],
  [2.377552378720951, 58.86919460735003],
  [2.4528454670659086, 58.81866255151574],
  [2.4927465606841666, 58.746819832163254],
  [2.529936334710291, 58.68682314326495],
  [2.5736383041458537, 58.664122726924376],
  [2.6333870751054085, 58.644638096256635],
  [2.897469258872429, 58.77758101758083],
  [2.59980841378691, 58.93663151925673],
  [2.4958671651074087, 58.98984313645493],
  [2.3853415978363626, 58.93972361654929],
  [2.3651027019345046, 58.92712885012805],
];

export const BACKGROUND_IMAGE_OUTLINE_POLYGON = polygon([
  BACKGROUND_IMAGE_OUTLINE_COORDS,
]);

export const INITIAL_VIEW_STATE = {
  longitude: 2.555,
  latitude: 58.78,
  zoom: 10.5,
  pitch: 0,
  bearing: 0,
  maxZoom: 13.5,
  minZoom: 9,
  padding: { top: 0, right: 0, bottom: 0, left: 0 },
} as const;
