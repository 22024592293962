import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Icon, Typography } from '@equinor/amplify-component-lib';
import { add } from '@equinor/eds-icons';

import {
  AddButtonWrapper,
  TypographyWithLeftBorder,
} from '../ZoneSelector.styles';
import { PrognosisDetailDto, StratigraphicUnit } from 'src/api';
import { useGetLastUsedZones } from 'src/hooks';
import { usePostDetail } from 'src/pages/EditPrognosis/hooks/usePostDetail';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const LastUsedZones: FC = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore } = getValues();
  const { data: lastUsedZones } = useGetLastUsedZones();
  const { mutate: addPrognosisDetail } = usePostDetail();

  const handleButtonClick = (zone: StratigraphicUnit) => {
    const newDetail: Partial<PrognosisDetailDto> = {
      lithostratSubzone: zone.stratUnitIdentifier,
      colorHtml: zone.colorHtml,
      topAge: zone.topAge,
      stratColumnIdentifier: zone.stratColumnIdentifier ?? undefined,
    };

    addPrognosisDetail({ detail: newDetail });
  };

  return (
    <>
      <TypographyWithLeftBorder group="navigation" variant="button">
        Suggested
      </TypographyWithLeftBorder>
      {lastUsedZones ? (
        <AddButtonWrapper>
          {lastUsedZones?.map((zone: StratigraphicUnit, index: number) => {
            if (!zone.stratUnitIdentifier) return null;
            return (
              <Button
                key={index}
                onClick={() => handleButtonClick(zone)}
                data-testid={`add-last-used-zone-${index}`}
                variant="outlined"
              >
                <Icon data={add} />
                {zone.stratUnitIdentifier}
              </Button>
            );
          })}
        </AddButtonWrapper>
      ) : (
        <Typography>
          No previously used zones found for <b>{wellbore?.label ?? 'N/A'}</b>
        </Typography>
      )}
    </>
  );
};
