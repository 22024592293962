import { FC } from 'react';

import { Slider } from './Slider/Slider';
import {
  MapLegends,
  SliderAndButtons,
  SliderInner,
  Wrapper,
} from './Footer.styles';
import { PressureTypeChip } from 'src/components/PressureTypeChip/PressureTypeChip';
import { PRESSURE_TYPES_IN_ORDER } from 'src/constants';
import { DateRangeSelection } from 'src/pages/MapView/components/Footer/DateRangeSelection';
import { GuidelinesMenu } from 'src/pages/MapView/components/Footer/GuidelinesMenu';
import { WellborePurpose } from 'src/types';

export const Footer: FC = () => {
  return (
    <Wrapper>
      <MapLegends>
        {PRESSURE_TYPES_IN_ORDER.map((type) => {
          return <PressureTypeChip key={type} type={type} />;
        })}
        <PressureTypeChip type={WellborePurpose.PRODUCTION} />
        <PressureTypeChip type={WellborePurpose.INJECTION} />
      </MapLegends>
      <SliderAndButtons>
        <GuidelinesMenu />
        <SliderInner>
          <Slider />
        </SliderInner>
        <DateRangeSelection />
      </SliderAndButtons>
    </Wrapper>
  );
};
