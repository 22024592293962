import { spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

export const MainDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;
