import { FC, useEffect, useMemo, useState } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';

import { ONE_DAY_IN_MILLISECONDS } from 'src/constants';
import { useMeasuredData } from 'src/hooks';
import { getNumberOfDays } from 'src/pages/MapView/components/Footer/Footer.utils';
import { StyledSlider } from 'src/pages/MapView/components/Footer/Slider/Slider.styles';
import {
  getDayMarks,
  getFewMonthMarks,
  getManyMonthMarks,
  getYearMarks,
} from 'src/pages/MapView/components/Footer/Slider/Slider.utils';
import { usePressurePoints } from 'src/pages/MapView/hooks';
import { RangeEnum } from 'src/types';
import { getDateForDaysIntoPast } from 'src/utils';

const getMarks = (range: RangeEnum, fromDate: Date, toDate: Date) => {
  const daysInRange = getNumberOfDays(range, fromDate, toDate);

  const fromUnix = fromDate.getTime();
  const toUnix = toDate.getTime();

  if (daysInRange <= 31) {
    return getDayMarks(fromUnix, toUnix);
  }
  if (daysInRange > 31 && daysInRange <= 180) {
    return getFewMonthMarks(fromUnix, toUnix);
  }
  if (daysInRange > 180 && daysInRange <= 370) {
    return getManyMonthMarks(fromUnix, toUnix);
  }
  if (daysInRange > 370) {
    return getYearMarks(fromUnix, toUnix);
  }
};

export const Slider: FC = () => {
  const { selectedDate, setSelectedDate } = usePressurePoints();
  const { fromDate, toDate, range } = useMeasuredData();
  const [value, setValue] = useState<number>(
    selectedDate ? selectedDate.getTime() : getDateForDaysIntoPast(1).getTime()
  );

  // If the selected date is outside the range, set the selected date to the closest date in the range
  useEffect(() => {
    if (value < fromDate.getTime()) {
      setSelectedDate(fromDate);
      setValue(fromDate.getTime());
    } else if (value > toDate.getTime()) {
      setSelectedDate(toDate);
      setValue(toDate.getTime());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate, fromDate, range]);

  const marks = useMemo(
    () => getMarks(range, fromDate, toDate),
    [range, fromDate, toDate]
  );

  return (
    <StyledSlider
      value={value}
      min={fromDate.getTime()}
      marks={marks}
      max={toDate.getTime()}
      step={ONE_DAY_IN_MILLISECONDS}
      track={false}
      onChange={(_, newValue) => {
        if (typeof newValue === 'number' && newValue !== value) {
          setValue(newValue);
        }
      }}
      onChangeCommitted={(_, value) => {
        if (typeof value === 'number') {
          const newDate = new Date(new Date(value).setHours(0, 0, 0, 0));
          if (newDate.getTime() !== selectedDate?.getTime()) {
            setSelectedDate(newDate);
          }
        }
      }}
      valueLabelDisplay="on"
      valueLabelFormat={(value) =>
        formatDate(new Date(value), { format: 'DD.MM.YY' })
      }
      aria-labelledby="non-linear-slider"
    />
  );
};
