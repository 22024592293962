import { useAuth } from '@equinor/amplify-component-lib';

import { usePrognosisFromId } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { StatusIdEnum } from 'src/types/status';

export function useShouldShowApproverActions() {
  const { data: prognosisData } = usePrognosisFromId();
  const { userAccessForField } = useGetUserAccess();
  const { account } = useAuth();
  const approverShortName = account?.username.split('@').at(0) ?? '';

  const isAssignedApprover = prognosisData?.approveUser === approverShortName;
  const isApproverRole = userAccessForField?.userRoles?.approver;
  const isSubmitted = prognosisData?.statusId === StatusIdEnum.SUBMITTED;

  return isAssignedApprover && isApproverRole && isSubmitted;
}
