import { SelectOption } from '@equinor/amplify-component-lib/dist/molecules/Select/Select.types';

import { WellboreDetailed } from 'src/api';
import { Measurement } from 'src/types';

const getComboBoxChildren = (well: WellboreDetailed): Measurement[] => {
  return Array.from({ length: well.screenPerfCount ?? 0 }, (_, i) => i + 1).map(
    (dhpg) => {
      return {
        wellboreUuid: well.wellboreUuid,
        value: `${well.wellboreUuid}:${dhpg}`,
        label: `${well.wellName}:${dhpg}`,
        dhpg: dhpg,
      };
    }
  );
};
export const getComboBoxItem = (
  well: WellboreDetailed
): SelectOption<Measurement> => {
  return {
    wellboreUuid: well.wellboreUuid,
    value: `${well.wellboreUuid}:${well.screenPerfCount === 1 ? 1 : 0}`,
    label: well.wellName ?? '',
    children: well.screenPerfCount === 1 ? [] : getComboBoxChildren(well),
    dhpg: well.screenPerfCount === 1 ? 1 : 0,
  };
};
