/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WellPlanningPhase } from '../models/WellPlanningPhase';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WellPlanningPhaseService {
  /**
   * Get well planning phases
   * @returns WellPlanningPhase OK
   * @throws ApiError
   */
  public static getApiV1PrognosesWellPlanningPhases(): CancelablePromise<
    Array<WellPlanningPhase>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/WellPlanningPhases',
      errors: {
        500: `Internal Server Error`,
      },
    });
  }
}
