import { FC, MouseEvent, useCallback } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { ScaleBand, ScaleLinear } from '@visx/vendor/d3-scale';

import { ChartDataPoint } from 'components/Chart/Chart.types';
import { isNow } from 'components/Chart/Chart.utils';

import { motion } from 'framer-motion';

interface ElementsProps {
  dateScale: ScaleBand<Date>;
  dateScaleBandwidth: number;
  pressureScale: ScaleLinear<number, number, never>;
  data: ChartDataPoint[];
  onMouseMove: (value: ChartDataPoint, event: MouseEvent<Element>) => void;
  onMouseExit: () => void;
}

interface SvgCircleProps {
  cx: number | undefined;
  cy: number;
  date: Date;
  color: string;
}

const SvgCircle = ({ cx, cy, date, color }: SvgCircleProps) => (
  <motion.circle
    cx={cx}
    cy={cy}
    r={isNow(date) ? 8 : 6}
    fill={color}
    strokeWidth={2}
    pointerEvents="none"
    initial={{
      opacity: 0,
      r: 0,
    }}
    animate={{
      opacity: 1,
      r: isNow(date) ? 8 : 6,
    }}
    transition={{
      duration: 1,
    }}
  />
);

export const Elements: FC<ElementsProps> = ({
  dateScale,
  dateScaleBandwidth,
  pressureScale,
  data,
  onMouseExit,
  onMouseMove,
}) => {
  const handleOnMouseMove = useCallback(
    (event: MouseEvent<Element>, currentElement: ChartDataPoint) => {
      onMouseMove(currentElement, event);
    },
    [onMouseMove]
  );

  return (
    <Group left={dateScaleBandwidth / 2}>
      <LinePath
        data={data as unknown as ChartDataPoint[]}
        x={(d) => dateScale(d.date) ?? 0}
        y={(d) => pressureScale(d.expected)}
        stroke={colors.ui.background__scrim.rgba}
        strokeDasharray={'4'}
        strokeWidth={2}
        shapeRendering="geometricPrecision"
      />

      {data?.map((dataPoint: ChartDataPoint, index: number) => (
        <g
          key={`circle-${index}`}
          onMouseMove={(e) => handleOnMouseMove(e, dataPoint)}
          onMouseLeave={onMouseExit}
        >
          <SvgCircle
            cx={dateScale(dataPoint.date)}
            cy={pressureScale(dataPoint.high)}
            date={dataPoint.date}
            color={dataPoint.color!}
          />
          <SvgCircle
            cx={dateScale(dataPoint.date)}
            cy={pressureScale(dataPoint.low)}
            date={dataPoint.date}
            color={dataPoint.color!}
          />
          <motion.rect
            x={
              (dateScale(dataPoint.date) ?? 0) - (isNow(dataPoint.date) ? 8 : 2)
            }
            y={pressureScale(dataPoint.high)}
            width={isNow(dataPoint.date) ? 16 : 4}
            height={
              pressureScale(dataPoint.low) - pressureScale(dataPoint.high)
            }
            fill={dataPoint.color}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
          />
          <motion.circle
            cx={dateScale(dataPoint.date)}
            cy={pressureScale(dataPoint.expected)}
            r={6}
            fill={colors.ui.background__default.rgba}
            stroke={colors.ui.background__scrim.rgba}
            strokeOpacity={0.4}
            strokeWidth={4}
            initial={{
              opacity: 0,
              r: 0,
            }}
            animate={{
              opacity: 1,
              r: 6,
            }}
            transition={{
              duration: 1,
            }}
          />
          <motion.circle
            cx={dateScale(dataPoint.date)}
            cy={pressureScale(dataPoint.initial)}
            r={3}
            fill={colors.text.static_icons__default.rgba}
            initial={{
              opacity: 0,
              r: 0,
            }}
            animate={{
              opacity: 1,
              r: 3,
            }}
            transition={{
              duration: 1,
            }}
          />
        </g>
      ))}

      <LinePath
        data={data}
        // @ts-expect-error - data is not a ChartDataPoint[]
        x={(d) => dateScale(d.date)}
        y={(d) => pressureScale(d.initial)}
        stroke={colors.ui.background__scrim.rgba}
        shapeRendering="geometricPrecision"
      />
    </Group>
  );
};
