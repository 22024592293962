import { Button, colors, spacings } from '@equinor/amplify-component-lib';

import { MAP_FOOTER_HEIGHT } from 'src/constants';

import styled from 'styled-components';

export const MapLegends = styled.div`
  padding-bottom: ${spacings.small};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacings.small};
`;

export const Wrapper = styled.div`
  position: absolute;
  transform: translateX(-50%);
  height: ${MAP_FOOTER_HEIGHT};
  bottom: 0;
  left: 50%;
  z-index: 600;
  width: calc(
    100% + 1px
  ); // 1px here to avoid tiny gap between the footer and the sidebar
  background-color: ${colors.ui.background__default.rgba};
  padding: ${spacings.medium_small} ${spacings.large};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
`;

export const SliderAndButtons = styled.div`
  padding-top: ${spacings.medium_small};
  display: grid;
  grid-template-columns: 70px 1fr 70px;
  gap: ${spacings.x_large};
  align-items: center;
`;

export const StyledButton = styled(Button)`
  justify-self: center;
  width: 70px;
  height: 48px;
  transition: 0.1s;
  span {
    grid-gap: 0;
  }
`;

export const SliderInner = styled.div`
  width: 100%;
  > span {
    .MuiSlider-rail {
      padding: 0 8px;
      width: calc(100% + 16px);
      position: relative;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
