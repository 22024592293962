import { useContext } from 'react';

import { PressureDataStateContext } from 'src/pages/PlotView/providers/PressureDataProvider';

export const usePressureData = () => {
  const context = useContext(PressureDataStateContext);
  if (context === undefined) {
    throw new Error(
      'usePressureData must be used within a PressureDataContext'
    );
  }
  return context;
};
