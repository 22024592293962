/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FilterType {
  RULE = 'RULE',
  MANUAL = 'MANUAL',
  DRAG = 'DRAG',
  AUTO = 'AUTO',
}
