import { createContext, FC, ReactNode, useReducer } from 'react';

import { Field } from '@equinor/amplify-component-lib';

import { Unit } from 'src/types/prognosisDetails';
import { ViewPrognosesOptions } from 'src/utils/dashboard';

const localStorageKey = 'client-appstatecontext';

export type ThemeMode = 'light' | 'dark';

interface AppState {
  field: Field | undefined;
  themeMode: ThemeMode;
  unit: Unit;
  draftGroup: string;
  airGap: number | undefined;
}

interface AppContextState {
  field: Field | undefined;
  setField: (val: Field) => void;
  themeMode: ThemeMode;
  setThemeMode: (val: ThemeMode) => void;
  draftGroup: ViewPrognosesOptions;
  setDraftGroup: (val: ViewPrognosesOptions) => void;
  unit: Unit;
  setUnit: (val: Unit) => void;
  airGap: number | undefined;
  setAirGap: (val: number) => void;
}

const getDefaultState = (): AppState => {
  const localStorageData = localStorage.getItem(localStorageKey);

  if (localStorageData) {
    return JSON.parse(localStorageData) as AppState;
  }
  return {
    field: undefined,
    themeMode: 'light',
    unit: Unit.BAR,
    draftGroup: ViewPrognosesOptions.SUBMITTED_TO_ME,
    airGap: undefined,
  };
};

const updateLocalStorage = (state: AppState) => {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
};

export const AppContext = createContext<AppContextState | undefined>(undefined);

const appStateReducer = (state: AppState, newState: AppState) => {
  updateLocalStorage(newState);
  return newState;
};

interface AppStateContextProviderProps {
  children: ReactNode;
}

export const AppStateContextProvider: FC<AppStateContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appStateReducer, getDefaultState());

  const setField = (val: Field) => {
    dispatch({
      field: val,
      themeMode: state.themeMode,
      unit: state.unit,
      draftGroup: state.draftGroup,
      airGap: state.airGap,
    });
  };

  const setThemeMode = (val: ThemeMode) => {
    dispatch({
      field: state.field,
      themeMode: val,
      unit: state.unit,
      draftGroup: state.draftGroup,
      airGap: state.airGap,
    });
  };

  const setDraftGroup = (val: ViewPrognosesOptions) => {
    dispatch({
      field: state.field,
      themeMode: state.themeMode,
      unit: state.unit,
      draftGroup: val,
      airGap: state.airGap,
    });
  };

  const setUnit = (val: Unit) => {
    dispatch({
      field: state.field,
      themeMode: state.themeMode,
      unit: val,
      draftGroup: state.draftGroup,
      airGap: state.airGap,
    });
  };

  const setAirGap = (val: number) => {
    dispatch({
      field: state.field,
      themeMode: state.themeMode,
      unit: state.unit,
      draftGroup: state.draftGroup,
      airGap: val,
    });
  };

  return (
    <AppContext.Provider
      value={{
        field: state.field,
        setField,
        themeMode: state.themeMode ?? 'light',
        setThemeMode,
        unit: state.unit,
        setUnit,
        draftGroup:
          (state.draftGroup as ViewPrognosesOptions) ??
          ViewPrognosesOptions.SUBMITTED_TO_ME,
        setDraftGroup,
        airGap: state.airGap,
        setAirGap,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
