import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants/queryKeys';

export const usePrognosisFromId = () => {
  const { prognosisId } = useParams();

  const usingId = Number(prognosisId);

  return useQuery({
    queryKey: [PROGNOSIS_KEY, prognosisId],
    queryFn: () => PrognosisService.getApiV1Prognoses1(usingId),
    enabled: !Number.isNaN(usingId),
  });
};
