import { useMemo } from 'react';

import { colors, Typography } from '@equinor/amplify-component-lib';

import { useMeasuredData } from 'src/hooks';
import { Cell } from 'src/pages/PlotView/components/WellboreInformation/MeasurementList/TableHeader';
import { usePressure } from 'src/pages/PlotView/hooks/usePressure';
import { Measurement } from 'src/types';
import { roundTo } from 'src/utils';

import styled from 'styled-components';

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  border-bottom: 1px solid #dcdcdc;

  :hover {
    background: ${colors.interactive.table__cell__fill_hover.rgba};
  }
`;
export const TableBody = ({
  compact,
  measurement,
}: {
  compact: boolean;
  measurement: Measurement;
}) => {
  const { wellbores } = useMeasuredData();
  const { pressureData } = usePressure(measurement);

  const wellBore = useMemo(
    () =>
      wellbores.find((well) => well.wellboreUuid === measurement.wellboreUuid),
    [wellbores, measurement]
  );
  const gradient = useMemo(() => {
    const pressure = pressureData?.filter((item) => item?.gradient !== 0) ?? [];

    return (
      pressure.reduce((pre, curr) => {
        return (pre += curr?.gradient ?? 0);
      }, 0) / pressure.length
    );
  }, [pressureData]);

  const Values = [
    {
      value:
        wellBore?.screenPerfCount && wellBore?.screenPerfCount > 1
          ? `${measurement.label} : ${measurement.dhpg}`
          : measurement.label,
      borderLeft: false,
    },
    { value: wellBore?.purpose ?? '-', borderLeft: false },
    { value: wellBore?.block ?? '-', borderLeft: false },
    { value: wellBore?.formation ?? '-', borderLeft: false },
    { value: roundTo(wellBore?.gaugeMd) ?? '-', borderLeft: true },
    {
      value:
        roundTo(measurement.dhpg === 1 ? wellBore?.md1 : wellBore?.md2) ?? '-',
      borderLeft: false,
    },
    { value: roundTo(wellBore?.gaugeTvd) ?? '-', borderLeft: true },
    {
      value:
        roundTo(measurement.dhpg === 1 ? wellBore?.tvd1 : wellBore?.tvd2) ??
        '-',
      borderLeft: false,
    },
    { value: wellBore?.datumDepth ?? '-', borderLeft: false },
    {
      value:
        roundTo(
          measurement.dhpg === 1 ? wellBore?.utm1![1] : wellBore?.utm2![1]
        ) ?? '-',
      borderLeft: true,
    },
    {
      value:
        roundTo(
          measurement.dhpg === 1 ? wellBore?.utm1![0] : wellBore?.utm2![0]
        ) ?? '-',
      unit: '(m)',
      borderLeft: false,
    },
    { value: roundTo(gradient, 3), borderLeft: false },
  ];

  return (
    <Row>
      {Values.map((item, index) => (
        <Cell
          key={index}
          $borderLeft={item.borderLeft}
          $compact={compact}
          $paddingRight={
            index === Values.length - 1 || Values[index + 1].borderLeft
          }
        >
          <Typography group="table" variant="cell_text">
            {item.value}
          </Typography>
        </Cell>
      ))}
    </Row>
  );
};
