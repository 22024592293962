import { FC } from 'react';

import { Icon, spacings, Typography } from '@equinor/amplify-component-lib';
import { chevron_right, tune } from '@equinor/eds-icons';

import { MeasuredDataFilter } from 'src/api';
import { DisabledLabel } from 'src/pages/EditMeasuredData/components/FilterPanel/components/ChangeItem';
import { FilterItemWrapper } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItemWrapper';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { getDateRangeLabelWithIndefinite } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${spacings.small};
  width: 100%;
`;

const SectionAndDisabledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RuleName = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

const ChevronIcon = styled.div`
  margin-left: auto;
`;

interface FilterItemManualProps {
  filter: MeasuredDataFilter;
  selected?: boolean;
  dragging?: boolean;
}

export const FilterItemRule: FC<FilterItemManualProps> = ({
  dragging,
  filter,
  selected,
}) => {
  const { setSelectedFilter } = useEditMeasuredData();
  const { fromDate, toDate } = filter.rule ?? {};

  const handleClick = () => {
    setSelectedFilter(filter);
  };

  return (
    <FilterItemWrapper
      dragging={dragging}
      filter={filter}
      selected={selected}
      onClick={handleClick}
    >
      <Wrapper>
        <Icon data={tune} />
        <SectionAndDisabledWrapper>
          <Section>
            <RuleName>{filter.title}</RuleName>
          </Section>
          <Typography group="input" variant="label">
            {getDateRangeLabelWithIndefinite(fromDate ?? '', toDate ?? '')}
          </Typography>
          {filter.disabled && (
            <DisabledLabel group="paragraph" variant="body_short">
              Disabled
            </DisabledLabel>
          )}
        </SectionAndDisabledWrapper>
        <ChevronIcon>
          <Icon data={chevron_right} />
        </ChevronIcon>
      </Wrapper>
    </FilterItemWrapper>
  );
};
