export const getUsernameFromEmail = (email: string): string => {
  return email.split('@')[0];
};

interface CheckIfUserCanEditProps {
  creator: string;
  currentUser: string;
}

export const checkIfUserCanEdit = ({
  creator,
  currentUser,
}: CheckIfUserCanEditProps): boolean => {
  if (creator !== currentUser) {
    return false;
  }
  return true;
};
