import { FC, useMemo } from 'react';

import { Typography } from '@equinor/eds-core-react';

import { Gradient } from 'src/pages/MapView/components/Legend/Gradient';
import {
  Container,
  GradientWrapper,
  Header,
} from 'src/pages/MapView/components/Legend/Legend.styles';
import { generateTicks } from 'src/pages/MapView/components/Legend/Legend.utils';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground } from 'src/pages/MapView/MapView.types';

export const Legend: FC = () => {
  const { legendRange, backgroundLayer } = useMapOptions();

  const ticks = useMemo(() => {
    if (legendRange.min === legendRange.max) {
      return undefined;
    }
    return generateTicks(legendRange.min, legendRange.max);
  }, [legendRange.max, legendRange.min]);

  if (ticks === undefined || backgroundLayer !== MapBackground.INTERPOLATED)
    return null;

  return (
    <Container>
      <Header>
        <Typography group="ui" variant="accordion_header">
          Map Legend
        </Typography>
      </Header>
      <GradientWrapper>
        <Gradient ticks={ticks} />
      </GradientWrapper>
    </Container>
  );
};
