import { FC, useMemo } from 'react';

import {
  Button,
  colors,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { measure, well } from '@equinor/eds-icons';

import { MapTool } from '../MapSettings/MapTools';
import { useMapOptions } from 'src/pages/MapView/hooks';

import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 80px;
  left: ${spacings.medium};
  display: flex;
  align-items: center;
  gap: ${spacings.medium};
  padding: ${spacings.small};
  border-radius: 4px;
  background-color: ${colors.ui.background__default.rgba};
`;

export const ActiveMapTool: FC = () => {
  const { mapTool, setMapTool } = useMapOptions();

  const toolName = useMemo(() => {
    switch (mapTool) {
      case MapTool.MEASURE_AREA:
        return 'Measure area';
      case MapTool.SELECT_AREA:
        return 'Select several wells';
      default:
        return '';
    }
  }, [mapTool]);

  const toolIcon = useMemo(() => {
    switch (mapTool) {
      case MapTool.MEASURE_AREA:
        return measure;
      case MapTool.SELECT_AREA:
        return well;
    }
  }, [mapTool]);

  const handleStopOnClick = () => {
    setMapTool(MapTool.DEFAULT);
  };

  if (mapTool === MapTool.DEFAULT) return;
  return (
    <Container>
      {toolIcon && <Icon data={toolIcon} />}
      <Typography>{`${toolName}`}</Typography>
      <Button variant="outlined" onClick={handleStopOnClick}>
        Stop
      </Button>
    </Container>
  );
};
