import { colors } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

interface ButtonContainerProps {
  $isSelected?: boolean;
}
export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${({ $isSelected }) =>
    $isSelected
      ? colors.interactive.primary__selected_highlight.rgba
      : 'inherit'};
  border-bottom: 1px solid
    ${({ $isSelected }) =>
      $isSelected
        ? colors.interactive.primary__resting.rgba
        : colors.ui.background__medium.rgba};
`;

export const Header = styled.div`
  display: flex;
  grid-row: 1 / span 3;
  border-bottom: 2px solid ${colors.ui.background__medium.rgba};
`;
