import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';

import { PressureDataSimple } from 'src/api';
import { PressureTypeChip } from 'src/components/PressureTypeChip/PressureTypeChip';

interface TypeProps {
  measurement: PressureDataSimple;
}

export const Type: FC<TypeProps> = ({ measurement }) => {
  if (measurement.pressureType === undefined) {
    return null;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography group="paragraph" variant="overline">
        Pressure type
      </Typography>
      <PressureTypeChip type={measurement.pressureType} />
    </div>
  );
};
