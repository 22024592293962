import { spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const AttributesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacings.medium};
  padding-top: ${spacings.medium};
`;
