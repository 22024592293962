import { lineString, Position, Units } from '@turf/helpers';
import length from '@turf/length';

import { DragPoint, MeasureAreaShape } from './useMeasureArea.types';

import { WebMercatorViewport } from 'deck.gl';

export const areCoordsCloseInPixels = (
  coordinateA: Position,
  coordinateB: Position,
  viewport: WebMercatorViewport
) => {
  const [AX, AY] = viewport.project(coordinateA);
  const [BX, BY] = viewport.project(coordinateB);
  const threshold = 10;
  const latitudeAbs = Math.abs(AX - BX);
  const longitudeAbs = Math.abs(AY - BY);
  return latitudeAbs < threshold && longitudeAbs < threshold;
};

export const getPointsCloseToCoordinate = (
  coordinate: Position,
  shapes: MeasureAreaShape[],
  viewport: WebMercatorViewport
) => {
  const finishedShapes = [...shapes].slice(0, shapes.length - 1);

  const closePoints: DragPoint[] = [];
  finishedShapes.forEach((shape, shapeIndex) =>
    shape.shape.forEach((point, pointIndex) => {
      if (pointIndex === shape.shape.length - 1) return;
      if (areCoordsCloseInPixels(point, coordinate, viewport)) {
        closePoints.push({ shapeIndex, pointIndex });
      }
    })
  );
  return closePoints;
};

export const getNewGeoValue = (
  isFirstValue: boolean,
  shouldFinishShape: boolean,
  coordinates: Position,
  shapeOrigin: Position
) => {
  if (shouldFinishShape) {
    return [shapeOrigin];
  }
  if (isFirstValue) {
    return [coordinates, coordinates];
  }
  return [coordinates];
};

export const calcDistance = (polyline: Position[], units: Units): number => {
  if (polyline.length <= 1) return 0;

  const polylineLength = length(lineString(polyline.map((p) => [p[1], p[0]])), {
    units: units,
  });

  return Math.round((polylineLength + Number.EPSILON) * 100) / 100;
};

export const getSideMeasurements = (shape: Position[]) => {
  const measurementArray: number[] = [];
  shape.forEach((point, index) => {
    if (typeof shape[index + 1] === 'undefined') {
      return;
    }
    measurementArray.push(
      calcDistance([point, shape[index + 1]], 'kilometers')
    );
  });
  return measurementArray;
};

export const calcArea = (polyline: Position[]): number => {
  let area = 0.0;

  if (polyline.length < 3) return 0;

  const convertToRadian = (degree: number) => {
    return (degree * Math.PI) / 180;
  };

  const toKm2 = (m2: number) => {
    return m2 / 1000000;
  };

  const earthRadius = 6378137;

  for (let i = 0; i < polyline.length - 1; i++) {
    const p1: Position = polyline[i];
    const p2: Position = polyline[i + 1];
    area +=
      convertToRadian(p2[1] - p1[1]) *
      (2 + Math.sin(convertToRadian(p1[0])) + Math.sin(convertToRadian(p2[0])));
  }

  area = toKm2((Math.abs(area) * earthRadius * earthRadius) / 2);

  return Math.round((area + Number.EPSILON) * 100) / 100;
};
