import { colors, Icon, Typography } from '@equinor/amplify-component-lib';
import { well } from '@equinor/eds-icons';

interface HeaderProps {
  label: string;
  title: string;
}

export const Header = ({ label, title }: HeaderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Typography group="paragraph" variant="overline">
          {label}
        </Typography>
        <Typography group="heading" variant="h6">
          {title}
        </Typography>
      </div>
      <Icon
        size={32}
        data={well}
        color={colors.interactive.primary__resting.rgba}
      />
    </div>
  );
};
