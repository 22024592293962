import { PrognosisDetailDto } from 'src/api';

// create a function that sorts the array of objects by the top age
export const sortByTopAge = (arr: PrognosisDetailDto[]) => {
  // Check that elements of the array have a topAge value that is not null, if it'a null, set it to 9999
  // This is so that the sort function can handle it
  arr.forEach((element) => {
    if (!element.topAge) {
      element.topAge = 9999;
    }
  });

  return arr.sort((a, b) => {
    if (a.topAge && b.topAge) {
      return a.topAge - b.topAge;
    }
    return 0;
  });
};
