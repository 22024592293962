import { Position } from '@turf/helpers';

export interface DragPoint {
  shapeIndex: number;
  pointIndex: number;
}

export interface MeasureAreaShape {
  shape: Position[];
  measurements: {
    area: number;
    sides: number[];
  };
  canComplete?: boolean;
  isComplete?: boolean;
}

export enum Interact {
  CLICK = 'click',
  HOVER = 'hover',
  DRAGGING = 'dragging',
}
