import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';

import { HeaderDescription } from './HeaderDescription/HeaderDescription';
import { Container, ContainerDetails } from './ColumnHeader.styles';
import { PrognosisDetailDto } from 'src/api';
import { HeaderDescriptionType } from 'src/types/prognosisDetails';
import { prognosisDetailKeyToString } from 'src/utils/prognosis';

interface ColumnHeaderProps {
  columnSpan: number;
  header: string;
  headerDescriptions: HeaderDescriptionType[];
  prognosisDetailKeys: (keyof PrognosisDetailDto)[];
  isFirstColumn?: boolean;
}

export const ColumnHeader: FC<ColumnHeaderProps> = ({
  columnSpan,
  header,
  headerDescriptions,
  prognosisDetailKeys,
  isFirstColumn = false,
}) => {
  return (
    <>
      <Container $amount={columnSpan}>
        <Typography
          variant="body_short"
          group="paragraph"
          data-testid={`table-header-${header}`}
        >
          {header}
        </Typography>
      </Container>
      {headerDescriptions.map((headerDescription) => (
        <HeaderDescription
          key={headerDescription.content}
          {...headerDescription}
        />
      ))}
      {prognosisDetailKeys.map((key) => (
        <ContainerDetails key={key} $isFirstColumn={isFirstColumn}>
          <Typography key={key} group="table" variant="cell_header">
            {prognosisDetailKeyToString(key)}
          </Typography>
        </ContainerDetails>
      ))}
    </>
  );
};
