import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import { date } from '@equinor/amplify-component-lib';

import { OptionalAttributes } from '../providers/OptionalAttributeProvider';
import { useGetBlob } from './useGetBlob';
import {
  useGetAllWellbores,
  useGetApproverByField,
  useGetPrognosisTypes,
  usePrognosisDetailsFromId,
  usePrognosisFromId,
} from 'src/hooks';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

interface UseInitializeEditProgramArgs {
  setOptionalAttributes: Dispatch<SetStateAction<OptionalAttributes>>;
}

export function useInitializeEditProgram({
  setOptionalAttributes,
}: UseInitializeEditProgramArgs) {
  const { prognosisId } = useParams();
  const { data: operations } = useGetPrognosisTypes();
  const { data: approvers } = useGetApproverByField();
  const { data: wellbores } = useGetAllWellbores();
  const { data: existingPrognosis } = usePrognosisFromId();
  const { data: existingPrognosisDetails } = usePrognosisDetailsFromId();
  const [isInitializing, setIsInitializing] = useState(
    prognosisId !== undefined && prognosisId !== ''
  );

  const { insertImages } = useGetBlob();
  const { reset } = useFormContext<EditPrognosisFormValues>();

  useEffect(() => {
    const initializeForm = async () => {
      const segment: boolean =
        existingPrognosisDetails?.some((detail) => detail.segment) ?? false;

      const datum: boolean =
        existingPrognosisDetails?.some(
          (detail) =>
            detail.datumDepth ||
            detail.pressureDatumExpected ||
            detail.pressureDatumHigh ||
            detail.pressureDatumLow ||
            detail.pressureGradientToDatumDepth
        ) ?? false;

      const totalDepthRow = false; //TODO: Implement this

      setOptionalAttributes({
        segment,
        datum,
        totalDepthRow,
      });

      const selectedOperation = operations?.find(
        (item) => item.id === existingPrognosis?.operationId
      );

      const selectedApprover = approvers?.find(
        (item) => item.shortName === existingPrognosis?.approveUser
      );

      const selectedWellbore = wellbores?.find(
        (well) =>
          well.uniqueWellboreIdentifier ===
          existingPrognosis?.wellboreIdentifier
      );

      const commentWithImages = await insertImages(
        existingPrognosis?.comment ?? ''
      );

      const formValues: EditPrognosisFormValues = {
        title: existingPrognosis?.title ?? '',
        operation: selectedOperation
          ? {
              label: lookupValueToComboBoxRequired(selectedOperation.name, true)
                .label,
              value: selectedOperation.id?.toString() ?? '',
            }
          : { value: 'N/A', label: 'N/A' },
        wellPlanningPhase: {
          label: existingPrognosis?.wellPlanningPhase?.toString() ?? '',
          value: existingPrognosis?.wellPlanningPhaseId?.toString() ?? '',
        },
        approver: selectedApprover
          ? {
              value: selectedApprover.shortName,
              label: selectedApprover.displayName,
            }
          : { value: '', label: '' },
        validFrom:
          date.formatDate(new Date(existingPrognosis?.validFromDate ?? ''), {
            format: 'YYYY-MM-DD',
          }) ?? '',
        validTo:
          date.formatDate(new Date(existingPrognosis?.validToDate ?? ''), {
            format: 'YYYY-MM-DD',
          }) ?? '',
        wellbore: selectedWellbore
          ? {
              value: selectedWellbore.wellboreUuid ?? '',
              label: selectedWellbore.uniqueWellboreIdentifier ?? '',
            }
          : { value: '', label: '' },
        prognosisDetails: existingPrognosisDetails ?? [],
        comment: commentWithImages ?? '',
        stratColumnIdentifier: existingPrognosis?.stratColumnIdentifier ?? '',
      };
      reset(formValues, {
        keepErrors: true,
      });
      setIsInitializing(false);
    };

    if (
      existingPrognosis &&
      operations &&
      approvers &&
      wellbores &&
      isInitializing
    ) {
      initializeForm();
    }
    /**
     * Added this disable because adding insertImages to the dependency array causes an infinite loop
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approvers,
    existingPrognosis,
    existingPrognosisDetails,
    isInitializing,
    operations,
    reset,
    setOptionalAttributes,
    wellbores,
  ]);

  return { isInitializing };
}
