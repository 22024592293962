import { tokens } from '@equinor/eds-tokens';

const { colors } = tokens;

export const MARGIN = {
  TOP: 8,
  RIGHT: 0,
  BOTTOM: 24,
  LEFT: 44,
} as const;

export const Y_NUM_TICKS = 5;

export const TICK_LABEL_PROPS = {
  fontSize: 12,
  fontFamily: 'Equinor',
  color: colors.text.static_icons__tertiary.rgba,
} as const;

export const CIRCLE_RADIUS = 3;
export const STROKE_WIDTH = 2;
export const TRANSITION = {
  duration: 1,
};
