import { FC } from 'react';

import { colors, Typography } from '@equinor/amplify-component-lib';

import { LineStyleType } from 'src/types';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  justify-content: space-between;
`;

export const LineStyleLegend: FC<LineStyleType> = ({
  label,
  strokeDasharray,
  isMainLineLegend,
}) => {
  return (
    <Wrapper>
      <Typography group="ui" variant="chip__badge">
        {label}
      </Typography>
      {isMainLineLegend ? (
        <svg width="100%" viewBox="0 0 100 12">
          <path
            stroke={colors.text.static_icons__default.rgba}
            strokeWidth="6px"
            strokeDasharray={strokeDasharray}
            d="M6 6 l88 0"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg width="100%" viewBox="0 0 100 8">
          <path
            stroke={colors.text.static_icons__tertiary.rgba}
            strokeWidth="4px"
            strokeDasharray={strokeDasharray}
            d="M4 4 l92 0"
            strokeLinecap="round"
          />
        </svg>
      )}
    </Wrapper>
  );
};
