import { FC } from 'react';

import { colors, Icon, OptionalTooltip } from '@equinor/amplify-component-lib';
import { arrow_drop_right } from '@equinor/eds-icons';

import {
  ColorCircle,
  Container,
  MainRow,
  ReferenceContainer,
  Segment,
  StyledTypography,
  TextContainer,
  Zone,
} from './ZoneCell.styles';

interface ZoneCellProps {
  zoneName: string;
  segment?: string;
  nickName?: string;
  color: string;
  isEditing: boolean;
  onClick?: () => void;
}

export const ZoneCell: FC<ZoneCellProps> = ({
  zoneName,
  segment,
  nickName,
  color,
  onClick,
}) => {
  const hasSegment = segment != undefined;
  const hasNickName = nickName != undefined;

  return (
    <Container onClick={onClick}>
      <MainRow>
        <ColorCircle color={color} />
        <TextContainer>
          <OptionalTooltip
            title={hasNickName ? nickName : zoneName}
            placement="left"
          >
            <Zone
              variant="cell_header"
              data-testid="zone-name-cell"
              group="table"
              color={colors.interactive.primary__resting.rgba}
            >
              {hasNickName ? nickName : zoneName}
            </Zone>
          </OptionalTooltip>
          {hasSegment && (
            <OptionalTooltip title={segment} placement="left">
              <Segment
                variant="cell_text"
                group="table"
                color={colors.interactive.primary__resting.rgba}
              >
                {segment}
              </Segment>
            </OptionalTooltip>
          )}
        </TextContainer>
      </MainRow>
      {hasNickName && (
        <ReferenceContainer>
          <Icon
            data={arrow_drop_right}
            size={16}
            color={colors.text.static_icons__tertiary.rgba}
          />
          <StyledTypography
            variant="cell_header"
            group="table"
            color={colors.text.static_icons__tertiary.rgba}
          >
            {zoneName}
          </StyledTypography>
        </ReferenceContainer>
      )}
    </Container>
  );
};
