import { useContext } from 'react';

import { MapInnerContext } from 'src/pages/MapView/components/Map/hooks/useDeckGlMapProps/useDeckGlMapProps';

export const useMapInner = () => {
  const context = useContext(MapInnerContext);
  if (context.viewport === undefined) {
    throw new Error('useMapContext must be used within the  DeckGL component');
  }
  return context;
};
