/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrognosisBlobDto } from '../models/PrognosisBlobDto';
import type { UploadResult } from '../models/UploadResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BlobService {
  /**
   * Get access token for blob storage
   * @returns string OK
   * @throws ApiError
   */
  public static getApiV1BlobAccessToken(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Blob/AccessToken',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get blobs by prognosis Id
   * @param prognosisId
   * @returns PrognosisBlobDto OK
   * @throws ApiError
   */
  public static getApiV1Blob(
    prognosisId: number
  ): CancelablePromise<Array<PrognosisBlobDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Blob/{prognosisId}',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Upload a new blob
   * @param prognosisId
   * @param formData
   * @returns UploadResult OK
   * @throws ApiError
   */
  public static postApiV1Blob(
    prognosisId: number,
    formData?: {
      file: Blob;
    }
  ): CancelablePromise<UploadResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Blob/{prognosisId}',
      path: {
        prognosisId: prognosisId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Delete a blob
   * @param blobName
   * @returns boolean OK
   * @throws ApiError
   */
  public static deleteApiV1Blob(blobName: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Blob/{blobName}',
      path: {
        blobName: blobName,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Delete blobs by prognosis Id
   * @param prognosisId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteApiV1BlobByPrognosisId(
    prognosisId: number
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Blob/ByPrognosisId/{prognosisId}',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
