import { useMemo, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';

import {
  SelectOptionRequired,
  SingleSelect,
  Typography,
} from '@equinor/amplify-component-lib';

import { ConfirmDialog } from '../../ConfimDialog/ConfirmDialog';
import { ComboboxContainer } from '../FormData.styles';
import { Wellbore } from 'src/api';
import {
  useGetAllWellbores,
  useGetDrilledWellbores,
  useGetPlannedWellbores,
} from 'src/hooks';
import { Section } from 'src/pages/EditPrognosis/EditPrognosis.styles';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { usePutDraftPrognosis } from 'src/pages/EditPrognosis/hooks/usePutDraftPrognosis';
import { EditPrognosisFormValues, OperationValues } from 'src/utils';

interface NextValueProps {
  key: keyof EditPrognosisFormValues;
  field: ControllerRenderProps<EditPrognosisFormValues>;
  item: SelectOptionRequired | undefined;
}

export const SelectWellbore = () => {
  const [selectLabel, setSelectLabel] = useState('Wellbores');
  const {
    control,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext<EditPrognosisFormValues>();
  const [open, setOpen] = useState(false);
  const [nextValue, setNextValue] = useState<NextValueProps>();
  const { prognosisDetails } = watch();

  const { operation } = watch();

  const { data: allWellbores, isLoading: isLoadingWellbores } =
    useGetAllWellbores();
  const { data: plannedWellbores } = useGetPlannedWellbores();
  const { data: drilledWellbores } = useGetDrilledWellbores();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();

  const wellboreOptions: SelectOptionRequired[] = useMemo(() => {
    if (!operation) {
      return [
        {
          value: '',
          label: 'Select an operation first',
        },
      ];
    }

    if (operation.value.toString() === OperationValues.NEW_WELL.toString()) {
      setSelectLabel('Planned wellbores');
      return (
        plannedWellbores?.map((item: Wellbore) => ({
          value: item.wellboreUuid ?? '',
          label: item.uniqueWellboreIdentifier ?? 'N/A',
        })) ?? []
      );
    }

    if (
      operation.value.toString() === OperationValues.INTERVENTION.toString() ||
      operation.value.toString() === OperationValues.P_N_A.toString()
    ) {
      setSelectLabel('Drilled wellbores');
      return (
        drilledWellbores?.map((item: Wellbore) => ({
          value: item.wellboreUuid ?? '',
          label: item.uniqueWellboreIdentifier ?? 'N/A',
        })) ?? []
      );
    }

    setSelectLabel('Wellbores');
    return (
      allWellbores?.map((item: Wellbore) => ({
        value: item.wellboreUuid ?? '',
        label: item.uniqueWellboreIdentifier ?? 'N/A',
      })) ?? []
    );
  }, [allWellbores, plannedWellbores, drilledWellbores, operation]);

  const handleUpdateField = async ({ key, field, item }: NextValueProps) => {
    if (key == 'wellbore' && prognosisDetails?.length !== 0) {
      setOpen(true);
      setNextValue({ key, field, item });
      return;
    }

    field.onChange(item);
    await trigger(key);
    const isValid = !errors[key] || Object.keys(errors[key]).length === 0;
    if (!isValid) return;
    updateDraftPrognosis();
  };

  const handleConfirm = async () => {
    setOpen(false);

    if (!nextValue) return;

    prognosisDetails.map((detail) => {
      deletePrognosisDetail(detail.id);
    });

    const { key, field, item } = nextValue;
    field.onChange(item);
    await trigger(key);
    const isValid = !errors[key] || Object.keys(errors[key]).length === 0;
    if (!isValid) return;
    updateDraftPrognosis();
  };

  if (!operation) {
    return (
      <Section>
        <Typography variant="h4">Select an operation first</Typography>
      </Section>
    );
  }

  return (
    <Section>
      <Typography variant="h4">Select a wellbore to start</Typography>
      <ComboboxContainer>
        <Controller
          name="wellbore"
          control={control}
          rules={{ required: 'Wellbore is required' }}
          render={({ field }) => (
            <SingleSelect
              label={selectLabel}
              items={wellboreOptions}
              placeholder="Select one..."
              value={field.value}
              loading={isLoadingWellbores}
              onSelect={(item) => {
                handleUpdateField({
                  key: 'wellbore',
                  field,
                  item,
                });
              }}
              meta="Required"
              variant={errors.wellbore ? 'error' : undefined}
              clearable={false}
            />
          )}
        />
      </ComboboxContainer>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleConfirm()}
      />
    </Section>
  );
};
