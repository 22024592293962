import { ChangeEvent, FC } from 'react';

import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { DateRangeOptions } from './DateRangeOptions';
import { johanSverdrupStartDateUnix } from 'src/constants';
import { useMeasuredData } from 'src/hooks';
import { RangeEnum } from 'src/types';
import { getDateForDaysIntoPast } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 1rem;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

/**
 * @deprecated should probably swap to using EDS DatePicker / DateRangePicker
 */
export const DateRangeMenu: FC = () => {
  const { handleRangeChange, fromDate, setFromDate, toDate, setToDate } =
    useMeasuredData();

  const handleFromDateOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    if (date !== null && date.getTime() !== fromDate.getTime()) {
      setFromDate(
        new Date(
          new Date(date.setHours(0, 0, 0, 0)).getTime() -
            date.getTimezoneOffset() * 60000
        )
      );
      handleRangeChange(RangeEnum.CUSTOM);
    }
  };
  const handleToDateOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    if (date.getTime() !== toDate.getTime()) {
      setToDate(
        new Date(
          new Date(date.setHours(0, 0, 0, 0)).getTime() -
            date.getTimezoneOffset() * 60000
        )
      );
      handleRangeChange(RangeEnum.CUSTOM);
    }
  };

  return (
    <Wrapper>
      <DatePickerWrapper>
        <CustomDatePicker
          handleOnBlur={handleFromDateOnBlur}
          date={fromDate}
          label="From"
          // Set the min to the first year of the data we are using for johan sverdrup
          // TODO: This needs to be made dynamic based on field
          minDate={new Date(johanSverdrupStartDateUnix).toDateString()}
          maxDate={toDate.toDateString()}
        />
        <CustomDatePicker
          handleOnBlur={handleToDateOnBlur}
          date={toDate}
          label="To"
          minDate={fromDate.toDateString()}
          maxDate={getDateForDaysIntoPast(1).toDateString()}
        />
      </DatePickerWrapper>
      <DateRangeOptions />
    </Wrapper>
  );
};
