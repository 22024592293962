import { CSSProperties, forwardRef } from 'react';

import { colors } from '@equinor/amplify-component-lib';

import { WellborePurpose } from 'src/types';

import styled from 'styled-components';

const getMarkerFillColor = (purpose: WellborePurpose) => {
  if (purpose === WellborePurpose.PRODUCTION) {
    return colors.text.static_icons__default.rgba;
  }
  return colors.ui.background__default.rgba;
};

const getMarkerBorderColor = (selected: boolean) => {
  if (selected) {
    return colors.interactive.success__resting.rgba;
  }
  return colors.text.static_icons__default.rgba;
};

const getHoverFillColor = (selected: boolean) => {
  if (selected) {
    return colors.interactive.primary__hover_alt.rgba;
  }
  return colors.interactive.primary__hover.rgba;
};

interface CircleProps {
  $selected: boolean;
  $purpose: WellborePurpose;
}

const Circle = styled.div<CircleProps>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ $purpose }) => getMarkerFillColor($purpose)};
  border: ${({ $selected }) => `2px solid ${getMarkerBorderColor($selected)}`};
  position: absolute;
  z-index: 10;
  :hover {
    background-color: ${({ $selected }) => getHoverFillColor($selected)};
  }
`;

interface MarkerProps {
  style: CSSProperties;
  onClick: () => void;
  purpose: WellborePurpose;
  selected?: boolean;
}

export const Marker = forwardRef<HTMLDivElement, MarkerProps>(
  ({ style, onClick, selected = false, purpose }, ref) => {
    return (
      <Circle
        onClick={onClick}
        ref={ref}
        style={style}
        $selected={selected}
        $purpose={purpose}
      />
    );
  }
);

Marker.displayName = 'Marker';
