import { spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  padding: 0 ${spacings.small};
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  flex: 1;
`;

export const Segment = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  flex: 1;
`;

export const Zone = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  flex: 2;
`;

export const ReferenceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const ColorCircle = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 12px;
  background-color: ${({ color }) => color};
`;

export const MainRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.x_small};
`;
