import { useMutation } from '@tanstack/react-query';

import { MeasuredDataFilter, MeasuredDataFilterService } from 'src/api';
import { SAVE_MEASURED_DATA_FILTER } from 'src/constants';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useGetFiltersGlobal } from 'src/pages/EditMeasuredData/hooks/useGetFiltersGlobal';
import { useUpdateQueryData } from 'src/pages/EditMeasuredData/hooks/useUpdateQueryData';
import { getDefaultFilterTitle } from 'src/utils';

export const useSaveFilter = () => {
  const {
    updateFilterQueryData,
    updateGlobalFilterQueryData,
    handleOnSuccess,
    handleOnError,
  } = useUpdateQueryData();
  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { data: globalFilters = [] } = useGetFiltersGlobal();

  return useMutation({
    mutationKey: [SAVE_MEASURED_DATA_FILTER],
    mutationFn: (filter: MeasuredDataFilter) => {
      const filterExists = filtersForWell.some((f) => f.id === filter.id);

      const globalFilterExists = globalFilters.some((f) => f.id === filter.id);

      if (filtersForWell.length > 0) {
        filter.filterOrder =
          Math.max(
            ...[...filtersForWell, ...globalFilters].map(
              (filter) => filter.filterOrder ?? 0
            )
          ) + 1;
      }

      if (!filter.title || filter.title.length === 0) {
        filter.title = getDefaultFilterTitle(filter);
      }

      if (filterExists || globalFilterExists) {
        return MeasuredDataFilterService.updateFilter(filter);
      }

      return MeasuredDataFilterService.saveFilter(filter);
    },
    onSuccess: (data) => {
      if (data.wellId) {
        updateFilterQueryData(data, false);
      } else {
        updateGlobalFilterQueryData(data, false);
      }
      handleOnSuccess();
    },
    onError: handleOnError,
  });
};
