import { colors } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

interface ContainerProps {
  $columnSpan: number;
  $columnStart: number;
  $isEditing: boolean;
  $rowSpan: number;
}
export const Container = styled.div<ContainerProps>`
  background-color: ${({ $isEditing }) =>
    $isEditing ? 'none' : colors.ui.background__light.rgba};
  border-right: 1px solid ${colors.ui.background__medium.rgba};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
  display: grid;
  grid-column: ${({ $columnStart, $columnSpan }) =>
    `${$columnStart} / span ${$columnSpan}`};
  grid-row: ${({ $rowSpan }) => `1 / span ${$rowSpan}`};
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
`;
