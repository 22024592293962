import { spacings, Typography } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

interface ContainerProps {
  $background: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: ${spacings.x_small};
  align-items: center;
  border-radius: ${spacings.x_large};
  border: 1px solid ${colors.ui.background__medium.rgba};
  background: ${({ $background }) => $background};
  width: fit-content;
`;
export const StyledTypography = styled(Typography)`
  padding: 0px ${spacings.x_small};
  line-height: normal;
`;
