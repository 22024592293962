import { colors, Icon, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

const { shape } = tokens;

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / span 2;
  gap: ${spacings.medium};
  padding: ${spacings.small} 0;
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  align-items: center;
`;

export const MessageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${spacings.x_small};
`;

export const CommentContainer = styled.div`
  display: flex;
  background-color: ${colors.ui.background__light.rgba};
  border-radius: ${shape.corners.borderRadius};
  padding: ${spacings.x_small};
  gap: ${spacings.medium};
`;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
