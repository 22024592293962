/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Operation } from '../models/Operation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PrognosisOperationService {
  /**
   * Get prognosis operations
   * @returns Operation OK
   * @throws ApiError
   */
  public static getApiV1PrognosesOperations(): CancelablePromise<
    Array<Operation>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/Operations',
      errors: {
        500: `Internal Server Error`,
      },
    });
  }
}
