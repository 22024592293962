import { FC } from 'react';

import { Icon } from '@equinor/amplify-component-lib';

import { Container, StyledTypography } from './StatusChip.styles';
import { variantStyles } from './StatusChip.types';
import { PrognosisDto } from 'src/api';
import { StatusIdEnum } from 'src/types/status';

interface StatusChipPropsWithVariant {
  statusId: PrognosisDto['statusId'];
}

export const StatusChip: FC<StatusChipPropsWithVariant> = ({ statusId }) => {
  const variant: StatusIdEnum = statusId;
  const { background, label, iconData } = variantStyles[variant];

  return (
    <Container $background={background}>
      {iconData && <Icon data={iconData} size={16} />}
      <StyledTypography group="ui" variant="chip__badge">
        {label}
      </StyledTypography>
    </Container>
  );
};
