import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Icon } from '@equinor/amplify-component-lib';
import { edit } from '@equinor/eds-icons';

import { ActionColumnContainer } from '../PrognosisDetailsTable.styles';
import { ButtonContainer, Header } from './DetailsActionColumn.styles';
import { PrognosisDetailDto } from 'src/api';
import { DataInputDialog } from 'src/pages/EditPrognosis/components/FormData/DataInputDialog/DataInputDialog';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

interface DetailsActionColumnProps {
  columnStart: number;
  rowSpan: number;
}

export const DetailsActionColumn: FC<DetailsActionColumnProps> = ({
  columnStart,
  rowSpan,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const formPrognosisDetails = getValues('prognosisDetails');
  const [prognosisDetailSnapshot, setPrognosisDetailSnapshot] =
    useState<PrognosisDetailDto>();

  const [currentInputIndex, setCurrentInputIndex] = useState<number>(0);

  const { selectedTableRows } = useEditPrognosis();

  const handleOpen = (index: number) => {
    setCurrentInputIndex(index);
    const openDetails = formPrognosisDetails[index];
    const copyDetails = { ...openDetails };
    setPrognosisDetailSnapshot(copyDetails);

    setOpen(true);
  };

  return (
    <ActionColumnContainer
      $columnStart={columnStart}
      $columnSpan={1}
      $rowSpan={rowSpan}
    >
      <Header />
      {formPrognosisDetails.map((prognosisDetail, index) => {
        const isSelected = selectedTableRows?.includes(prognosisDetail.id);

        return (
          <ButtonContainer key={prognosisDetail.id} $isSelected={isSelected}>
            <Button
              name="editDetailsButton"
              data-testid={`editDetailsButton-${index}`}
              variant="ghost_icon"
              onClick={() => handleOpen(index)}
            >
              <Icon data={edit} />
            </Button>
          </ButtonContainer>
        );
      })}
      <DataInputDialog
        open={open}
        close={() => setOpen(false)}
        index={currentInputIndex}
        prognosisDetailSnapshot={prognosisDetailSnapshot}
      />
    </ActionColumnContainer>
  );
};
