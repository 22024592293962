import { FC } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { HeaderDescriptionType } from 'src/types/prognosisDetails';

import styled from 'styled-components';

const { colors } = tokens;

interface ContainerProps {
  $columnSpan: number;
}

const Container = styled.div<ContainerProps>`
  grid-column: ${({ $columnSpan }) => `span ${$columnSpan}`};
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  margin: 0 ${spacings.small};
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HeaderDescription: FC<HeaderDescriptionType> = ({
  content,
  columnSpan,
}) => (
  <Container $columnSpan={columnSpan}>
    <Typography group="ui" variant="tooltip">
      {content}
    </Typography>
  </Container>
);
