import { css } from 'styled-components';

export function tickJustify(gridColumn: number, tickAmount: number) {
  if (gridColumn === 1) return 'flex-start';
  if (gridColumn === tickAmount) return 'flex-end';
  return 'center';
}

export function labelPosition(gridColumn: number, tickAmount: number) {
  if (gridColumn === 1) {
    return css`
      left: 0;
    `;
  } else if (gridColumn === tickAmount) {
    return css`
      justify-self: flex-end;
    `;
  }
  return css`
    left: 50%;
    transform: translateX(-50%);
  `;
}
