import { FC } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale';

import { AxisLabel } from '../LineChart.styles';
import { LinePlotMargin } from '../LineChart.types';
import { formatDate } from '../LineChart.utils';
import { RightAxisEnum } from 'src/types';

interface LineChartAxesProps {
  MARGIN: LinePlotMargin;
  yMax: number;
  xMax: number;
  valueScale: ScaleLinear<number, number, never>;
  axisRightScale: ScaleLinear<number, number, never>;
  dateScale: ScaleTime<number, number, never>;
  bottomAxisNumTicks: number;
  selectedRightAxis?: RightAxisEnum;
  backgroundColor?: string;
}

export const LineChartAxes: FC<LineChartAxesProps> = ({
  MARGIN,
  yMax,
  xMax,
  valueScale,
  axisRightScale,
  dateScale,
  selectedRightAxis,
  backgroundColor,
  bottomAxisNumTicks,
}) => {
  return (
    <>
      {/*rects to cover lines going outside lineplot area*/}
      <rect
        y={0}
        x={MARGIN.left}
        width={xMax}
        height={MARGIN.top}
        fill={
          backgroundColor ? backgroundColor : colors.ui.background__light.rgba
        }
      />
      <rect
        y={yMax + MARGIN.top}
        x={MARGIN.left}
        width={xMax}
        height={MARGIN.bottom}
        fill={
          backgroundColor ? backgroundColor : colors.ui.background__light.rgba
        }
      />
      <AxisLabel
        x={-(yMax / 2)}
        y={MARGIN.left / 2}
        style={{ transform: 'rotate(-90deg)' }}
      >
        Bar
      </AxisLabel>
      <AxisLeft
        left={MARGIN.left}
        top={MARGIN.top}
        hideTicks
        scale={valueScale}
        tickLabelProps={{
          fontSize: 10,
          fontFamily: 'Equinor',
        }}
        stroke={colors.ui.background__medium.rgba}
      />
      {selectedRightAxis && (
        <>
          <AxisLabel
            y={-(xMax + MARGIN.left + MARGIN.right - 10)}
            x={yMax / 2}
            style={{ transform: 'rotate(90deg)' }}
          >
            {selectedRightAxis}
          </AxisLabel>
          <AxisRight
            scale={axisRightScale}
            left={xMax + MARGIN.left}
            top={MARGIN.top}
            hideTicks
            tickLabelProps={{
              fontSize: 10,
              fontFamily: 'Equinor',
            }}
            stroke={colors.ui.background__medium.rgba}
          />
        </>
      )}

      <AxisBottom
        top={yMax + MARGIN.top}
        left={MARGIN.left}
        hideTicks
        scale={dateScale}
        tickLabelProps={{
          fontSize: 10,
          fontFamily: 'Equinor',
        }}
        tickFormat={formatDate}
        stroke={colors.ui.background__medium.rgba}
        numTicks={bottomAxisNumTicks}
      />
    </>
  );
};
