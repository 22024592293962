import { FC } from 'react';
import { Path, useFormContext } from 'react-hook-form';

import {
  Icon,
  spacings,
  TextField,
  Typography,
} from '@equinor/amplify-component-lib';
import { error_filled } from '@equinor/eds-icons';

import { TextFieldContainer } from '../DataInputDialog.styles';
import { PrognosisDetailKeys } from '../DataInputDialog.utils';
import { PrognosisDetailDto } from 'src/api';
import { getPrognosisDetailError } from 'src/utils/error';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

interface TextFieldsProps {
  label: string;
  index: number;
  keys: PrognosisDetailKeys;
}

export const TextFields: FC<TextFieldsProps> = ({ label, index, keys }) => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();

  const handleError = (key: keyof PrognosisDetailDto) => {
    return getPrognosisDetailError(errors, index, key);
  };

  const handleOnChange = (fieldPath: Path<EditPrognosisFormValues>) => {
    trigger(fieldPath);
  };

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        {Object.entries(keys).map(([key, value]) => {
          const fieldPath =
            `prognosisDetails.${index}.${key}` as Path<EditPrognosisFormValues>;

          return (
            <TextField
              {...register(fieldPath, {
                onChange: () => handleOnChange(fieldPath),
              })}
              key={key}
              id={key}
              placeholder={value?.placeholder ?? ''}
              helperIcon={
                handleError(key as keyof PrognosisDetailDto) && (
                  <Icon data={error_filled} size={16} />
                )
              }
              meta="Required"
              label={value.label}
              data-testid={fieldPath}
              helperText={
                handleError(key as keyof PrognosisDetailDto)?.message ||
                value.helperText
              }
              variant={
                handleError(key as keyof PrognosisDetailDto)
                  ? 'error'
                  : undefined
              }
            />
          );
        })}
      </TextFieldContainer>
    </Container>
  );
};
