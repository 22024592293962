import { FC, useEffect, useMemo } from 'react';

import {
  SelectOptionRequired,
  SingleSelect,
} from '@equinor/amplify-component-lib';

import { RightAxisEnum } from '../../types/editMode';
import { HelpLine } from 'src/api';
import {
  getRightAxisArray,
  hasCumulativeData,
  hasGradient,
  hasInstantData,
  hasOnStreamHours,
  hasProductivityIndex,
  showRightAxisComboBox,
} from 'src/utils';

import styled from 'styled-components';

const ComboBoxWidth = styled.div`
  width: 180px;
`;

interface SelectRightAxisProps {
  helpLines: HelpLine[];
  selectedRightAxis: RightAxisEnum | undefined;
  setSelectedRightAxis: (value: RightAxisEnum | undefined) => void;
}

export const SelectRightAxis: FC<SelectRightAxisProps> = ({
  selectedRightAxis,
  setSelectedRightAxis,
  helpLines,
}) => {
  const handleOnSelect = (value: SelectOptionRequired | undefined) => {
    if (!value) return;
    setSelectedRightAxis(value.value as RightAxisEnum);
  };

  useEffect(() => {
    const allRightAxesFromHelplines = getRightAxisArray(helpLines);
    if (
      selectedRightAxis &&
      allRightAxesFromHelplines.some(
        (rightAxis) => rightAxis === selectedRightAxis
      )
    )
      return;

    setSelectedRightAxis(allRightAxesFromHelplines[0]);
  }, [helpLines, selectedRightAxis, setSelectedRightAxis]);

  const rightAxisArray: SelectOptionRequired[] = useMemo(() => {
    const rightAxisArray = [];
    hasInstantData(helpLines) &&
      rightAxisArray.push({
        value: RightAxisEnum.INSTANT,
        label: RightAxisEnum.INSTANT,
      });
    hasCumulativeData(helpLines) &&
      rightAxisArray.push({
        value: RightAxisEnum.CUMULATIVE,
        label: RightAxisEnum.CUMULATIVE,
      });
    hasGradient(helpLines) &&
      rightAxisArray.push({
        value: RightAxisEnum.GRADIENT,
        label: RightAxisEnum.GRADIENT,
      });
    hasOnStreamHours(helpLines) &&
      rightAxisArray.push({
        value: RightAxisEnum.ON_STREAM_HOURS,
        label: RightAxisEnum.ON_STREAM_HOURS,
      });
    hasProductivityIndex(helpLines) &&
      rightAxisArray.push({
        value: RightAxisEnum.PRODUCTIVITY_INDEX,
        label: RightAxisEnum.PRODUCTIVITY_INDEX,
      });
    return rightAxisArray;
  }, [helpLines]);

  const selectedRightAxisItem = useMemo(() => {
    return rightAxisArray.find(
      (item) => (item.value as RightAxisEnum) === selectedRightAxis
    );
  }, [rightAxisArray, selectedRightAxis]);

  if (showRightAxisComboBox(helpLines)) {
    return (
      <ComboBoxWidth>
        <SingleSelect
          items={rightAxisArray}
          value={selectedRightAxisItem}
          placeholder={'Select right Y-axis'}
          onSelect={handleOnSelect}
          clearable={false}
          lightBackground
        />
      </ComboBoxWidth>
    );
  }

  return null;
};
