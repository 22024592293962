import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

export const Container = styled.div`
  display: flex;
  padding: ${spacings.large};
  justify-content: center;
  flex: 1 0 0;
`;

export const Wrapper = styled.div`
  display: flex;
  max-width: calc(1600px - ${spacings.large} * 2);
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacings.large};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
`;

interface CellDividerProps {
  $amount: number;
}

export const CellDivider = styled.hr<CellDividerProps>`
  grid-column: ${({ $amount }) => `span ${$amount}`};
  height: 1px;
  background: ${colors.ui.background__medium.rgba};
`;
