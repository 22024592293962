import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Filter, FilterService } from 'src/api';
import { PATCH_FILTER_KEY } from 'src/constants/queryKeys';
import { useApp } from 'src/hooks';

export const usePatchFilterView = (
  filters: { id: string; value: string }[]
) => {
  const queryClient = useQueryClient();
  const { field } = useApp();

  return useMutation({
    mutationKey: [PATCH_FILTER_KEY],
    mutationFn: async () => {
      await Promise.all(
        filters.map((filter) =>
          FilterService.patchApiV1Filter(filter.id, filter.value)
        )
      );
    },
    onSuccess: () => {
      queryClient.setQueryData(['filter', field?.uuid], (data: Filter[]) => {
        return data.map((item) => {
          const filter = filters.find((filter) => filter.id === item.id);
          if (filter) {
            item.title = filter.value;
          }
          return item;
        });
      });
    },
  });
};
