import { Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const ColumnGroupHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const RowGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  height: 32px;
`;

const RowGroupHeaderWithBorderLeft = styled(RowGroupHeader)`
  border-left: 1px solid #dcdcdc;
`;

export const TableGroupHeader = () => {
  return (
    <ColumnGroupHeader>
      <RowGroupHeader style={{ gridColumnStart: 1, gridColumnEnd: 5 }}>
        <Typography group="heading" variant="h6">
          Wellbore information
        </Typography>
      </RowGroupHeader>
      <RowGroupHeaderWithBorderLeft
        style={{ gridColumnStart: 5, gridColumnEnd: 7 }}
      >
        <Typography group="paragraph" variant="overline">
          Measured depth (MD)
        </Typography>
      </RowGroupHeaderWithBorderLeft>
      <RowGroupHeaderWithBorderLeft
        style={{ gridColumnStart: 7, gridColumnEnd: 10 }}
      >
        <Typography group="paragraph" variant="overline">
          True vertical depth (TVD)
        </Typography>
      </RowGroupHeaderWithBorderLeft>
      <RowGroupHeader />
    </ColumnGroupHeader>
  );
};
