import { useContext } from 'react';

import {
  MapOptionsContext,
  MapOptionsState,
} from '../providers/MapOptionsProvider';

export const useMapOptions = (): MapOptionsState => {
  const context = useContext(MapOptionsContext);
  if (context === undefined) {
    throw new Error('useMapOptions must be used within a MapOptionsProvider');
  }
  return context;
};
