/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrognosisCreateDto } from '../models/PrognosisCreateDto';
import type { PrognosisDto } from '../models/PrognosisDto';
import type { PrognosisUpdateDto } from '../models/PrognosisUpdateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PrognosisService {
  /**
   * Get prognoses
   * @param wellboreIdentifier
   * @param fieldIdentifier
   * @param status
   * @param operation
   * @param wellPlanningPhase
   * @param title
   * @param comment
   * @param createUser
   * @param approveUser
   * @param updateDateFrom
   * @param updateDateTo
   * @param validDateFrom
   * @param validDateTo
   * @param sortColumn
   * @param sortDirection
   * @param page
   * @param pageSize
   * @returns PrognosisDto OK
   * @throws ApiError
   */
  public static getApiV1Prognoses(
    wellboreIdentifier?: string,
    fieldIdentifier?: string,
    status?: string,
    operation?: string,
    wellPlanningPhase?: string,
    title?: string,
    comment?: string,
    createUser?: string,
    approveUser?: string,
    updateDateFrom?: string,
    updateDateTo?: string,
    validDateFrom?: string,
    validDateTo?: string,
    sortColumn?: string,
    sortDirection?: string,
    page?: number,
    pageSize?: number
  ): CancelablePromise<Array<PrognosisDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses',
      query: {
        WellboreIdentifier: wellboreIdentifier,
        FieldIdentifier: fieldIdentifier,
        Status: status,
        Operation: operation,
        WellPlanningPhase: wellPlanningPhase,
        Title: title,
        Comment: comment,
        CreateUser: createUser,
        ApproveUser: approveUser,
        UpdateDateFrom: updateDateFrom,
        UpdateDateTo: updateDateTo,
        ValidDateFrom: validDateFrom,
        ValidDateTo: validDateTo,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Page: page,
        PageSize: pageSize,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Create a new prognosis
   * @param requestBody
   * @returns number Created
   * @throws ApiError
   */
  public static postApiV1Prognoses(
    requestBody?: PrognosisCreateDto
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Prognoses',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get prognosis by id
   * @param id
   * @returns PrognosisDto OK
   * @throws ApiError
   */
  public static getApiV1Prognoses1(
    id: number
  ): CancelablePromise<PrognosisDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Update prognosis
   * @param id
   * @param emailComment
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static putApiV1Prognoses(
    id: number,
    emailComment?: string,
    requestBody?: PrognosisUpdateDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/Prognoses/{id}',
      path: {
        id: id,
      },
      query: {
        emailComment: emailComment,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Delete prognosis
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteApiV1Prognoses(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Prognoses/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
