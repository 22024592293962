import { shape, spacings, Typography } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

interface ContainerProps {
  $isChanged?: boolean;
  $isSelected?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: ${spacings.small};
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid
    ${({ $isSelected }) =>
      $isSelected
        ? colors.interactive.primary__resting.rgba
        : colors.ui.background__medium.rgba};
  background: ${({ $isChanged, $isSelected }) =>
    $isSelected
      ? colors.interactive.primary__selected_highlight.rgba
      : $isChanged
        ? colors.ui.background__info.rgba
        : 'inherit'};
  max-width: 100%;
  min-width: 60px;
`;

interface TypographyContainerProps {
  $backgroundColor?: string;
}

export const TypographyContainer = styled.div<TypographyContainerProps>`
  display: flex;
  border-radius: ${shape.corners.borderRadius};
  padding: 0 ${spacings.x_small};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: right;
`;
