import { useState } from 'react';

import { spacings, Switch } from '@equinor/amplify-component-lib';

import { MeasurementList } from 'src/pages/PlotView/components/WellboreInformation/MeasurementList/MeasurementList';

import styled from 'styled-components';

const Container = styled.div`
  margin-top: ${spacings.large};
  h6 {
    margin: auto 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacings.medium};
  height: fit-content;
`;

const CompactSwitch = styled(Switch)`
  height: 40px;
`;

export const WellboreInformation = () => {
  const [compact, setCompact] = useState(true);
  const handleCompactOnClick = () => setCompact((c) => !c);

  return (
    <Container>
      <Header>
        <CompactSwitch
          label="Compact"
          checked={compact}
          size="default"
          onClick={handleCompactOnClick}
        />
      </Header>
      <MeasurementList compact={compact} />
    </Container>
  );
};
