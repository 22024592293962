import { Button, Icon, OptionalTooltip } from '@equinor/amplify-component-lib';
import { edit } from '@equinor/eds-icons';

import { AdvancedFilter } from 'src/pages/EditMeasuredData/components/AdvancedFilter/AdvancedFilter';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

export const EditFilterButton = () => {
  const { selectedFilter } = useEditMeasuredData();
  const noFilterSelected = selectedFilter === undefined;

  const { advancedFilterDialogOptions, setAdvancedFilterDialogOptions } =
    useEditMeasuredData();

  const handleEditClick = () => {
    setAdvancedFilterDialogOptions({
      open: true,
      initialFilters: selectedFilter ? [selectedFilter] : [],
      isUpdatingFilter: true,
    });
  };

  return (
    <>
      <OptionalTooltip
        title={noFilterSelected ? 'Please select a rule to edit' : ''}
      >
        <Button
          disabled={noFilterSelected}
          variant="ghost_icon"
          onClick={handleEditClick}
        >
          <Icon data={edit} />
        </Button>
      </OptionalTooltip>
      {advancedFilterDialogOptions.open && <AdvancedFilter />}
    </>
  );
};
