import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MeasuredDataFilter } from 'src/api';
import { FilterCondition } from 'src/api';
import { FilterDataTypes } from 'src/api';
import { FilterType } from 'src/api';
import { useMeasuredData } from 'src/hooks';
import { useApp } from 'src/hooks/useApp';
import { AdvancedFilterDialog } from 'src/pages/EditMeasuredData/components/AdvancedFilterDialog/AdvancedFilterDialog';
import { useDeleteFilter } from 'src/pages/EditMeasuredData/hooks/useDeleteFilter';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useSaveFilter } from 'src/pages/EditMeasuredData/hooks/useSaveFilter';
import { createEmptyRule } from 'src/pages/EditMeasuredData/utils/advancedFilters';
import { cleanFiltersOfTempId, createTemporaryId } from 'src/utils';

export const AdvancedFilter: FC = () => {
  const { data: filtersForWell = [] } = useGetFiltersForWell();

  const { mutate: saveFilter } = useSaveFilter();
  const { mutate: deleteFilter } = useDeleteFilter();

  const { field } = useApp();
  const { wellId, dhpg } = useParams();
  const { fromDate, toDate } = useMeasuredData();
  const { setSelectedFilter, advancedFilterDialogOptions } =
    useEditMeasuredData();
  const filtersTypeRule = filtersForWell.filter(
    (f) => f.type === FilterType.RULE
  );

  const [filters, setFilters] = useState<MeasuredDataFilter[]>(
    advancedFilterDialogOptions.initialFilters ?? filtersTypeRule
  );

  const handleAddNewRule = () => {
    setFilters([
      ...filters,
      createEmptyRule({
        field,
        wellId,
        dhpg,
        fromDate,
        toDate,
      }),
    ]);
  };

  const handleDeleteRule = (id: string | null | undefined) => {
    setFilters((prevFilters) => [
      ...prevFilters.filter((filter) => filter.id !== id),
    ]);
  };

  const handleAddNewCriteria = (id: string | null | undefined) => {
    const newFilter = filters.map((filter) => {
      if (filter.id === id) {
        filter.rule?.criteria?.push({
          criteriaId: createTemporaryId(),
          dataType: FilterDataTypes.PRESSURE,
          condition: FilterCondition.EQUAL,
          criteria: 0,
          filterCriteriaOrder: 0,
        });
      }
      return filter;
    });
    setFilters(newFilter);
  };

  const handleDeleteCriteria = (
    id: string | null | undefined,
    criteriaId: string | null | undefined
  ) => {
    const index = filters.findIndex((filter) => filter.id === id);

    setFilters((prevFilters) => [
      ...prevFilters.slice(0, index),
      {
        ...prevFilters[index],
        rule: {
          ...prevFilters[index].rule,
          criteria: [
            ...(prevFilters[index].rule?.criteria?.filter(
              (criteria) => criteria.criteriaId !== criteriaId
            ) ?? []),
          ],
        },
      },
      ...prevFilters.slice(index + 1),
    ]);
  };

  const handleUpdateFilter = (
    id: string,
    value: Partial<MeasuredDataFilter>
  ) => {
    setFilters((prevFilters) => {
      const filterToEdit = prevFilters.find((filter) => filter.id === id);
      if (!filterToEdit) return prevFilters;
      return [
        ...(prevFilters.filter((filter) => filter.id !== id) ?? []),
        {
          ...filterToEdit,
          ...value,
        },
      ];
    });
  };

  const handleSave = () => {
    const cleanFilters = cleanFiltersOfTempId(filters);

    cleanFilters.forEach((filter) => {
      saveFilter(filter);
    });
    setSelectedFilter(undefined);
  };

  const handleDelete = () => {
    filters.forEach((filter) => {
      filter.id && deleteFilter(filter.id);
    });
  };

  return (
    <AdvancedFilterDialog
      filters={filters}
      isUpdatingFilter={advancedFilterDialogOptions.isUpdatingFilter ?? false}
      onSave={handleSave}
      onDelete={handleDelete}
      onAddNewRule={handleAddNewRule}
      onDeleteRule={handleDeleteRule}
      onAddNewCriteria={handleAddNewCriteria}
      onDeleteCriteria={handleDeleteCriteria}
      updateFilter={handleUpdateFilter}
    />
  );
};
