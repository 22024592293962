import { round } from 'lodash';

export function generateTicks(min: number, max: number) {
  const difference = max - min;

  const amountOfTicks = difference < 6 ? difference : 6;
  const space = round(difference / amountOfTicks, 2);

  const ticks = [Math.floor(min)];

  while (ticks.length < amountOfTicks) {
    const newValue = round(ticks[ticks.length - 1] + space, 1);
    ticks.push(newValue);
  }
  ticks.push(round(max, 1));

  return ticks;
}
