import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

export const Container = styled.div`
  display: flex;
  padding: ${spacings.large};
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background-color: ${colors.ui.background__default.rgba};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
  position: sticky;
  bottom: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.medium};
  margin-left: auto;
`;
