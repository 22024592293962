import { useContext } from 'react';

import { LinePlotContext } from '../providers/LinePlotProvider';

export const useLinePlot = () => {
  const context = useContext(LinePlotContext);

  if (context === undefined) {
    throw new Error('useLinePlot must be used within LinePlotProvider');
  }

  return { ...context };
};
