import { FC, useMemo } from 'react';

import { ApplicationTabsView } from './ApplicationTabsView/ApplicationTabsView';
import { Tab } from './Tab/Tab';
import { TabType } from 'src/constants/tab';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';

interface ApplicationTabsProps {
  handleShowFilterSidebar: () => void;
  showFilterSidebar: boolean;
}

export const ApplicationTabs: FC<ApplicationTabsProps> = ({
  handleShowFilterSidebar,
  showFilterSidebar,
}) => {
  const { userAccessForField } = useGetUserAccess();

  const usingTabList = useMemo(() => {
    const list = [TabType.Approved, TabType.Historical];

    if (
      userAccessForField?.userRoles?.submitter ||
      userAccessForField?.userRoles?.approver
    ) {
      list.unshift(TabType.MyActionableItems);
    }
    return list;
  }, [userAccessForField]);

  const usingTabPanels = useMemo(() => {
    const list = [
      <Tab key={TabType.Approved} currentTab={TabType.Approved} />,
      <Tab key={TabType.Historical} currentTab={TabType.Historical} />,
    ];

    if (
      userAccessForField?.userRoles?.submitter ||
      userAccessForField?.userRoles?.approver
    ) {
      list.unshift(
        <Tab
          key={TabType.MyActionableItems}
          currentTab={TabType.MyActionableItems}
        />
      );
    }
    return list;
  }, [userAccessForField]);

  return (
    <ApplicationTabsView
      usingTabList={usingTabList}
      usingTabPanels={usingTabPanels}
      handleShowFilterSidebar={handleShowFilterSidebar}
      showFilterSidebar={showFilterSidebar}
    />
  );
};
