import { useCallback, useState } from 'react';

import { PickingInfo, WebMercatorViewport } from '@deck.gl/core';
import { Position } from '@turf/helpers';

import {
  areCoordsCloseInPixels,
  calcArea,
  getNewGeoValue,
  getPointsCloseToCoordinate,
  getSideMeasurements,
} from './useMeasureArea.utils';
import {
  DragPoint,
  Interact,
  MeasureAreaShape,
} from 'src/pages/MapView/components/Map/hooks/useDeckGlMapProps/useMeasureArea.types';

const INITIAL_SHAPE: MeasureAreaShape = {
  shape: [],
  measurements: {
    area: 0,
    sides: [],
  },
};

export function useMeasureArea() {
  const [measureAreaShapes, setMeasureAreaShapes] = useState<
    MeasureAreaShape[]
  >([INITIAL_SHAPE]);

  const updateCurrentShape = (
    interactionType: Interact,
    coordinate: Position,
    viewport: WebMercatorViewport
  ) => {
    const currentShape = measureAreaShapes[measureAreaShapes.length - 1];
    const isFirstValue = currentShape.shape.length === 0;
    if (isFirstValue && interactionType === Interact.HOVER) return;

    const shouldCompleteShape =
      currentShape.shape.length >= 4 &&
      areCoordsCloseInPixels(coordinate, currentShape.shape[0], viewport);

    if (
      !shouldCompleteShape &&
      getPointsCloseToCoordinate(coordinate, measureAreaShapes, viewport)
        .length !== 0 &&
      interactionType === Interact.CLICK
    )
      return;

    const newValue = getNewGeoValue(
      isFirstValue,
      shouldCompleteShape,
      coordinate,
      currentShape.shape[0]
    );

    const newGeoShape =
      interactionType === Interact.HOVER || shouldCompleteShape
        ? [
            ...currentShape.shape.slice(0, currentShape.shape.length - 1),
            ...newValue,
          ]
        : [...currentShape.shape, ...newValue];

    const newMeasureAreaShapes = [
      ...measureAreaShapes.slice(0, measureAreaShapes.length - 1),
      {
        shape: newGeoShape,
        measurements: {
          sides: getSideMeasurements(newGeoShape),
          area: shouldCompleteShape ? calcArea(newGeoShape) : 0,
        },
        canComplete: shouldCompleteShape,
        isComplete: shouldCompleteShape && interactionType === Interact.CLICK,
      },
    ];

    if (shouldCompleteShape && interactionType === Interact.CLICK) {
      newMeasureAreaShapes.push(INITIAL_SHAPE);
    }
    setMeasureAreaShapes(newMeasureAreaShapes);
  };

  const movePoint = (
    shapeIndex: number,
    pointIndex: number,
    newValue: Position
  ) => {
    setMeasureAreaShapes((prev) => {
      const isStartingPoint = pointIndex === 0;

      const newStartingPointShape = [
        newValue,
        ...prev[shapeIndex].shape.slice(
          pointIndex + 1,
          prev[shapeIndex].shape.length - 1
        ),
        newValue,
      ];

      const newShape = [
        ...prev[shapeIndex].shape.slice(0, pointIndex),
        newValue,
        ...prev[shapeIndex].shape.slice(
          pointIndex + 1,
          prev[shapeIndex].shape.length
        ),
      ];
      return [
        ...prev.slice(0, shapeIndex),
        {
          ...prev[shapeIndex],
          shape: isStartingPoint ? newStartingPointShape : newShape,
          measurements: {
            sides: getSideMeasurements(newShape),
            area: calcArea(newShape),
          },
        },
        ...prev.slice(shapeIndex + 1, prev.length),
      ];
    });
  };

  const updateMeasureAreaLayer = (
    interactionType: Interact,
    info: PickingInfo,
    dragPoint?: DragPoint
  ) => {
    const { coordinate, viewport } = info;
    if (!coordinate || !viewport) return;

    if (dragPoint !== undefined) {
      movePoint(dragPoint.shapeIndex, dragPoint.pointIndex, coordinate);
    }
    if (interactionType !== Interact.DRAGGING) {
      updateCurrentShape(
        interactionType,
        coordinate,
        viewport as WebMercatorViewport
      );
    }
  };

  const getDraggingPoint = (info: PickingInfo) => {
    const { coordinate, viewport } = info;
    if (!coordinate) return;
    const closePoints = getPointsCloseToCoordinate(
      coordinate,
      measureAreaShapes,
      viewport as WebMercatorViewport
    );

    if (closePoints.length > 1) return;
    if (closePoints.length === 1) {
      return closePoints[0];
    }

    return;
  };

  const resetMeasureAreaShapes = useCallback(() => {
    setMeasureAreaShapes([INITIAL_SHAPE]);
  }, [setMeasureAreaShapes]);

  return {
    measureAreaShapes,
    resetMeasureAreaShapes,
    updateMeasureAreaLayer,
    getDraggingPoint,
  };
}
