import { FC } from 'react';

import { Icon, Typography } from '@equinor/amplify-component-lib';
import { view_list } from '@equinor/eds-icons';

import { StyledButton } from '../FilterViewEditDialog.styles';
import { Filter } from 'src/api';

interface LoadViewProps {
  item: Filter;
  isSelected: boolean;
  handleSetSelected: (index: string) => void;
}

export const LoadView: FC<LoadViewProps> = ({
  item,
  isSelected,
  handleSetSelected,
}) => {
  const handleOnClick = () => {
    if (item.id != undefined) {
      handleSetSelected(item.id);
    } else {
      console.error('Item id is undefined');
    }
  };

  return (
    <StyledButton
      variant="ghost"
      $isSelected={isSelected}
      onClick={handleOnClick}
    >
      <Icon data={view_list} />
      <Typography variant="button" group="navigation">
        {item.title}
      </Typography>
    </StyledButton>
  );
};
