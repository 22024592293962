import { usePressureData } from './usePressureData';
import { PressureDataSimple } from 'src/api';
import { Measurement } from 'src/types';

export const usePressure = ({ wellboreUuid, dhpg }: Measurement) => {
  const { pressureData, errors } = usePressureData();

  const wellbore = pressureData.reduce((pre, curr) => {
    if (curr.length > 0 && curr[0].wellboreUuid === wellboreUuid) {
      pre.push(curr);
    }
    return pre;
  }, [] as PressureDataSimple[][]);

  const pressure = wellbore.find((well) =>
    well.find((val) => val.dhpg === dhpg)
  );

  return {
    pressureData: pressure,
    loading: pressure === undefined || pressure.length === 0,
    error: wellboreUuid && errors.includes(wellboreUuid),
  };
};
