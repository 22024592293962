import { FC } from 'react';

import { ParentSize } from '@visx/responsive';

import { LineChartScrim } from 'components/Charts/LineChart/Components/LineChartScrim';

import { HorizontalBrush } from './Components/HorizontalBrush/HorizontalBrush';
import { HorizontalBrushSkeleton } from './Skeletons/HorizontalBrushSkeleton';
import { LineChartSkeleton } from './Skeletons/LineChartSkeleton';
import { LineChartLine, LinePlotMargin } from './LineChart.types';
import { LineChartInner } from './LineChartInner';
import { PressureDataSimple } from 'src/api';
import { useLineChartData } from 'src/hooks';
import { RightAxisEnum } from 'src/types';

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

interface ParentSizeWrapperProps {
  $height: string;
}

const ParentSizeWrapper = styled.div<ParentSizeWrapperProps>`
  height: ${({ $height }) => $height};
`;

interface LineChartProps {
  wellbores: PressureDataSimple[][];
  lineChartHeight: string;
  backgroundColor?: string;
  helpLineChartData?: LineChartLine[];
  horizontalBrushHeight?: string;
  selectedRightAxis?: RightAxisEnum;
  showLoadingSkeleton?: boolean;
  showLoadingBlur?: boolean;
  isDragActive?: boolean;
}

export const LineChart: FC<LineChartProps> = ({
  wellbores,
  lineChartHeight,
  backgroundColor,
  horizontalBrushHeight,
  helpLineChartData,
  selectedRightAxis,
  showLoadingSkeleton,
  showLoadingBlur,
  isDragActive,
}) => {
  const {
    mainChartData,
    brushChartData,
    showFullChart,
    onBrushChange,
    setCurrentBrushRange,
  } = useLineChartData(wellbores);

  const MARGIN: LinePlotMargin = {
    top: 20,
    right: selectedRightAxis ? 80 : 24,
    bottom: 32,
    left: 100,
  };

  return (
    <Wrapper>
      <ParentSizeWrapper $height={lineChartHeight}>
        <ParentSize>
          {(parent) => {
            if (showLoadingSkeleton) {
              return (
                <LineChartSkeleton
                  height={parent.height}
                  width={parent.width}
                  margin={MARGIN}
                />
              );
            }

            return (
              <>
                {showLoadingBlur && <LineChartScrim />}
                <LineChartInner
                  backgroundColor={backgroundColor}
                  width={parent.width}
                  height={parent.height}
                  wellbores={mainChartData}
                  helplines={helpLineChartData}
                  margin={MARGIN}
                  selectedRightAxis={selectedRightAxis}
                  isDragActive={isDragActive}
                />
              </>
            );
          }}
        </ParentSize>
      </ParentSizeWrapper>
      {horizontalBrushHeight && (
        <ParentSizeWrapper $height={horizontalBrushHeight}>
          <ParentSize>
            {(parent) => {
              if (showLoadingSkeleton)
                return (
                  <HorizontalBrushSkeleton
                    margin={MARGIN}
                    height={parent.height}
                  />
                );
              return (
                <HorizontalBrush
                  width={parent.width}
                  height={parent.height}
                  wellbores={brushChartData}
                  onBrushChange={onBrushChange}
                  showFullChart={showFullChart}
                  setMainChartData={setCurrentBrushRange}
                  margin={MARGIN}
                />
              );
            }}
          </ParentSize>
        </ParentSizeWrapper>
      )}
    </Wrapper>
  );
};
