import { useState } from 'react';

import { Button, Icon } from '@equinor/amplify-component-lib';
import { keyboard } from '@equinor/eds-icons';

import { ManualDataEntry } from '../ManualDataEntry/ManualDataEntry';
import { useGetPressureDataWellboresFlat } from 'src/pages/EditMeasuredData/hooks/useGetPressureDataWellboresFlat';

export const ManualDataButton = () => {
  const [isManualDataEntryOpen, setIsManualDataEntryOpen] = useState(false);

  const { isLoading: pressureDataLoading } = useGetPressureDataWellboresFlat();

  const handleOnClick = () => {
    setIsManualDataEntryOpen(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOnClick}
        disabled={pressureDataLoading}
      >
        <Icon data={keyboard} />
        Manual data entry
      </Button>
      {isManualDataEntryOpen && (
        <ManualDataEntry onClose={() => setIsManualDataEntryOpen(false)} />
      )}
    </>
  );
};
