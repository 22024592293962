import { useEffect, useState } from 'react';

export const useSavingState = (isMutating: number) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingTimeout, setIsSavingTimeout] = useState(false);

  const timer: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (isMutating > 0) {
      setIsSaving(true);
      setIsSavingTimeout(true);
      setTimeout(() => {
        setIsSavingTimeout(false);
      }, 2000);
    }
  }, [isMutating]);

  useEffect(() => {
    if (isMutating === 0 && !isSavingTimeout) {
      setIsSaving(false);

      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [isMutating, isSavingTimeout]);

  return isSaving;
};
