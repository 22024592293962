import { useHelplines } from '../../ManageHelpLines';
import { FullWidthCheckBox } from './SelectData.styles';
import { DataType } from 'src/api';
import { MeasurementType } from 'src/api';
import { SourceTypes } from 'src/api';

export const Gradient = () => {
  const {
    selectedWellbore,
    selectedHelpLines,
    addHelpLine,
    removeHelpLine,
    source,
  } = useHelplines();

  const isChecked = (dataType: DataType) => {
    return selectedHelpLines.some(
      (hl) =>
        hl.id === selectedWellbore?.wellboreUuid &&
        hl.type === SourceTypes.WELLBORE &&
        hl.dataTypes?.includes(dataType)
    );
  };

  const handleClick = (dataType: DataType) => {
    if (isChecked(dataType)) {
      removeHelpLine({
        id: selectedWellbore?.wellboreUuid,
        type: SourceTypes.WELLBORE,
        dataTypes: [dataType],
        formations: [],
        source: source,
        measureType: MeasurementType.FLOW,
        name: selectedWellbore?.wellName ?? '',
      });
    } else {
      addHelpLine({
        id: selectedWellbore?.wellboreUuid,
        type: SourceTypes.WELLBORE,
        dataTypes: [dataType],
        formations: [],
        source: source,
        measureType: MeasurementType.FLOW,
        name: selectedWellbore?.wellName ?? '',
      });
    }
  };
  return (
    <FullWidthCheckBox
      label="Gradient"
      checked={isChecked(DataType.GRADIENT)}
      onClick={() => handleClick(DataType.GRADIENT)}
    />
  );
};
