import { Typography } from '@equinor/amplify-component-lib';

import { useHelplines } from '../ManageHelpLines';

import styled from 'styled-components';

const CenteredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PleaseSelect = () => {
  const { selectedWellbore, source } = useHelplines();

  if (selectedWellbore === undefined) {
    return (
      <CenteredText>
        <Typography>Please select a wellbore</Typography>
      </CenteredText>
    );
  }

  if (source === undefined) {
    return (
      <CenteredText>
        <Typography>Please select a data source</Typography>
      </CenteredText>
    );
  }

  return null;
};
