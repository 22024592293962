import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors, shape, elevation } = tokens;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${shape.corners.borderRadius};
  background: ${colors.ui.background__default.rgba};
  box-shadow: ${elevation.raised};
  padding: ${spacings.large};
  height: calc(100% - (${spacings.large} * 2));
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: inherit;
`;
