import { FC, useMemo } from 'react';

import { Button, spacings, useSideBar } from '@equinor/amplify-component-lib';

import { LinePlotDateRange } from 'components/LinePlotDateRange/LinePlotDateRange';

import { EditPlot } from './components/EditPlot/EditPlot';
import { SelectRightAxis } from './components/SelectRightAxis/SelectRightAxis';
import { ToolBar } from './components/ToolBar/ToolBar';
import { useLinePlot } from './hooks/useLinePlot';
import { LinePlotHeader } from 'src/components/LinePlotHeader/LinePlotHeader';
import {
  FILTER_PANEL_WIDTH,
  SIDE_BAR_WIDTH,
  SIDE_BAR_WIDTH_OPEN,
} from 'src/constants';
import { FilterPanel } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterPanel';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useGetFiltersGlobal } from 'src/pages/EditMeasuredData/hooks/useGetFiltersGlobal';
import { getNameFromMeasurement } from 'src/utils';

import styled from 'styled-components';

interface ContainerProps {
  $open: boolean;
  $sidebarOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div<ContainerProps>`
  margin: ${spacings.large};
  width: ${({ $open, $sidebarOpen }) => {
    const currentSidebarWidth = $sidebarOpen
      ? SIDE_BAR_WIDTH_OPEN
      : SIDE_BAR_WIDTH;
    const currentFilterPanelWidth = $open ? FILTER_PANEL_WIDTH : '0px';

    return `calc(100vw - ${currentSidebarWidth} - ${currentFilterPanelWidth} - ${spacings.large} * 2);`;
  }};
`;

export const EditMeasuredDataInner: FC = () => {
  const { isOpen } = useSideBar();

  const { filteredData, selectedRightAxis, setSelectedRightAxis, helpLines } =
    useLinePlot();
  const { filterPanelOpen, toggleFilterPanelOpen } = useEditMeasuredData();

  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { data: globalFilters = [] } = useGetFiltersGlobal();

  const totalActiveEdits = useMemo(() => {
    return filtersForWell.length + globalFilters.length;
  }, [filtersForWell.length, globalFilters.length]);

  return (
    <Container $open={filterPanelOpen} $sidebarOpen={isOpen}>
      <Wrapper $open={filterPanelOpen} $sidebarOpen={isOpen}>
        <LinePlotHeader
          title={
            filteredData !== undefined && filteredData.length !== 0
              ? getNameFromMeasurement(filteredData[0])
              : ''
          }
        >
          <LinePlotDateRange />
          <SelectRightAxis
            helpLines={helpLines}
            selectedRightAxis={selectedRightAxis}
            setSelectedRightAxis={setSelectedRightAxis}
          />
          <Button variant="ghost" onClick={toggleFilterPanelOpen}>
            {totalActiveEdits > 0
              ? `${totalActiveEdits} active edits`
              : 'Active edits'}
          </Button>
        </LinePlotHeader>

        <EditPlot
          pressureData={
            filteredData !== undefined && filteredData.length !== 0
              ? [filteredData]
              : []
          }
        />
        <ToolBar />
      </Wrapper>

      <FilterPanel />
    </Container>
  );
};
