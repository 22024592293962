import { FC } from 'react';

import { MeasuredDataFilter } from 'src/api';
import { ChangeItem } from 'src/pages/EditMeasuredData/components/FilterPanel/components/ChangeItem';
import { FilterItemWrapper } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItemWrapper';

interface FilterItemDragProps {
  filter: MeasuredDataFilter;
  selected?: boolean;
  dragging?: boolean;
}

export const FilterItemChange: FC<FilterItemDragProps> = ({
  dragging,
  filter,
  selected,
}) => {
  // filter.changes should always only contain a single change when the type of filter is manual/drag/auto. Only rules have multiple changes.
  const change = filter.changes?.[0];

  if (!change) return null;

  return (
    <FilterItemWrapper dragging={dragging} filter={filter} selected={selected}>
      <ChangeItem
        change={change}
        disabled={filter.disabled ?? false}
        type={filter.type}
      />
    </FilterItemWrapper>
  );
};
