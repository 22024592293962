import { colors, Typography } from '@equinor/amplify-component-lib';

import { FilterCriteria } from 'src/api';
import { getOperatorSymbol } from 'src/utils';

import styled from 'styled-components';

export const CriteriaItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  p {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export const CriteriaItem = ({
  criteria,
  number,
}: {
  criteria: FilterCriteria;
  number: number;
}) => {
  return (
    <CriteriaItemWrapper>
      <Typography>Criteria {number}</Typography>
      <div>
        <Typography>{`${criteria.dataType}  ${getOperatorSymbol(
          criteria.condition
        )} ${criteria.criteria}`}</Typography>
      </div>
    </CriteriaItemWrapper>
  );
};
