import isPointInPolygon from '@turf/boolean-point-in-polygon';
import {
  featureCollection,
  point as turfPoint,
  polygon,
  Position,
} from '@turf/helpers';
import interpolate from '@turf/interpolate';
import nearestPoint from '@turf/nearest-point';

import { PolygonFeature, PressureDataSimple } from 'src/api';
import {
  BACKGROUND_IMAGE_OUTLINE_POLYGON,
  BOTTOM_RIGHT_DATA_GRID_COORDS,
  TOP_LEFT_DATA_GRID_COORDS,
  TURF_INTERPOLATE_CELL_SIZE,
  TURF_INTERPOLATE_WEIGHT,
} from 'src/pages/MapView/MapView.constants';
import { DataSet } from 'src/pages/MapView/MapView.types';
import { isBetweenNumbers } from 'src/utils';

import { round } from 'lodash';

const CLOSE_PRECISION = 3;

export function getClosestDatapointValue(
  dataSet: DataSet | undefined,
  coordinate: number[] | undefined
): number | undefined {
  if (dataSet === undefined || coordinate === undefined) return undefined;

  const roundedCoordinates = [
    round(coordinate[0], CLOSE_PRECISION),
    round(coordinate[1], CLOSE_PRECISION),
  ];

  for (const point of dataSet) {
    const roundedPoint = [
      round(point[1], CLOSE_PRECISION),
      round(point[0], CLOSE_PRECISION),
    ];
    if (
      roundedPoint[0] === roundedCoordinates[0] &&
      roundedPoint[1] === roundedCoordinates[1]
    ) {
      return point[2];
    }
  }
}

export const isInSelectedArea = (
  [posLat, posLng]: number[],
  [startLng, startLat]: number[],
  [endLng, endLat]: number[]
) => {
  return (
    isBetweenNumbers(posLat, [startLat, endLat]) &&
    isBetweenNumbers(posLng, [startLng, endLng])
  );
};

export const getNearestPoint = (
  pressurePoint: PressureDataSimple,
  pressurePoints: PressureDataSimple[]
): Position => {
  const points = featureCollection(
    pressurePoints
      .filter((pp) => pp !== pressurePoint)
      .map((item) => {
        const [lat, lng] = item.measurementPosition?.coordinates ?? [0, 0];
        return turfPoint([lng, lat]);
      })
  );

  const [lat, lng] = pressurePoint.measurementPosition?.coordinates ?? [0, 0];
  const targetPoint = turfPoint([lng, lat]);

  const nearest = nearestPoint(targetPoint, points);

  return nearest.geometry.coordinates;
};

export const getDataGridCorners = (pressurePoints: PressureDataSimple[]) => {
  const values = pressurePoints.map((point) => point.pressureFinal);

  const pressurePointsMedian =
    Math.min(...values) + (Math.max(...values) - Math.min(...values)) / 2;

  return [
    turfPoint(TOP_LEFT_DATA_GRID_COORDS, {
      value: pressurePointsMedian,
    }),
    turfPoint(BOTTOM_RIGHT_DATA_GRID_COORDS, {
      value: pressurePointsMedian,
    }),
  ];
};

export const getDataGridInPolygon = (
  pressurePoints: PressureDataSimple[] | undefined,
  mainFieldOutline?: PolygonFeature
) => {
  if (!pressurePoints) return { data: [], min: 0, max: 1 };

  const filteredPressurePoints = pressurePoints?.filter(
    (point) => point.pressureFinal > 0
  );

  const pressurePointsFeatureCollection = featureCollection([
    ...getDataGridCorners(filteredPressurePoints),
    ...filteredPressurePoints.map((point) => {
      return turfPoint(point.measurementPosition?.coordinates ?? [], {
        value: point.pressureFinal,
      });
    }),
  ]);

  const interpolatedValues = interpolate(
    pressurePointsFeatureCollection,
    TURF_INTERPOLATE_CELL_SIZE,
    {
      gridType: 'point',
      property: 'value',
      units: 'meters',
      weight: TURF_INTERPOLATE_WEIGHT,
    }
  );

  let min = Infinity;
  let max = -Infinity;
  const pointsInsidePolygon: DataSet = [];

  for (const point of interpolatedValues.features) {
    if (
      isPointInPolygon(
        [point.geometry.coordinates[1], point.geometry.coordinates[0]],
        mainFieldOutline
          ? polygon(mainFieldOutline.geometry?.coordinates ?? [])
          : BACKGROUND_IMAGE_OUTLINE_POLYGON
      )
    ) {
      const value = point.properties?.value as number;
      pointsInsidePolygon.push([
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
        value,
      ]);
      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
    }
  }

  return { data: pointsInsidePolygon, min, max };
};
