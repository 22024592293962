import { FC, useRef } from 'react';

import { Typography } from '@equinor/amplify-component-lib';

import { MoreOptionsMenu } from 'components/MoreOptionsMenu/MoreOptionsMenu';

import { useGetPrognosisHistory } from '../../hooks/useGetPrognosisHistory';
import { HistoryItem } from './HistoryItem/HistoryItem';
import { HistoryItemSkeleton } from './HistoryItem/HistoryItemSkeleton';
import { Container, Header, ItemWrapper } from './HistoryCard.styles';

interface HistoryCardProps {
  isApproved: boolean;
}
export const HistoryCard: FC<HistoryCardProps> = ({ isApproved }) => {
  const { data: prognosisHistory, isLoading } = useGetPrognosisHistory();
  const cardRef = useRef<HTMLDivElement | null>(null);

  return (
    <Container ref={cardRef}>
      <Header>
        <Typography variant="h4">History</Typography>
        <MoreOptionsMenu cardElement={cardRef.current} />
      </Header>
      <ItemWrapper>
        {isLoading &&
          new Array(2)
            .fill(0)
            .map((skeleton, index) => (
              <HistoryItemSkeleton
                key={`history-skeleton-${skeleton + index}`}
                offset={index}
              />
            ))}
        {!isLoading &&
          prognosisHistory?.map((history, index) => (
            <HistoryItem
              key={`history-${history.message}-${index}`}
              index={index}
              isApproved={isApproved}
              message={history.message}
              comment={history.comment}
              date={history.date}
              data-testid={`history-item-${index}`}
            />
          ))}
        {!isLoading && prognosisHistory?.length === 0 && (
          <Typography data-testid="no-history">No history available</Typography>
        )}
      </ItemWrapper>
    </Container>
  );
};
