import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { MeasuredDataFilterService } from 'src/api';
import { MEASURED_DATA_FILTERS_KEY } from 'src/constants';

export const useGetFiltersForWell = () => {
  const { wellId, dhpg } = useParams();
  return useQuery({
    queryKey: [MEASURED_DATA_FILTERS_KEY, wellId, dhpg],
    queryFn: () =>
      MeasuredDataFilterService.getFilters(wellId, parseInt(dhpg ?? '')),
  });
};