import { FC, useState } from 'react';

import {
  Button,
  colors,
  Dialog,
  spacings,
  Switch,
  TextField,
  Typography,
} from '@equinor/amplify-component-lib';

import { Unit } from 'src/types/prognosisDetails';

import { useApp } from 'hooks/useApp';
import { styled } from 'styled-components';
import { z } from 'zod';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: ${spacings.small};
  padding: 0px ${spacings.medium};
  align-self: flex-end;
  position: relative;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${spacings.medium};
  justify-content: flex-end;
`;

const airGapSchema = z
  .number()
  .min(0, { message: 'Air gap must be a positive number' });

export const UnitToggle: FC = () => {
  const { unit, setUnit, airGap, setAirGap } = useApp();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSetAirGapToLocalStorage = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (airGap !== undefined) {
      const result = airGapSchema.safeParse(airGap);
      if (result.success) {
        setAirGap(airGap);
        setUnit(Unit.SG);
        setOpenDialog(false);
        setError(null);
      } else {
        setError(result.error.errors[0].message);
      }
    }
  };

  const handleUnitToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setOpenDialog(true);
      setUnit(Unit.SG);
    } else {
      setUnit(Unit.BAR);
    }
  };

  const setActiveColor = (activeUnit: Unit) => {
    if (unit === activeUnit) {
      return colors.interactive.primary__resting.hex;
    }
    return colors.text.static_icons__tertiary.hex;
  };

  return (
    <Container>
      <Typography color={setActiveColor(Unit.BAR)} bold>
        Bar
      </Typography>
      <Switch
        label=""
        onChange={handleUnitToggle}
        value={unit}
        checked={unit === Unit.SG}
      />
      <Typography bold color={setActiveColor(Unit.SG)}>
        SG
      </Typography>
      <Dialog
        open={openDialog}
        width={350}
        onClose={() => setOpenDialog(false)}
        title={
          <>
            <Typography bold>SG Converter</Typography>
            <Typography variant="caption">
              Define the air gap manually (m)
            </Typography>
          </>
        }
      >
        <Form onSubmit={handleSetAirGapToLocalStorage}>
          <TextField
            id="airGapTextField"
            label=""
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAirGap(Number(e.target.value))
            }
            value={airGap}
            variant={error ? 'error' : undefined}
            helperText={error || ''}
            unit="m"
          />
          <ButtonWrapper>
            <Button
              color="primary"
              type="submit"
              variant="outlined"
              onClick={() => setOpenDialog(false)}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save air gap
            </Button>
          </ButtonWrapper>
        </Form>
      </Dialog>
    </Container>
  );
};
