import { FC } from 'react';

import { MeasuredDataFilter } from 'src/api';
import { FilterType } from 'src/api';
import { FilterItemAuto } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItem.auto';
import { FilterItemChange } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItem.change';
import { FilterItemRule } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItem.rule';

interface FilterItemProps {
  selected?: boolean;
  filter?: MeasuredDataFilter;
  dragging?: boolean;
}

export const FilterItem: FC<FilterItemProps> = ({
  selected,
  filter,
  dragging,
}) => {
  switch (filter?.type) {
    case FilterType.AUTO:
      return <FilterItemAuto filter={filter} />;
    case FilterType.MANUAL:
    case FilterType.DRAG:
      return (
        <FilterItemChange
          dragging={dragging}
          filter={filter}
          selected={selected}
        />
      );
    case FilterType.RULE:
      return (
        <FilterItemRule
          dragging={dragging}
          filter={filter}
          selected={selected}
        />
      );
    default:
      return null;
  }
};
