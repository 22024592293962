import { FC, RefObject, useRef, useState } from 'react';

import {
  Button,
  exportComponent,
  formatDate,
  Icon,
  Menu,
  useSnackbar,
} from '@equinor/amplify-component-lib';
import {
  arrow_drop_down,
  arrow_drop_up,
  copy,
  download,
} from '@equinor/eds-icons';

import { PressureDataSimple } from 'src/api';
import { useMeasuredData } from 'src/hooks';
import { usePressureData } from 'src/pages/PlotView/hooks/usePressureData';
import { getNameFromMeasurement } from 'src/utils';

interface ExportProps {
  copyToClipboardRef: RefObject<HTMLElement>;
}

export const Export: FC<ExportProps> = ({ copyToClipboardRef }) => {
  const menuRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { pressureData } = usePressureData();
  const { from, to } = useMeasuredData();

  const handleCopyToClipboard = async () => {
    if (copyToClipboardRef.current === null) {
      showSnackbar(`Error: 'Ref cannot be null when copying to clipboard!'`);
      return;
    }

    // Remove box-shadow just when copying to clipboard
    for (const child of copyToClipboardRef.current.children[1].children) {
      child.setAttribute('style', 'box-shadow: none !important');
    }
    const blob = await exportComponent(copyToClipboardRef);
    for (const child of copyToClipboardRef.current.children[1].children) {
      child.removeAttribute('style');
    }
    const data = [new ClipboardItem({ [blob.type]: blob })];
    await navigator.clipboard.write(data);
    showSnackbar('Copied to clipboard');
  };

  const downloadFile = (
    pressureData: PressureDataSimple[][],
    suggestedName: string
  ) => {
    const headers =
      [
        ['Date'],
        pressureData.map((items) => getNameFromMeasurement(items[0])),
      ].join(',') + '\n';

    const days = pressureData[0].map((item) => item.prodDay);

    const data = days
      .map((day) =>
        [
          formatDate(day, { format: 'YYYY-MM-DD' }),
          ...pressureData.map(
            (items) =>
              items.find((item) => item.prodDay === day)?.pressureFinal ?? ''
          ),
        ].join(',')
      )
      .join('\n');

    const blob = new Blob([headers, data], {
      type: 'text/csv',
    });

    const a = document.createElement('a');
    a.download = suggestedName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      <Button variant="outlined" ref={menuRef} onClick={handleToggle}>
        Export <Icon data={open ? arrow_drop_up : arrow_drop_down} />
      </Button>
      <Menu
        anchorEl={menuRef.current}
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom-end"
      >
        <Menu.Section index={0} title="Export options">
          <Menu.Item onClick={handleCopyToClipboard} disabled>
            {/* TODO: fix screenshot logic */}
            Copy screenshot <Icon data={copy} />
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              downloadFile(pressureData, `dailyAveragePressure_${from}-${to}`)
            }
          >
            Export as .csv <Icon data={download} />
          </Menu.Item>
        </Menu.Section>
      </Menu>
    </>
  );
};
