import { tokens } from '@equinor/eds-tokens';

import {
  labelPosition,
  tickJustify,
} from 'src/pages/MapView/components/Legend/Gradient.utils';
import { MapColor } from 'src/types';

import styled from 'styled-components';

const { colors } = tokens;

interface GradientElementProps {
  $colors: MapColor[];
  $isVelocityMap: boolean;
}

export const GradientElement = styled.div<GradientElementProps>`
  width: 100%;
  height: 16px;
  background: linear-gradient(
    90deg,
    ${({ $colors, $isVelocityMap }) => {
      const colorSize = 100 / $colors.length;
      return $colors.map(
        (color, index) =>
          `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${
            (color.at(3) ?? 255) / 255
          })${
            $isVelocityMap
              ? `${index * colorSize}% ${(index + 1) * colorSize}%`
              : ''
          }${index !== $colors.length - 1 ? ',' : ''}`
      );
    }}
  );
  position: relative;
  margin-bottom: calc(1rem + 8px);
`;

interface TickContainerProps {
  $tickAmount: number;
}

export const TickContainer = styled.div<TickContainerProps>`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr repeat(${(props) => props.$tickAmount - 2}, 2fr) 1fr;
  width: 100%;
  height: 100%;
`;

interface TickProps {
  $gridColumn: number;
  $tickAmount: number;
}

export const Tick = styled.div<TickProps>`
  display: grid;
  grid-template-columns: ${({ $gridColumn, $tickAmount }) => {
    if ($gridColumn === 1) return '1fr repeat(2, 2fr)';
    else if ($gridColumn === $tickAmount) return 'repeat(2, 2fr) 1fr';
    return 'repeat(5, 1fr)';
  }};
  position: relative;
  justify-items: center;
  grid-column: ${(props) => props.$gridColumn};
  justify-self: ${(props) => tickJustify(props.$gridColumn, props.$tickAmount)};
  margin-top: 20px; // 16px (height of gradient + 4px)
  height: 6px;
  width: 100%;
  z-index: -1;

  > label {
    color: ${colors.text.static_icons__secondary.rgba};
    user-select: none;
    position: absolute;
    bottom: calc(-1rem - 4px);
    margin: 0;
    ${(props) => labelPosition(props.$gridColumn, props.$tickAmount)}
  }
`;

export const MainTick = styled.div`
  height: 6px;
  width: 1px;
  background: ${colors.text.static_icons__default.rgba};
  &:nth-child(2) {
    justify-self: flex-start;
  }
  &:last-child {
    justify-self: flex-end;
  }
`;

export const SmallTick = styled.div`
  height: 4px;
  width: 1px;
  background: ${colors.text.static_icons__tertiary.rgba};
`;
