import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { formatDate } from '@equinor/amplify-component-lib';
import { Table } from '@equinor/eds-core-react';

import { PressureDataSimple } from 'src/api';
import {
  InputCurrent,
  ManualDataPressureValue,
} from 'src/pages/EditMeasuredData/components/ManualDataEntry/InputCurrent';
import { CellBody } from 'src/pages/EditMeasuredData/components/ManualDataEntry/ManualDataTable/CellBody';
import { CellHeader } from 'src/pages/EditMeasuredData/components/ManualDataEntry/ManualDataTable/CellHeader';
import { roundTo } from 'src/utils';

export interface ManualDataTableDataObject {
  date: string;
  pressureRaw: number;
  pressureCorrected: number;
  pressureFinal: ManualDataPressureValue;
}

interface ManualDataTableProps {
  setIsSubmitActive: Dispatch<SetStateAction<boolean>>;
  filteredData: PressureDataSimple[];
  updateManualFilters: (
    value: string,
    dataObject: ManualDataTableDataObject
  ) => void;
}

// TODO: Fix table to use the one from amplify-components
export const ManualDataTable = ({
  setIsSubmitActive,
  filteredData,
  updateManualFilters,
}: ManualDataTableProps) => {
  const [tableData, setTableData] = useState<ManualDataTableDataObject[]>(
    filteredData?.map((data) => {
      return {
        date: data.prodDay ?? '',
        pressureRaw: data.pressureRaw ?? 0,
        pressureCorrected: data.pressureDepthCorrected ?? 0,
        pressureFinal: {
          original: roundTo(data.pressureFinal, 2)?.toString() ?? '0',
          new: roundTo(data.pressureFinal, 2)?.toString() ?? '0',
        },
      };
    }) ?? []
  );

  const updateTableData = useCallback(
    (value: string, dataObject: ManualDataTableDataObject) => {
      const idx = tableData.findIndex((data) => data.date === dataObject.date);
      setTableData([
        ...tableData.slice(0, idx),
        {
          ...dataObject,
          pressureFinal: { ...dataObject.pressureFinal, new: value },
        },
        ...tableData.slice(idx + 1, tableData.length),
      ]);
    },
    [tableData]
  );

  const handleOnChange = useCallback(
    (value: string, dataObject: ManualDataTableDataObject) => {
      updateTableData(value, dataObject);
      updateManualFilters(value, dataObject);
    },
    [updateManualFilters, updateTableData]
  );

  useEffect(() => {
    setIsSubmitActive(
      tableData.some((data) => {
        return (
          data.pressureFinal.original !== data.pressureFinal.new &&
          data.pressureFinal.new !== undefined
        );
      })
    );
  }, [setIsSubmitActive, tableData]);

  return (
    <Table>
      <Table.Head sticky>
        <Table.Row>
          <CellHeader label="Date" />
          <CellHeader label="Raw pressure" unit="bar" />
          <CellHeader label="Datum pressure" unit="bar" />
          <CellHeader label="QC pressure" unit="bar" />
          <CellHeader label="Current edit" unit="bar" />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {tableData?.map((data) => {
          return (
            <Table.Row key={data.date}>
              <CellBody
                label={formatDate(data.date, {
                  format: 'DD.MM.YYYY',
                })}
              />
              <CellBody label={roundTo(data.pressureRaw, 2) ?? ''} />
              <CellBody label={roundTo(data.pressureCorrected, 2) ?? ''} />
              <CellBody label={roundTo(data.pressureFinal.original, 2) ?? ''} />
              <Table.Cell>
                <InputCurrent
                  pressureFinal={data.pressureFinal}
                  onChange={(value) => handleOnChange(value, data)}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
