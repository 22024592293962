import { FC } from 'react';

import { Icon } from '@equinor/amplify-component-lib';
import { arrow_down, arrow_up } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

import { HeaderValueContainer } from './HeaderRow.styles';
import { SortDirection } from 'src/hooks/useSortDirection';
import { Container } from 'src/pages/Dashboard/components/PrognosisCard/PrognosisCard.styles';

const { colors } = tokens;

interface HeaderContainerValuesProps {
  label: string;
  sortDirection?: string;
  handleSort?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const HeaderRowValues: FC<HeaderContainerValuesProps> = ({
  label,
  sortDirection,
  handleSort,
}) => {
  return (
    <Container>
      <HeaderValueContainer
        group="table"
        variant="cell_header"
        onClick={handleSort}
      >
        {label}
        {sortDirection === SortDirection.DESCENDING && (
          <Icon
            data={arrow_down}
            size={18}
            color={colors.interactive.primary__resting.rgba}
          />
        )}
        {sortDirection === SortDirection.ASCENDING && (
          <Icon
            data={arrow_up}
            size={18}
            color={colors.interactive.primary__resting.rgba}
          />
        )}
      </HeaderValueContainer>
    </Container>
  );
};
