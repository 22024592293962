import { FC } from 'react';

import { OptionalTooltip } from '@equinor/amplify-component-lib';

import { MeasuredDataFilter } from 'src/api';
import { FilterTypeIcon } from 'src/pages/EditMeasuredData/components/FilterPanel/components/FilterTypeIcon';
import { getfilterTooltipTitle } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/utils';

import styled from 'styled-components';

const CollapsedFilterItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-bottom: 1px solid #dcdcdc;
`;

interface CollapsedFilterItemProps {
  filter: MeasuredDataFilter;
}

export const CollapsedFilterItem: FC<CollapsedFilterItemProps> = ({
  filter,
}) => {
  if (!filter) return null;

  return (
    <OptionalTooltip title={getfilterTooltipTitle(filter)} placement="left">
      <CollapsedFilterItemWrapper>
        <FilterTypeIcon type={filter.type} />
      </CollapsedFilterItemWrapper>
    </OptionalTooltip>
  );
};
