import { useMemo } from 'react';

import { PolygonLayer } from '@deck.gl/layers';
import isPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';

import { PolygonFeature } from 'src/api';
import { useGetFieldOutlineFeatureCollection } from 'src/pages/MapView/components/Map/hooks/useGetFieldOutlineFeatureCollection';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground, MapLayer } from 'src/pages/MapView/MapView.types';

export function useFieldOutline() {
  const {
    data: fieldOutlineFeatureCollection,
    mainFieldOutline,
    mainFieldOutlineIndex,
  } = useGetFieldOutlineFeatureCollection();
  const { backgroundLayer } = useMapOptions();

  interface FieldOutlineFeature {
    feature: PolygonFeature;
    isInsideMainOutline: boolean;
  }

  const featuresWithIsInsideBoolean: FieldOutlineFeature[] = useMemo(() => {
    return (fieldOutlineFeatureCollection?.features ?? []).map(
      (feature, index) => {
        const point = feature.geometry?.coordinates.at(0)?.at(0);

        const polygonCoordinates = mainFieldOutline?.geometry?.coordinates;
        if (!point || !polygonCoordinates)
          return { feature: feature, isInsideMainOutline: false };
        const polygonToCheck = polygon(polygonCoordinates);

        return {
          feature: feature,
          isInsideMainOutline:
            index !== mainFieldOutlineIndex &&
            isPointInPolygon(point, polygonToCheck),
        };
      }
    );
  }, [fieldOutlineFeatureCollection, mainFieldOutline, mainFieldOutlineIndex]);

  const filteredFeatures = useMemo(
    () =>
      featuresWithIsInsideBoolean?.filter((feature) => {
        const lengthOfFeature =
          feature.feature.geometry?.coordinates.at(0)?.length;
        return lengthOfFeature && lengthOfFeature > 40;
      }) ?? [],
    [featuresWithIsInsideBoolean]
  );

  return useMemo(
    () =>
      new PolygonLayer({
        id: MapLayer.FIELD_OUTLINE,
        filled: backgroundLayer !== MapBackground.INTERPOLATED,
        getFillColor: (d: FieldOutlineFeature) =>
          d.isInsideMainOutline ? [220, 220, 220] : [255, 255, 255], // d.isInsideMainOutline ? ui.background.medium : colors.text.static_icons__primary_white
        data: filteredFeatures,
        getPolygon: (d: FieldOutlineFeature) => d.feature.geometry?.coordinates,
        getLineColor: [111, 111, 111], // Equinor text - static icons tertiary
        getLineWidth: 1,
        lineWidthMinPixels: 2,
      }),
    [backgroundLayer, filteredFeatures]
  );
}
