import { useContext } from 'react';

import { AppContext } from '../providers/AppStateProvider';

export const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppStateProvider');
  }
  return context;
};
