import { FC, Fragment, useMemo } from 'react';
import { useParams } from 'react-router';

import { Chip, colors, Icon, spacings } from '@equinor/amplify-component-lib';
import { info_circle } from '@equinor/eds-icons';

import { LineStyleLegend } from './LineStyleLegend';
import { HelpLine, PressureDataSimple } from 'src/api';
import { MEASURED_DATA_PLOT_DEFAULT_LINE_STYLES } from 'src/constants';
import { LineStyleType } from 'src/types';
import {
  addHelpLineStyles,
  getLineChartLineColor,
  getNameFromMeasurement,
} from 'src/utils';

import { useMeasuredDataWellbore } from 'hooks/useMeasuredDataWellbore';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-top: ${spacings.large};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacings.small};
`;

const MainWellboreGaugeChip = styled(Chip)`
  padding-left: 0;
`;

interface ColoredDotProps {
  $color: string;
}

const ColoredDot = styled.div<ColoredDotProps>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${colors.ui.background__medium.rgba};
`;

interface PlotFooterProps {
  data?: PressureDataSimple[];
  helpLines?: HelpLine[];
}

export const PlotFooter: FC<PlotFooterProps> = ({ data, helpLines }) => {
  const { dhpg, wellId } = useParams();
  const { wellbore } = useMeasuredDataWellbore(wellId);
  const wellboreGaugeName =
    data !== undefined && data.length !== 0
      ? getNameFromMeasurement(data[0])
      : 'Current edit';

  const color = useMemo(
    () =>
      getLineChartLineColor({
        value: wellbore?.wellboreUuid ?? '',
        dhpg: Number(dhpg),
        label: wellbore?.wellboreName ?? '',
      }),
    [wellbore, dhpg]
  );

  const lineStyles: LineStyleType[] = useMemo(() => {
    if (helpLines && helpLines.length === 0) {
      return addHelpLineStyles(
        helpLines,
        MEASURED_DATA_PLOT_DEFAULT_LINE_STYLES
      );
    }
    return MEASURED_DATA_PLOT_DEFAULT_LINE_STYLES;
  }, [helpLines]);

  return (
    <Container>
      <Icon data={info_circle} />
      {wellId && (
        <MainWellboreGaugeChip>
          <ColoredDot $color={color} />
          {wellboreGaugeName}
        </MainWellboreGaugeChip>
      )}
      {lineStyles.map((lineStyle, index, array) => (
        <Fragment key={lineStyle.label}>
          <LineStyleLegend {...lineStyle} />
          {index === 1 && array.length > 2 && <VerticalDivider />}
        </Fragment>
      ))}
    </Container>
  );
};
