import { FC } from 'react';

import { animation, colors } from '@equinor/amplify-component-lib';

import { ActionBar } from './ActionBar/ActionBar';
import { FILTER_PANEL_WIDTH } from 'src/constants';
import { Header } from 'src/pages/EditMeasuredData/components/FilterPanel/components/Header';
import { FilterPanelBody } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterPanelBody';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

import { motion } from 'framer-motion';
import styled from 'styled-components';

interface WrapperProps {
  $open: boolean;
}

const Wrapper = styled(motion.div)<WrapperProps>`
  flex-shrink: 0;
  display: ${({ $open }) => ($open ? 'grid' : 'none')};
  transition: display ${animation.transitionMS};
  grid-template-rows: 64px auto 57px;
  height: calc(100vh - 65px);
  position: fixed;
  right: 0;
  background-color: ${colors.ui.background__default.rgba};
  width: ${FILTER_PANEL_WIDTH};
  border-left: 1px solid ${colors.ui.background__medium.rgba};

  * {
    // to stop text selections appearing when users are using shift to select changes in the list
    user-select: none;
  }
`;

export const FilterPanel: FC = () => {
  const { filterPanelOpen } = useEditMeasuredData();

  return (
    <Wrapper $open={filterPanelOpen}>
      <Header />
      <FilterPanelBody />
      <ActionBar />
    </Wrapper>
  );
};
