import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';
import { EditPrognosisFormValues } from 'src/utils';

export const useDeleteDetail = () => {
  const { prognosisId } = useParams();
  const { setValue, clearErrors } = useFormContext<EditPrognosisFormValues>();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (id: number) => {
      return await PrognosisDetailService.deleteApiV1PrognosesDetails(id);
    },
    onMutate: async (id: number) => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, prognosisId],
      });

      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, prognosisId]) ?? [];

      const updatedDetails = previousDetails.filter(
        (detail) => detail.id !== id
      );

      queryClient.setQueryData([PROGNOSIS_DETAIL, prognosisId], updatedDetails);

      setValue('prognosisDetails', updatedDetails);

      // If we don't clear the errors, they will shift up/down and display the error on the wrong cell
      clearErrors('prognosisDetails');

      return { previousDetails, updatedDetails };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, prognosisId],
        context?.previousDetails ?? []
      );
      setValue('prognosisDetails', context?.previousDetails ?? []);
      showSnackbar('Failed to delete detail.');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, prognosisId],
      });
    },
  });
};
