import { colors } from '@equinor/amplify-component-lib';
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { Group } from '@visx/group';

const PATH_WIDTH = 20;

export const VerticalBrushHandle = ({
  y,
  width,
  isBrushActive,
}: BrushHandleRenderProps) => {
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={(width - PATH_WIDTH) / 2} top={y}>
      <path
        fill="#f2f2f2"
        d="M 0 4 l 12 0"
        stroke={colors.interactive.primary__resting.rgba}
        strokeWidth="8"
        strokeLinecap="round"
        style={{ cursor: 'ns-resize' }}
      />
    </Group>
  );
};
