import { useFormContext } from 'react-hook-form';

import { useQuery } from '@tanstack/react-query';

import { ReservoirZoneService } from 'src/api';
import { LAST_USED_ZONES_KEY } from 'src/constants/queryKeys';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const useGetLastUsedZones = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore } = getValues();

  const cleanStringForUrl = (str: string): string => {
    return encodeURIComponent(str);
  };

  const identifier = cleanStringForUrl(wellbore?.label) ?? '';

  return useQuery({
    queryKey: [wellbore?.value, LAST_USED_ZONES_KEY],
    queryFn: () =>
      ReservoirZoneService.getApiV1ReservoirZonesLastUsed(identifier),
  });
};
