import { useContext } from 'react';

import { OptionalAttributeContext } from '../providers/OptionalAttributeProvider';

export function useOptionalAttributes() {
  const context = useContext(OptionalAttributeContext);

  if (context === undefined) {
    throw new Error(
      'useOptionalAttributes must be used within a OptionalAttributeProvider'
    );
  }

  return context;
}
