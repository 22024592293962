import { FC } from 'react';

import { DotProgress } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

const Wrapper = styled.div`
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LineChartScrim: FC = () => {
  return (
    <Wrapper>
      <DotProgress color="primary" size={64} />
    </Wrapper>
  );
};
