import { MapElements } from '../../../MapElements/MapElements';
import { MeasureArea } from '../../../MeasureArea/MeasureArea';
import { MeasureAreaShape } from './useMeasureArea.types';
import { AreaNames } from 'src/pages/MapView/components/AreaNames/AreaNames';
import { MapTool } from 'src/pages/MapView/components/MapSettings/MapTools';
import { SelectionArea } from 'src/pages/MapView/components/SelectionArea/SelectionArea';
import { useMapOptions } from 'src/pages/MapView/hooks';

interface UseMapChildrenArgs {
  measureAreaShapes: MeasureAreaShape[];
}

export function useMapChildren({ measureAreaShapes }: UseMapChildrenArgs) {
  const { mapTool } = useMapOptions();

  return (
    <>
      <MapElements />
      <AreaNames />
      {mapTool === MapTool.SELECT_AREA && <SelectionArea />}
      {mapTool === MapTool.MEASURE_AREA && (
        <MeasureArea shapes={measureAreaShapes} />
      )}
    </>
  );
}
