import { colors, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Container = styled.div`
  background: ${colors.ui.background__light.hex};
  padding: ${spacings.medium};
  position: absolute;
  bottom: calc(
    117px
  ); // This legend is going to be moved into footer soonTM, so just hardcoding the px value for now
  right: 0;
  z-index: 1000;
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: ${spacings.x_large};
  p {
    user-select: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > svg {
    color: ${colors.text.static_icons__secondary.hex};
    cursor: pointer;
    &:hover {
      color: ${colors.text.static_icons__default.hex};
    }
  }
`;

export const GradientWrapper = styled.div`
  position: relative;
`;
