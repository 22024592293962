/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PressureType {
  MEASURED = 'Measured',
  AUTO_CORRECTED = 'AutoCorrected',
  CORRECTED = 'Corrected',
  MISSING = 'Missing',
  INTERPOLATED = 'Interpolated',
}
