import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisService, PrognosisUpdateDto } from 'src/api';
import {
  APPROVE_PROGNOSIS_KEY,
  PROGNOSIS_KEY,
  REJECT_PROGNOSIS_KEY,
} from 'src/constants/queryKeys';
import { usePrognosisFromId } from 'src/hooks';
import { StatusIdEnum } from 'src/types/status';
import { OperationValues } from 'src/utils';

export const usePrognosisApproval = () => {
  const navigate = useNavigate();
  const { prognosisId } = useParams();
  const { data } = usePrognosisFromId();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const pickedData: PrognosisUpdateDto = {
    title: data?.title ?? 'N/A',
    operationId: data?.operationId ?? undefined,
    wellPlanningPhaseId: data?.wellPlanningPhaseId ?? undefined,
    statusId: data?.statusId ?? StatusIdEnum.HISTORICAL,
    approveUser: data?.approveUser,
    ciTargetUuid: data?.ciTargetUuid,
    wellboreIdentifier: data?.wellboreIdentifier,
    wellpathRevision: data?.wellpathRevision,
    comment: data?.comment,
    validFromDate: data?.validFromDate,
    validToDate: data?.validToDate,
    updateUser: data?.updateUser ?? 'N/A',
  };

  if (
    pickedData.operationId?.toString() !== OperationValues.NEW_WELL.toString()
  ) {
    delete pickedData.wellPlanningPhaseId;
  }

  const body = useRef({
    statusId: StatusIdEnum.APPROVED as number,
    validFromDate: new Date().toISOString(),
    approveDate: new Date().toISOString(),
  });

  const approvePrognosis = useMutation({
    mutationKey: [APPROVE_PROGNOSIS_KEY],
    mutationFn: (optionalComment: string) => {
      body.current = {
        statusId: StatusIdEnum.APPROVED,
        validFromDate: new Date().toISOString(),
        approveDate: new Date().toISOString(),
      };

      return PrognosisService.putApiV1Prognoses(
        Number(prognosisId),
        optionalComment,
        {
          ...pickedData,
          ...body.current,
        }
      );
    },
    onSuccess: () => {
      showSnackbar('Review submitted');
      queryClient.setQueryData([PROGNOSIS_KEY, prognosisId], {
        ...data,
        ...body.current,
      });
    },
  });

  const revisePrognosis = useMutation({
    mutationKey: [REJECT_PROGNOSIS_KEY],
    mutationFn: async (optionalComment: string) => {
      return PrognosisService.putApiV1Prognoses(
        Number(prognosisId),
        optionalComment,
        {
          ...pickedData,
          statusId: StatusIdEnum.REVISION_REQUIRED,
        }
      );
    },
    onSuccess: () => {
      showSnackbar('Review submitted');
      navigate('/dashboard');
    },
  });

  return { approvePrognosis, revisePrognosis };
};
