import { FC, useRef, useState } from 'react';

import {
  Button,
  exportComponent,
  Icon,
  Menu,
  useSnackbar,
} from '@equinor/amplify-component-lib';
import { file_copy, more_vertical } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

const MenuItem = styled(Menu.Item)`
  > div {
    grid-template-columns: auto 1fr;
  }
`;

interface MoreOptionsMenuProps {
  cardElement: HTMLDivElement | null;
  className?: string;
}

export const MoreOptionsMenu: FC<MoreOptionsMenuProps> = ({
  cardElement,
  className,
}) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  const handleOnCopyToClipboard = async () => {
    if (!cardElement) return;

    // Temporary remove box-shadow because it breaks export image
    cardElement.setAttribute('style', 'box-shadow: none');

    try {
      const blob = await exportComponent({ current: cardElement });
      const data = [new ClipboardItem({ [blob.type]: blob })];

      await navigator.clipboard.write(data);
      showSnackbar('Copied to clipboard!');
    } catch (e) {
      console.error(e);
      showSnackbar('Failed to copy to clipboard');
    }

    cardElement.removeAttribute('style');
  };

  return (
    <>
      <Button
        ref={anchorRef}
        variant="ghost_icon"
        onClick={handleOnClick}
        className={className}
        aria-label="Options"
      >
        <Icon data={more_vertical} />
      </Button>
      {open && (
        <Menu
          open
          anchorEl={anchorRef.current}
          placement="bottom-start"
          onClose={handleOnClose}
        >
          <Menu.Section title="Options">
            <MenuItem onClick={handleOnCopyToClipboard}>
              <Icon
                data={file_copy}
                color={colors.interactive.primary__resting.rgba}
              />
              Copy to clipboard
            </MenuItem>
          </Menu.Section>
        </Menu>
      )}
    </>
  );
};
