import { createContext } from 'react';

import { MapView } from '@deck.gl/core';
import { DeckGLContextValue } from '@deck.gl/react';

import { useMapChildren } from './useMapChildren';
import { useMapLayers } from './useMapLayers';
import { useMapMouseInteractions } from './useMapMouseInteractions';
import { useViewStateHandler } from './useViewStateHandler';
import { CONTROLLER_PROPS } from 'src/constants';
import { useMapOptions } from 'src/pages/MapView/hooks';

// <DeckGL> does not accept potentially undefined ContextProvider, default value as any for simplicity
export const MapInnerContext = createContext<DeckGLContextValue>({} as never);

export function useDeckGlMapProps() {
  const {
    hoverPoint,
    handleOnHover,
    handleOnClick,
    handleOnDrag,
    handleOnDragEnd,
    handleOnDragStart,
    measureAreaShapes,
  } = useMapMouseInteractions();

  const { viewState, infoFooterViewState } = useMapOptions();

  const { handleOnViewStateChanged, handleReCenter, isOutsideBounds } =
    useViewStateHandler();
  const { mapLayers, handleLayerFiltering } = useMapLayers();

  const children = useMapChildren({
    measureAreaShapes,
  });

  return {
    deckglProps: {
      views: [
        new MapView({
          id: 'map',
          width: `100%`,
          controller: true,
        }),
      ],
      controller: CONTROLLER_PROPS,
      layers: mapLayers,
      layerFilter: handleLayerFiltering,
      onViewStateChange: handleOnViewStateChanged,
      onClick: handleOnClick,
      onDrag: handleOnDrag,
      onDragStart: handleOnDragStart,
      onDragEnd: handleOnDragEnd,
      viewState: { map: viewState },
      onHover: handleOnHover,
      ContextProvider: MapInnerContext.Provider,
      children,
    },
    handleReCenter,
    isOutsideBounds,
    hoverPoint,
    infoFooterViewState,
  };
}
