import { FC } from 'react';

import { MapViewState } from '@deck.gl/core';

import { MAP_FOOTER_HEIGHT } from 'src/constants';
import { HoverCoordinates } from 'src/pages/MapView/components/InfoFooter/HoverCoordinates';
import { Scale } from 'src/pages/MapView/components/InfoFooter/Scale';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  bottom: ${MAP_FOOTER_HEIGHT};
  left: 0;
  display: flex;
  flex-direction: column;
`;

interface InfoFooterProps {
  viewport: MapViewState;
  hoverPoint: number[] | undefined;
}

export const InfoFooter: FC<InfoFooterProps> = ({ viewport }) => {
  return (
    <Wrapper>
      <Scale key={`scale-zoom-${viewport.zoom}`} viewport={viewport} />
      <HoverCoordinates key={`hover-coordinates`} />
    </Wrapper>
  );
};
