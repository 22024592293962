import { MouseEvent } from 'react';

import { colors } from '@equinor/amplify-component-lib';

import { ChartDataPoint, ChartDataPointCategory } from './Chart.types';

const PAD_PERCENTAGE = 0.2;

export function isNow(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function allPressureValuesFromDataPoint(
  dataPoint: ChartDataPoint
): number[] {
  const values = [dataPoint.expected, dataPoint.high, dataPoint.low];
  if (dataPoint.initial) {
    values.push(dataPoint.initial);
  }
  return values;
}

export function pressureValuesFromDataPoint(
  dataPoint: ChartDataPoint
): Omit<ChartDataPoint, 'depth' | 'zone' | 'date' | 'map'> {
  return {
    low: dataPoint.low,
    high: dataPoint.high,
    expected: dataPoint.expected,
    initial: dataPoint.initial,
  };
}

export function dataPointKeyToCategory(
  key: keyof ChartDataPoint
): ChartDataPointCategory {
  switch (key) {
    case 'low':
    case 'high':
      return ChartDataPointCategory.LOW_HIGH;
    case 'initial':
      return ChartDataPointCategory.INITIAL;
    case 'expected':
    default:
      return ChartDataPointCategory.EXPECTED;
  }
}

export function dataPointColor(
  category: ChartDataPointCategory,
  color?: string,
  graphColors?: string[]
) {
  const colorString = graphColors?.map((color) => `${color}`).join(',');

  let fillColor = color || colors.ui.background__medium.rgba;
  if (colorString) {
    fillColor = `conic-gradient(from 180deg at 50% 50%, ${colorString})`;
  }

  switch (category) {
    case ChartDataPointCategory.EXPECTED:
      return {
        stroke: colors.ui.background__scrim.hex,
        fill: colors.ui.background__default.rgba,
      };

    case ChartDataPointCategory.LOW_HIGH:
      return {
        stroke: color,
        fill: fillColor,
      };
    case ChartDataPointCategory.INITIAL:
      return {
        stroke: colors.text.static_icons__default.hex,
        fill: colors.text.static_icons__default.hex,
      };
  }
}

export function formatTick(date: Date) {
  if (isNow(date)) return 'Now';

  return date.toLocaleDateString('en-US', {
    month: 'short',
    year: '2-digit',
  });
}

export function getDepthDomain(data: ChartDataPoint[]): [number, number] {
  const min = Math.min(...data.map((d) => d.depth));
  const max = Math.max(...data.map((d) => d.depth));

  const diff = (max - min) * PAD_PERCENTAGE;

  return [max + diff, min - diff];
}

export function getPressureDomain(data: ChartDataPoint[]): [number, number] {
  const min = Math.min(...data.flatMap(allPressureValuesFromDataPoint));
  const max = Math.max(...data.flatMap(allPressureValuesFromDataPoint));

  const diff = (max - min) * PAD_PERCENTAGE;

  return [Math.max(min - diff, 0), max + diff];
}

export function localPoint(event: MouseEvent<Element, globalThis.MouseEvent>): {
  x: number;
  y: number;
} {
  const rect = event.currentTarget.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  return { x, y };
}
