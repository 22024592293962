import { FC } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useNavigate } from 'react-router';

import {
  Button,
  colors,
  formatDate,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { external_link } from '@equinor/eds-icons';

import { PrognosisDto } from 'src/api';
import { headerTitles } from 'src/constants';
import { lookupValueToComboBoxRequired } from 'src/utils';

import { styled } from 'styled-components';

const color = colors.interactive.secondary__resting.hex;

const ListItem = styled.div`
  margin-top: ${spacings.small};
  border: 1px solid ${color};
  border-radius: ${spacings.x_small};
  padding: ${spacings.small};
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${spacings.large};
  > button {
    color: ${color};
    place-self: center;
  }
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr);
  column-gap: ${spacings.large};
  & > *:nth-child(n + 2):nth-child(-n + 13):not(:nth-child(7)) {
    place-self: start end;
  }
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

interface OverlappingNotificationProps {
  prognosis: PrognosisDto;
}

export const OverlappingPrognosis: FC<OverlappingNotificationProps> = ({
  prognosis,
}) => {
  const navigate = useNavigate();
  const operation = lookupValueToComboBoxRequired(
    prognosis.operation,
    true
  ).label;

  return (
    <ListItem data-testid="overlapping-prognosis-item">
      <ItemGrid>
        {headerTitles.map((title) => (
          <Fragment key={title}>
            <Typography group="table" variant="cell_text" color={color}>
              {title}
            </Typography>
          </Fragment>
        ))}
        <Typography group="table" variant="cell_text_bold" color={color}>
          {prognosis.title}
        </Typography>
        <Typography group="table" variant="cell_text_bold" color={color}>
          {prognosis.createUser}
        </Typography>
        <Typography group="table" variant="cell_text_bold" color={color}>
          {prognosis.approveUser}
        </Typography>
        <Typography group="table" variant="cell_text_bold" color={color}>
          <Capitalize>{prognosis.status}</Capitalize>
        </Typography>
        <Typography group="table" variant="cell_text_bold" color={color}>
          {operation}
        </Typography>
        <div>
          <Typography group="table" variant="cell_text_bold" color={color}>
            {`${formatDate(prognosis.validFromDate)} - ${formatDate(prognosis.validToDate)}`}
          </Typography>
        </div>
      </ItemGrid>
      <Button
        variant="ghost_icon"
        aria-label="save action"
        color="primary"
        data-testid="overlapping-prognosis-item-button"
        onClick={() => {
          navigate(`/detailed-view/${prognosis.id}`);
        }}
      >
        <Icon data={external_link}></Icon>
      </Button>
    </ListItem>
  );
};
