import { useMutation } from '@tanstack/react-query';

import { MeasuredDataFilterService } from 'src/api';
import { DELETE_MEASURED_DATA_FILTER } from 'src/constants';
import { useUpdateQueryData } from 'src/pages/EditMeasuredData/hooks/useUpdateQueryData';

export const useDeleteFilter = () => {
  const { deleteFilterQueryData, handleOnSuccess, handleOnError } =
    useUpdateQueryData();
  return useMutation({
    mutationKey: [DELETE_MEASURED_DATA_FILTER],
    mutationFn: (filterId: string) =>
      MeasuredDataFilterService.deletefilterbyId(filterId),
    onSuccess: (data) => {
      deleteFilterQueryData([data]);
      handleOnSuccess();
    },
    onError: handleOnError,
  });
};
