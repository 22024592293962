import { useEffect, useState } from 'react';

import { useAuth, useDebounce } from '@equinor/amplify-component-lib';
import { useInfiniteQuery } from '@tanstack/react-query';

import { FilterValue } from '../pages/Dashboard/providers/DashboardProvider';
import { useApp } from './useApp';
import { useGetUserAccess } from './useGetUserAccess';
import { SortDirection } from './useSortDirection';
import { ApiError, PrognosisDto, PrognosisService } from 'src/api';
import { PAGE_SIZE } from 'src/constants/queryKeys';
import { TabType } from 'src/constants/tab';
import { StatusNameEnum } from 'src/types/status';
import { ViewPrognosesOptions } from 'src/utils/dashboard';

interface usePrognosesArgs {
  currentTab: TabType;
  filterValue?: FilterValue;
  sortDirection?: SortDirection;
}

export const usePrognoses = ({
  currentTab,
  filterValue,
  sortDirection,
}: usePrognosesArgs) => {
  const { field } = useApp();
  const debouncedFilterValue = useDebounce(filterValue, 500);
  const [isSearching, setIsSearching] = useState(false);
  const { draftGroup } = useApp();

  const ShowAll = draftGroup === ViewPrognosesOptions.ALL_PROGNOSES;
  const showAllAssigned = draftGroup === ViewPrognosesOptions.ASSIGNED_TO_ME;
  const showSubmitted = draftGroup === ViewPrognosesOptions.SUBMITTED_TO_ME;

  useEffect(() => {
    const equalFilterValues =
      JSON.stringify(filterValue) === JSON.stringify(debouncedFilterValue);
    if (!equalFilterValues && !isSearching) {
      setIsSearching(true);
    } else if (equalFilterValues && isSearching) {
      setIsSearching(false);
    }
  }, [debouncedFilterValue, filterValue, isSearching]);

  let prognosisStatus: string | undefined = undefined;
  let approveUser: string | undefined = undefined;
  let createUser: string | undefined = undefined;
  let sortColumn: keyof PrognosisDto | undefined = undefined;

  const { userAccessForField } = useGetUserAccess();
  const { account } = useAuth();
  const userShortName = account?.username.split('@').at(0) ?? undefined;

  switch (currentTab) {
    case TabType.MyActionableItems:
      if (userAccessForField?.userRoles?.submitter) {
        prognosisStatus = [
          StatusNameEnum.DRAFT,
          StatusNameEnum.SUBMITTED,
          StatusNameEnum.REVISION_REQUIRED,
          StatusNameEnum.OUTDATED,
        ].join(',');
        createUser = userShortName;
      } else if (userAccessForField?.userRoles?.approver) {
        if (!ShowAll && showAllAssigned) {
          prognosisStatus = [
            StatusNameEnum.DRAFT,
            StatusNameEnum.REVISION_REQUIRED,
            StatusNameEnum.SUBMITTED,
          ].join(',');
          approveUser = userShortName;
        } else if (!ShowAll && showSubmitted) {
          prognosisStatus = [StatusNameEnum.SUBMITTED].join(',');
          approveUser = userShortName;
        } else {
          prognosisStatus = [
            StatusNameEnum.DRAFT,
            StatusNameEnum.SUBMITTED,
            StatusNameEnum.REVISION_REQUIRED,
            StatusNameEnum.OUTDATED,
          ].join(',');
        }
      }
      sortColumn = 'updateDate';
      break;

    case TabType.Approved:
      prognosisStatus = [StatusNameEnum.APPROVED].join(',');
      sortColumn = 'wellboreIdentifier';
      break;
    case TabType.Historical:
      prognosisStatus = [StatusNameEnum.HISTORICAL].join(',');
      sortColumn = 'validToDate';
      break;
  }

  const query = useInfiniteQuery({
    queryKey: [
      prognosisStatus,
      approveUser,
      createUser,
      Object.values(debouncedFilterValue ?? {}),
      sortDirection,
      sortColumn,
      field?.name,
    ],
    queryFn: ({ pageParam }) =>
      PrognosisService.getApiV1Prognoses(
        debouncedFilterValue?.wellbore ?? undefined,
        field?.name ?? undefined,
        debouncedFilterValue?.status ?? prognosisStatus,
        debouncedFilterValue?.operation ?? undefined,
        '',
        debouncedFilterValue?.search ?? undefined, // title
        undefined, // comment
        debouncedFilterValue?.createdBy ?? createUser,
        debouncedFilterValue?.approver ?? approveUser,
        debouncedFilterValue?.lastModifiedFrom ?? undefined, // update_dateFrom
        filterValue?.lastModifiedTo ?? undefined, // update_dateTo
        undefined, // validDateFrom
        undefined, // validDateTo
        sortColumn,
        sortDirection,
        pageParam,
        PAGE_SIZE
      ),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.length !== PAGE_SIZE) {
        return undefined;
      }

      return pages.length + 1;
    },
    initialPageParam: 1,
    retry: (failureCount, error: ApiError) => {
      if (error.status === 404) return false;
      return failureCount < 4;
    },
  });
  return { ...query, isSearching };
};
