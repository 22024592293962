import { FC, useMemo } from 'react';

import { SkeletonGradient } from '@equinor/amplify-component-lib';
import { curveLinear } from '@visx/curve';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';

import { LinePlotMargin } from '../LineChart.types';
import { DummyData } from './LineChartSkeleton';

interface PlotSkeletonProps {
  width: number;
  height: number;
  margin: LinePlotMargin;
  data: DummyData[];
}

const getSkeletonPlotX = (d: DummyData) => {
  return d.x;
};
const getSkeletonPlotY = (d: DummyData) => {
  return d.y;
};

export const PlotSkeleton: FC<PlotSkeletonProps> = ({
  margin,
  width,
  height,
  data,
}) => {
  const xMax = useMemo(
    () => Math.max(0, width - margin.right - margin.left),
    [margin.left, margin.right, width]
  );

  const yMax = useMemo(
    () => Math.max(0, Number(height) - margin.bottom - margin.top),
    [margin.bottom, margin.top, height]
  );

  const leftAxisScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [0, 10],
      }),
    [yMax]
  );

  const bottomAxisScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        domain: [0, data.length - 1],
      }),
    [data.length, xMax]
  );

  return (
    <svg width={width} height={height}>
      <defs>
        <SkeletonGradient />
      </defs>
      <Group top={margin.top} left={margin.left}>
        <GridRows
          scale={leftAxisScale}
          width={xMax}
          height={yMax}
          stroke="#e0e0e0"
        />
        <LinePath
          data={data}
          x={(d) => bottomAxisScale(getSkeletonPlotX(d))}
          y={(d) => leftAxisScale(getSkeletonPlotY(d))}
          stroke="url(#skeleton-gradient)"
          strokeWidth={10}
          strokeOpacity={2}
          width={width}
          curve={curveLinear}
        />
      </Group>
    </svg>
  );
};
