import { FC } from 'react';

import {
  Button,
  Chip,
  colors,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { clear } from '@equinor/eds-icons';

import { DataType, HelpLine } from 'src/api';
import { getRightAxisTypeFromHelpLineType } from 'src/pages/EditMeasuredData/hooks/useLineChartHelpLines';
import { useLinePlot } from 'src/pages/EditMeasuredData/hooks/useLinePlot';
import { getCheckboxLabel } from 'src/pages/EditMeasuredData/utils/lineplot';
import { getHelpLineColor } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${colors.ui.background__medium.rgba};
  padding: ${spacings.x_small};
  gap: ${spacings.small};
  height: 36px;
`;
const WellboreName = styled.div`
  display: flex;
  gap: ${spacings.x_small};
  align-items: center;
  margin-right: ${spacings.small};
`;

interface ColorIndicatorProps {
  $color: string;
}

const ColorIndicator = styled.div<ColorIndicatorProps>`
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

const CloseButton = styled(Button)`
  width: 26px;
  height: 26px;
`;

interface HelplinesForWellboreProps {
  helpLines: HelpLine[];
}

export const HelplinesForWellbore: FC<HelplinesForWellboreProps> = ({
  helpLines,
}) => {
  const { selectedRightAxis, removeHelpLines } = useLinePlot();
  const handleChipOnClick = (helpLine: HelpLine) => {
    removeHelpLines([helpLine]);
  };

  const handleClearWellboreClick = () => {
    removeHelpLines(helpLines);
  };

  if (helpLines.length === 0) return null;
  return (
    <Wrapper>
      <WellboreName>
        <ColorIndicator $color={getHelpLineColor(helpLines[0])} />
        <Typography group="navigation" variant="button">
          {helpLines[0].name}
        </Typography>
      </WellboreName>
      {helpLines.map((helpLine) => {
        if (helpLine.type === DataType.PRODUCTIVITY_INDEX_INTERPOLATED)
          return null;
        const rightAxisType = getRightAxisTypeFromHelpLineType(helpLine);
        return (
          <Chip
            key={`${helpLine.name}-${helpLine.measureType}-${helpLine.type}`}
            onDelete={() => handleChipOnClick(helpLine)}
            selected={
              rightAxisType === undefined
                ? true
                : rightAxisType === selectedRightAxis
            }
          >
            {getCheckboxLabel(helpLines, helpLine)}
          </Chip>
        );
      })}
      <CloseButton variant="ghost_icon" onClick={handleClearWellboreClick}>
        <Icon data={clear} size={18} />
      </CloseButton>
    </Wrapper>
  );
};
