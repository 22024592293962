import { ChangeEvent, FC } from 'react';

import { Button, Icon, TextField } from '@equinor/amplify-component-lib';
import { add, delete_to_trash } from '@equinor/eds-icons';

import {
  AddNewCriteriaButtonWrapper,
  DeleteRuleButton,
  DeleteRuleWrapper,
  RuleOptionsWrapper,
} from './AdvancedFilterDialog.styles';
import { Criteria } from './Criteria';
import { DateSelection, DateSelectionType } from './DateSelection';
import { Filter, MeasuredDataFilter } from 'src/api';
import { FilterCriteria } from 'src/api';
import { FilterRule } from 'src/api';
import { getDefaultFilterTitle } from 'src/utils';

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  width: 97%;
  gap: 16px;
  background: rgba(173, 226, 230, 0.1);
  border: 1px solid rgba(222, 237, 238, 1);
  border-radius: 4px;
  box-sizing: border-box;
`;

interface RuleProps {
  filter: MeasuredDataFilter;
  isLastRuleLeft: boolean;
  onDeleteRule: (id: string | undefined | null) => void;
  onAddNewCriteria: (id: string | undefined | null) => void;
  onDeleteCriteria: (
    id: string | undefined | null,
    criteriaId: string | null | undefined
  ) => void;
  updateFilter: (id: string, value: Partial<Filter>) => void;
  updateRule: (id: string, value: Partial<FilterRule>) => void;
}

export const Rule: FC<RuleProps> = ({
  filter,
  isLastRuleLeft,
  onDeleteRule,
  onAddNewCriteria,
  onDeleteCriteria,
  updateFilter,
  updateRule,
}) => {
  const handleUpdateCriteria = (
    criteriaId: string,
    value: Partial<FilterCriteria>
  ) => {
    const criteria = filter?.rule?.criteria ?? [];

    const index = criteria.findIndex(
      (criteria) => criteria.criteriaId === criteriaId
    );
    updateRule(filter.id ?? '', {
      criteria: [
        ...criteria.slice(0, index),
        {
          ...criteria[index],
          ...value,
        },
        ...criteria.slice(index + 1),
      ],
    });
  };

  const handleTitleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!filter.id) return;
    updateFilter(filter.id, {
      title: event.target.value,
    });
  };

  return (
    <>
      <Wrapper key={filter.id}>
        <RuleOptionsWrapper>
          <TextField
            label="Rule name"
            id="filter-title"
            value={filter.title ?? ''}
            onChange={handleTitleOnChange}
            placeholder={getDefaultFilterTitle(filter)}
          />
          <DateSelection
            type={DateSelectionType.FROM}
            filter={filter}
            updateRule={updateRule}
          />
          <DateSelection
            type={DateSelectionType.TO}
            filter={filter}
            updateRule={updateRule}
          />
        </RuleOptionsWrapper>
        {filter.rule?.criteria?.map((criteria, index, array) => (
          <Criteria
            key={`criteria-${criteria.criteriaId}-${criteria.dataType}-${criteria.condition}-${index}`}
            criteria={criteria}
            onDelete={() => onDeleteCriteria(filter.id, criteria.criteriaId)}
            onUpdateCriteria={handleUpdateCriteria}
            isLastCriteria={index === array.length - 1}
          />
        ))}
        <AddNewCriteriaButtonWrapper>
          <Button
            variant="ghost"
            color="secondary"
            onClick={() => onAddNewCriteria(filter.id)}
          >
            <Icon data={add} />
            Add new criteria
          </Button>
        </AddNewCriteriaButtonWrapper>
        {!isLastRuleLeft && (
          <DeleteRuleWrapper>
            <DeleteRuleButton
              variant="ghost_icon"
              color="secondary"
              onClick={() => onDeleteRule(filter.id)}
            >
              <Icon data={delete_to_trash} />
            </DeleteRuleButton>
          </DeleteRuleWrapper>
        )}
      </Wrapper>
    </>
  );
};
