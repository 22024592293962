import { FC, useRef } from 'react';

import { colors, elevation, spacings } from '@equinor/amplify-component-lib';
import { useIsMutating } from '@tanstack/react-query';

import { PlotFooter } from 'components/PlotFooter/PlotFooter';

import { useIsFiltersLoading } from '../..//hooks/useIsFiltersLoading';
import { useLineChartHelpLines } from '../..//hooks/useLineChartHelpLines';
import { useLinePlot } from '../..//hooks/useLinePlot';
import { useGetPressureDataWellboresFlat } from '../../hooks/useGetPressureDataWellboresFlat';
import { SelectedHelpLines } from './SelectedHelplines/SelectedHelpLines';
import { FilterType, MeasuredDataFilter, PressureDataSimple } from 'src/api';
import { LineChart } from 'src/components/Charts/LineChart/LineChart';
import {
  ACTIONS_AND_TITLE_HEIGHT,
  LINE_CHART_BRUSH_HEIGHT,
  LINE_PLOT_FOOTER_HEIGHT,
  SAVE_MEASURED_DATA_FILTER,
  TOOLBAR_FOOTER_HEIGHT,
  TOP_BAR_HEIGHT,
} from 'src/constants';

import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  padding: ${spacings.medium};
  background-color: ${colors.ui.background__default.rgba};
  border-radius: 4px;
  box-shadow: ${elevation.raised};
`;

interface EditPlotProps {
  pressureData: PressureDataSimple[][];
}

export const EditPlot: FC<EditPlotProps> = ({ pressureData }) => {
  const {
    helpLines,
    selectedRightAxis,
    isLoading,
    isDragActive,
    filteredData,
  } = useLinePlot();
  const { helpLineChartData } = useLineChartHelpLines(helpLines);
  const { isDeletingFilters } = useIsFiltersLoading();
  const selectedHelpLinesRef = useRef<HTMLDivElement>(null);
  const { isFetching: pressureDataLoading } = useGetPressureDataWellboresFlat();
  const lastMutationWasDrag = useRef(false);

  useIsMutating({
    // @ts-expect-error The predicate expects a boolean return, but i just need to set/unset the ref
    predicate: (mutation) => {
      if (
        mutation.options.mutationKey?.includes(SAVE_MEASURED_DATA_FILTER) &&
        (mutation.state.variables as MeasuredDataFilter).type ===
          FilterType.DRAG
      ) {
        lastMutationWasDrag.current = true;
      } else if (lastMutationWasDrag.current) {
        lastMutationWasDrag.current = false;
      }
    },
  });

  return (
    <Container>
      <SelectedHelpLines ref={selectedHelpLinesRef} />
      <LineChart
        wellbores={pressureData}
        // medium spacings are padding of white plot wrapper - large spacings are gaps in header and above line plot footer and two outer margins
        lineChartHeight={`calc(
            100vh - (${spacings.medium} * 2) - ${selectedHelpLinesRef.current?.clientHeight ?? 36}px - (${spacings.large} * 4) - ${LINE_CHART_BRUSH_HEIGHT} - ${LINE_PLOT_FOOTER_HEIGHT} - ${TOOLBAR_FOOTER_HEIGHT}
             - ${ACTIONS_AND_TITLE_HEIGHT} - ${TOP_BAR_HEIGHT})
            `}
        helpLineChartData={helpLineChartData}
        horizontalBrushHeight={LINE_CHART_BRUSH_HEIGHT}
        selectedRightAxis={selectedRightAxis}
        backgroundColor={colors.ui.background__default.rgba}
        showLoadingSkeleton={isLoading}
        showLoadingBlur={
          isDeletingFilters ||
          (pressureDataLoading && !lastMutationWasDrag.current)
        }
        isDragActive={isDragActive}
      />
      <PlotFooter data={filteredData} helpLines={helpLines} />
    </Container>
  );
};
