import { FC, useMemo } from 'react';

import { WebMercatorViewport } from '@deck.gl/core';
import { colors } from '@equinor/amplify-component-lib';

import { useMapInner } from 'src/pages/MapView/components/Map/hooks/useMapInner';
import { useMapOptions } from 'src/pages/MapView/hooks';

import styled from 'styled-components';

const Area = styled.div`
  background-color: ${colors.text.static_icons__tertiary.hex}33;
  box-sizing: border-box;
  border: 2px dashed ${colors.text.static_icons__secondary.rgba};
  position: absolute;
  z-index: 200;
`;

export const SelectionArea: FC = () => {
  const { selectionAreaStart, selectionAreaEnd } = useMapOptions();
  const { viewport } = useMapInner();
  const typedViewport = viewport as WebMercatorViewport;

  const [startX, startY] = useMemo(() => {
    if (!selectionAreaStart.current) return [undefined, undefined];
    return typedViewport.project(selectionAreaStart.current);
  }, [selectionAreaStart, typedViewport]);

  const [endX, endY] = useMemo(() => {
    if (!selectionAreaEnd) return [undefined, undefined];
    return typedViewport.project(selectionAreaEnd);
  }, [selectionAreaEnd, typedViewport]);

  const transformStyle = useMemo(() => {
    if (!startX || !endX || !startY || !endY) return '';
    const transformX = startX > endX;
    const transformY = startY > endY;
    return `translate(${transformX ? '-100%' : '0%'}, ${
      transformY ? '-100%' : '0%'
    })`;
  }, [endX, endY, startX, startY]);

  if (!startX || !endX || !startY || !endY) return null;
  return (
    <Area
      style={{
        width: Math.abs(endX - startX),
        height: Math.abs(endY - startY),
        top: `${startY}px`,
        left: `${startX}px`,
        transform: transformStyle,
      }}
    />
  );
};
