/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Area } from '../models/Area';
import type { CaseDto } from '../models/CaseDto';
import type { OutlineTypeGet } from '../models/OutlineTypeGet';
import type { PolygonFeatureCollection } from '../models/PolygonFeatureCollection';
import type { WellboreLines } from '../models/WellboreLines';
import type { WellBoreMaster } from '../models/WellBoreMaster';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FieldService {
  /**
   * Get Cases.
   * @param fieldUuid
   * @returns CaseDto OK
   * @throws ApiError
   */
  public static getCases(fieldUuid: string): CancelablePromise<Array<CaseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/Case',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get field outline types for specified ensemble id.
   * @param fieldUuid
   * @param caseId
   * @returns OutlineTypeGet OK
   * @throws ApiError
   */
  public static getFieldOutlineTypesByCaseId(
    fieldUuid: string,
    caseId: string
  ): CancelablePromise<Array<OutlineTypeGet>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/Case/{caseId}/fieldOutlines/outlineTypes',
      path: {
        fieldUuid: fieldUuid,
        caseId: caseId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get field outline features and geometries for specified ensemble id and file id.
   * @param fieldUuid
   * @param caseId
   * @param fileId
   * @returns PolygonFeatureCollection OK
   * @throws ApiError
   */
  public static getFieldOutlineFeaturesByFileId(
    fieldUuid: string,
    caseId: string,
    fileId: string
  ): CancelablePromise<PolygonFeatureCollection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/Case/{caseId}/fieldOutlines/features/{fileId}',
      path: {
        fieldUuid: fieldUuid,
        caseId: caseId,
        fileId: fileId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get faultline types for specified ensemble id.
   * @param fieldUuid
   * @param caseId
   * @returns OutlineTypeGet OK
   * @throws ApiError
   */
  public static getFaultlineTypesByCaseId(
    fieldUuid: string,
    caseId: string
  ): CancelablePromise<Array<OutlineTypeGet>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/Case/{caseId}/faultLines/outlineTypes',
      path: {
        fieldUuid: fieldUuid,
        caseId: caseId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get faultline features and geometries for specified ensemble id and file id.
   * @param fieldUuid
   * @param caseId
   * @param fileId
   * @returns PolygonFeatureCollection OK
   * @throws ApiError
   */
  public static getFaultlineFeaturesByFileId(
    fieldUuid: string,
    caseId: string,
    fileId: string
  ): CancelablePromise<PolygonFeatureCollection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/Case/{caseId}/faultLines/features/{fileId}',
      path: {
        fieldUuid: fieldUuid,
        caseId: caseId,
        fileId: fileId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * GetArea outlines for a field
   * @param fieldUuid
   * @returns Area OK
   * @throws ApiError
   */
  public static getAreas(fieldUuid: string): CancelablePromise<Array<Area>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/areas',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get Wells in area
   * @param fieldUuId
   * @param area
   * @returns WellBoreMaster OK
   * @throws ApiError
   */
  public static getWellsInArea(
    fieldUuId: string,
    area: string
  ): CancelablePromise<Array<WellBoreMaster>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuId}/areas/{area}/wells',
      path: {
        fieldUuId: fieldUuId,
        area: area,
      },
    });
  }
  /**
   * wellpositions for a field
   * @param fieldUuid
   * @returns WellboreLines OK
   * @throws ApiError
   */
  public static getWellpositions(
    fieldUuid: string
  ): CancelablePromise<Array<WellboreLines>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/wellpositions',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
