import { FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  SelectOption,
  SelectOptionRequired,
  SingleSelect,
} from '@equinor/amplify-component-lib';

import { useDeleteDetail } from '../../hooks/useDeleteDetail';
import { usePutDraftPrognosis } from '../../hooks/usePutDraftPrognosis';
import { ConfirmDialog } from '../ConfimDialog/ConfirmDialog';
import { DoubleField, Form, TextField } from './FormHeader.styles';
import { useGetApproverByField } from 'src/hooks/useGetApproverByField';
import { useGetPrognosisTypes } from 'src/hooks/useGetPrognosisTypes';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { OperationValues } from 'src/utils';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { useGetWellPlanningPhases } from 'hooks/useWellPlanningPhases';

export const FormHeader: FC = () => {
  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useFormContext<EditPrognosisFormValues>();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();
  const { isInitializing } = useEditPrognosis();
  const { data: operations } = useGetPrognosisTypes();
  const { data: approvers } = useGetApproverByField();
  const { data: planningPhases } = useGetWellPlanningPhases();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();
  const { prognosisDetails } = watch();

  const [open, setOpen] = useState(false);
  const [nextValue, setNextValue] = useState<
    SelectOptionRequired | undefined
  >(); // used to store the next value when the user selects a new operation

  const operationValues = getValues('operation');

  const showPlanningPhase =
    operationValues?.value === OperationValues.NEW_WELL.toString();

  // convert data to SelectOptionRequired shape
  const operationOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      operations?.map((item) => ({
        value: item.id?.toString() ?? '',
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [operations]);

  const approverOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      approvers?.map((item) => ({
        value: item.shortName,
        label: item.displayName,
      })) ?? []
    );
  }, [approvers]);

  const handleUpdateTitle = () => {
    trigger('title');

    const titleErrors = errors.title;
    const hasTitleErrors = titleErrors && Object.keys(titleErrors).length > 0;

    if (isDirty && !hasTitleErrors) {
      updateDraftPrognosis();
    }
  };

  const handleUpdateField = (
    key: 'operation' | 'wellPlanningPhase' | 'approver',
    item: SelectOption<SelectOptionRequired> | undefined
  ) => {
    const previousValue: SelectOptionRequired | undefined = getValues(key);

    if (key == 'operation' && prognosisDetails?.length !== 0) {
      setOpen(true);
      setNextValue(item as SelectOptionRequired);
      return;
    }

    setValue(key, {
      value: item?.value ?? 'N/A',
      label: item?.label ?? 'N/A',
    });

    const isFieldDirty = item?.value !== previousValue?.value;

    if (isFieldDirty) {
      updateDraftPrognosis();
    }
  };

  const handleConfirm = () => {
    const previousValue: SelectOptionRequired | undefined =
      getValues('operation');

    if (nextValue) {
      setValue('operation', nextValue);
    }

    prognosisDetails.map((detail) => {
      deletePrognosisDetail(detail.id);
    });

    const isFieldDirty = nextValue?.value !== previousValue?.value;

    if (isFieldDirty) {
      setOpen(false);
      updateDraftPrognosis();
    }
  };

  const handleUpdateDate = () => {
    const validFrom = watch('validFrom');
    const validTo = watch('validTo');

    if (validFrom && validTo) {
      trigger('validTo');
      trigger('validFrom');
    }

    updateDraftPrognosis();
  };

  return (
    <Form>
      <TextField
        id="Title"
        label="Title"
        placeholder="E.g Revised prognosis #1"
        disabled={isInitializing}
        {...register('title', {
          onBlur: () => handleUpdateTitle(),
        })}
        meta="Required"
        variant={errors.title ? 'error' : undefined}
        helperText={errors.title?.message}
        data-testid="title-input"
      />
      <DoubleField>
        <Controller
          name="operation"
          control={control}
          render={({ field }) => (
            <SingleSelect
              label="Operation"
              items={operationOptions}
              placeholder="Select one..."
              value={field.value}
              onSelect={(item) => {
                handleUpdateField('operation', item);
              }}
              loading={isInitializing}
              lightBackground
              meta="Required"
              variant={errors.operation ? 'error' : undefined}
              helperText={errors.operation?.message}
              data-testid="operation-input"
              clearable={false}
            />
          )}
        />
        {showPlanningPhase && planningPhases?.length !== 0 && (
          <Controller
            name="wellPlanningPhase"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <SingleSelect
                label="Planning phase"
                items={
                  planningPhases?.map((phase) => ({
                    value: phase.id.toString(),
                    label: phase.name,
                  })) ?? []
                }
                placeholder="Select one..."
                value={field.value}
                onSelect={(item) => {
                  handleUpdateField('wellPlanningPhase', item);
                }}
                loading={isInitializing}
                lightBackground
                meta="Required"
                variant={errors.wellPlanningPhase ? 'error' : undefined}
                helperText={errors.wellPlanningPhase?.message}
                data-testid="planning-phase-input"
                clearable={false}
              />
            )}
          />
        )}
      </DoubleField>
      <Controller
        name="approver"
        control={control}
        render={({ field }) => (
          <SingleSelect
            label="Approver"
            items={approverOptions}
            placeholder="Select one..."
            value={field.value}
            onSelect={(item) => {
              handleUpdateField('approver', item);
            }}
            loading={isInitializing}
            lightBackground
            meta="Required"
            variant={errors.approver ? 'error' : undefined}
            helperText={errors.approver?.message}
            data-testid="approver-input"
            clearable={false}
          />
        )}
      />
      <DoubleField>
        <TextField
          id="validFrom"
          label="Valid from"
          type="date"
          disabled={isInitializing}
          {...register('validFrom', {
            onChange: () => handleUpdateDate(),
          })}
          meta="Required"
          variant={errors.validFrom ? 'error' : undefined}
          helperText={errors.validFrom?.message}
          data-testid="valid-from-input"
        />
        <TextField
          id="validTo"
          label="Valid to"
          type="date"
          disabled={isInitializing}
          {...register('validTo', {
            onChange: () => handleUpdateDate(),
          })}
          meta="Required"
          variant={errors.validTo ? 'error' : undefined}
          helperText={errors.validTo?.message}
          data-testid="valid-to-input"
        />
      </DoubleField>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleConfirm()}
      />
    </Form>
  );
};
