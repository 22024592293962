import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog, spacings, Typography } from '@equinor/amplify-component-lib';

import { General } from './General/General';
import { Gradients } from './Gradients/Gradients';
import { TextFields } from './TextFields/TextFields';
import { datumKeys, referenceKeys } from './DataInputDialog.utils';
import { PrognosisDetailDto } from 'src/api';
import { EMPTY_DETAIL } from 'src/constants';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { usePutDetail } from 'src/pages/EditPrognosis/hooks/usePutDetail';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.x_large};
  padding: ${spacings.medium};
  max-height: 60vh;
  overflow-y: auto;
`;

interface DataInputDialogProps {
  open: boolean;
  close: () => void;
  index: number;
  prognosisDetailSnapshot: PrognosisDetailDto | undefined;
}

export const DataInputDialog: FC<DataInputDialogProps> = ({
  open,
  close,
  index,
  prognosisDetailSnapshot,
}) => {
  const {
    watch,
    formState: { errors, isDirty },
    resetField,
    clearErrors,
    setValue,
  } = useFormContext<EditPrognosisFormValues>();
  const { mutate: updateDetail } = usePutDetail();
  const { prognosisDetails } = watch();
  const { optionalAttributes } = useOptionalAttributes();

  const detail = prognosisDetails[index];

  const handleAddData = () => {
    const detailErrors = errors.prognosisDetails?.[index];
    const hasDetailErrors =
      detailErrors && Object.keys(detailErrors).length > 0;

    if (isDirty && !hasDetailErrors) {
      updateDetail(detail);
      close();
    }
  };

  const handleOnCancel = () => {
    resetField(`prognosisDetails.${index}`, {
      defaultValue: prognosisDetailSnapshot,
    });
    close();
  };

  const handleOnClear = () => {
    const clearedDetail = {
      ...prognosisDetailSnapshot,
      ...EMPTY_DETAIL,
    } as PrognosisDetailDto;
    setValue(
      `prognosisDetails.${index}`,
      { ...clearedDetail },
      { shouldDirty: true }
    );
    clearErrors(`prognosisDetails.${index}`);
  };

  return (
    <Container>
      <Dialog
        actions={[
          {
            color: 'danger',
            onClick: handleOnClear,
            text: 'Clear values',
            variant: 'ghost',
            position: 'left',
          },
          {
            color: 'primary',
            onClick: handleOnCancel,
            text: 'Cancel',
            variant: 'outlined',
          },
          {
            color: 'primary',
            onClick: handleAddData,
            text: 'Save data',
            variant: 'contained',
          },
        ]}
        title={
          <>
            <Typography variant="h6">
              Fill data for {detail?.lithostratSubzone}
            </Typography>
          </>
        }
        width={840}
        withContentPadding={false}
        onClose={handleOnCancel}
        open={open}
        withBorders
      >
        <Container>
          <General label="General" index={index} />
          <TextFields label="Reference" index={index} keys={referenceKeys} />
          {optionalAttributes.datum && (
            <TextFields label="Datum" index={index} keys={datumKeys} />
          )}
          <Gradients label="Gradients" index={index} />
        </Container>
      </Dialog>
    </Container>
  );
};
