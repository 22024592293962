import { Breadcrumbs } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { styled } from 'styled-components';

const { colors } = tokens;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  > ol > li:first-child > a {
    color: ${colors.interactive.primary__resting.rgba};
    font-weight: 500;
  }
  > ol > li:last-child > span {
    color: ${colors.interactive.disabled__text.rgba};
    font-weight: 500;
    cursor: not-allowed;
    text-decoration: none;
  }
`;
