import { useCallback } from 'react';

import { useAreaOutlines } from '../useAreaOutlines';
import { useFaultLines } from '../useFaultLines';
import { useFieldOutline } from '../useFieldOutline';
import { useInterpolatedMap } from '../useInterpolatedMap';
import { useWellborePaths } from '../useWellborePaths';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground, MapLayer } from 'src/pages/MapView/MapView.types';

import { Layer as DeckGlLayer } from 'deck.gl';

export function useMapLayers() {
  const { layers, backgroundLayer } = useMapOptions();

  const fieldOutline = useFieldOutline();
  const faultLines = useFaultLines();
  const areaOutlines = useAreaOutlines();
  const interpolatedMap = useInterpolatedMap();
  const wellborePaths = useWellborePaths();

  const mapLayers: DeckGlLayer[] = [
    interpolatedMap,
    fieldOutline,
    faultLines,
    areaOutlines,
    wellborePaths,
  ];

  const handleLayerFiltering = useCallback(
    ({ layer }: { layer: { id: string } }) => {
      return (
        layers.includes(layer.id as MapLayer) ||
        layer.id === 'measure-area-layer' ||
        (layer.id as MapBackground) === backgroundLayer
      );
    },
    [backgroundLayer, layers]
  );

  return {
    mapLayers,
    handleLayerFiltering,
  };
}
