import { colors, spacings } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

interface RowProps {
  $isSelected?: boolean;
}

export const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ $isSelected }) =>
    $isSelected ? colors.interactive.primary__selected_hover.rgba : 'inherit'};
  border-bottom: 1px solid
    ${({ $isSelected }) =>
      $isSelected
        ? colors.text.static_icons__tertiary.rgba
        : colors.ui.background__medium.rgba};
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: fit-content;
  padding: 0 ${spacings.small};
`;
