import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { useQuery } from '@tanstack/react-query';

import { WellService } from '../api';
import { useApp } from './useApp';
import {
  FIELDS_WITH_MEASURED_DATA,
  MEASURED_DATA_FEATURE_KEY,
} from 'src/constants';
import { DRILLED_WELLS_FEATURES_KEY } from 'src/constants/queryKeys';

export function useGetDrilledWellsFeatures() {
  const { field } = useApp();
  const { showContent } = useFeatureToggling({
    featureKey: MEASURED_DATA_FEATURE_KEY,
    showIfKeyIsMissing: false,
  });

  return useQuery({
    queryKey: [DRILLED_WELLS_FEATURES_KEY, field?.uuid],
    queryFn: () =>
      WellService.getDrilledWellsFeaturesByField(field?.uuid ?? ''),
    staleTime: 5000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled:
      FIELDS_WITH_MEASURED_DATA.includes(field?.name ?? '') && showContent, // TODO: remove this when all fields have measured data
  });
}
