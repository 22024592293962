import {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  TouchEvent,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useDrag } from '@visx/drag';
import { HandlerArgs } from '@visx/drag/lib/Drag';
import { ScaleLinear } from '@visx/vendor/d3-scale';

import {
  FilterType,
  MeasuredDataFilter,
  PressureDataSimple,
  PressureType,
} from 'src/api';
import { WellboreTooltip } from 'src/components/Charts/LineChart/LineChart.types';
import { PRES_DATA_FOR_WELLBORES_FLAT_KEY } from 'src/constants';
import { useApp, useMeasuredData } from 'src/hooks';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useSaveFilter } from 'src/pages/EditMeasuredData/hooks/useSaveFilter';

import styled from 'styled-components';

interface DragCircleProps {
  $color: string;
}

const DragCircle = styled.circle<DragCircleProps>`
  &:hover {
    stroke: ${({ $color }) => $color};
  }
`;

interface DraggableDataPointProps {
  tooltipData: WellboreTooltip;
  tooltipLeft: number | undefined;
  width: number;
  height: number;
  isDraggingDate: Date | undefined;
  valueScale: ScaleLinear<number, number, never>;
  setIsDraggingDate: Dispatch<SetStateAction<Date | undefined>>;
  handleTooltip: (
    event:
      | TouchEvent<SVGRectElement | SVGCircleElement>
      | MouseEvent<SVGRectElement | SVGCircleElement>,
    manualValue?: number
  ) => null | undefined;
}

export const DraggableDataPoint: FC<DraggableDataPointProps> = ({
  handleTooltip,
  tooltipData,
  tooltipLeft,
  width,
  height,
  valueScale,
  isDraggingDate,
  setIsDraggingDate,
}) => {
  const { mutate: saveFilter } = useSaveFilter();
  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { field } = useApp();
  const { wellId, dhpg } = useParams();
  const { from, to } = useMeasuredData();
  const queryClient = useQueryClient();
  const isDragging = useMemo(
    () => isDraggingDate !== undefined,
    [isDraggingDate]
  );

  const handleOnDragEnd = (args: HandlerArgs) => {
    setIsDraggingDate(undefined);
    if (args.dy === 0) return;
    const existingManualOrDragFilterForDate = filtersForWell.find((filter) => {
      if (
        filter.type === FilterType.MANUAL ||
        filter.type === FilterType.DRAG
      ) {
        const filterDateString = filter.changes?.at(0)?.date;

        if (!filterDateString) return;
        const filterDate = new Date(new Date(filterDateString).toDateString());
        return filterDate.toDateString() === tooltipData.date.toDateString();
      }
    });

    if (!args.y) return;

    let newFilter: MeasuredDataFilter;

    if (existingManualOrDragFilterForDate) {
      newFilter = {
        ...existingManualOrDragFilterForDate,
        type: FilterType.DRAG,
        changes: [
          {
            ...existingManualOrDragFilterForDate.changes?.at(0),
            filterChangeOrder:
              existingManualOrDragFilterForDate.changes?.at(0)
                ?.filterChangeOrder ?? 0,
            to: valueScale.invert(args.y + args.dy),
          },
        ],
      };
    } else {
      newFilter = {
        wellId,
        fieldGuid: field?.uuid ?? '',
        dhpg: Number(dhpg),
        type: FilterType.DRAG,
        disabled: false,
        filterOrder: 0,
        changes: [
          {
            filterChangeOrder: 0,
            // TODO: This .setHours is a quick fix for release to Johan Sverdrup, so make sure the selected date is the same when making the ISO string.
            //  Will probably cause issues on international fields. Dec. 2024
            date: new Date(tooltipData.date.setHours(3, 0, 0, 0)).toISOString(),
            from: valueScale.invert(args.y),
            to: valueScale.invert(args.y + args.dy),
          },
        ],
      };
    }

    queryClient.setQueryData(
      [PRES_DATA_FOR_WELLBORES_FLAT_KEY, field?.uuid, from, to, wellId],
      (prev: PressureDataSimple[] | undefined) => {
        if (!prev) return;
        return prev.map((item) => {
          const newFilterValue = newFilter.changes?.at(0)?.to;
          if (
            new Date(item.prodDay).toDateString() ===
              tooltipData.date.toDateString() &&
            newFilterValue
          ) {
            return {
              ...item,
              pressureType: PressureType.CORRECTED,
              pressureFinal: newFilterValue,
            };
          }
          return item;
        });
      }
    );

    if (!wellId || !dhpg) return;

    saveFilter(newFilter);
  };

  const handleOnDragStart = () => {
    setIsDraggingDate(tooltipData.date);
  };

  const {
    dragStart,
    dragEnd,
    dragMove,
    y = 0,
    dy,
  } = useDrag({
    y: tooltipData.circleTop,
    onDragEnd: handleOnDragEnd,
    onDragStart: handleOnDragStart,
    snapToPointer: false,
    resetOnStart: true,
    isDragging: isDragging,
    restrict: { xMin: tooltipLeft, xMax: tooltipLeft },
  });

  const handleOnMove = (
    event:
      | MouseEvent<SVGRectElement | SVGCircleElement>
      | TouchEvent<SVGRectElement | SVGCircleElement>
  ) => {
    if (isDragging) {
      dragMove(event);
    }

    handleTooltip(event, y + dy);
  };

  return (
    <>
      <rect
        fill="transparent"
        width={width}
        height={height}
        onMouseUp={dragEnd}
        onMouseMove={isDragging ? handleOnMove : handleTooltip}
        onTouchStart={dragStart}
        onTouchEnd={isDragging ? dragEnd : undefined}
      />
      <DragCircle
        $color={tooltipData.color}
        id="draggable-circle"
        cx={tooltipLeft}
        cy={y}
        r={4}
        fill={tooltipData.color}
        transform={`translate(0, ${dy})`}
        strokeWidth={4}
        stroke={isDragging ? tooltipData.color : 'transparent'}
        cursor={isDragging ? 'grabbing' : 'grab'}
        onMouseMove={handleOnMove}
        onMouseUp={dragEnd}
        onMouseDown={dragStart}
        onTouchMove={handleOnMove}
        onTouchEnd={dragEnd}
        onTouchStart={dragStart}
      />
    </>
  );
};
