import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';
import { TooltipWithBounds } from '@visx/tooltip';

import { styled } from 'styled-components';

const { colors, elevation, shape } = tokens;

const Wrapper = styled.div`
  position: relative;
`;

const Tooltip = styled(TooltipWithBounds)`
  background: ${colors.ui.background__scrim.rgba} !important;
  box-shadow: ${elevation.overlay};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${shape.rounded.borderRadius};
  p {
    color: ${colors.text.static_icons__primary_white.rgba};
  }
  p:first-child {
    font-weight: 600;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${shape.corners.borderRadius};
  background: ${colors.ui.background__default.rgba};
  box-shadow: ${elevation.raised};
  padding: ${spacings.large};
  height: calc(100% - (${spacings.large} * 2));
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

const ChartContainer = styled.div`
  width: 100%;
  height: inherit;
`;

export { Wrapper, Tooltip, Card, Header, HeaderSection, ChartContainer };
