import { Dispatch, FC, ReactNode, SetStateAction, useRef } from 'react';

import {
  colors,
  Icon,
  Menu,
  OptionalTooltip,
  spacings,
} from '@equinor/amplify-component-lib';
import { arrow_drop_down, arrow_drop_up, IconData } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';
import { useOutsideClick } from '@equinor/eds-utils';

import {
  SETTINGS_BUTTON_HEIGHT,
  SETTINGS_BUTTON_WIDTH,
} from 'src/pages/MapView/components/MapSettings/MapSettings.constants';

import styled from 'styled-components';

const { shape } = tokens;

const ButtonContainer = styled.div`
  display: flex;
`;

interface ButtonProps {
  $isLastItem?: boolean;
  $open: boolean;
}

const MenuContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 0 ${spacings.small};
`;

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${SETTINGS_BUTTON_HEIGHT};
  width: ${SETTINGS_BUTTON_WIDTH};
  padding: ${spacings.small};
  border-radius: 0;
  border: none;
  ${(props) =>
    props.$isLastItem &&
    `
    border-top-right-radius: ${shape.button.borderRadius};
    border-bottom-right-radius: ${shape.button.borderRadius};
  `}
  background: ${(props) =>
    props.$open
      ? colors.ui.background__light.rgba
      : colors.ui.background__default.rgba};
  transition: background 200ms;
  &:hover {
    cursor: pointer;
    background-color: ${colors.interactive.primary__hover_alt.rgba};
  }
`;

interface SettingsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  icon: IconData;
  title: string;
  children: ReactNode;
  isLastItem?: boolean;
}

export const SettingsMenu: FC<SettingsProps> = ({
  open,
  setOpen,
  icon,
  title,
  isLastItem,
  children,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleOnClick = () => {
    setOpen(!open);
  };

  useOutsideClick(containerRef.current as HTMLElement, (e) => {
    if (!anchorRef.current?.contains(e.target as Node) && open) {
      setOpen(false);
    }
  });

  return (
    <>
      <ButtonContainer ref={containerRef}>
        <OptionalTooltip title={title}>
          <Button
            onClick={() => handleOnClick()}
            $isLastItem={isLastItem}
            $open={open}
          >
            <Icon
              data={icon}
              size={24}
              color={colors.interactive.primary__resting.rgba}
            />
            <Icon
              data={open ? arrow_drop_up : arrow_drop_down}
              size={24}
              color={colors.interactive.primary__resting.rgba}
            />
          </Button>
        </OptionalTooltip>
      </ButtonContainer>
      <Menu
        open={open}
        anchorEl={containerRef.current}
        placement="bottom-end"
        ref={anchorRef}
        style={{
          background: colors.ui.background__light.rgba,
          zIndex: 400,
        }}
      >
        <Menu.Section title={title}>
          <MenuContentWrapper>{children}</MenuContentWrapper>
        </Menu.Section>
      </Menu>
    </>
  );
};
