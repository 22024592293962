import { useState } from 'react';

import {
  colors,
  elevation,
  Icon,
  spacings,
} from '@equinor/amplify-component-lib';
import { arrow_drop_right, list } from '@equinor/eds-icons';

import { MapLayers } from './MapLayers';
import {
  SETTINGS_BUTTON_HEIGHT,
  SETTINGS_BUTTON_WIDTH,
} from 'src/pages/MapView/components/MapSettings/MapSettings.constants';
import { MapTools } from 'src/pages/MapView/components/MapSettings/MapTools';
import { WellboreSelector } from 'src/pages/MapView/components/MapSettings/WellboreSelector';

import { motion } from 'framer-motion';
import { useMeasuredData } from 'hooks/useMeasuredData';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: ${spacings.medium};
  left: ${spacings.medium};
  display: flex;
  box-shadow: ${elevation.raised};
`;

const InspectWellsButton = styled.div`
  background-color: ${colors.ui.background__default.rgba};
  display: flex;
  height: ${SETTINGS_BUTTON_HEIGHT};
  align-items: center;
  padding: ${spacings.small};

  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  gap: ${spacings.small};
  &:hover {
    cursor: pointer;
    background-color: ${colors.interactive.primary__hover_alt.rgba};
  }
`;

export const MapSettings = () => {
  const { selectedMeasurements } = useMeasuredData();

  const [isWellboreSelectOpen, setIsWellboreSelectOpen] = useState(false);

  const handleWellboreSelectOnClick = () => {
    setIsWellboreSelectOpen(!isWellboreSelectOpen);
  };

  const closeWellboreSelect = () => {
    setIsWellboreSelectOpen(false);
  };

  return (
    <Container>
      <motion.div
        initial={{ width: 'auto' }}
        animate={{
          width: isWellboreSelectOpen
            ? `calc(100vw - 64px - (${spacings.medium} * 2) - (${SETTINGS_BUTTON_WIDTH} * 2))`
            : 'auto',
        }}
      >
        {isWellboreSelectOpen ? (
          <WellboreSelector onClose={closeWellboreSelect} />
        ) : (
          <InspectWellsButton onClick={handleWellboreSelectOnClick}>
            <Icon
              data={list}
              color={colors.interactive.primary__resting.rgba}
            />
            Select wells to inspect{' '}
            {selectedMeasurements.length > 0
              ? `(${selectedMeasurements.length})`
              : ''}
            <Icon
              data={arrow_drop_right}
              color={colors.interactive.primary__resting.rgba}
            />
          </InspectWellsButton>
        )}
      </motion.div>
      <MapTools />
      <MapLayers />
    </Container>
  );
};
