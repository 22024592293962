import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { formatDate } from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { WellboreDetailed } from '../api';
import { PRES_DATA_FOR_WELLBORES_FLAT_KEY } from '../constants';
import { getDefaultBrushRange } from 'src/components/Charts/LineChart/LineChart.utils';
import { useApp } from 'src/hooks';
import { useGetDrilledWellsFeatures } from 'src/hooks/useGetDrilledWellsFeatures';
import { getNumberOfDays } from 'src/pages/MapView/components/Footer/Footer.utils';
import { Measurement, RangeEnum } from 'src/types';
import { compareMeasurements, getDateForDaysIntoPast } from 'src/utils';

interface MeasuredDataContextType {
  wellbores: WellboreDetailed[];
  fromDate: Date;
  toDate: Date;
  setFromDate: (value: Date) => void;
  setToDate: (value: Date) => void;
  from: string;
  to: string;
  daysBetweenFromTo: number;
  range: RangeEnum;
  handleRangeChange: (range: RangeEnum) => void;
  currentBrushRange: [Date, Date];
  setCurrentBrushRange: Dispatch<SetStateAction<[Date, Date]>>;
  selectedMeasurements: Measurement[];
  setSelectedMeasurements: Dispatch<SetStateAction<Measurement[]>>;
  addMeasurements: (measurements: Measurement[]) => void;
  removeMeasurement: (measurement: Measurement) => void;
  clear: () => void;
}

export const MeasuredDataContext = createContext<
  MeasuredDataContextType | undefined
>(undefined);

interface MeasuredDataProviderProps {
  children: ReactNode;
}

export const MeasuredDataProvider: FC<MeasuredDataProviderProps> = ({
  children,
}) => {
  const { data: wellbores } = useGetDrilledWellsFeatures();

  const [fromDate, setFromDate] = useState(getDateForDaysIntoPast(91));
  const [toDate, setToDate] = useState(getDateForDaysIntoPast(1));
  const [range, setRange] = useState<RangeEnum>(RangeEnum.THREE_MONTHS);

  const queryClient = useQueryClient();
  const { field } = useApp();
  const [selectedMeasurements, setSelectedMeasurements] = useState<
    Measurement[]
  >([]);

  const daysBetweenFromTo = useMemo(() => {
    return Math.round(
      (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24) // Unix time between fromDate/toDate divided by unix time for a single day
    );
  }, [fromDate, toDate]);

  const [currentBrushRange, setCurrentBrushRange] = useState<[Date, Date]>(
    getDefaultBrushRange(daysBetweenFromTo)
  );

  const handleRangeChange = (range: RangeEnum) => {
    if (range !== RangeEnum.CUSTOM) {
      const fromDate =
        getDateForDaysIntoPast(1).getTime() -
        1000 * 60 * 60 * 24 * getNumberOfDays(range);
      setFromDate(new Date(fromDate));
      setToDate(getDateForDaysIntoPast(1));
    }

    setRange(range);
  };

  const from = useMemo(
    () => formatDate(fromDate, { format: 'YYYY-MM-DD' }),
    [fromDate]
  );

  const to = useMemo(
    () => formatDate(toDate, { format: 'YYYY-MM-DD' }),
    [toDate]
  );

  const addMeasurements = (measurements: Measurement[]) => {
    setSelectedMeasurements((sm) => [
      ...sm,
      ...measurements.filter(
        (m) => sm.find((s) => compareMeasurements(s, m)) === undefined
      ),
    ]);
  };

  const removeMeasurement = (measurement: Measurement) => {
    const dhpg = measurement.dhpg === 0 ? 1 : measurement.dhpg;
    setSelectedMeasurements((Measurements) => [
      ...Measurements.filter(
        (w) => !(w.value === measurement.value && w.dhpg === dhpg)
      ),
    ]);
    queryClient.invalidateQueries({
      queryKey: [
        PRES_DATA_FOR_WELLBORES_FLAT_KEY,
        measurement.value,
        from,
        to,
        field?.uuid,
      ],
    });
  };

  return (
    <MeasuredDataContext.Provider
      value={{
        wellbores: wellbores ?? [],
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        from,
        daysBetweenFromTo,
        range,
        handleRangeChange,
        to,
        currentBrushRange,
        setCurrentBrushRange,
        selectedMeasurements,
        setSelectedMeasurements,
        removeMeasurement,
        addMeasurements,
        clear: () => setSelectedMeasurements([]),
      }}
    >
      {children}
    </MeasuredDataContext.Provider>
  );
};
