import { useAuth } from '@equinor/amplify-component-lib';

import { usePrognosisFromId } from 'src/hooks';
import { StatusIdEnum } from 'src/types/status';

/**
 * Custom hook to determine if the edit actions should be shown for the current user.
 *
 * This hook checks if the current user is the creator of the prognosis data and if the status of the prognosis
 * is either "submitted" or "revision required". If both conditions are met, it returns `true`, indicating that
 * the edit actions should be shown.
 *
 * @returns {boolean} - `true` if the edit actions should be shown, otherwise `false`.
 */
export function useShouldShowEditActions() {
  const { data: prognosisData } = usePrognosisFromId();
  const { account } = useAuth();

  const currentUserShortName = account?.username.split('@').at(0) ?? '';
  const isCreator = prognosisData?.createUser === currentUserShortName;
  const isSubmitted = prognosisData?.statusId === StatusIdEnum.SUBMITTED;
  const isRevisionRequired =
    prognosisData?.statusId === StatusIdEnum.REVISION_REQUIRED;

  return isCreator && (isSubmitted || isRevisionRequired);
}
