import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { LookupValuesService } from 'src/api';
import { LOOKUP_VALUES } from 'src/constants/queryKeys';

export function useLookupValues() {
  const { field } = useApp();

  return useQuery({
    queryKey: [LOOKUP_VALUES, field?.name ?? ''],
    queryFn: () =>
      LookupValuesService.getApiV1PrognosesLookupValues(field?.name ?? 'N/A'),
    enabled: field !== undefined,
  });
}
