import { ChangeEvent, FC, useMemo } from 'react';

import {
  Button,
  Chip,
  colors,
  Icon,
  SelectOptionRequired,
  SingleSelect,
  spacings,
  TextField,
} from '@equinor/amplify-component-lib';
import { close } from '@equinor/eds-icons';

import {
  ConditionOptions,
  DataTypeOptions,
} from './AdvancedFilterDialog.constants';
import { FilterCondition } from 'src/api';
import { FilterCriteria } from 'src/api';
import { FilterDataTypes } from 'src/api';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  gap: ${spacings.medium};
  overflow: visible;
  position: relative;
`;

const RemoveCriteriaButtonWrapper = styled.div`
  padding-top: 15px;
`;

const AndChip = styled(Chip)`
  position: absolute;
  left: 50%;
  transform: translateX(-100%);
  bottom: -28px;
  border: 1px solid ${colors.ui.background__medium.rgba};
  color: ${colors.text.static_icons__default.rgba} !important;
  &:hover {
    color: ${colors.text.static_icons__default.rgba} !important;
    border: 1px solid ${colors.ui.background__medium.rgba};
    background-color: ${colors.ui.background__light.rgba};
  }
`;

interface CriteriaProps {
  criteria: FilterCriteria;
  onDelete: () => void;
  onUpdateCriteria: (id: string, value: Partial<FilterCriteria>) => void;
  isLastCriteria: boolean;
}

export const Criteria: FC<CriteriaProps> = ({
  criteria,
  onDelete,
  onUpdateCriteria,
  isLastCriteria,
}) => {
  const criteriaValue = useMemo(() => {
    return criteria.criteria.toString();
  }, [criteria.criteria]);

  const handleOnSelectDataType = (value: SelectOptionRequired | undefined) => {
    if (!value) return;
    onUpdateCriteria(criteria.criteriaId ?? '', {
      dataType: value.value as FilterDataTypes,
    });
  };
  const handleOnSelectCriteria = (value: SelectOptionRequired | undefined) => {
    if (!value) return;
    onUpdateCriteria(criteria.criteriaId ?? '', {
      condition: value.value as FilterCondition,
    });
  };

  const handleCriteriaOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    onUpdateCriteria(criteria.criteriaId ?? '', {
      criteria: value,
    });
  };

  return (
    <Wrapper>
      <SingleSelect
        label="Data type"
        placeholder="Select data type..."
        items={DataTypeOptions}
        value={DataTypeOptions.find(
          (option) => (option.value as FilterDataTypes) === criteria.dataType
        )}
        onSelect={handleOnSelectDataType}
      />

      <SingleSelect
        label="Condition"
        placeholder="Select condition..."
        items={ConditionOptions}
        value={ConditionOptions.find(
          (option) => (option.value as FilterCondition) === criteria.condition
        )}
        clearable
        onSelect={handleOnSelectCriteria}
      />
      <div>
        <TextField
          id="criteria"
          label="Criteria"
          placeholder="Enter value..."
          value={criteriaValue}
          type="number"
          onChange={handleCriteriaOnChange}
        />
      </div>
      {!isLastCriteria && <AndChip>AND</AndChip>}
      <RemoveCriteriaButtonWrapper>
        <Button variant="ghost_icon" color="secondary" onClick={onDelete}>
          <Icon data={close} />
        </Button>
      </RemoveCriteriaButtonWrapper>
    </Wrapper>
  );
};
