import { useContext } from 'react';

import { EditMeasuredDataContext } from 'src/pages/EditMeasuredData/providers/EditMeasuredDataProvider';

export const useEditMeasuredData = () => {
  const context = useContext(EditMeasuredDataContext);
  if (context === undefined) {
    throw new Error(
      'useEditMeasuredData must be used within EditMeasuredDataProvider'
    );
  }

  return { ...context };
};
