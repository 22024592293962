export interface LegendRange {
  min: number;
  max: number;
}

export type DataSet = [number, number, number][];

export enum MapLayer {
  FIELD_OUTLINE = 'Field outline',
  FAULT_LINES = 'Fault lines',
  AREAS_OUTLINE = 'Areas outline',
  INJECTORS = 'Injectors',
  PRODUCERS = 'Producers',
  OPEN_WELLS = 'Open wells',
  CLOSED_WELLS = 'Closed wells',
  WELLBORE_PATHS = 'Wellbore paths',
  PRESSURE_VALUE = 'Pressure value',
  WELLBORE_LABEL = 'Wellbore label',
}

export enum MapBackground {
  INTERPOLATED = 'Interpolated map',
}
