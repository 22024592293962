import { colors } from '@equinor/amplify-component-lib';
import Slider from '@mui/material/Slider';
import { styled as muiStyled } from '@mui/material/styles';

export const StyledSlider = muiStyled(Slider)({
  color: colors.ui.background__medium.rgba,
  height: 14,
  margin: 0,

  '& .MuiSlider-rail': {
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    zIndex: '30',
    backgroundColor: '#fff',
    border: `2px solid ${colors.interactive.primary__resting.rgba}`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontFamily: 'Equinor',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '1.1px',
    textAlign: 'center',
    background: 'unset',
    padding: '0 8px',
    height: 32,
    color: colors.text.static_icons__default.rgba,
    opacity: 0.8,
    marginTop: '54px',
  },
  '& .MuiSlider-mark': {
    backgroundColor: colors.interactive.disabled__text.rgba,
    height: '3px',
    width: '3px',
    zIndex: '10',
    borderRadius: '50%',
  },
  '& .MuiSlider-markLabel': {
    color: colors.text.static_icons__tertiary.rgba,
    marginTop: '-40px',
    fontFamily: 'Equinor',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '12px',
    textAlign: 'center',
  },
}) as typeof Slider;
