import { FC } from 'react';

import { DotProgress } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const LoadingFilters = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(5px);
  z-index: 600;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LoadingBlur: FC = () => {
  return (
    <LoadingFilters>
      <div>
        <DotProgress color="primary" size={64} />
      </div>
    </LoadingFilters>
  );
};
