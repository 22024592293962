import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { faker } from '@faker-js/faker';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useOptionalAttributes } from './useOptionalAttributes';
import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';
import { cleanPrognosisDetail, EditPrognosisFormValues } from 'src/utils';

export const usePostDetail = () => {
  const { prognosisId } = useParams();
  const { optionalAttributes } = useOptionalAttributes();
  const { setValue, clearErrors } = useFormContext<EditPrognosisFormValues>();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const createNewDetail = (
    detail: Partial<PrognosisDetailDto> = {},
    newPrognosisId?: number
  ) => {
    const baseDetail: Partial<PrognosisDetailDto> = {
      fluidType: 'Oil',
      depthUnit: 'm',
      pressureUnit: 'bar',
      temperatureUnit: 'C',
      pressureGradientDepthUnit: 'bar/m',
      prognosisId: newPrognosisId ?? Number(prognosisId),
      reservoirZone: detail.lithostratSubzone ?? '',
      id: faker.number.int(),
      ...detail,
    };

    return cleanPrognosisDetail(baseDetail, optionalAttributes);
  };

  return useMutation({
    mutationKey: [PROGNOSIS_DETAIL, prognosisId],
    mutationFn: async ({
      detail,
      newPrognosisId,
    }: {
      detail: Partial<PrognosisDetailDto>;
      newPrognosisId?: number;
    }) => {
      const cleanedDetails = createNewDetail(detail, newPrognosisId);
      return await PrognosisDetailService.postApiV1PrognosesDetails(
        newPrognosisId ?? Number(prognosisId),
        cleanedDetails
      );
    },
    onMutate: async ({
      detail,
      newPrognosisId,
    }: {
      detail: Partial<PrognosisDetailDto>;
      newPrognosisId?: number;
    }) => {
      const cleanedDetails = createNewDetail(detail);
      const usingId = newPrognosisId ?? Number(prognosisId);

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId.toString()],
      });

      // Snapshot the previous value
      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, usingId.toString()]) ?? [];

      const updatedDetails = [...previousDetails, cleanedDetails];

      // Optimistically update to the new value
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, usingId.toString()],
        updatedDetails
      );

      // Add the new detail to the form
      setValue('prognosisDetails', updatedDetails);

      // If we don't clear the errors, they will shift up/down and display the error on the wrong cell
      clearErrors('prognosisDetails');

      // Return a context object with the snapshotted value
      return { previousDetails, optimisticDetail: cleanedDetails, usingId };
    },
    onSuccess: (responseId, variables, context) => {
      // Update the cache with the actual ID
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, context.usingId.toString()],
        (oldDetails: PrognosisDetailDto[] = []) =>
          oldDetails.map((detail) =>
            detail === context?.optimisticDetail
              ? { ...detail, id: responseId } // Add actual ID to the optimistic detail
              : detail
          )
      );

      // Update the form with the actual ID
      setValue(
        'prognosisDetails',
        queryClient.getQueryData([
          PROGNOSIS_DETAIL,
          context.usingId.toString(),
        ]) ?? []
      );
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, context?.usingId.toString()],
        context?.previousDetails ?? []
      );
      setValue('prognosisDetails', context?.previousDetails ?? []);
      showSnackbar('Failed to create detail.');
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, prognosisId],
      });
    },
  });
};
