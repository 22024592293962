import { FC, useMemo } from 'react';

import { arrow_drop_right } from '@equinor/eds-icons';

import { useHelplines } from '../../ManageHelpLines';
import { StyledListItem } from '../../ManageHelpLines.styles';
import { Count } from '../Count';
import { WellboreDetailed } from 'src/api';

interface SelectWellboreOptionProps {
  wellbore: WellboreDetailed | undefined;
}

export const SelectWellboreOption: FC<SelectWellboreOptionProps> = ({
  wellbore,
}) => {
  const { selectedWellbore, setSelectedWellbore, selectedHelpLines } =
    useHelplines();

  const count = useMemo(() => {
    if (!wellbore) return 0;
    return selectedHelpLines.filter(
      (helpLine) => helpLine.id === wellbore.wellboreUuid
    ).length;
  }, [selectedHelpLines, wellbore]);

  const handleOnClick = () => {
    setSelectedWellbore(wellbore);
  };

  if (!wellbore) return null;

  return (
    <StyledListItem
      label={wellbore.wellName}
      onClick={handleOnClick}
      selected={wellbore?.wellboreUuid === selectedWellbore?.wellboreUuid}
      leadingContent={count > 0 ? <Count value={count} /> : undefined}
      trailingContent={arrow_drop_right}
    />
  );
};
