import { PressureType } from 'src/api';
import { DateOptions, RangeEnum } from 'src/types';
import { getDaysBetweenDates } from 'src/utils';

export const getNumberOfDays = (range: RangeEnum, from?: Date, to?: Date) => {
  if (range === RangeEnum.CUSTOM && from && to) {
    return getDaysBetweenDates(from, to);
  }
  const rangeValue = DateOptions.find(
    (option) => option.label === range
  )?.range;

  if (!rangeValue) return 31;

  return rangeValue;
};

export const getGuidelinesTextForPressureType = (
  pressureType: PressureType
) => {
  switch (pressureType) {
    case PressureType.MEASURED:
      return 'Pressure value after the general depth conversion InPress does';
    case PressureType.CORRECTED:
      return 'Specifically adjusted data point from InPress admin';
    case PressureType.AUTO_CORRECTED:
    case PressureType.INTERPOLATED:
      return 'Interpolated between manually adjusted and datum values';
    case PressureType.MISSING:
      return 'Values with no measured data and only valid data in either past or future';
  }
};
