import { spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
  margin-top: ${spacings.large};
  p {
    white-space: nowrap;
  }
`;
