import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ColumnHeader } from '../ColumnHeader/ColumnHeader';
import { CommentCell } from '../CommentColumn/CommentCell';
import { SegmentColumnContainer } from '../PrognosisDetailsTable.styles';
import { ZoneCell } from './ZoneCell/ZoneCell';
import { ButtonGroup, Row } from './DetailsSegmentColumn.styles';
import { PrognosisDetailDto } from 'src/api';
import { usePrognosisDetailsFromId } from 'src/hooks';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { HeaderDescriptionType } from 'src/types/prognosisDetails';
import { sortByTopAge } from 'src/utils';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

interface DetailsSegmentColumnProps {
  header: string;
  headerDescriptions: HeaderDescriptionType[];
  prognosisDetailKeys: (keyof PrognosisDetailDto)[];
  rowSpan: number;
  isEditing: boolean;
}

export const DetailsSegmentColumn: FC<DetailsSegmentColumnProps> = ({
  header,
  headerDescriptions,
  prognosisDetailKeys,
  rowSpan,
  isEditing,
}) => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { data: apiPrognosisDetails } = usePrognosisDetailsFromId();
  const { selectedTableRows, addSelectedTableRow, removeSelectedTableRow } =
    useEditPrognosis();

  const formPrognosisDetails = getValues('prognosisDetails');
  const details = isEditing ? formPrognosisDetails : apiPrognosisDetails;
  const dataToUse = sortByTopAge(details ?? []);

  const handleRowClick = (id: number) => () => {
    if (selectedTableRows?.includes(id)) {
      removeSelectedTableRow(id);
    } else {
      addSelectedTableRow(id, isEditing);
    }
  };

  return (
    <SegmentColumnContainer $columnStart={1} $columnSpan={1} $rowSpan={rowSpan}>
      <ColumnHeader
        header={header}
        headerDescriptions={headerDescriptions}
        prognosisDetailKeys={prognosisDetailKeys}
        columnSpan={1}
        isFirstColumn
      />
      {dataToUse?.map((prognosisDetail) => {
        const isSelected = selectedTableRows?.includes(prognosisDetail.id);

        return (
          <Row key={prognosisDetail.id} $isSelected={isSelected}>
            <ZoneCell
              onClick={handleRowClick(prognosisDetail.id)}
              zoneName={prognosisDetail.lithostratSubzone ?? 'N/A'}
              segment={prognosisDetail.segment ?? undefined}
              nickName={prognosisDetail.reservoirZoneNickname || undefined}
              isEditing={isEditing}
              color={prognosisDetail.colorHtml ?? ''}
            />
            <ButtonGroup>
              <CommentCell detailComment={prognosisDetail.comment} />
            </ButtonGroup>
          </Row>
        );
      })}
    </SegmentColumnContainer>
  );
};
