import { CSSProperties, forwardRef, useMemo } from 'react';

import { shape, spacings, Typography } from '@equinor/amplify-component-lib';

import { PressureType } from 'src/api';
import { getMapLabelColors, LabelColorType, roundTo } from 'src/utils';

import styled from 'styled-components';

interface LabelProps {
  $labelColors: LabelColorType;
}

const Label = styled.div<LabelProps>`
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%);
  background-color: ${({ $labelColors }) => $labelColors.background};
  color: ${({ $labelColors }) => $labelColors.text};
  padding: ${spacings.xx_small} ${spacings.small};
  min-width: 72px;
  z-index: 20;
  border-radius: ${shape.rounded.borderRadius};
`;

const Text = styled(Typography)`
  color: inherit;
  margin: auto;
  text-align: center;
  text-shadow:
    0 1px 10px rgba(0, 0, 0, 0.2),
    0 4px 5px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14);
`;

interface PressureValueLabelProps {
  value: number | undefined;
  style: CSSProperties;
  onClick: () => void;
  type: PressureType | undefined;
}

export const PressureValueLabel = forwardRef<
  HTMLDivElement,
  PressureValueLabelProps
>(({ onClick, value, style, type }, ref) => {
  const labelColors = useMemo(() => {
    return getMapLabelColors(type ?? PressureType.MEASURED);
  }, [type]);

  const labelText = useMemo(() => {
    if (type === PressureType.MISSING || value === -1) return '-';
    return roundTo(value, 2);
  }, [type, value]);

  return (
    <Label style={style} ref={ref} onClick={onClick} $labelColors={labelColors}>
      <Text group="heading" variant="h6" color="">
        {labelText}
      </Text>
    </Label>
  );
});

PressureValueLabel.displayName = 'PressureValueLabel';
