import { FC } from 'react';

import { Chip } from '@equinor/amplify-component-lib';

import { DateOptions } from './DateRangeMenu.types';
import { useMeasuredData } from 'src/hooks';

import styled from 'styled-components';

export const DateOptionsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 48px;
`;

export const DateRangeOptions: FC = () => {
  const { range, handleRangeChange } = useMeasuredData();

  return (
    <DateOptionsWrapper>
      {DateOptions.map((item) => (
        <Chip
          key={item.label}
          variant={range === item.label ? 'default' : 'white'}
          onClick={() => handleRangeChange(item.label)}
        >
          {item.label}
        </Chip>
      ))}
    </DateOptionsWrapper>
  );
};
