import { FilterType } from 'src/api';
import { FilterDescription } from 'src/pages/EditMeasuredData/components/FilterPanel/components/FilterDescription';
import { FilterItems } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItems';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

export const FilterPanelBody = () => {
  const { selectedFilter } = useEditMeasuredData();

  if (!selectedFilter) return <FilterItems />;

  if (selectedFilter.type === FilterType.RULE)
    return <FilterDescription rule={selectedFilter.rule} />;

  return null;
};
