import { FC, useMemo } from 'react';

import { SkeletonGradient } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';
import { faker } from '@faker-js/faker';
import { AxisBottom } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';

import {
  CIRCLE_RADIUS,
  MARGIN,
  STROKE_WIDTH,
  TICK_LABEL_PROPS,
  Y_NUM_TICKS,
} from '../Chart.constants';
import { Wrapper } from '../Chart.styles';
import { ChartDataPoint } from '../Chart.types';
import { getPressureDomain } from '../Chart.utils';
import { Legend } from '../Legend/Legend';

import { motion } from 'framer-motion';

const { colors } = tokens;

interface SkeletonChartProps {
  width: number;
  height: number;
  orientation?: 'horizontal' | 'vertical';
}

export const SkeletonChart: FC<SkeletonChartProps> = ({
  width,
  height,
  orientation,
}) => {
  const xMax = useMemo(() => width - MARGIN.RIGHT, [width]);
  const yMax = useMemo(() => height - MARGIN.BOTTOM, [height]);

  const isHorizontal = orientation === 'horizontal';

  const fakeData: Omit<ChartDataPoint, 'map' | 'date'>[] = useMemo(
    () =>
      new Array(4).fill(0).map((_, index) => {
        return {
          zone: `Zone ${index}`,
          initial: faker.number.int({ min: 150, max: 250 }),
          depth: faker.number.int({ min: 500, max: 2500 }),
          low: faker.number.int({ min: 100, max: 300 }),
          high: faker.number.int({ min: 300, max: 500 }),
          expected: faker.number.int({ min: 150, max: 250 }),
        };
      }),
    []
  );

  const pressureScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax - MARGIN.BOTTOM, MARGIN.TOP],
        domain: getPressureDomain(fakeData as ChartDataPoint[]),
        round: true,
      }),
    [fakeData, yMax]
  );

  const depthScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        domain: [300, 3000],
        round: true,
      }),
    [xMax]
  );

  return (
    <Wrapper data-testid="skeletonTestId">
      <svg width={width} height={height}>
        <GridRows scale={pressureScale} width={width} numTicks={Y_NUM_TICKS} />

        {fakeData.map((data, index) => {
          const x = depthScale(data.depth);
          const y = pressureScale(data.high);
          const width = isHorizontal ? pressureScale(data.low) - pressureScale(data.high) : 12;
          const height = isHorizontal ? 12 : pressureScale(data.low) - pressureScale(data.high);
          const rx = CIRCLE_RADIUS + STROKE_WIDTH * 2;

          return (
            <motion.rect
              role="loadingRect"
              key={`rect-${index}`}
              r={CIRCLE_RADIUS * 3}
              x={x}
              y={y}
              width={width}
              height={height}
              rx={rx}
              fill="url(#skeleton-gradient)"
              animate={{
                opacity: [0,1,0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
              }}
            />
          );
        })}
        <g>
          <Text
            x={width}
            y={yMax - MARGIN.BOTTOM - 3}
            textAnchor="end"
            {...TICK_LABEL_PROPS}
          >
            Depth
          </Text>
          <Text
            x={width - 1}
            y={TICK_LABEL_PROPS.fontSize}
            angle={-90}
            textAnchor="end"
            {...TICK_LABEL_PROPS}
          >
            Pressure
          </Text>
        </g>
        <AxisBottom
          scale={depthScale}
          numTicks={Y_NUM_TICKS}
          top={yMax - MARGIN.BOTTOM}
          hideTicks
          tickLabelProps={{
            opacity: 0,
          }}
          tickLength={2}
          tickLineProps={{
            stroke: colors.ui.background__medium.rgba,
          }}
          stroke={colors.ui.background__medium.rgba}
          strokeWidth={2}
        />
        <defs>
          <SkeletonGradient />
        </defs>
      </svg>

      <Legend />
    </Wrapper>
  );
};
