import { FC } from 'react';

import {
  colors,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { check } from '@equinor/eds-icons';

import {
  ANIMATED_SQUARE_SIZE,
  CHIP_HEIGHT,
  LEFT_ALIGNMENT,
  TOP_ALIGNMENT,
} from 'src/constants/savingChip';

import { motion } from 'framer-motion';
import { styled } from 'styled-components';

const Chip = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  border-radius: ${spacings.x_large};
  padding: 1px;
  width: fit-content;
  height: ${CHIP_HEIGHT}px;
  background-color: ${colors.interactive.success__resting.rgba};
  overflow: hidden;
`;

const AnimatedBorder = styled(motion.div)`
  position: absolute;
  z-index: 2;
  top: ${TOP_ALIGNMENT}px;
  left: ${LEFT_ALIGNMENT}px;
  width: ${ANIMATED_SQUARE_SIZE}px;
  height: ${ANIMATED_SQUARE_SIZE}px;
  background: conic-gradient(
    ${colors.ui.background__medium.rgba},
    ${colors.ui.background__medium.rgba},
    ${colors.interactive.success__resting.rgba}
  );
  pointer-events: none;
`;

interface ChipContentProps {
  $isSaving: boolean;
}
const ChipContent = styled.div<ChipContentProps>`
  display: flex;
  z-index: 3;
  position: relative;
  background-color: ${({ $isSaving }) =>
    $isSaving
      ? colors.ui.background__light.rgba
      : colors.interactive.success__highlight.rgba};
  border-radius: ${spacings.x_large};
  padding: ${spacings.x_small};
  width: fit-content;
`;

export const StyledTypography = styled(Typography)`
  padding: 0px ${spacings.x_small};
  line-height: normal;
  align-self: center;
`;

interface SavingChipProps {
  isSaving: boolean;
}

export const SavingChip: FC<SavingChipProps> = ({ isSaving }) => {
  return (
    <Chip>
      {isSaving && (
        <AnimatedBorder
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}
        />
      )}
      <ChipContent $isSaving={isSaving}>
        <StyledTypography
          group="ui"
          variant="chip__badge"
          color={!isSaving ? colors.interactive.success__text.rgba : undefined}
        >
          {isSaving ? 'Saving...' : 'All changes saved to draft'}
        </StyledTypography>
        {!isSaving && (
          <Icon
            data={check}
            color={colors.interactive.success__text.rgba}
            size={16}
          />
        )}
      </ChipContent>
    </Chip>
  );
};
