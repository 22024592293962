import { useCallback, useMemo } from 'react';

import { Bounds } from '@visx/brush/lib/types';

import { PressureDataSimple } from 'src/api';
import { LineChartLine } from 'src/components/Charts/LineChart/LineChart.types';
import {
  createChartData,
  getDate,
  getDefaultBrushRange,
} from 'src/components/Charts/LineChart/LineChart.utils';
import { useMeasuredData } from 'src/hooks/useMeasuredData';

export const useLineChartData = (pressureData: PressureDataSimple[][]) => {
  const { currentBrushRange, setCurrentBrushRange, daysBetweenFromTo } =
    useMeasuredData();

  const brushChartData: LineChartLine[] = useMemo(() => {
    return pressureData.map((data) => {
      return createChartData(data);
    });
  }, [pressureData]);

  const onBrushChange = useCallback(
    (domain: Bounds | null) => {
      if (!domain) return;
      const { x0, x1 } = domain;
      const filteredDates = brushChartData[0].data
        .filter((data) => {
          const x = getDate(data).getTime();
          return x0 < x && x < x1;
        })
        .map((data) => data.date);
      if (filteredDates.length > 0) {
        setCurrentBrushRange([
          filteredDates[0],
          filteredDates[filteredDates.length - 1],
        ]);
      }
    },
    [brushChartData, setCurrentBrushRange]
  );

  const pressureDataFullRange = useMemo(() => {
    const firstWellbore = pressureData.at(0);
    if (firstWellbore) {
      return [
        new Date(firstWellbore[0].prodDay ?? ''),
        new Date(firstWellbore[firstWellbore.length - 1].prodDay ?? ''),
      ] as [Date, Date];
    }
    return getDefaultBrushRange(daysBetweenFromTo);
  }, [pressureData, daysBetweenFromTo]);

  const showFullChart = () => {
    setCurrentBrushRange(pressureDataFullRange);
  };

  const mainChartData: LineChartLine[] = useMemo(() => {
    return pressureData.map((data) => {
      return createChartData(data, currentBrushRange);
    });
  }, [currentBrushRange, pressureData]);

  return {
    mainChartData,
    brushChartData,
    setCurrentBrushRange,
    showFullChart,
    onBrushChange,
  };
};
