import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { colors, Icon, Menu, Typography } from '@equinor/amplify-component-lib';
import { add, edit } from '@equinor/eds-icons';

import { MeasuredDataFilter } from 'src/api';
import { FilterType } from 'src/api';
import { useMeasuredData } from 'src/hooks';
import { useApp } from 'src/hooks/useApp';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useGetFiltersGlobal } from 'src/pages/EditMeasuredData/hooks/useGetFiltersGlobal';
import { useLinePlot } from 'src/pages/EditMeasuredData/hooks/useLinePlot';
import { createEmptyRule } from 'src/pages/EditMeasuredData/utils/advancedFilters';
import { getNameFromMeasurement } from 'src/utils';

interface AdvancedFilterMenuProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  onClose: () => void;
  onClick: (filter: MeasuredDataFilter[] | undefined) => void;
}

export const AdvancedFilterMenu: FC<AdvancedFilterMenuProps> = ({
  anchorEl,
  open,
  onClose,
  onClick,
}) => {
  const { field } = useApp();
  const { fromDate, toDate } = useMeasuredData();
  const { filteredData } = useLinePlot();
  const { wellId, dhpg } = useParams();

  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { data: globalFilters = [] } = useGetFiltersGlobal();

  const rulesForWell = useMemo(
    () => filtersForWell.filter((filter) => filter.type === FilterType.RULE),
    [filtersForWell]
  );

  const handleGlobalAddOnClick = () => {
    onClick([createEmptyRule({ field, fromDate, toDate })]);
  };
  const handleGlobalEditOnClick = () => {
    onClick(globalFilters);
  };
  const handleWellboreAddOnClick = () => {
    onClick([createEmptyRule({ field, wellId, dhpg, fromDate, toDate })]);
  };
  const handleWellboreEditOnClick = () => {
    onClick(rulesForWell);
  };

  return (
    <Menu
      id="menu-complex"
      aria-labelledby="anchor-complex"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      placement="top"
      title="Advanced filters"
    >
      <Menu.Section title="Global filters">
        <Menu.Item onClick={handleGlobalAddOnClick}>
          <Icon
            data={add}
            size={16}
            color={colors.text.static_icons__tertiary.rgba}
          />
          <Typography group="navigation" variant="menu_title" as="span">
            Add global filter
          </Typography>
        </Menu.Item>
        <Menu.Item
          onClick={handleGlobalEditOnClick}
          disabled={globalFilters.length === 0}
        >
          <Icon
            data={edit}
            size={16}
            color={colors.text.static_icons__tertiary.rgba}
          />
          <Typography group="navigation" variant="menu_title" as="span">
            Edit global filters
          </Typography>
        </Menu.Item>
      </Menu.Section>
      <Menu.Section
        title={`${getNameFromMeasurement(filteredData?.at(0)) ?? 'Wellbore'} filters`}
      >
        <Menu.Item onClick={handleWellboreAddOnClick}>
          <Icon
            data={add}
            size={16}
            color={colors.text.static_icons__tertiary.rgba}
          />
          <Typography group="navigation" variant="menu_title" as="span">
            Add new rule
          </Typography>
        </Menu.Item>

        <Menu.Item
          onClick={handleWellboreEditOnClick}
          disabled={
            !filtersForWell.some((filter) => filter.type === FilterType.RULE)
          }
        >
          <Icon
            data={edit}
            size={16}
            color={colors.text.static_icons__tertiary.rgba}
          />
          <Typography group="navigation" variant="menu_title" as="span">
            Edit all rules
          </Typography>
        </Menu.Item>
      </Menu.Section>
    </Menu>
  );
};
