import { Dispatch, FC, MouseEvent, SetStateAction, useMemo } from 'react';

import { colors, spacings } from '@equinor/amplify-component-lib';

import { MeasureAreaShape } from 'src/pages/MapView/components/Map/hooks/useDeckGlMapProps/useMeasureArea.types';
import { HighlightShape } from 'src/pages/MapView/components/MeasureArea/MeasureArea';
import { roundTo } from 'src/utils';
import { ShapeType } from 'src/utils/measureArea';

import styled from 'styled-components';

const MeasurementText = styled.div`
  position: absolute;
  padding: ${spacings.small};
  transform: translate(-50%, -50%);
  background-color: ${colors.ui.background__light.rgba};
  border-radius: 5px;
  cursor: crosshair;
`;

const SideText = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: ${colors.ui.background__light.rgba};
  padding: ${spacings.x_small};
  border-radius: 5px;
  font-size: 12px;
  cursor: crosshair;
`;

const getAveragePosition = (a: number[], b: number[]) => {
  const [ax, ay] = a;
  const [bx, by] = b;
  return [(ax + bx) / 2, (ay + by) / 2];
};

interface ShapeMeasurementsProps {
  shape: MeasureAreaShape;
  shapePixel: number[][];
  index: number;
  setHighlightShape: Dispatch<SetStateAction<HighlightShape | undefined>>;
}

export const ShapeMeasurements: FC<ShapeMeasurementsProps> = ({
  shape,
  shapePixel,
  index,
  setHighlightShape,
}) => {
  const { measurements } = shape;
  const measurementCoords = measurements.sides.map((side, index) => {
    return getAveragePosition(shapePixel[index], shapePixel[index + 1]);
  });

  const positions = useMemo(() => {
    if (!shapePixel) return;
    const xArray = shapePixel.map((point) => point[0]);
    const yArray = shapePixel.map((point) => point[1]);
    const averageX = (Math.min(...xArray) + Math.max(...xArray)) / 2;
    const averageY = (Math.min(...yArray) + Math.max(...yArray)) / 2;
    return {
      area: [averageX, averageY],
      distance: [averageX, Math.min(...yArray) - 48],
    };
  }, [shapePixel]);

  const circumference = useMemo(() => {
    return roundTo(
      measurements.sides.reduce((partialSum, a) => partialSum + a, 0),
      2
    );
  }, [measurements.sides]);

  const handleOnMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    setHighlightShape({
      shapeIndex: index,
      type: e.currentTarget.id as ShapeType,
    });
  };
  const handleOnMouseLeave = () => {
    setHighlightShape(undefined);
  };

  return (
    <>
      {positions && shape.isComplete && (
        <MeasurementText
          id={ShapeType.AREA}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          style={{
            left: positions.area[0],
            top: positions.area[1],
          }}
        >
          {measurements.area} km<sup>2</sup>
        </MeasurementText>
      )}
      {positions && shape.isComplete && (
        <MeasurementText
          id={ShapeType.CIRCUMFERENCE}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          style={{
            left: positions.distance[0],
            top: positions.distance[1],
          }}
        >
          {circumference} km
        </MeasurementText>
      )}

      {measurements.sides.map((side, index) => {
        return (
          <SideText
            key={`${side}//${index}//${shapePixel[index][0]}`}
            style={{
              left: measurementCoords[index][0],
              top: measurementCoords[index][1],
            }}
          >
            {roundTo(side, 2) + ' km'}
          </SideText>
        );
      })}
    </>
  );
};
