import { FC, useMemo } from 'react';

import { ComboBox, SelectOptionRequired } from '@equinor/amplify-component-lib';

import { filterValueKeyToLabel } from '../../Dashboard.utils';
import { useDashboard } from 'src/pages/Dashboard/hooks/useDashboard';
import { FilterValue } from 'src/pages/Dashboard/providers/DashboardProvider';

interface FilterOptionProps {
  filterValueKey: keyof FilterValue;
  items: SelectOptionRequired[];
  placeholder: string;
}

const isComboBoxDisabled = (
  filterValueKey: string,
  activeTab: number
): boolean => {
  return filterValueKey === 'status' && activeTab !== 0;
};

export const FilterOption: FC<FilterOptionProps> = ({
  filterValueKey,
  items,
  placeholder,
}) => {
  const { filterValue, updateFilterValue, activeTab } = useDashboard();
  const value = filterValue[filterValueKey]?.split(',');

  const values = items.filter((item) => value?.includes(item.value));
  const label = useMemo(
    () => filterValueKeyToLabel(filterValueKey),
    [filterValueKey]
  );

  const handleOnSelect = (values: SelectOptionRequired[]) => {
    if (values.length === 0) {
      updateFilterValue({ [filterValueKey]: undefined });
    } else {
      const filterValueString = values.map((item) => item.value).join(',');
      updateFilterValue({ [filterValueKey]: filterValueString });
    }
  };

  return (
    <ComboBox
      values={values}
      items={items}
      label={label}
      placeholder={placeholder}
      lightBackground
      onSelect={handleOnSelect}
      disabled={isComboBoxDisabled(filterValueKey, activeTab)}
    />
  );
};
