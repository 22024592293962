import { FC, RefObject, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConfirmationPopup } from '@equinor/amplify-component-lib';
import { Autocomplete, Button, Icon } from '@equinor/eds-core-react';
import { edit, timeline } from '@equinor/eds-icons';

import { LinePlotDateRange } from 'components/LinePlotDateRange/LinePlotDateRange';

import { useGetUserAccess, useMeasuredData } from 'src/hooks';
import { Export } from 'src/pages/PlotView/components/Actions/Export';
import { Measurement } from 'src/types';
import { getNameFromMeasurement } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: start;
`;

const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

const EditIconsWrapper = styled.div`
  position: relative;
  > svg:last-child {
    position: absolute;
    top: -3px;
    left: -2px;
    transform: scale(0.65);
  }
`;

interface ActionsProps {
  copyToClipboardRef: RefObject<HTMLElement>;
}

export const Actions: FC<ActionsProps> = ({ copyToClipboardRef }) => {
  const { userAccessForField } = useGetUserAccess();
  const navigate = useNavigate();
  const [showEditMode, setShowEditMode] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<Measurement | null>(null);
  const { selectedMeasurements } = useMeasuredData();

  const handleEditMode = () => {
    if (selectedMeasurements.length === 1) {
      navigate(
        `/measured-data/edit/${selectedMeasurements[0]?.wellboreUuid}/${selectedMeasurements[0]?.dhpg}`
      );
    } else {
      setShowEditMode(true);
    }
  };

  return (
    <Wrapper>
      <ActionsWrapper>
        <LinePlotDateRange />
        <Export copyToClipboardRef={copyToClipboardRef} />

        {(userAccessForField?.userRoles.submitter ||
          userAccessForField?.userRoles.approver) && (
          <Button
            variant="contained"
            onClick={handleEditMode}
            disabled={selectedMeasurements.length < 1}
          >
            <EditIconsWrapper>
              <Icon data={edit} />
              <Icon data={timeline} size={16} />
            </EditIconsWrapper>
            Edit
          </Button>
        )}
      </ActionsWrapper>
      <ConfirmationPopup
        show={showEditMode}
        title="Edit data"
        body="Select wellbore"
        actions={[
          <Button
            key="A1"
            variant="ghost"
            onClick={() => setShowEditMode(false)}
          >
            Cancel
          </Button>,
          <Button
            key="A2"
            onClick={() =>
              navigate(
                `/measured-data/edit/${selectedMeasurement?.wellboreUuid}/${selectedMeasurement?.dhpg}`
              )
            }
            disabled={selectedMeasurement === null}
          >
            Continue
          </Button>,
        ]}
        onClose={() => setShowEditMode(false)}
      >
        <Autocomplete
          label=""
          autoWidth
          placeholder="Type to find wellbore..."
          options={selectedMeasurements}
          selectedOptions={selectedMeasurement ? [selectedMeasurement] : []}
          onOptionsChange={(options) => {
            setSelectedMeasurement(
              options.selectedItems.length ? options.selectedItems[0] : null
            );
          }}
          optionLabel={(item) => getNameFromMeasurement(item)}
        />
      </ConfirmationPopup>
    </Wrapper>
  );
};
