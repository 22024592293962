import { useQuery } from '@tanstack/react-query';

import { PrognosisOperationService } from 'src/api';
import { PROGNOSIS_TYPES_KEY } from 'src/constants/queryKeys';

export const useGetPrognosisTypes = () => {
  return useQuery({
    queryKey: [PROGNOSIS_TYPES_KEY],
    queryFn: () => PrognosisOperationService.getApiV1PrognosesOperations(),
  });
};
