import { FC, useMemo } from 'react';

import { ComboBox, sortByWellboreName } from '@equinor/amplify-component-lib';
import { SelectOption } from '@equinor/amplify-component-lib/dist/molecules/Select/Select.types';

import { ComboBoxChipWithColoredDot } from 'components/SelectWellboreComboBox/ComboBoxChipWithColoredDot';
import { getComboBoxItem } from 'components/SelectWellboreComboBox/SelectWellboreComboBox.utils';

import { Measurement } from 'src/types';

import { useMeasuredData } from 'hooks/useMeasuredData';

interface SelectWellboreComboBoxProps {
  withColoredDots?: boolean;
}

export const SelectWellboreComboBox: FC<SelectWellboreComboBoxProps> = ({
  withColoredDots,
}) => {
  const { wellbores, selectedMeasurements, setSelectedMeasurements } =
    useMeasuredData();

  const wellboreOptions = useMemo(() => {
    if (!wellbores) return [];
    return wellbores
      .map((wellbore) => {
        return getComboBoxItem(wellbore);
      })
      .sort((a: Measurement, b: Measurement) =>
        sortByWellboreName(a.label, b.label)
      );
  }, [wellbores]);

  const handleOnSelect = (
    values: SelectOption<Measurement>[],
    selectedValue?: SelectOption<Measurement>
  ) => {
    if (values.length === 0) setSelectedMeasurements([]);
    if (selectedValue?.children && selectedValue?.children.length > 0) {
      const childrenValues = [
        ...selectedValue.children.map((child) => child.value),
      ];
      const allChildrenWasSelected = childrenValues.every((childValue) => {
        return selectedMeasurements.some(
          (selectedValue) => selectedValue.value === childValue
        );
      });
      if (allChildrenWasSelected) {
        setSelectedMeasurements(
          selectedMeasurements.filter(
            (measurement) => !childrenValues.includes(measurement.value)
          )
        );
      } else {
        setSelectedMeasurements([
          ...selectedMeasurements,
          ...selectedValue.children.filter(
            (child) =>
              !selectedMeasurements.some(
                (selectedValue) => selectedValue.value === child.value
              )
          ),
        ]);
      }
    } else if (selectedValue) {
      setSelectedMeasurements(values);
    }
  };

  return (
    <ComboBox
      disabled={wellboreOptions.length === 0}
      onSelect={handleOnSelect}
      syncParentChildSelection={true}
      placeholder="Select wellbore..."
      values={selectedMeasurements}
      items={wellboreOptions}
      customValueComponent={
        withColoredDots ? ComboBoxChipWithColoredDot : undefined
      }
    />
  );
};
