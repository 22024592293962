import { faker } from '@faker-js/faker';
import { TickFormatter } from '@visx/axis';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import { ScaleBand, ScaleLinear } from '@visx/vendor/d3-scale';

export interface ChartDataPoint {
  map(
    arg0: (
      dataPoint: ChartDataPoint,
      index: number
    ) => import('react/jsx-runtime').JSX.Element
  ): import('react').ReactNode;
  zone?: string;
  depth: number;
  low: number;
  high: number;
  initial: number;
  expected: number;
  date: Date;
  index?: number;
  color?: string;
}

export interface ChartProps {
  width: number;
  height: number;
  data: ChartDataPoint[];
  showTooltip?: (
    args: Pick<
      Pick<
        UseTooltipParams<ChartProps>,
        'tooltipData' | 'tooltipTop' | 'tooltipLeft' | 'tooltipOpen'
      >,
      'tooltipData' | 'tooltipTop' | 'tooltipLeft'
    >
  ) => void;
  hideTooltip?: () => void;
  tooltipLeft: number | undefined;
  tooltipTop: number | undefined;
  tooltipData: object | undefined;
  children?: React.ReactNode;
  formatTick?: TickFormatter<number | Date> | undefined;
  xScale: ScaleBand<Date> | ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  xMax: number;
  yMax: number;
  xLabel: string;
  yLabel: string;
  graphColors: string[];
  rangeColor?: string;
}

export enum ChartDataPointCategory {
  EXPECTED = 'Expected',
  LOW_HIGH = 'Range',
  INITIAL = 'Initial',
}

export enum ChartDataCategory {
  REFERENCE = 'Reference',
  REFERENCE_ID = faker.number.int(),
  DATUM = 'Datum',
  DATUM_ID = faker.number.int(),
}

export interface ITooltip {
  tooltipData: ChartDataPoint;
  tooltipLeft: number;
  tooltipTop: number;
}

export enum ChartVariant {
  OVER_TIME = 'overTime',
  PROGNOSIS = 'prognosis',
}

export interface ChartSelect {
  name: string;
  id: number;
}
