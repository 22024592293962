import { Divider, spacings, Typography } from '@equinor/amplify-component-lib';

import { Column } from '../../components/Column';
import { useHelplines } from '../../ManageHelpLines';
import { SelectSourceOption } from './SelectSourceOption';

import styled from 'styled-components';

const StyledSectionLabel = styled(Typography)`
  margin-left: ${spacings.medium};
  margin-top: ${spacings.medium};
`;

export const SelectSource = () => {
  const { selectedWellbore } = useHelplines();

  if (selectedWellbore === undefined) return null;

  return (
    <Column title={`${selectedWellbore.wellboreName} data sources`}>
      <StyledSectionLabel group="paragraph" variant="overline">
        Pressure
      </StyledSectionLabel>
      <SelectSourceOption source="Dhpg1" />
      {selectedWellbore.screenPerfCount === 2 && (
        <SelectSourceOption source="Dhpg2" />
      )}
      <Divider variant="small" size="1" color="medium" />
      <StyledSectionLabel group="paragraph" variant="overline">
        GRADIENT
      </StyledSectionLabel>
      <SelectSourceOption source="GRADIENT" />
      <Divider variant="small" size="1" color="medium" />
      <StyledSectionLabel group="paragraph" variant="overline">
        FLOW
      </StyledSectionLabel>
      <SelectSourceOption source="FLOW" />
    </Column>
  );
};
