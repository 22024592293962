import { RangeEnum } from 'src/types';

interface DateOption {
  label: RangeEnum;
  range: number;
}

export const DateOptions: DateOption[] = [
  { label: RangeEnum.THREE_MONTHS, range: 91 },
  { label: RangeEnum.YEAR, range: 365 },
  { label: RangeEnum.THREE_YEARS, range: 1095 },
  { label: RangeEnum.CUSTOM, range: 91 },
];
