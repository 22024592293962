import { FC, useRef } from 'react';

import {
  colors,
  elevation,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { LineChart } from 'components/Charts/LineChart/LineChart';
import { PlotFooter } from 'components/PlotFooter/PlotFooter';
import { SelectWellboreComboBox } from 'components/SelectWellboreComboBox/SelectWellboreComboBox';

import {
  LINE_CHART_BOTTOM_PADDING,
  LINE_CHART_BRUSH_HEIGHT,
  LINE_CHART_HEIGHT,
  LINE_PLOT_FOOTER_HEIGHT,
} from 'src/constants';
import { WellboreInformation } from 'src/pages/PlotView/components/WellboreInformation/WellboreInformation';
import { usePressureData } from 'src/pages/PlotView/hooks/usePressureData';

import { useMeasuredData } from 'hooks/useMeasuredData';
import styled from 'styled-components';

const PageContent = styled.div`
  padding: ${spacings.large};
  background-color: ${colors.ui.background__default.rgba};
  border-radius: 4px;
  box-shadow: ${elevation.raised};
`;

const LinePlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(
    ${LINE_CHART_HEIGHT} + ${LINE_CHART_BRUSH_HEIGHT} +
      ${LINE_PLOT_FOOTER_HEIGHT} + ${LINE_CHART_BOTTOM_PADDING}
  );
`;

const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
`;

const SelectWellboresWrapper = styled.div`
  width: 100%;
`;

export const Plot: FC = () => {
  const { pressureData, loading } = usePressureData();

  const { selectedMeasurements } = useMeasuredData();
  const copyToClipboardRef = useRef<HTMLDivElement>(null);

  return (
    <PageContent ref={copyToClipboardRef}>
      <LinePlotWrapper>
        <SelectWellboresWrapper>
          <SelectWellboreComboBox withColoredDots />
        </SelectWellboresWrapper>
        {selectedMeasurements.length === 0 ? (
          <NoDataWrapper>
            <Typography variant="h3">
              No data selected. Please select a wellbore to view data.
            </Typography>
          </NoDataWrapper>
        ) : (
          <LineChart
            wellbores={pressureData}
            lineChartHeight="30rem"
            horizontalBrushHeight={LINE_CHART_BRUSH_HEIGHT}
            backgroundColor={colors.ui.background__default.rgba}
            showLoadingSkeleton={loading}
          />
        )}

        <PlotFooter />
      </LinePlotWrapper>
      {selectedMeasurements.length > 0 && <WellboreInformation />}
    </PageContent>
  );
};
