import { useEffect, useState } from 'react';

interface UseInViewResult {
  ref: (node?: Element | null) => void;
  inView: boolean;
}

export function useInView(threshold: number | undefined): UseInViewResult {
  const [ref, setRef] = useState<Element | undefined | null>(undefined);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: '0px', threshold }
    );

    if (ref) observer.observe(ref);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref, threshold]);

  return { ref: setRef, inView: isIntersecting };
}
