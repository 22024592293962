import { FC, useMemo, useRef, useState } from 'react';

import { Position } from '@turf/helpers';

import { PressureDataSimple } from 'src/api';
import { useMeasuredData } from 'src/hooks';
import { useMapInner } from 'src/pages/MapView/components/Map/hooks/useMapInner';
import { Marker } from 'src/pages/MapView/components/MapElements/components/Marker';
import { NameLabel } from 'src/pages/MapView/components/MapElements/components/NameLabel';
import { PressureValueLabel } from 'src/pages/MapView/components/MapElements/components/PressureValueLabel';
import { ContextMenuWellbore } from 'src/pages/MapView/components/MapElements/MapMarker/ContextMenuWellbore';
import { useMapOptions, usePressurePoints } from 'src/pages/MapView/hooks';
import { MapLayer } from 'src/pages/MapView/MapView.types';
import { WellborePurpose } from 'src/types';

import { useMeasuredDataWellbore } from 'hooks/useMeasuredDataWellbore';

const RightOrLeft = (lng: number, nearest: Position) => {
  if (lng - nearest[0] > 0) {
    return 'right';
  } else {
    return 'left';
  }
};

export const MapMarker: FC<PressureDataSimple> = (measurement) => {
  const {
    measurementPosition,
    pressureFinal,
    wellboreUuid,
    wellboreName,
    dhpg,
  } = measurement;
  const { viewport } = useMapInner();
  const typedViewport = viewport;
  const { wellbore } = useMeasuredDataWellbore(wellboreUuid);
  const { nearestNeighbor, pressurePoints } = usePressurePoints();
  const { layers } = useMapOptions();
  const { selectedMeasurements } = useMeasuredData();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const [lat, lng] = useMemo(() => {
    if (measurementPosition?.coordinates) {
      return measurementPosition.coordinates;
    }
    return [0, 0];
  }, [measurementPosition?.coordinates]);
  const [left, top] = typedViewport.project([lng, lat]);

  const isSelected = useMemo(() => {
    return selectedMeasurements.some(
      (measurement) =>
        measurement.wellboreUuid === wellboreUuid && measurement.dhpg === dhpg
    );
  }, [dhpg, selectedMeasurements, wellboreUuid]);

  const showPressureLabel = useMemo(() => {
    return layers.includes(MapLayer.PRESSURE_VALUE);
  }, [layers]);

  const showWellboreLabel = useMemo(() => {
    return layers.includes(MapLayer.WELLBORE_LABEL);
  }, [layers]);

  const rightOrLeft = useMemo(
    () =>
      RightOrLeft(
        lng,
        nearestNeighbor.get(`${wellboreUuid}-${dhpg}`) ?? [0, 0]
      ),
    [dhpg, lng, nearestNeighbor, wellboreUuid]
  );

  const name = useMemo(() => {
    const pps = pressurePoints.filter((pp) => pp.wellboreUuid === wellboreUuid);
    // TODO: Maybe revert this if needed after name consistency improvements
    if (pps.length > 1) {
      return `${wellboreName}`;
    }
    return wellboreName ?? '-';
  }, [pressurePoints, wellboreName, wellboreUuid]);

  const toggleContextMenu = () => {
    setIsContextMenuOpen((prev) => !prev);
  };

  const closeContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  return (
    <>
      <Marker
        onClick={toggleContextMenu}
        ref={anchorEl}
        style={{ top: top, left: left }}
        selected={isSelected}
        purpose={wellbore?.purpose as WellborePurpose}
      />
      {showPressureLabel && (
        <PressureValueLabel
          style={{
            top: top - 14,
            left: rightOrLeft === 'left' ? left - 50 : left + 50,
          }}
          value={pressureFinal}
          onClick={toggleContextMenu}
          type={measurement.pressureType}
        />
      )}
      {showWellboreLabel && (
        <NameLabel
          style={{ top: top - 34, left: left }}
          wellboreUuid={wellboreUuid}
          wellboreName={name}
          dhpg={dhpg}
          onClick={toggleContextMenu}
        />
      )}
      <ContextMenuWellbore
        open={isContextMenuOpen}
        style={{
          top: top - 410,
          left: left - 144,
        }}
        pressureData={measurement}
        name={name}
        onClose={closeContextMenu}
      />
    </>
  );
};

MapMarker.displayName = 'MapMarker';
