import { FC } from 'react';
import { useNavigate } from 'react-router';

import { Icon, Typography } from '@equinor/amplify-component-lib';
import { chevron_right } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

import { IconChip } from '../IconChip/IconChip';
import {
  SubCardContent,
  TitleContainer,
  TitleRow,
} from '../PrognosisCard.styles';
import { PrognosisValues } from '../PrognosisValues';
import { ValidityDate } from '../ValidityDate/ValidityDate';
import { PrognosisDto } from 'src/api';
import { TabType } from 'src/constants/tab';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';

const { colors } = tokens;
interface RelatedPrognosisProps extends PrognosisDto {
  currentTab: TabType;
}

export const RelatedPrognosis: FC<RelatedPrognosisProps> = ({
  approveUser,
  createUser,
  id,
  operation,
  title,
  validFromDate,
  validToDate,
  statusId,
}) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (id) {
      navigate(`/detailed-view/${id}`);
    }
  };

  const formattedOperation = lookupValueToComboBoxRequired(
    operation,
    true
  ).label;

  return (
    <SubCardContent onClick={handleOnClick}>
      <TitleContainer>
        <TitleRow>
          <IconChip statusId={statusId} isSubCard={true} />
          <Typography>{title || 'N/A'}</Typography>
        </TitleRow>
      </TitleContainer>
      <ValidityDate
        statusId={statusId}
        validFromDate={validFromDate}
        validToDate={validToDate}
      />
      <PrognosisValues label={formattedOperation} />
      <PrognosisValues label={createUser} isShortName />
      <PrognosisValues label={approveUser} isShortName />
      <Icon
        data={chevron_right}
        color={colors.interactive.primary__resting.rgba}
      />
    </SubCardContent>
  );
};
