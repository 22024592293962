import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { PressureService } from 'src/api';
import { PRES_DATA_FOR_WELLBORES_FLAT_KEY } from 'src/constants';
import { useApp, useMeasuredData } from 'src/hooks';

export const useGetPressureDataWellboresFlat = () => {
  const { field } = useApp();
  const { from, to } = useMeasuredData();
  const { wellId } = useParams();

  const {
    data: pressureData,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: [PRES_DATA_FOR_WELLBORES_FLAT_KEY, field?.uuid, from, to, wellId],
    queryFn: () =>
      PressureService.getpressureDataForWellboresFlat(
        field?.uuid ?? '',
        from,
        to,
        wellId ? [wellId] : undefined
      ),

    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { pressureData, isLoading, isFetching, isError, error };
};
