import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { Table } from '@equinor/eds-core-react';

interface CellHeaderProps {
  label: string;
  unit?: string;
}

export const CellHeader: FC<CellHeaderProps> = ({ label, unit }) => {
  return (
    <Table.Cell>
      <Typography group="table" variant="cell_header">
        {label}{' '}
        {unit && (
          <>
            <span>({unit})</span>
          </>
        )}
      </Typography>
    </Table.Cell>
  );
};
