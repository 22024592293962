import { HelpLine } from 'src/api';
import { PressureLabels } from 'src/constants';

export const getCheckboxLabel = (
  helplinesForWellbore: HelpLine[],
  helpLine: HelpLine
) => {
  const shouldAddMeasureType =
    helplinesForWellbore.filter((dt) => dt.type === helpLine.type).length > 1;

  if (shouldAddMeasureType) {
    return (
      PressureLabels[helpLine.type] + ' (' + (helpLine.measureType ?? '') + ')'
    );
  }

  return PressureLabels[helpLine.type];
};
