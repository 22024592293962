import { FC } from 'react';

import { colors, formatDate, Typography } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { UnitToggle } from '../UnitToggle/UnitToggle';
import { FlexWrapper, Info, Metadata } from './PrognosisInfo.styles';
import { PrognosisInfoSkeleton } from './PrognosisInfoSkeleton';
import { TOGGLE_UNITS_FEATURE_KEY } from 'src/constants';
import { useFullName, usePrognosisFromId } from 'src/hooks';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';

export const PrognosisInfo: FC = () => {
  const { data, isLoading } = usePrognosisFromId();
  const { data: createdBy, isLoading: isLoadingCreatedBy } = useFullName(
    data?.createUser
  );
  const { data: approver, isLoading: isLoadingApprover } = useFullName(
    data?.approveUser
  );

  const formatOperation = lookupValueToComboBoxRequired(
    data?.operation,
    true
  ).label;

  const formatWellPlanningPhase = lookupValueToComboBoxRequired(
    data?.wellPlanningPhase,
    true
  ).label;

  const { showContent } = useFeatureToggling({
    featureKey: TOGGLE_UNITS_FEATURE_KEY,
  });

  if (isLoading || isLoadingCreatedBy || isLoadingApprover || data == undefined)
    return <PrognosisInfoSkeleton />;

  return (
    <FlexWrapper>
      <Info>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Validity
          </Typography>
          <Typography group="table" variant="cell_text" color="warning">
            {formatDate(data.validFromDate ?? 'N/A', {
              format: 'DD. month YYYY',
              month: 'short',
            })}
            {' - '}
            {formatDate(data.validToDate ?? 'N/A', {
              format: 'DD. month YYYY',
              month: 'short',
            })}
          </Typography>
        </Metadata>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Created by
          </Typography>
          <Typography group="table" variant="cell_text">
            {createdBy}
          </Typography>
        </Metadata>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Approver
          </Typography>
          <Typography group="table" variant="cell_text">
            {approver}
          </Typography>
        </Metadata>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Wellbore
          </Typography>
          <Typography group="table" variant="cell_text">
            {data?.wellboreIdentifier ?? 'N/A'}
          </Typography>
        </Metadata>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Operation
          </Typography>
          <Typography group="table" variant="cell_text">
            {formatOperation}
          </Typography>
        </Metadata>
        {data?.wellPlanningPhase && (
          <Metadata>
            <Typography
              group="navigation"
              variant="label"
              color={colors.text.static_icons__tertiary.rgba}
            >
              Planning phase
            </Typography>
            <Typography group="table" variant="cell_text">
              {formatWellPlanningPhase}
            </Typography>
          </Metadata>
        )}
      </Info>
      {showContent && <UnitToggle />}
    </FlexWrapper>
  );
};
