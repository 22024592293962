import { FC } from 'react';

import { SkeletonBase, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import {
  CardContent,
  CardWrapper,
  TitleContainer,
  TitleRow,
} from './PrognosisCard.styles';

import styled from 'styled-components';

const { shape } = tokens;

// Tried using typography fontsize for these but height did not match
const Wellbore = styled(SkeletonBase)`
  height: 30px;
  width: 11rem;
  border-radius: ${shape.corners.borderRadius};
  margin-bottom: 2px;
`;

const Title = styled(SkeletonBase)`
  height: 22px;
  width: 15rem;
  border-radius: ${shape.corners.borderRadius};
  margin-top: 2px;
`;

const Icon = styled(SkeletonBase)`
  height: 26px;
  width: 26px;
  border-radius: ${spacings.x_large};
`;

const ModifiedDate = styled(SkeletonBase)`
  height: 1rem;
  width: 10rem;
  border-radius: ${shape.corners.borderRadius};
`;

const Operation = styled(SkeletonBase)`
  height: 1rem;
  width: 4.5rem;
  border-radius: ${shape.corners.borderRadius};
`;

const Name = styled(SkeletonBase)`
  height: 1rem;
  width: 2.5rem;
  border-radius: ${shape.corners.borderRadius};
`;

interface PrognosisCardSkeletonProps {
  offset: number;
}

export const PrognosisCardSkeleton: FC<PrognosisCardSkeletonProps> = ({
  offset,
}) => (
  <CardWrapper
    role="progressbar"
    aria-label="Progress bar"
    $statusColor="transparent"
  >
    <CardContent>
      <TitleContainer>
        <Wellbore $offset={offset} />
        <TitleRow>
          <Icon $offset={offset} />
          <Title $offset={offset} />
        </TitleRow>
      </TitleContainer>
      <ModifiedDate $offset={offset} />
      <Operation $offset={offset} />
      <Name $offset={offset} />
      <Name $offset={offset} />
    </CardContent>
  </CardWrapper>
);
