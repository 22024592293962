import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGetFilterViews } from './useGetFilterViews';
import { Filter, FilterService } from 'src/api';
import { FILTER_KEY } from 'src/constants/queryKeys';
import { useApp } from 'src/hooks';

export const useDeleteFilterView = (deleteList: string[]) => {
  const queryClient = useQueryClient();
  const { field } = useApp();
  const { data } = useGetFilterViews();

  return useMutation({
    mutationFn: async () => {
      for (const itemId of deleteList) {
        await FilterService.deleteApiV1Filter(itemId);
      }
    },
    onSuccess: () => {
      queryClient.setQueryData(
        [FILTER_KEY, field?.uuid],
        data?.filter((item: Filter) => !deleteList.includes(item.id ?? ''))
      );
    },
  });
};
