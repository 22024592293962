import { PrognosisDetailDto } from 'src/api';
import { Unit } from 'src/types';

const airGap = localStorage.getItem(Unit.AIR_GAP);

export const calcSg = (pressure: number, height: number) => {
  if (!pressure) return;

  /**
   * Constant value to calculate SG
   *  */
  const gradient = 0.0981;

  /**
   * Total height from platform floor to the depth of prognosis detail
   * Airgap is set by user in localstorage, until we get it from backend
   */
  const totalHeight = Number(airGap) + height;

  const SG = pressure / (gradient * totalHeight);

  return Number(SG.toFixed(4));
};

export const ConvertPrognosisToSG = (prognosis: PrognosisDetailDto) => {
  return {
    ...prognosis,
    pressureExpected: calcSg(
      prognosis.pressureExpected!,
      prognosis.referenceDepth!
    ),
    pressureHigh: calcSg(prognosis.pressureHigh!, prognosis.referenceDepth!),
    pressureLow: calcSg(prognosis.pressureLow!, prognosis.referenceDepth!),
    pressureInitial: calcSg(
      prognosis.pressureInitial!,
      prognosis.referenceDepth!
    ),
  };
};
