import { FC } from 'react';

import { TopBar } from '@equinor/amplify-component-lib';
import { GuidelineSections } from '@equinor/amplify-component-lib/dist/organisms/TopBar';
import {
  account_circle,
  info_circle,
  platform,
  settings,
} from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

const { colors } = tokens;

const sections: GuidelineSections[] = [
  {
    sectionName: 'Top bar',
    items: [
      {
        title: 'Field Selector',
        icon: platform,
        color: colors.interactive.primary__resting.hex,
      },
      {
        title: 'Guidelines',
        icon: info_circle,
        color: colors.interactive.primary__resting.hex,
      },
      {
        title: 'Settings',
        icon: settings,
        color: colors.interactive.primary__resting.hex,
      },
      {
        title: 'Account',
        icon: account_circle,
        color: colors.interactive.primary__resting.hex,
      },
    ],
  },
];
export const Guidelines: FC = () => {
  return <TopBar.Guidelines sections={sections} />;
};
