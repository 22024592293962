import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors, shape, elevation } = tokens;

export const Container = styled.div`
  display: flex;
  padding: ${spacings.large};
  justify-content: center;
`;

export const MainContent = styled.div`
  display: flex;
  max-width: calc(1600px - ${spacings.large} * 2);
  width: 100%;
  flex-direction: column;
  gap: ${spacings.large};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacings.large};
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartContainer = styled.div`
  height: 400px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

export const Wrapper = styled.div`
  display: flex;
  background: ${colors.ui.background__default.rgba};
  border-radius: ${shape.corners.borderRadius};
  box-shadow: ${elevation.raised};
  padding: ${spacings.large};
  grid-column: span 2;
  gap: ${spacings.large};
`;
