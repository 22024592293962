import { SelectOptionRequired } from '@equinor/amplify-component-lib';

import { HelpLineInput, MeasurementType } from 'src/api';
import { Sources } from 'src/pages/EditMeasuredData/components/ManageHelpLines/ManageHelpLines.types';

export enum RangeEnum {
  THREE_MONTHS = 'Past 3 months',
  YEAR = 'Past year',
  THREE_YEARS = 'Past 3 years',
  CUSTOM = 'Custom',
}

export enum IndefiniteTimeStringType {
  PAST = 'past',
  FUTURE = 'future',
}

export enum MeasuredDataPage {
  MAP = 'map',
  PLOT = 'plot',
  EDIT = 'edit',
}

interface DateOption {
  label: RangeEnum;
  range: number;
}

export interface ValueLabel {
  value: number;
  label: string;
}

export type MapColor =
  | [number, number, number]
  | [number, number, number, number];

export const DateOptions: DateOption[] = [
  { label: RangeEnum.THREE_MONTHS, range: 91 },
  { label: RangeEnum.YEAR, range: 365 },
  { label: RangeEnum.THREE_YEARS, range: 1095 },
  { label: RangeEnum.CUSTOM, range: 91 },
];

export interface Measurement extends SelectOptionRequired {
  dhpg: number;
  wellboreUuid?: string;
}

export interface PressureTypeValues {
  label: string;
  background: string;
  color: string;
}

export enum WellborePurpose {
  INJECTION = 'injection',
  PRODUCTION = 'production',
}

export enum RightAxisEnum {
  INSTANT = 'Instant',
  CUMULATIVE = 'Cumulative',
  GRADIENT = 'Gradient',
  ON_STREAM_HOURS = 'On stream hours',
  PRODUCTIVITY_INDEX = 'Productivity index',
}

export interface LineStyle {
  borderDash: number[];
  borderCapStyle: string;
}

export type HelpLineType = HelpLineInput & {
  source?: Sources;
  measureType?: MeasurementType;
  name: string;
};

export interface LineStyleType {
  label: string;
  strokeDasharray: string;
  isMainLineLegend?: boolean;
}
