import { FC, MouseEvent } from 'react';

import { StyledTypography } from '../PrognosisCard/PrognosisCard.styles';
import { Button, HeaderContainer, HeaderWrapper } from './HeaderRow.styles';
import { HeaderRowValues } from './HeaderRowValues';
import { TabType } from 'src/constants/tab';

interface HeaderRowProps {
  currentTab?: TabType;
  sortDirection?: string;
  handleSort?: (event: MouseEvent<HTMLDivElement>) => void;
  handleValidSort?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const HeaderRow: FC<HeaderRowProps> = ({
  currentTab,
  sortDirection,
  handleSort,
}) => {
  return (
    <HeaderContainer>
      <HeaderWrapper $approvedTab={currentTab === TabType.Approved}>
        <StyledTypography group="table" variant="cell_header">
          {'Well / Status / Title'}
        </StyledTypography>
        {currentTab === TabType.Historical ? (
          <Button>
            <HeaderRowValues
              label="Expired"
              sortDirection={sortDirection}
              handleSort={handleSort}
            />
          </Button>
        ) : (
          <Button>
            <HeaderRowValues
              label="Validity"
              sortDirection={sortDirection}
              handleSort={handleSort}
            />
          </Button>
        )}
        <HeaderRowValues label="Operation / Phase" />
        <HeaderRowValues label="Created by" />
        <HeaderRowValues label="Approver" />
      </HeaderWrapper>
    </HeaderContainer>
  );
};
