import { FC } from 'react';

import { ActionFooter } from './components/ActionFooter/ActionFooter';
import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';
import { FormData } from './components/FormData/FormData';
import { FormHeader } from './components/FormHeader/FormHeader';
import { OverlappingNotification } from './components/OverlappingNotification/OverlappingNotification';
import { Title } from './components/Title/Title';
import { EditPrognosisProvider } from './providers/EditPrognosisProvider';
import { Container, PageContainer, Wrapper } from './EditPrognosis.styles';

export const EditPrognosis: FC = () => {
  return (
    <EditPrognosisProvider>
      <PageContainer>
        <Container>
          <Wrapper>
            <Breadcrumbs />
            <OverlappingNotification />
            <Title />
            <FormHeader />
            <FormData />
          </Wrapper>
        </Container>
        <ActionFooter />
      </PageContainer>
    </EditPrognosisProvider>
  );
};
