import { Button, Icon } from '@equinor/amplify-component-lib';
import { timeline } from '@equinor/eds-icons';

import { ManageHelpLines } from 'src/pages/EditMeasuredData/components/ManageHelpLines/ManageHelpLines';
import { useLinePlot } from 'src/pages/EditMeasuredData/hooks/useLinePlot';

export const ManageHelpLinesButton = () => {
  const { helplinesDialogOpen, setHelplinesDialogOpen } = useLinePlot();

  const handleOnCloseHelpLines = () => {
    setHelplinesDialogOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={() => setHelplinesDialogOpen(true)}>
        <Icon data={timeline} />
        Manage help lines
      </Button>
      {helplinesDialogOpen && (
        <ManageHelpLines onClose={handleOnCloseHelpLines} />
      )}
    </>
  );
};
