import { useQuery } from '@tanstack/react-query';

import { UserService } from 'src/api';
import { FULL_NAME_QUERY } from 'src/constants/queryKeys';

export function useFullName(shortName: string | null | undefined) {
  return useQuery({
    queryKey: [FULL_NAME_QUERY, shortName],
    queryFn: async () => {
      try {
        const fullName = await UserService.getApiV1UserFullname(
          shortName ?? ''
        );
        if (fullName) return fullName;
      } catch (error) {
        console.error(error);
        console.warn(
          `Unable to find fullName for: ${shortName}, is it a shortName?`
        );
        return shortName;
      }
    },
    enabled: shortName !== undefined && shortName !== null,
    gcTime: Infinity,
    staleTime: Infinity,
  });
}
