import {
  Button,
  colors,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacings.small};
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: min(320px) auto 1fr auto;
  width: 100%;
  align-items: center;
  gap: ${spacings.medium};
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  gap: ${spacings.medium};
  flex-wrap: wrap;
`;

export const OpenDialogButton = styled(Button)`
  width: max-content;
  align-self: flex-start;
`;

export const FlexEnd = styled.div`
  align-self: flex-end;
`;

export const DialogChildren = styled.div`
  height: 560px;
  position: relative;
`;

export const ColumnName = styled(Typography)`
  margin-bottom: ${spacings.medium};
  border-bottom: 1px solid ${colors.ui.background__medium.hex};
`;

export const TypographyWithMargin = styled(Typography)`
  margin-bottom: ${spacings.small};
`;

export const TypographyWithLeftBorder = styled(Typography)`
  border-left: 1px solid ${colors.ui.background__medium.hex};
  color: ${colors.text.static_icons__tertiary.hex};
  height: 100%;
  padding: ${spacings.small};
`;
