import { spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 3fr) 2fr repeat(3, 1fr) 50px;
  border-left: ${spacings.x_small} solid transparent;
  width: 100%;
`;

interface HeaderWrapperProps {
  $approvedTab?: boolean;
}
export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / span 6;
  width: 100%;
  gap: ${spacings.large};
  padding: 0 ${spacings.medium};
`;

export const HeaderValueContainer = styled(Typography)`
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  gap: ${spacings.x_small};
`;

export const Button = styled.button`
  padding: 0;
`;
