/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StratigraphicUnit } from '../models/StratigraphicUnit';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReservoirZoneService {
  /**
   * Get top used reservoir zones
   * @param wellboreIdentifier
   * @returns StratigraphicUnit OK
   * @throws ApiError
   */
  public static getApiV1ReservoirZonesTopUsed(
    wellboreIdentifier: string
  ): CancelablePromise<Array<StratigraphicUnit>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/ReservoirZones/{wellboreIdentifier}/TopUsed',
      path: {
        wellboreIdentifier: wellboreIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get last used reservoir zones
   * @param wellboreIdentifier
   * @returns StratigraphicUnit OK
   * @throws ApiError
   */
  public static getApiV1ReservoirZonesLastUsed(
    wellboreIdentifier: string
  ): CancelablePromise<Array<StratigraphicUnit>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/ReservoirZones/{wellboreIdentifier}/LastUsed',
      path: {
        wellboreIdentifier: wellboreIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
