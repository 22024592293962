export const isBetweenNumbers = (number: number, range: [number, number]) => {
  const start = Math.min(...range);
  const end = Math.max(...range);

  return start < number && number < end;
};

export const roundTo = (
  value: number | string | null | undefined,
  decimal = 2
): number | undefined => {
  if (value === undefined || value === null) return undefined;
  if (typeof value === 'string' && isNaN(parseFloat(value))) return undefined;

  const numberValue = typeof value === 'number' ? value : parseFloat(value);
  return (
    Math.round(numberValue * Math.pow(10, decimal)) / Math.pow(10, decimal)
  );
};

export function mapToRange(
  value: number,
  input: { start: number; end: number },
  output: { start: number; end: number }
): number {
  const slope = (output.end - output.start) / (input.end - input.start);
  return output.start + slope * (value - input.start);
}
