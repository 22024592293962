import {
  Button,
  colors,
  Icon,
  Typography,
} from '@equinor/amplify-component-lib';
import { arrow_back_ios, close, history } from '@equinor/eds-icons';

import { FilterType } from 'src/api';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

import styled from 'styled-components';

interface WrapperProps {
  $open: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  flex-basis: ${(props) => (props.$open ? '300px' : '65px')};
  width: ${(props) => (props.$open ? '300px' : '65px')};
`;

const WrapperCollapsed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Header = () => {
  const {
    filterPanelOpen,
    toggleFilterPanelOpen,
    setSelectedFilter,
    selectedFilter,
  } = useEditMeasuredData();

  if (!filterPanelOpen) {
    return (
      <WrapperCollapsed>
        <Icon data={history} />
      </WrapperCollapsed>
    );
  }

  const handleClose = () => {
    toggleFilterPanelOpen();
    setSelectedFilter(undefined);
  };

  const title =
    selectedFilter?.type === FilterType.AUTO
      ? 'Auto interpolation'
      : 'Advanced filter';

  return (
    <Wrapper $open={filterPanelOpen}>
      {selectedFilter ? (
        <TitleWrapper>
          <Button
            variant="ghost_icon"
            onClick={() => {
              setSelectedFilter(undefined);
            }}
          >
            <Icon data={arrow_back_ios} color="secondary" />
          </Button>
          <Typography>{title}</Typography>
        </TitleWrapper>
      ) : (
        <TitleWrapper>
          <Typography group="heading" variant="h4">
            Active edits
          </Typography>
        </TitleWrapper>
      )}
      <Button variant="ghost_icon" onClick={handleClose}>
        <Icon data={close} />
      </Button>
    </Wrapper>
  );
};
