import { useRef, useState } from 'react';

import {
  colors,
  formatDate,
  Icon,
  Menu,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { calendar } from '@equinor/eds-icons';

import { DateRangeMenu } from 'src/components/DateRangeMenu/DateRangeMenu';
import { useMeasuredData } from 'src/hooks';

import styled from 'styled-components';

const DateRangeChip = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: ${spacings.small};
  gap: ${spacings.small};
  height: 36px;
  cursor: pointer;
  background-color: ${colors.ui.background__default.rgba};
  margin-right: 1px; // Margins to avoid being cut off by lineplot elements
  border-bottom: 1px solid ${colors.text.static_icons__tertiary.rgba};
  color: ${colors.text.static_icons__default.rgba};
  &:hover {
    border-bottom: 2px solid ${colors.text.static_icons__tertiary.rgba};
    svg {
      margin-top: 1px;
    }
  }
  svg {
    margin-left: ${spacings.small};
    fill: ${colors.text.static_icons__default.rgba};
  }
`;

export const LinePlotDateRange = () => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { fromDate, toDate } = useMeasuredData();

  const from = formatDate(fromDate, { format: 'DD.MM.YYYY' });
  const to = formatDate(toDate, { format: 'DD.MM.YYYY' });

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnClick = () => {
    setOpen((o) => !o);
  };

  return (
    <>
      <DateRangeChip ref={menuRef} onClick={handleOnClick}>
        <Typography group="input" variant={'text'}>
          {`${from} - ${to}`}
        </Typography>
        <Icon size={24} data={calendar} />
      </DateRangeChip>
      <Menu
        anchorEl={menuRef.current}
        open={open}
        onClose={handleOnClose}
        placement="bottom-end"
      >
        <Menu.Section index={0} title="Timespan">
          <DateRangeMenu />
        </Menu.Section>
      </Menu>
    </>
  );
};
