import { FC, useMemo } from 'react';

import { amplify_arrow_up_down, Icon } from '@equinor/amplify-component-lib';
import { keyboard, placeholder_icon, tune } from '@equinor/eds-icons';

import { FilterType } from 'src/api';

import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface FilterTypeIconProps {
  type?: FilterType;
}

export const FilterTypeIcon: FC<FilterTypeIconProps> = ({ type }) => {
  const filterIcon = useMemo(() => {
    switch (type) {
      case FilterType.AUTO:
        // TODO: Figure out if we can just remove auto as a filter type
        return <Icon data={placeholder_icon} />;
      case FilterType.RULE:
        return <Icon data={tune} />;
      case FilterType.DRAG:
        return <Icon data={amplify_arrow_up_down} />;
      case FilterType.MANUAL:
        return <Icon data={keyboard} />;
      default:
        return null;
    }
  }, [type]);

  return <IconWrapper>{filterIcon}</IconWrapper>;
};
