import {
  cleanRichTextValue,
  extractImageUrls,
} from '@equinor/amplify-component-lib';

import { BlobService } from 'src/api';
import { EMPTY_RICH_TEXT } from 'src/constants/prognosisComment';

/**
 * Clears the comment if it is equal to EMPTY_RICH_TEXT.
 *
 * @param comment - The comment to be cleared.
 * @returns The cleared comment or an empty string if it is equal to EMPTY_RICH_TEXT.
 */
export const clearComment = (comment: string) => {
  if (comment === EMPTY_RICH_TEXT) {
    return '';
  } else {
    return comment;
  }
};

export interface ImageType {
  b64: string;
  url: string;
}

/**
 * Escapes special characters in a string to be used in a regular expression.
 * @param string - The string to escape.
 * @returns The escaped string.
 */
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Replaces image tags in a comment with a specified alt attribute from an array of ImageType objects.
 *
 * @param comment - The comment string containing image tags.
 * @param addedImages - An array of ImageType objects containing base64 strings and URLs.
 * @returns The modified comment string with replaced image tags.
 */
const replaceImageTags = (comment: string, addedImages: ImageType[]) => {
  for (const image of addedImages) {
    const escapedB64 = escapeRegExp(image.b64);
    const slicedB64 = escapedB64.slice(0, 100);

    const imageTagPattern = new RegExp(`<img src="${slicedB64}.*?"(.*?)>`, 'g');
    comment = comment.replace(imageTagPattern, `<img alt="${image.url}" />`);
  }

  return comment;
};

/**
 * Checks if an image exists in a comment. This is to determine if the image has been removed before saving the comment.
 *
 * @param currentComment - The current comment to be checked against.
 * @param imageB64 - The base64 representation of the image to check.
 * @returns A boolean indicating whether the image exists in the comment.
 */
export const checkIfImageExists = (
  currentComment: string | undefined,
  imageB64: string
) => {
  if (currentComment == undefined || imageB64 == undefined) {
    return false;
  }

  const slicedB64 = imageB64.slice(0, 100);
  const imagePattern = new RegExp(slicedB64, 'g');

  return imagePattern.test(currentComment);
};

/**
 * Prepares a comment by clearing and replacing image tags, cleaning rich text values,
 * and removing unused images.
 *
 * @param existingComment - The existing comment to be modified.
 * @param currentComment - The current comment to be processed.
 * @param addedImages - An array of ImageType objects containing base64 strings and URLs.
 * @returns The prepared comment.
 */
export const prepareComment = async (
  existingComment: string,
  currentComment: string,
  addedImages: ImageType[]
) => {
  const clearedImages = clearComment(currentComment);
  const replacedImages = replaceImageTags(clearedImages, addedImages);
  const cleanedComment = cleanRichTextValue(replacedImages);
  const existingImages = extractImageUrls(existingComment);

  const removedImages = existingImages.filter(
    (alt) => !cleanedComment.includes(alt)
  );

  for (const image of removedImages) {
    await BlobService.deleteApiV1Blob(image);
  }

  return cleanedComment;
};
