import {
  amplify_arrow_up_down,
  Button,
  colors,
  Icon,
} from '@equinor/amplify-component-lib';

import { useLinePlot } from '../../hooks/useLinePlot';
import { useGetPressureDataWellboresFlat } from 'src/pages/EditMeasuredData/hooks/useGetPressureDataWellboresFlat';

import styled from 'styled-components';

interface DragToggleButtonProps {
  $isDragActive: boolean;
}

const StyledDragToggleButton = styled(Button)<DragToggleButtonProps>`
  width: 131px;
  &:has(svg) {
    padding-left: 0;
  }
  > span {
    color: ${(props) =>
      props.$isDragActive
        ? colors.text.static_icons__primary_white.rgba
        : colors.interactive.primary__resting.rgba};
  }
`;

export const DragToggleButton = () => {
  const { isDragActive, setIsDragActive } = useLinePlot();
  const { isLoading: pressureDataLoading } = useGetPressureDataWellboresFlat();

  return (
    <StyledDragToggleButton
      $isDragActive={isDragActive}
      variant={isDragActive ? 'contained' : 'outlined'}
      onClick={() => setIsDragActive(!isDragActive)}
      disabled={pressureDataLoading}
    >
      {isDragActive ? null : <Icon data={amplify_arrow_up_down} />}
      {isDragActive ? 'Done' : 'Drag tool'}
    </StyledDragToggleButton>
  );
};
