import { useMemo } from 'react';

import { PathLayer } from '@deck.gl/layers';
import { useQuery } from '@tanstack/react-query';

import { FieldService } from 'src/api';
import { MEASURED_DATA_MAP_KEY, WELLBORE_PATHS_QUERY_KEY } from 'src/constants';
import { useApp } from 'src/hooks';
import { usePressurePoints } from 'src/pages/MapView/hooks';
import { MapLayer } from 'src/pages/MapView/MapView.types';

export function useWellborePaths() {
  const { field } = useApp();

  const { pressurePoints } = usePressurePoints();
  const { data: drilledWelllines } = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, WELLBORE_PATHS_QUERY_KEY, field?.uuid],
    queryFn: () => FieldService.getWellpositions(field?.uuid ?? ''),
    staleTime: Infinity,
  });

  const welllines = useMemo(() => {
    return drilledWelllines
      ?.filter((wellline) =>
        pressurePoints.some(
          (pressurePoint) =>
            pressurePoint.wellboreUuid === wellline.wellboreUuid
        )
      )
      .map((wellline) => {
        const coordinates = wellline.wellPositions?.coordinates?.map(
          (coordinate) => {
            const [lat, lng] = coordinate;
            return [lng, lat];
          }
        );
        return {
          ...wellline,
          wellPositions: {
            ...wellline.wellPositions,
            coordinates: coordinates,
          },
        };
      });
  }, [pressurePoints, drilledWelllines]);

  return useMemo(
    () =>
      new PathLayer({
        id: MapLayer.WELLBORE_PATHS,
        filled: false,
        data: welllines?.map((wellline) => wellline.wellPositions.coordinates),
        getPath: (d: number[]) => d,
        getColor: [36, 55, 70], // Equinor interactive secondary resting
        widthUnits: 'meters',
        getWidth: 2,
        widthMinPixels: 1,
      }),
    [welllines]
  );
}
