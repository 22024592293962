import { FC } from 'react';

import { SkeletonBase, Typography } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { FlexWrapper, Info, Metadata } from './PrognosisInfo.styles';
import { TOGGLE_UNITS_FEATURE_KEY } from 'src/constants';

import styled from 'styled-components';

const { shape } = tokens;

const Chip = styled(SkeletonBase)`
  height: 26px;
  width: 4rem;
  border-radius: ${shape.rounded.borderRadius};
`;

const ExpirationDate = styled(SkeletonBase)`
  height: 24px;
  width: 9rem;
  border-radius: ${shape.corners.borderRadius};
`;

const Name = styled(SkeletonBase)`
  height: 24px;
  width: 12rem;
  border-radius: ${shape.corners.borderRadius};
`;

const Wellbore = styled(SkeletonBase)`
  height: 24px;
  width: 6rem;
  border-radius: ${shape.corners.borderRadius};
`;

const Operation = styled(SkeletonBase)`
  height: 24px;
  width: 8rem;
  border-radius: ${shape.corners.borderRadius};
`;

const UnitToggle = styled(SkeletonBase)`
  height: 48px;
  width: 8rem;
  border-radius: ${shape.corners.borderRadius};
`;

export const PrognosisInfoSkeleton: FC = () => {
  const { showContent } = useFeatureToggling({
    featureKey: TOGGLE_UNITS_FEATURE_KEY,
  });

  return (
    <FlexWrapper>
      <Info>
        <Metadata>
          <Typography group="navigation" variant="label">
            Status
          </Typography>
          <Chip />
        </Metadata>
        <Metadata>
          <Typography group="navigation" variant="label">
            Expiration
          </Typography>
          <ExpirationDate />
        </Metadata>
        <Metadata>
          <Typography group="navigation" variant="label">
            Created by
          </Typography>
          <Name />
        </Metadata>
        <Metadata>
          <Typography group="navigation" variant="label">
            Approver
          </Typography>
          <Name />
        </Metadata>
        <Metadata>
          <Typography group="navigation" variant="label">
            Wellbore
          </Typography>
          <Wellbore />
        </Metadata>
        <Metadata>
          <Typography group="navigation" variant="label">
            Operation
          </Typography>
          <Operation />
        </Metadata>
      </Info>
      {showContent && <UnitToggle />}
    </FlexWrapper>
  );
};
