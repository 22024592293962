/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DataType {
  FINAL = 'Final',
  DEPTH_CORRECTED = 'DepthCorrected',
  PICORRECTED = 'PICorrected',
  RAW = 'Raw',
  GRADIENT = 'Gradient',
  INSTANT_PRODUCTION = 'InstantProduction',
  INSTANT_INJECTION = 'InstantInjection',
  INSTANT_VOIDAGE = 'InstantVoidage',
  CUMULATIVE_PRODUCTION = 'CumulativeProduction',
  CUMULATIVE_INJECTION = 'CumulativeInjection',
  CUMULATIVE_VOIDAGE = 'CumulativeVoidage',
  ON_STREAM_HOURS = 'OnStreamHours',
  PRODUCTIVITY_INDEX = 'ProductivityIndex',
  PRODUCTIVITY_INDEX_INTERPOLATED = 'ProductivityIndexInterpolated',
}
