import { useParams } from 'react-router';

import { useQueryClient } from '@tanstack/react-query';

import { BlobService } from 'src/api';
import { GET_IMAGE, IMG_WITH_ALT } from 'src/constants/image';

export const useGetBlob = () => {
  const queryClient = useQueryClient();
  const { prognosisId } = useParams();

  /**
   * Inserts base64-encoded images into the provided content string.
   *
   * @param content - The content string to insert images into.
   * @returns The content string with base64-encoded images inserted.
   */
  const insertImages = async (content: string, id?: number) => {
    let contentWithB64 = content;
    const matches = content.matchAll(IMG_WITH_ALT);

    const imageData = await queryClient.fetchQuery({
      queryKey: [GET_IMAGE, prognosisId],
      queryFn: () => BlobService.getApiV1Blob(id ?? Number(prognosisId)),
    });

    const imageArray = Array.isArray(imageData) ? imageData : [imageData];

    for (const match of matches) {
      const imageTag = match[0];
      const path = match[2];

      const image = imageArray.find((data) => data.blobName === path);

      const src = `data:${image?.contentType};base64,${image?.content}`;
      // Insert b64 src
      contentWithB64 = contentWithB64.replace(
        imageTag,
        `<img src="${src}" alt="${path}">`
      );
    }

    return contentWithB64;
  };

  return { insertImages };
};
