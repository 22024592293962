import { colors } from '@equinor/amplify-component-lib';

import { LineStyleType, PressureTypeValues, WellborePurpose } from '../types';
import { DataType } from 'src/api';
import { HelpLine } from 'src/api';
import { PressureType } from 'src/api';

export const FIELDS_WITH_MEASURED_DATA = ['Johan Sverdrup']; // TODO: remove this when all fields have measured data

export const MEASURED_DATA_PLOT_DEFAULT_LINE_STYLES: LineStyleType[] = [
  { label: 'Measured', strokeDasharray: '', isMainLineLegend: true },
  {
    label: 'Corrected',
    strokeDasharray: '6,8',
    isMainLineLegend: true,
  },
] as const;

export const PressureTypesValues: {
  [key in PressureType | WellborePurpose]: PressureTypeValues;
} = {
  [PressureType.MEASURED]: {
    label: 'Datum',
    background: colors.dataviz.lightblue.darker,
    color: colors.text.static_icons__primary_white.rgba,
  },
  [PressureType.CORRECTED]: {
    label: 'Manual',
    background: colors.dataviz.lightgreen.default,
    color: colors.text.static_icons__primary_white.rgba,
  },
  [PressureType.AUTO_CORRECTED]: {
    label: 'Interpolated',
    background: colors.dataviz.lightgreen.default,
    color: colors.text.static_icons__primary_white.rgba,
  },
  [PressureType.INTERPOLATED]: {
    label: 'Interpolated',
    background: colors.dataviz.lightgray.default,
    color: colors.text.static_icons__primary_white.rgba,
  },
  [PressureType.MISSING]: {
    label: 'Missing',
    background: colors.dataviz.lightpink.lighter,
    color: colors.dataviz.lightpink.darker,
  },
  [WellborePurpose.INJECTION]: {
    label: 'Injector',
    background: colors.ui.background__default.rgba,
    color: colors.text.static_icons__default.rgba,
  },
  [WellborePurpose.PRODUCTION]: {
    label: 'Producer',
    background: colors.text.static_icons__default.rgba,
    color: colors.text.static_icons__primary_white.rgba,
  },
} as const;

export const PressureLabels: Record<string, string> = {
  [DataType.RAW]: 'Raw pressure',
  [DataType.DEPTH_CORRECTED]: 'Datum pressure',
  [DataType.FINAL]: 'QA pressure',
  [DataType.GRADIENT]: 'Gradient',
  [DataType.INSTANT_PRODUCTION]: 'Instant oil production',
  [DataType.CUMULATIVE_PRODUCTION]: 'Cumulative oil production',
  [DataType.INSTANT_INJECTION]: 'Instant injection',
  [DataType.CUMULATIVE_INJECTION]: 'Cumulative injection',
  [DataType.ON_STREAM_HOURS]: 'On stream hours',
  [DataType.PRODUCTIVITY_INDEX]: 'Productivity index',
} as const;

export const CONTROLLER_PROPS = {
  doubleClickZoom: false,
} as const;

export const getHelpLineStyleName = (helpLine: HelpLine) => {
  switch (helpLine.type) {
    case DataType.CUMULATIVE_INJECTION:
    case DataType.CUMULATIVE_VOIDAGE:
    case DataType.CUMULATIVE_PRODUCTION:
    case DataType.INSTANT_INJECTION:
    case DataType.PRODUCTIVITY_INDEX:
    case DataType.PRODUCTIVITY_INDEX_INTERPOLATED:
    case DataType.INSTANT_PRODUCTION:
    case DataType.INSTANT_VOIDAGE:
    case DataType.ON_STREAM_HOURS:
    case DataType.GRADIENT:
      return 'Right axis';
    case DataType.RAW:
      return 'Raw';
    case DataType.DEPTH_CORRECTED:
      return 'DC';
    case DataType.FINAL:
      return 'Final';
    default:
      return 'Helpline';
  }
};

export const RIGHT_AXES = [
  DataType.CUMULATIVE_INJECTION,
  DataType.CUMULATIVE_PRODUCTION,
  DataType.INSTANT_PRODUCTION,
  DataType.INSTANT_INJECTION,
  DataType.GRADIENT,
  DataType.ON_STREAM_HOURS,
  DataType.PRODUCTIVITY_INDEX,
  DataType.PRODUCTIVITY_INDEX_INTERPOLATED,
];

export const HELP_LINE_OPACITY_HEX_COLOR_STRING = '66';

export const PRESSURE_TYPES_IN_ORDER = [
  PressureType.MEASURED,
  PressureType.CORRECTED,
  PressureType.INTERPOLATED,
  PressureType.MISSING,
] as const;

export const TEMP_ID_PREFIX = 'temporary-id-';

export const SHOW_MANUAL_DATA_THRESHOLD = 180;

export const LINE_PLOT_WELL_COLORS: string[] = [
  '#043062',
  '#610198',
  '#871243',
  '#003d2e',
  '#795515',
  '#23aaf6',
  '#9b59f2',
  '#e1515d',
  '#089173',
  '#e3ab02',
  '#004799',
  '#9314db',
  '#a91e58',
  '#00664c',
  '#a0701c',
  '#85d0fa',
  '#ba99ff',
  '#ff808a',
  '#0ac29a',
  '#fdc835',
];
