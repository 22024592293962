import { useIsMutating } from '@tanstack/react-query';

import {
  ALL_MEASURED_DATA_FILTERS_MUTATION_KEYS,
  BULK_DELETE_MEASURED_DATA_FILTER,
  DELETE_MEASURED_DATA_FILTER,
} from 'src/constants';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useGetFiltersGlobal } from 'src/pages/EditMeasuredData/hooks/useGetFiltersGlobal';

export const useIsFiltersLoading = () => {
  const { isLoading: getFiltersForWell } = useGetFiltersForWell();
  const { isLoading: getFilterGlobal } = useGetFiltersGlobal();

  const isMutating =
    useIsMutating({
      predicate: (mutation) =>
        mutation.options.mutationKey?.some((key) =>
          ALL_MEASURED_DATA_FILTERS_MUTATION_KEYS.includes(key as string)
        ) ?? false,
    }) > 0;

  const isDeletingFilter =
    useIsMutating({
      predicate: (mutation) =>
        mutation.options.mutationKey?.some(
          (key) =>
            key === DELETE_MEASURED_DATA_FILTER ||
            key === BULK_DELETE_MEASURED_DATA_FILTER
        ) ?? false,
    }) > 0;

  return {
    filtersLoading: getFiltersForWell || getFilterGlobal || isMutating,
    isDeletingFilters: isDeletingFilter,
  };
};
