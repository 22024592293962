import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@equinor/amplify-component-lib';
import { add } from '@equinor/eds-icons';

import { HeaderContent, StyledTypography } from '../../Dashboard.styles';
import { useDashboard } from '../../hooks/useDashboard';
import { useApp } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { Search } from 'src/pages/Dashboard/components/Search/Search';
import { defaultValues } from 'src/providers/FormProvider';
import { EditPrognosisFormValues } from 'src/utils';

export const Header: FC = () => {
  const { field } = useApp();
  const { filterValue, updateFilterValue } = useDashboard();
  const { userAccessForField } = useGetUserAccess();
  const showButton = userAccessForField?.userRoles?.submitter ?? false;
  const { reset } = useFormContext<EditPrognosisFormValues>();
  const navigate = useNavigate();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFilterValue({ search: event.target.value });
  };

  const handleOnCreate = () => {
    reset(defaultValues); // Reset form to avoid showing old data
    navigate('create-new-prognosis');
  };

  return (
    <HeaderContent id="header">
      <StyledTypography variant="h1_bold">
        Prognoses in {field?.name}
      </StyledTypography>
      <Search value={filterValue.search} handleOnChange={handleOnChange} />
      {showButton && (
        <Button onClick={handleOnCreate}>
          Create new prognosis <Icon data={add} />
        </Button>
      )}
    </HeaderContent>
  );
};
