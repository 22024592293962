import { TopBar, useAuth } from '@equinor/amplify-component-lib';

export const Account = () => {
  const { account, logout, photo } = useAuth();

  return (
    <TopBar.Account
      account={account}
      logout={logout}
      photo={photo}
      roles={[]}
    />
  );
};
