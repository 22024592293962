import { FC } from 'react';

import { useMeasuredData } from 'src/hooks';
import { TableBody } from 'src/pages/PlotView/components/WellboreInformation/MeasurementList/TableBody';
import { TableGroupHeader } from 'src/pages/PlotView/components/WellboreInformation/MeasurementList/TableGroupHeader';
import { TableHeader } from 'src/pages/PlotView/components/WellboreInformation/MeasurementList/TableHeader';

import styled from 'styled-components';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
`;

interface MeasurementListProps {
  compact: boolean;
}

export const MeasurementList: FC<MeasurementListProps> = ({ compact }) => {
  const { selectedMeasurements } = useMeasuredData();

  return (
    <TableWrapper>
      <TableGroupHeader />
      <TableHeader />
      {selectedMeasurements.map((item) => {
        return (
          <TableBody
            key={`${item.label} : ${item.dhpg}`}
            compact={compact}
            measurement={item}
          />
        );
      })}
    </TableWrapper>
  );
};
