import {
  spacings,
  TextField as AmplifyTextField,
} from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

export const TextField = styled(AmplifyTextField)`
  input {
    background-color: ${colors.ui.background__default.rgba};
    border-bottom: 1px solid ${colors.text.static_icons__tertiary.rgba};
  }
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  justify-content: space-evenly;
  gap: ${spacings.large};
`;

export const DoubleField = styled.div`
  display: flex;
  gap: ${spacings.medium};
  div {
    width: 100%;
  }
`;
