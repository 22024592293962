import { FC } from 'react';

import {
  colors,
  Typography,
  useSnackbar,
} from '@equinor/amplify-component-lib';

import { FilterRule } from 'src/api';
import {
  CriteriaItem,
  CriteriaItemWrapper,
} from 'src/pages/EditMeasuredData/components/FilterPanel/components/CriteriaItem';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { getDateRangeLabelWithIndefinite } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  > p {
    font-size: 14px;
    padding: 12px 16px;
    border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  }
`;

interface FilterDescriptionProps {
  rule: FilterRule | undefined;
}

export const FilterDescription: FC<FilterDescriptionProps> = ({ rule }) => {
  const { setSelectedFilter } = useEditMeasuredData();
  const { showSnackbar } = useSnackbar();

  if (!rule) {
    setSelectedFilter(undefined);
    showSnackbar(
      'There was a problem getting details for the rule you clicked'
    );
    return null;
  }

  return (
    <Wrapper>
      <Typography>
        The criteria below excludes the impacted pressure data if one or more of
        the criteria are true. In such case, the new values of the impacted
        pressure data is calculated based on a linear auto-interpolation.
      </Typography>
      <CriteriaItemWrapper>
        <Typography>Daterange</Typography>
        <div>
          <Typography>
            {getDateRangeLabelWithIndefinite(
              rule.fromDate ?? '',
              rule.toDate ?? ''
            )}
          </Typography>
        </div>
      </CriteriaItemWrapper>
      {rule.criteria?.map((criteria, index) => {
        return (
          <CriteriaItem
            key={criteria.criteriaId}
            criteria={criteria}
            number={index + 1}
          />
        );
      })}
    </Wrapper>
  );
};
