import { useMutation } from '@tanstack/react-query';

import { MeasuredDataFilter, MeasuredDataFilterService } from 'src/api';
import { BULK_UPDATE_MEASURED_DATA_FILTER } from 'src/constants';
import { useUpdateQueryData } from 'src/pages/EditMeasuredData/hooks/useUpdateQueryData';

export const useBulkUpdateFilter = () => {
  const { updateBulkFilterQueryData, handleOnSuccess } = useUpdateQueryData();
  return useMutation({
    mutationKey: [BULK_UPDATE_MEASURED_DATA_FILTER],
    mutationFn: (filters: MeasuredDataFilter[]) =>
      MeasuredDataFilterService.bulkUpdateFilter(filters),
    onSuccess: (data) => {
      updateBulkFilterQueryData(data);
      handleOnSuccess();
    },
  });
};
