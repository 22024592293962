import { colors } from '@equinor/amplify-component-lib';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Text } from '@visx/text';
import { withTooltip } from '@visx/tooltip';

import { Legend } from './Legend/Legend';
import { Tooltip } from './Tooltip/Tooltip';
import { MARGIN, TICK_LABEL_PROPS } from './Chart.constants';
import { Wrapper } from './Chart.styles';
import { ChartDataPoint, ChartProps } from './Chart.types';

const Chart = ({
  width,
  height,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  children,
  formatTick,
  xScale,
  yScale,
  yMax,
  xLabel,
  yLabel,
  data,
  graphColors,
  rangeColor,
}: ChartProps) => {
  // The color is the same for all the data points in time chart, so we just use the first one
  return (
    <Wrapper>
      <svg width={width} height={height}>
        <GridRows scale={yScale} width={width} top={-10} numTicks={4} />

        <g>
          <Text
            x={width}
            y={yMax - MARGIN.BOTTOM - 5}
            textAnchor="end"
            {...TICK_LABEL_PROPS}
          >
            {xLabel}
          </Text>
          <Text
            x={width}
            y={TICK_LABEL_PROPS.fontSize + 5}
            angle={-90}
            textAnchor="end"
            {...TICK_LABEL_PROPS}
          >
            {yLabel}
          </Text>
        </g>

        {children}

        <AxisLeft
          scale={yScale}
          left={MARGIN.LEFT}
          hideAxisLine
          hideTicks
          tickLabelProps={TICK_LABEL_PROPS}
          numTicks={5}
        />

        <AxisBottom
          scale={xScale}
          numTicks={data.length}
          top={yMax - MARGIN.BOTTOM}
          tickLabelProps={TICK_LABEL_PROPS}
          tickLineProps={{
            stroke: colors.ui.background__medium.rgba,
          }}
          stroke={colors.ui.background__medium.rgba}
          strokeWidth={2}
          // @ts-expect-error - TS doesn't like the x/y props on the line element
          tickFormat={formatTick}
        />
      </svg>

      <Legend graphColors={graphColors} color={rangeColor} />
      <Tooltip
        tooltipData={tooltipData as ChartDataPoint}
        tooltipTop={tooltipTop!}
        tooltipLeft={tooltipLeft!}
      />
    </Wrapper>
  );
};

export default withTooltip(Chart);
