import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

interface ContainerProps {
  $amount: number;
}
export const Container = styled.div<ContainerProps>`
  grid-column: ${({ $amount }) => `span ${$amount}`};
  padding: ${spacings.small};
`;

interface ContainerDetailsProps {
  $isFirstColumn?: boolean;
}
export const ContainerDetails = styled.div<ContainerDetailsProps>`
  display: flex;
  padding: ${spacings.medium_small} ${spacings.small};
  border-bottom: 2px solid ${colors.ui.background__medium.rgba};
  justify-content: ${({ $isFirstColumn }) =>
    $isFirstColumn ? 'flex-start' : 'flex-end'};
`;
