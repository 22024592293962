import { useMemo } from 'react';

import { InterpolatedMapLayer } from '../layers/InterpolatedMapLayer';
import { useGetFieldOutlineFeatureCollection } from 'src/pages/MapView/components/Map/hooks/useGetFieldOutlineFeatureCollection';
import { useMapOptions, usePressurePoints } from 'src/pages/MapView/hooks';
import { RODRIGO_HEATMAP_COLOR_DOMAIN } from 'src/pages/MapView/MapView.constants';
import { MapBackground } from 'src/pages/MapView/MapView.types';

export function useInterpolatedMap() {
  const { backgroundLayer, setIsLoading, setLegendRange } = useMapOptions();
  const { pressurePointsWithValue } = usePressurePoints();
  const { mainFieldOutline } = useGetFieldOutlineFeatureCollection();
  const showHeatmap = useMemo(
    () => backgroundLayer === MapBackground.INTERPOLATED,
    [backgroundLayer]
  );

  return useMemo(
    () =>
      new InterpolatedMapLayer({
        id: MapBackground.INTERPOLATED,
        showHeatmap,
        colors: RODRIGO_HEATMAP_COLOR_DOMAIN,
        pressurePoints: pressurePointsWithValue,
        setIsLoading,
        setLegendRange,
        mainFieldOutline: mainFieldOutline,
      }),
    [
      showHeatmap,
      pressurePointsWithValue,
      setIsLoading,
      setLegendRange,
      mainFieldOutline,
    ]
  );
}
