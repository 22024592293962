import { useCallback, useMemo, useState } from 'react';

import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleLinear, scaleTime } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { extent } from '@visx/vendor/d3-array';

import { LineChartAxes } from './Components/LineChartAxes';
import { LineChartToolTip } from './Components/LineChartToolTip';
import { LineChartToolTipLine } from './Components/LineChartToolTipLine';
import { VerticalBrush } from './Components/VerticalBrush/VerticalBrush';
import { SVG } from './LineChart.styles';
import {
  LineChartData,
  LineChartLine,
  LinePlotMargin,
  TooltipData,
} from './LineChart.types';
import {
  getDate,
  getDefinedLineChartData,
  getValue,
  renderLeftAxisLines,
} from './LineChart.utils';
import { DataType } from 'src/api';
import { DataPointCircle } from 'src/components/Charts/LineChart/Components/DataPointCircle';
import { SHOW_MANUAL_DATA_THRESHOLD } from 'src/constants';
import { RightAxisEnum } from 'src/types';
import { getHelpLineStrokeDashArray } from 'src/utils';

interface LineChartProps {
  wellbores: LineChartLine[];
  width: number;
  height: number;
  margin: LinePlotMargin;
  backgroundColor?: string;
  helplines?: LineChartLine[];
  selectedRightAxis?: RightAxisEnum;
  isDragActive?: boolean;
}

export const LineChartInner = withTooltip<LineChartProps, TooltipData>(
  ({
    width,
    height,
    margin,
    wellbores,
    backgroundColor,
    helplines,
    selectedRightAxis,
    isDragActive,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }: LineChartProps & WithTooltipProvidedProps<TooltipData>) => {
    const [valueScaleDomain, setValueScaleDomain] = useState<number[]>([]);
    const xMax = useMemo(
      () => Math.max(0, width - margin.right - margin.left),
      [margin.left, margin.right, width]
    );

    const yMax = useMemo(
      () => Math.max(0, height - margin.bottom - margin.top),
      [margin.bottom, margin.top, height]
    );

    const firstWellboreData = useMemo(
      () => (wellbores[0] ? wellbores[0].data : []),
      [wellbores]
    );

    const rightAxisLines = useMemo(() => {
      return helplines?.filter(
        (helpline) =>
          helpline.rightAxisType !== undefined &&
          helpline.helplineDataType !== DataType.PRODUCTIVITY_INDEX
      );
    }, [helplines]);

    const rightAxisDataPoints = useMemo(() => {
      if (!helplines) return [];
      return helplines.filter(
        (hl) => hl.helplineDataType === DataType.PRODUCTIVITY_INDEX
      );
    }, [helplines]);

    const leftAxisHelpLines = useMemo(
      () =>
        helplines?.filter((helpline) => helpline.rightAxisType === undefined),
      [helplines]
    );

    const rightAxisDomain = useMemo(() => {
      if (rightAxisLines) {
        if (helplines?.at(0)?.rightAxisType === RightAxisEnum.ON_STREAM_HOURS) {
          return [-1, 25];
        }

        const allRightAxisValues = rightAxisLines.flatMap((line) =>
          line.data.map((data) => data.value)
        );

        const min = Math.min(...allRightAxisValues);
        const max = Math.max(...allRightAxisValues);
        const padding = (max - min) * 0.1;
        return [Math.max(-2, min - padding), max + padding];
      }
      return [0, 1];
    }, [helplines, rightAxisLines]);

    const valueScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax, 0],
          domain: valueScaleDomain,
        }),
      [valueScaleDomain, yMax]
    );

    const axisRightScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax, 0],
          domain: rightAxisDomain,
        }),
      [rightAxisDomain, yMax]
    );

    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [0, xMax],
          domain: extent(firstWellboreData, getDate) as [Date, Date],
        }),
      [firstWellboreData, xMax]
    );

    const bottomAxisNumTicks = useMemo(() => {
      if (firstWellboreData.length <= 10) {
        return firstWellboreData.length - 1;
      }
      if (width < 900) {
        return 8;
      }
      return 10;
    }, [firstWellboreData.length, width]);

    const getRightAxisLinePathY = useCallback(
      (d: LineChartData) => {
        const value = axisRightScale(getValue(d));
        if (!value) return 0;
        return value;
      },
      [axisRightScale]
    );

    const rightAxisLinePaths = useMemo(() => {
      return rightAxisLines?.map((helpline) => {
        return (
          <LinePath
            key={`${helpline.name}-${helpline.helplineDataType}`}
            data={helpline.data.reverse()}
            x={(d) => dateScale(getDate(d))}
            y={(d) => getRightAxisLinePathY(d)}
            defined={
              helpline.rightAxisType !== RightAxisEnum.ON_STREAM_HOURS
                ? getDefinedLineChartData
                : undefined
            }
            stroke={helpline.color}
            strokeWidth={2}
            strokeOpacity={2}
            strokeDasharray={getHelpLineStrokeDashArray(
              helpline?.helplineDataType
            )}
          />
        );
      });
    }, [dateScale, getRightAxisLinePathY, rightAxisLines]);

    const leftAxisHelpLinePaths = useMemo(
      () =>
        leftAxisHelpLines?.map((helpline) => {
          return (
            <LinePath
              key={`${helpline.name}-${
                helpline.helplineDataType
              }-${helpline.color}`}
              data={helpline.data.reverse()}
              x={(d) => dateScale(getDate(d))}
              y={(d) => valueScale(getValue(d))}
              defined={getDefinedLineChartData}
              stroke={helpline.color}
              strokeWidth={2}
              strokeOpacity={2}
              strokeDasharray={getHelpLineStrokeDashArray(
                helpline?.helplineDataType
              )}
            />
          );
        }),
      [dateScale, leftAxisHelpLines, valueScale]
    );

    return (
      <>
        <SVG width={width} height={height}>
          <Group top={margin.top} left={margin.left}>
            <GridRows
              scale={valueScale}
              width={xMax}
              height={yMax}
              stroke="#e0e0e0"
            />
            {renderLeftAxisLines(wellbores, dateScale, valueScale)}
            {leftAxisHelpLinePaths}
            {rightAxisLinePaths}
            <LineChartToolTipLine
              wellbores={wellbores}
              helplines={helplines}
              MARGIN={margin}
              yMax={yMax}
              xMax={xMax}
              width={width}
              height={height}
              valueScale={valueScale}
              axisRightScale={axisRightScale}
              isDragActive={isDragActive}
              dateScale={dateScale}
              bottomAxisNumTicks={bottomAxisNumTicks}
              showTooltip={showTooltip}
              tooltipLeft={tooltipLeft}
              tooltipData={tooltipData}
              hideTooltip={hideTooltip}
            />
            {firstWellboreData.length < SHOW_MANUAL_DATA_THRESHOLD && (
              <DataPointCircle
                lineChartLines={wellbores}
                valueScale={valueScale}
                dateScale={dateScale}
              />
            )}
            {rightAxisDataPoints.length > 0 && (
              <DataPointCircle
                lineChartLines={rightAxisDataPoints}
                valueScale={axisRightScale}
                dateScale={dateScale}
              />
            )}
          </Group>
          <LineChartAxes
            MARGIN={margin}
            yMax={yMax}
            xMax={xMax}
            valueScale={valueScale}
            axisRightScale={axisRightScale}
            dateScale={dateScale}
            bottomAxisNumTicks={bottomAxisNumTicks}
            selectedRightAxis={selectedRightAxis}
            backgroundColor={backgroundColor}
          />

          {firstWellboreData.length !== 0 && (
            <VerticalBrush
              firstWellboreData={firstWellboreData}
              yMax={yMax}
              xMax={xMax}
              valueScaleDomain={valueScaleDomain}
              setValueScaleDomain={setValueScaleDomain}
              valueScale={valueScale}
              wellbores={wellbores}
              helpLines={helplines}
            />
          )}
        </SVG>
        <LineChartToolTip
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={tooltipData}
          MARGIN={margin}
        />
      </>
    );
  }
);
