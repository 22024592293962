import { FC } from 'react';

import { EditMeasuredDataInner } from 'src/pages/EditMeasuredData/EditMeasuredDataInner';
import { EditMeasuredDataProvider } from 'src/pages/EditMeasuredData/providers/EditMeasuredDataProvider';
import { LinePlotProvider } from 'src/pages/EditMeasuredData/providers/LinePlotProvider';

export const EditMeasuredData: FC = () => {
  return (
    <EditMeasuredDataProvider>
      <LinePlotProvider>
        <EditMeasuredDataInner />
      </LinePlotProvider>
    </EditMeasuredDataProvider>
  );
};
