/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HelpLine } from '../models/HelpLine';
import type { HelpLineInput } from '../models/HelpLineInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HelpLineService {
  /**
   * @param fieldUuId
   * @param fromDate
   * @param toDate
   * @param requestBody
   * @returns HelpLine OK
   * @throws ApiError
   */
  public static getHelpLines(
    fieldUuId: string,
    fromDate?: string,
    toDate?: string,
    requestBody?: Array<HelpLineInput>
  ): CancelablePromise<Array<HelpLine>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/HelpLine/{fieldUuId}',
      path: {
        fieldUuId: fieldUuId,
      },
      query: {
        fromDate: fromDate,
        toDate: toDate,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
