import { forwardRef, useMemo } from 'react';

import { SkeletonBase, spacings } from '@equinor/amplify-component-lib';

import { HelplinesForWellbore } from 'src/pages/EditMeasuredData/components/EditPlot/SelectedHelplines/HelplinesForWellbore';
import { ManageHelpLinesButton } from 'src/pages/EditMeasuredData/components/EditPlot/SelectedHelplines/ManageHelpLinesButton';
import { useLinePlot } from 'src/pages/EditMeasuredData/hooks/useLinePlot';

import { useGetMeasuredDataHelpLines } from 'hooks/useGetMeasuredDataHelpLines';
import styled from 'styled-components';

const Container = styled.div`
  flex-grow: 10;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacings.small};
`;

const HelpLinesForWellboreSkeleton = styled(SkeletonBase)`
  width: 15%;
  height: 36px;
  border-radius: 4px;
`;

export const SelectedHelpLines = forwardRef<HTMLDivElement>((_, ref) => {
  const { helpLines, selectedHelpLinesForQuery, helplinesDialogOpen } =
    useLinePlot();
  const { isLoading: helpLineLoading } = useGetMeasuredDataHelpLines(
    selectedHelpLinesForQuery,
    helplinesDialogOpen
  );
  const helpLinesByWellbore = useMemo(() => {
    const uniqueWellboresWithHelplines = helpLines
      ?.map((helpline) => helpline.name)
      .filter((value, index, array) => array.indexOf(value) === index);

    return (
      uniqueWellboresWithHelplines?.map(
        (wellboreName) =>
          helpLines?.filter((helpline) => helpline.name === wellboreName) ?? []
      ) ?? []
    );
  }, [helpLines]);

  return (
    <Container ref={ref}>
      <ManageHelpLinesButton />
      {helpLineLoading
        ? new Array(3)
            .fill(0)
            .map((_, index) => <HelpLinesForWellboreSkeleton key={index} />)
        : helpLinesByWellbore.map((helpLines) => (
            <HelplinesForWellbore
              key={helpLines.at(0)?.name}
              helpLines={helpLines}
            />
          ))}
    </Container>
  );
});

SelectedHelpLines.displayName = 'SelectedHelpLines';
