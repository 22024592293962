import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { PrognosisHistoryService } from 'src/api';
import { PROGNOSIS_HISTORY_KEY } from 'src/constants/queryKeys';

export const useGetPrognosisHistory = () => {
  const { prognosisId } = useParams();

  return useQuery({
    queryKey: [PROGNOSIS_HISTORY_KEY, prognosisId],
    queryFn: () =>
      PrognosisHistoryService.getApiV1PrognosesHistory(Number(prognosisId)),
  });
};
