import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import { useLocalStorage } from '@equinor/amplify-component-lib';

import { MeasuredDataFilter } from 'src/api';
import { MEASURED_DATA_EDIT_FILTER_PANEL_OPEN_KEY } from 'src/constants';
import { DEFAULT_ADVANCED_FILTER_OPTIONS } from 'src/pages/EditMeasuredData/EditMeasuredData.const';
import { AdvancedFilterDialogOptions } from 'src/pages/EditMeasuredData/types/editMode';

export const SelectionOptions = {
  CLICK: 'CLICK',
  CTRL: 'CTRL',
  SHIFT: 'SHIFT',
} as const;
export type SelectionType = keyof typeof SelectionOptions;
export type FilterSelect = MeasuredDataFilter & {
  selectionType: SelectionType;
};

interface EditMeasuredDataProps {
  filterPanelOpen: boolean;
  toggleFilterPanelOpen: () => void;
  selectedFilters: FilterSelect[];
  setSelectedFilters: Dispatch<SetStateAction<FilterSelect[]>>;
  selectedFilter: MeasuredDataFilter | undefined;
  setSelectedFilter: Dispatch<SetStateAction<MeasuredDataFilter | undefined>>;
  advancedFilterDialogOptions: AdvancedFilterDialogOptions;
  setAdvancedFilterDialogOptions: Dispatch<
    SetStateAction<AdvancedFilterDialogOptions>
  >;
}

export const EditMeasuredDataContext = createContext<
  EditMeasuredDataProps | undefined
>(undefined);

export const EditMeasuredDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [filterPanelOpen, setFilterPanelOpen] = useLocalStorage(
    MEASURED_DATA_EDIT_FILTER_PANEL_OPEN_KEY,
    false
  );
  const [selectedFilter, setSelectedFilter] = useState<MeasuredDataFilter>();
  const [selectedFilters, setSelectedFilters] = useState<FilterSelect[]>([]);
  const [advancedFilterDialogOptions, setAdvancedFilterDialogOptions] =
    useState<AdvancedFilterDialogOptions>(DEFAULT_ADVANCED_FILTER_OPTIONS);

  const toggleFilterPanelOpen = useCallback(() => {
    setFilterPanelOpen(!filterPanelOpen);
    setSelectedFilter(undefined);
  }, [setFilterPanelOpen, filterPanelOpen]);

  return (
    <EditMeasuredDataContext.Provider
      value={{
        filterPanelOpen,
        toggleFilterPanelOpen,
        selectedFilters,
        setSelectedFilters,
        selectedFilter,
        setSelectedFilter,
        advancedFilterDialogOptions,
        setAdvancedFilterDialogOptions,
      }}
    >
      {children}
    </EditMeasuredDataContext.Provider>
  );
};
