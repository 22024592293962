export const SAVING_CHIP_WIDTH = 61;
export const CHIP_HEIGHT = 24;

export const ANIMATED_SQUARE_SIZE = 70;
export const ANIMATED_SQUARE_CENTER = ANIMATED_SQUARE_SIZE / 2;

export const CHIP_CENTER_FROM_TOP = CHIP_HEIGHT / 2;
export const CHIP_CENTER_FROM_LEFT = SAVING_CHIP_WIDTH / 2;

export const TOP_ALIGNMENT = CHIP_CENTER_FROM_TOP - ANIMATED_SQUARE_CENTER;
export const LEFT_ALIGNMENT = CHIP_CENTER_FROM_LEFT - ANIMATED_SQUARE_CENTER;
