import { ReactNode } from 'react';

import { Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const StyledColumn = styled.div`
  border-right: 1px solid #dcdcdc;
  overflow-y: auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1rem;
`;

export const Column = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <StyledColumn>
      <TitleWrapper>
        <Typography
          group="navigation"
          variant="menu_title"
          style={{ margin: 0 }}
          color="default"
        >
          {title}
        </Typography>
      </TitleWrapper>
      {children}
    </StyledColumn>
  );
};
