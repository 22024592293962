import { PrognosisDetailDto } from 'src/api';

/**
 * Finds the previous detail value based on the provided key and index.
 *
 * @param prognosisDetailKey - The key of the detail to search for.
 * @param detailId - The id of the detail to search for.
 * @param data. The array of PrognosisDetailDto objects to search in.
 * @returns The previous detail value as a string, or undefined if not found.
 */
export const findPreviousDetail = (
  prognosisDetailKey: keyof PrognosisDetailDto,
  detailId: number,
  data: PrognosisDetailDto[]
): string | undefined => {
  for (const prognosisDetail of data) {
    if (prognosisDetail.id === detailId) {
      return prognosisDetail[prognosisDetailKey]?.toString();
    }
  }
};
