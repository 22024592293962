import { useCallback, useMemo } from 'react';

import {
  FlyToInterpolator,
  MapViewState,
  TRANSITION_EVENTS,
} from '@deck.gl/core';

import { MapTool } from 'src/pages/MapView/components/MapSettings/MapTools';
import { useMapOptions } from 'src/pages/MapView/hooks';
import {
  INITIAL_VIEW_STATE,
  LATITUDE_RANGE,
  LONGITUDE_RANGE,
} from 'src/pages/MapView/MapView.constants';

import { debounce } from 'lodash';

export function useViewStateHandler() {
  const {
    viewState,
    setViewState,
    infoFooterViewState,
    setInfoFooterViewState,
    mapTool,
    isDraggingMeasureAreaPoint,
  } = useMapOptions();

  const isOutsideBounds = useMemo(() => {
    const { longitude, latitude } = viewState;
    const [latMin, latMax] = LATITUDE_RANGE;
    const [lngMin, lngMax] = LONGITUDE_RANGE;

    return (
      longitude <= lngMin ||
      longitude >= lngMax ||
      latitude <= latMin ||
      latitude >= latMax
    );
  }, [viewState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetInfoFooterViewState = useCallback(
    debounce(setInfoFooterViewState, 300),
    [setInfoFooterViewState]
  );

  const handleOnViewStateChanged = useCallback(
    ({ viewState }: { viewState: MapViewState }) => {
      const changedViewState = viewState;

      const noPanning =
        mapTool === MapTool.SELECT_AREA || isDraggingMeasureAreaPoint.current;
      setViewState((prev) => {
        return {
          longitude: noPanning ? prev.longitude : changedViewState.longitude,
          latitude: noPanning ? prev.latitude : changedViewState.latitude,
          zoom: changedViewState.zoom,
          maxZoom: INITIAL_VIEW_STATE.maxZoom,
          minZoom: INITIAL_VIEW_STATE.minZoom,
        };
      });
      debouncedSetInfoFooterViewState(changedViewState);
    },
    [
      debouncedSetInfoFooterViewState,
      isDraggingMeasureAreaPoint,
      mapTool,
      setViewState,
    ]
  );

  const handleReCenter = () => {
    setViewState({
      longitude: INITIAL_VIEW_STATE.longitude,
      latitude: INITIAL_VIEW_STATE.latitude,
      zoom: INITIAL_VIEW_STATE.zoom,
      maxZoom: INITIAL_VIEW_STATE.maxZoom,
      transitionDuration: 'auto',
      transitionInterruption: TRANSITION_EVENTS.IGNORE,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  return {
    infoFooterViewState,
    handleReCenter,
    isOutsideBounds,
    viewState,
    setViewState,
    handleOnViewStateChanged,
  };
}
