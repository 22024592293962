import { useMemo } from 'react';

import { InfoElement } from '@equinor/amplify-component-lib';

import { PressureDataSimple } from 'src/api';
import {
  CommonContextMenuProps,
  ContextMenu,
} from 'src/pages/MapView/components/MapElements/components/ContextMenu';
import { Header } from 'src/pages/MapView/components/MapElements/components/Header';
import { WellborePurpose } from 'src/types';
import { roundTo } from 'src/utils';

import styled from 'styled-components';

const InfoElementsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

interface AreaContextMenuProps extends CommonContextMenuProps {
  block: string | undefined | null;
  type: WellborePurpose;
  measurements: PressureDataSimple[];
  average: string;
}

export const ContextMenuArea = ({
  block,
  type,
  measurements,
  onClose,
  style,
  average,
  open,
}: AreaContextMenuProps) => {
  const min = useMemo((): string => {
    let min = Infinity;
    for (const measurement of measurements) {
      if (measurement.pressureFinal && measurement.pressureFinal < min) {
        min = measurement.pressureFinal;
      }
    }
    return `${roundTo(min, 2)}`;
  }, [measurements]);

  const max = useMemo((): string => {
    let max = 0;
    for (const measurement of measurements) {
      if (measurement.pressureFinal && measurement.pressureFinal > max) {
        max = measurement.pressureFinal;
      }
    }
    return `${roundTo(max, 2)}`;
  }, [measurements]);

  return (
    <ContextMenu open={open} onClose={onClose} style={style}>
      <Header
        label={block ?? ''}
        title={
          type === WellborePurpose.PRODUCTION
            ? 'Average of Producers'
            : 'Average of Injectors'
        }
      />
      <InfoElementsWrapper>
        <InfoElement title="min. value" content={min} />
        <InfoElement title="avg. value" content={average.toString()} />
        <InfoElement title="max. value" content={max} />
      </InfoElementsWrapper>
    </ContextMenu>
  );
};
