import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ItemType, SideBar, useAuth } from '@equinor/amplify-component-lib';
import { pressure, timeline, view_list } from '@equinor/eds-icons';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import {
  FIELDS_WITH_MEASURED_DATA,
  MEASURED_DATA_FEATURE_KEY,
} from 'src/constants';

import { useApp } from 'hooks/useApp';

export const ApplicationSideBar: FC = () => {
  const { field } = useApp();
  const { account } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { showContent } = useFeatureToggling({
    username: account?.username ?? '',
    featureKey: MEASURED_DATA_FEATURE_KEY,
    showIfKeyIsMissing: false,
  });

  const menuItems: ItemType[] = useMemo(() => {
    // TODO: inpress: look into how url naming should be, if it should differentiate between measured data and progonosis data etc
    const items = [
      {
        name: 'Prognosis',
        icon: view_list,
        onClick: () => {
          navigate('/dashboard');
        },
        link: '/dashboard',
      },
    ];
    // TODO: remove the 'FIELDS_WITH_MEASURED_DATA' check when all fields have measured data
    if (showContent && FIELDS_WITH_MEASURED_DATA.includes(field?.name ?? '')) {
      items.unshift(
        {
          name: 'Map',
          icon: pressure,
          onClick: () => {
            navigate('/measured-data/map');
          },
          link: '/measured-data/map',
        },
        {
          name: 'Plot',
          icon: timeline,
          onClick: () => {
            navigate('/measured-data/plot');
          },
          link: '/measured-data/plot',
        }
      );
    }
    return items;
  }, [field?.name, navigate, showContent]);

  return (
    <SideBar>
      {menuItems.map((m) => (
        <SideBar.Item key={m.name} {...m} currentUrl={location.pathname} />
      ))}
    </SideBar>
  );
};
