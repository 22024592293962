import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { useDeletePrognosis } from '../../pages/EditPrognosis/hooks/useDeletePrognosis';

interface DeleteDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  open,
  title,
  onClose,
}) => {
  const { mutateAsync: deletePrognosis, isPending: isDeleting } =
    useDeletePrognosis();

  const handleDeletePrognosis = async () => {
    if (isDeleting) return;

    await deletePrognosis();
  };

  if (!open) return null;

  return (
    <Dialog
      title={`Confirm delete prognosis - ${title}`}
      open={open}
      onClose={onClose}
      width={500}
      withContentPadding
      actions={[
        {
          position: 'right',
          onClick: onClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleDeletePrognosis,
          text: 'Delete',
          color: 'danger',
          disabled: isDeleting,
          isLoading: isDeleting,
        },
      ]}
    >
      <Typography variant="body_short">
        This action cannot be undone.
      </Typography>
    </Dialog>
  );
};
