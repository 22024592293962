import { FC, useMemo } from 'react';

import { PlotSkeleton } from '../Skeletons/PlotSkeleton';
import {
  AxisItem,
  BottomAxis,
  Container,
  LeftAxis,
  LinePlotSkeletonOuter,
  VerticalBrushSkeleton,
} from './LineChartSkeleton.styles';
import { LinePlotMargin } from 'src/components/Charts/LineChart/LineChart.types';

export interface DummyData {
  x: number;
  y: number;
}

const getDummyNumber = (prev: number) => {
  const changeNumber = Math.floor(Math.random() * 5) - 2;
  if (changeNumber >= -1 && 1 >= changeNumber) return prev;
  if (prev === 2) {
    return prev + 1;
  }
  if (prev === 8) {
    return prev - 1;
  }
  return prev + Math.sign(changeNumber);
};

interface LineChartSkeletonProps {
  height: number;
  width: number;
  margin: LinePlotMargin;
}

export const LineChartSkeleton: FC<LineChartSkeletonProps> = ({
  margin,
  height,
  width,
}) => {
  const dummyData = useMemo(() => {
    const data: DummyData[] = [];
    let prev = 5;
    for (let i = 0; i < 30; i++) {
      prev = getDummyNumber(prev);
      data.push({ x: i, y: prev });
    }
    return data;
  }, []);

  const bottomAxisLabelAmount = Array.from(Array(12).keys());
  const leftAxisLabelAmount = Array.from(Array(10).keys());

  return (
    <>
      <Container>
        <LinePlotSkeletonOuter $height={height}>
          <VerticalBrushSkeleton $margin={margin} />
          <PlotSkeleton
            width={width}
            height={height}
            margin={margin}
            data={dummyData}
          />
          <BottomAxis $margin={margin} $itemWidth={48}>
            {bottomAxisLabelAmount.map((test) => (
              <AxisItem key={test} />
            ))}
          </BottomAxis>
          <LeftAxis $margin={margin} $itemWidth={24}>
            {leftAxisLabelAmount.map((test) => (
              <AxisItem key={test} />
            ))}
          </LeftAxis>
        </LinePlotSkeletonOuter>
      </Container>
    </>
  );
};
