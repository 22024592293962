/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FilterCondition {
  LESSTHAN = 'LESSTHAN',
  LESSTHANOREQUAL = 'LESSTHANOREQUAL',
  EQUAL = 'EQUAL',
  GREATERTHANOREQUAL = 'GREATERTHANOREQUAL',
  GREATERTHAN = 'GREATERTHAN',
  NOTEQUAL = 'NOTEQUAL',
}
