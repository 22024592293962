import { FC, useState } from 'react';

import {
  Button,
  colors,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { Icon } from '@equinor/eds-core-react';
import { chevron_down, chevron_up } from '@equinor/eds-icons';

import { motion } from 'framer-motion';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  align-self: stretch;
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-radius: ${spacings.x_small};
  padding: ${spacings.medium};
  background-color: ${colors.ui.background__default.rgba};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface BorderedSectionProps {
  title: string;
  children: React.ReactNode;
}

export const BorderedSection: FC<BorderedSectionProps> = ({
  title,
  children,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const handleHideSection = () => {
    setIsHidden(!isHidden);
  };

  const variants = {
    visible: {
      opacity: 1,
      transition: { duration: 0.2 },
      height: 'auto',
    },
    hidden: { opacity: 0, height: 0 },
  };

  return (
    <Container>
      <Header>
        <Typography variant="h4">{title}</Typography>
        <Button variant="ghost" onClick={handleHideSection}>
          {isHidden ? 'Show' : 'Hide'} section
          <Icon data={isHidden ? chevron_down : chevron_up} />
        </Button>
      </Header>
      <motion.div
        animate={isHidden ? 'hidden' : 'visible'}
        transition={{ duration: 0.2 }}
        variants={variants}
      >
        <div style={{ pointerEvents: isHidden ? 'none' : undefined }}>
          {children}
        </div>
      </motion.div>
    </Container>
  );
};
