import {
  colors,
  shape,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const SVG = styled.svg`
  text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const TooltipDate = styled(Typography)`
  grid-column: 1 / 4;
  color: ${colors.text.static_icons__primary_white.rgba};
`;

export const TooltipName = styled(Typography)`
  grid-column: 2 / 3;
  justify-self: start;
  color: ${colors.text.static_icons__primary_white.rgba};
`;

export const TooltipValue = styled(Typography)`
  grid-column: 3 / 4;
  justify-self: end;
  font-weight: 700;
  color: ${colors.text.static_icons__primary_white.rgba};
`;

export const TooltipColorWrapper = styled.div`
  background-color: white;
  height: ${spacings.medium_small};
  padding: ${spacings.xx_small};
  display: flex;
  border-radius: ${shape.rounded.borderRadius};
`;

interface TooltipColorProps {
  $color: string;
}

export const TooltipColor = styled.div<TooltipColorProps>`
  align-self: center;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  width: ${spacings.small};
  height: ${spacings.small};
`;

export const AxisLabel = styled.text`
  fill: ${colors.text.static_icons__secondary.rgba};
  font-family: 'Equinor', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-anchor: middle;
  dominant-baseline: central;
  transform-origin: 0 0;
`;
