import { useQuery } from '@tanstack/react-query';

import { ConfigService } from 'src/api';
import { MEASURED_DATA_ACCESS_CHECK_KEY } from 'src/constants';
import { useApp } from 'src/hooks';

export const useMeasuredDataAccessCheck = () => {
  const { field } = useApp();

  return useQuery({
    queryKey: [MEASURED_DATA_ACCESS_CHECK_KEY, field?.uuid],
    queryFn: () => ConfigService.accessCheck(field?.uuid ?? ''),
  });
};
