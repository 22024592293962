import { FC } from 'react';

import { DeckGL } from '@deck.gl/react';
import { Button, colors, spacings } from '@equinor/amplify-component-lib';

import { InfoFooter } from '../InfoFooter/InfoFooter';
import { useDeckGlMapProps } from 'src/pages/MapView/components/Map/hooks';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.ui.background__medium.rgba};
  overflow: hidden;
`;

const ReCenterButton = styled(Button)`
  position: absolute;
  left: calc(50% - 36px);
  transform: translateX(-50%);
  top: ${spacings.large};
`;

export const MapInner: FC = () => {
  const {
    deckglProps,
    isOutsideBounds,
    infoFooterViewState,
    handleReCenter,
    hoverPoint,
  } = useDeckGlMapProps();

  return (
    <Wrapper>
      <DeckGL {...deckglProps} />
      <InfoFooter hoverPoint={hoverPoint} viewport={infoFooterViewState} />
      {isOutsideBounds && (
        <ReCenterButton
          onClick={handleReCenter}
          variant="outlined"
          color="secondary"
        >
          Re-center map
        </ReCenterButton>
      )}
    </Wrapper>
  );
};
