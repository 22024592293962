import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { Brush } from '@visx/brush';
import BaseBrush from '@visx/brush/lib/BaseBrush';
import { Bounds } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { ScaleLinear } from '@visx/vendor/d3-scale';

import { BRUSH_STYLE } from 'components/Charts/LineChart/LineChart.constants';

import {
  LineChartData,
  LineChartLine,
  VerticalBrushData,
} from '../../LineChart.types';
import {
  getHeatMapCount,
  getHeatMapValue,
  getLineChartLimitsForVertBrush,
} from '../../LineChart.utils';
import { VerticalBrushHandle } from './VerticalBrushHandle';

export const MARGIN = {
  top: 20,
  right: 24,
  bottom: 32,
  left: 100,
};

interface VerticalBrushProps {
  wellbores: LineChartLine[];
  firstWellboreData: LineChartData[];
  yMax: number;
  xMax: number;
  valueScale: ScaleLinear<number, number, never>;
  valueScaleDomain: number[];
  setValueScaleDomain: Dispatch<SetStateAction<number[]>>;
  helpLines?: LineChartLine[];
}

export const VerticalBrush: FC<VerticalBrushProps> = ({
  wellbores,
  yMax,
  valueScaleDomain,
  setValueScaleDomain,
  helpLines,
}) => {
  const verticalBrushRef = useRef<BaseBrush | null>(null);

  const { minValuePaddedFloor, maxValuePaddedCeil, flatValues } = useMemo(
    () => getLineChartLimitsForVertBrush(wellbores, helpLines),
    [helpLines, wellbores]
  );

  const getCount = useCallback(
    (value: number, space: number) => {
      return flatValues.filter((d) => value < d && d < value + space).length;
    },
    [flatValues]
  );

  const heatMapData = useMemo(() => {
    const difference = maxValuePaddedCeil - minValuePaddedFloor;
    const amountOfBuckets = 30;
    const space = difference / amountOfBuckets;
    const buckets: VerticalBrushData[] = [
      {
        value: minValuePaddedFloor,
        count: getCount(minValuePaddedFloor, space),
      },
    ];
    while (buckets.length <= amountOfBuckets) {
      const newValue = {
        value: buckets[buckets.length - 1].value + space,
        count: getCount(buckets[buckets.length - 1].value, space),
      };
      if (newValue.value > maxValuePaddedCeil) {
        buckets.push({
          value: maxValuePaddedCeil,
          count: getCount(maxValuePaddedCeil, space),
        });
      } else {
        buckets.push(newValue);
      }
    }

    return buckets;
  }, [getCount, maxValuePaddedCeil, minValuePaddedFloor]);

  const bucketScale = useMemo(
    () =>
      scaleBand({
        range: [yMax, 0],
        domain: heatMapData.map(getHeatMapValue),
      }),
    [heatMapData, yMax]
  );

  const brushValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [
          Math.min(...heatMapData.map(getHeatMapValue)),
          Math.max(...heatMapData.map(getHeatMapValue)),
        ],
      }),

    [heatMapData, yMax]
  );

  const countScale = useMemo(
    () =>
      scaleLinear({
        range: [0, 20],
        domain: [0, 1],
      }),
    []
  );

  const opacityScale = useMemo(
    () =>
      scaleLinear({
        range: [0.1, 1],
        domain: [
          Math.min(...heatMapData.map(getHeatMapCount)),
          Math.max(...heatMapData.map(getHeatMapCount)),
        ],
      }),
    [heatMapData]
  );

  const initialBrushPosition = useMemo(() => {
    if (!valueScaleDomain || valueScaleDomain.length === 0) return undefined;

    const [start, end] = valueScaleDomain;
    return {
      start: {
        y: brushValueScale(start),
      },
      end: {
        y: brushValueScale(end),
      },
    };
  }, [brushValueScale, valueScaleDomain]);

  useEffect(() => {
    setValueScaleDomain([minValuePaddedFloor, maxValuePaddedCeil]);
  }, [maxValuePaddedCeil, minValuePaddedFloor, setValueScaleDomain]);

  const onBrushChange = useCallback(
    (domain: Bounds | null) => {
      if (!domain?.y1 || !domain?.y0) return;
      setValueScaleDomain([domain.y0, domain.y1]);
    },
    [setValueScaleDomain]
  );

  const handleOnClickBrushWrapper = () => {
    setValueScaleDomain([minValuePaddedFloor, maxValuePaddedCeil]);
  };

  return (
    <Group top={MARGIN.top} left={3}>
      {!heatMapData.every((data) => data.count === 0) &&
        heatMapData.map((d) => {
          const name = d;
          const barWidth = bucketScale.bandwidth();
          const barY = bucketScale(getHeatMapValue(d));
          const barX = 0;
          const opacity = opacityScale(getHeatMapCount(d));
          if (isNaN(name.value)) return null;
          return (
            <Bar
              key={`bar-${name.value}-${name.count}`}
              x={barX + 1}
              y={barY}
              width={20}
              height={barWidth}
              fill={colors.ui.background__scrim.rgba}
              fillOpacity={opacity}
            />
          );
        })}
      {initialBrushPosition !== undefined && (
        <Brush
          yScale={brushValueScale}
          xScale={countScale}
          width={22}
          margin={MARGIN}
          height={yMax}
          handleSize={8}
          innerRef={verticalBrushRef}
          resizeTriggerAreas={['top', 'bottom']}
          initialBrushPosition={initialBrushPosition}
          brushDirection="vertical"
          onChange={onBrushChange}
          onClick={handleOnClickBrushWrapper}
          selectedBoxStyle={BRUSH_STYLE}
          useWindowMoveEvents
          renderBrushHandle={(props) => <VerticalBrushHandle {...props} />}
        />
      )}
    </Group>
  );
};
