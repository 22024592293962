import { useRef, useState } from 'react';

import { Button, Icon } from '@equinor/amplify-component-lib';
import { chevron_up, tune } from '@equinor/eds-icons';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { AdvancedFilterMenu } from './AdvancedFilterMenu';
import { MeasuredDataFilter } from 'src/api';
import { SHOW_ADVANCED_FILTERS } from 'src/constants';
import { AdvancedFilter } from 'src/pages/EditMeasuredData/components/AdvancedFilter/AdvancedFilter';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useGetPressureDataWellboresFlat } from 'src/pages/EditMeasuredData/hooks/useGetPressureDataWellboresFlat';

export const AdvancedFilterButton = () => {
  const [advancedFilterMenuOpen, setAdvancedFilterMenuOpen] = useState(false);
  const { advancedFilterDialogOptions, setAdvancedFilterDialogOptions } =
    useEditMeasuredData();
  const { isLoading: pressureDataLoading } = useGetPressureDataWellboresFlat();
  const { showContent } = useFeatureToggling({
    featureKey: SHOW_ADVANCED_FILTERS,
  });

  const AdvancedFilterMenuRef = useRef<HTMLButtonElement>(null);

  const handleMenuSelect = (filters: MeasuredDataFilter[] | undefined) => {
    setAdvancedFilterMenuOpen(false);
    setAdvancedFilterDialogOptions((prev) => {
      return { ...prev, open: true, initialFilters: filters ?? [] };
    });
  };

  const handleButtonOnClick = () => {
    setAdvancedFilterMenuOpen((prev) => !prev);
  };

  const handleMenuOnClose = () => {
    setAdvancedFilterMenuOpen(false);
  };

  if (!showContent) return null;

  return (
    <>
      <Button
        ref={AdvancedFilterMenuRef}
        variant="outlined"
        onClick={handleButtonOnClick}
        disabled={pressureDataLoading}
      >
        <Icon data={tune} />
        Advanced filters
        <Icon data={chevron_up} />
      </Button>
      <AdvancedFilterMenu
        anchorEl={AdvancedFilterMenuRef.current}
        open={advancedFilterMenuOpen}
        onClose={handleMenuOnClose}
        onClick={handleMenuSelect}
      />

      {advancedFilterDialogOptions.open && <AdvancedFilter />}
    </>
  );
};
