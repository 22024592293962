import { useQuery } from '@tanstack/react-query';

import { useMeasuredData } from './useMeasuredData';
import { HelpLineService } from 'src/api';
import { MEASURED_DATA_HELPLINES_KEY } from 'src/constants';
import { useApp } from 'src/hooks/useApp';
import { HelpLineType } from 'src/types';

export const useGetMeasuredDataHelpLines = (
  selectedHelpLines: HelpLineType[],
  helplinesDialogOpen: boolean
) => {
  const { field } = useApp();
  const { from, to } = useMeasuredData();
  return useQuery({
    queryKey: [
      MEASURED_DATA_HELPLINES_KEY,
      field?.uuid,
      from,
      to,
      selectedHelpLines,
    ],
    queryFn: () =>
      HelpLineService.getHelpLines(
        field?.uuid ?? '',
        from,
        to,
        selectedHelpLines
      ),
    enabled: !helplinesDialogOpen && selectedHelpLines.length > 0,
    gcTime: 1000 * 60 * 60 * 12, // 12 hours
    staleTime: 1000 * 60 * 60 * 12,
  });
};
