import { useMutation } from '@tanstack/react-query';

import { MeasuredDataFilterService } from 'src/api';
import { BULK_DELETE_MEASURED_DATA_FILTER } from 'src/constants';
import { useUpdateQueryData } from 'src/pages/EditMeasuredData/hooks/useUpdateQueryData';

export const useBulkDeleteFilter = () => {
  const { deleteFilterQueryData, handleOnSuccess, handleOnError } =
    useUpdateQueryData();
  return useMutation({
    mutationKey: [BULK_DELETE_MEASURED_DATA_FILTER],
    mutationFn: (filterId: string[]) =>
      MeasuredDataFilterService.bulkDeletefilterbyId(filterId),
    onSuccess: (data) => {
      deleteFilterQueryData(data);
      handleOnSuccess();
    },
    onError: handleOnError,
  });
};
