/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrognosisHistoryEntryDto } from '../models/PrognosisHistoryEntryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PrognosisHistoryService {
  /**
   * Get prognosis history
   * @param prognosisId
   * @returns PrognosisHistoryEntryDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesHistory(
    prognosisId: number
  ): CancelablePromise<Array<PrognosisHistoryEntryDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/{prognosisId}/History',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
