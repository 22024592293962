import { FC, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';

import {
  SelectOptionRequired,
  SingleSelect,
} from '@equinor/amplify-component-lib';

import { ConfirmDialog } from '../../../ConfimDialog/ConfirmDialog';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { usePutDraftPrognosis } from 'src/pages/EditPrognosis/hooks/usePutDraftPrognosis';
import { EditPrognosisFormValues } from 'src/utils';

import { useGetStratColumns } from 'hooks/useGetStratigraphicValues';
import styled from 'styled-components';

interface NextValueProps {
  key: keyof EditPrognosisFormValues;
  field: ControllerRenderProps<EditPrognosisFormValues>;
  item: SelectOptionRequired | undefined;
}

const ComboboxContainer = styled.div`
  width: 100%;
  max-width: 320px;
  align-self: flex-start;
`;

export const SelectColumn: FC = () => {
  const {
    control,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext<EditPrognosisFormValues>();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();
  const { stratColumnIdentifier } = watch();
  const [nextValue, setNextValue] = useState<NextValueProps>();
  const [open, setOpen] = useState(false);
  const { prognosisDetails } = watch();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();

  const { data: stratColumn, isLoading } = useGetStratColumns();

  const handleUpdateField = async ({ key, field, item }: NextValueProps) => {
    if (key == 'stratColumnIdentifier' && prognosisDetails?.length !== 0) {
      setOpen(true);
      setNextValue({ key, field, item });
      return;
    }

    field.onChange(item?.value);
    await trigger(key);
    const isValid = !errors[key] || Object.keys(errors[key]).length === 0;

    if (!isValid) return;
    updateDraftPrognosis();
  };

  const handleConfirm = async () => {
    setOpen(false);

    if (!nextValue) return;

    prognosisDetails.map((detail) => {
      deletePrognosisDetail(detail.id);
    });

    const { key, field, item } = nextValue;
    field.onChange(item?.value);
    await trigger(key);
    const isValid = !errors[key] || Object.keys(errors[key]).length === 0;
    if (!isValid) return;
    updateDraftPrognosis();
  };

  return (
    <ComboboxContainer>
      <Controller
        name={'stratColumnIdentifier'}
        control={control}
        rules={{ required: 'Wellbore is required' }}
        render={({ field }) => {
          return (
            <SingleSelect
              items={
                stratColumn?.map(({ identifier }) => ({
                  value: identifier ?? '',
                  label: identifier ?? '',
                })) ?? []
              }
              placeholder="Select one..."
              value={{
                label: field.value ?? stratColumnIdentifier,
                value: field.value ?? stratColumnIdentifier,
              }}
              loading={isLoading}
              onSelect={(item) => {
                handleUpdateField({
                  key: 'stratColumnIdentifier',
                  field,
                  item,
                });
              }}
              variant={errors.wellbore ? 'error' : undefined}
              clearable={false}
            />
          );
        }}
      />
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleConfirm()}
      />
    </ComboboxContainer>
  );
};
