import { spacings } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${spacings.small};
  margin-bottom: ${spacings.medium};
`;

export const SingleSelectContainer = styled.div`
  flex: 1;
`;

export const ComboSelectContainer = styled.div`
  flex: 2;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: ${spacings.small};
`;
