import { colors } from '@equinor/amplify-component-lib';

import { StatusIdEnum } from 'src/types';

interface Variants {
  variant: 'updated' | 'valid' | 'invalid' | 'placeholder';
}

export const getCellColor = (variant?: Variants['variant']) => {
  switch (variant) {
    case 'updated':
      return {
        textColor: colors.dataviz.lightblue.lighter,
        backgroundColor: colors.dataviz.darkblue.default,
      };
    case 'valid':
      return {
        textColor: colors.dataviz.darkblue.darker,
        backgroundColor: colors.dataviz.lightblue.default,
      };
    case 'invalid':
      return {
        textColor: colors.interactive.danger__text.rgba,
        backgroundColor: colors.interactive.danger__highlight.rgba,
      };
    case 'placeholder':
      return {
        textColor: colors.interactive.disabled__text.rgba,
        backgroundColor: undefined,
      };
    default:
      return {
        textColor: colors.text.static_icons__default.rgba,
        backgroundColor: undefined,
      };
  }
};

export const getStatusColor = (
  statusId: StatusIdEnum | undefined,
  isValid: boolean | undefined
): string => {
  if (statusId === StatusIdEnum.OUTDATED) {
    return colors.dataviz.lightyellow.darker;
  }
  if (statusId === StatusIdEnum.REVISION_REQUIRED) {
    return colors.dataviz.lightpink.darker;
  }
  if (statusId === StatusIdEnum.APPROVED && isValid) {
    return colors.dataviz.lightgreen.darker;
  }
  return colors.text.static_icons__default.rgba;
};
