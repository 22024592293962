import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export interface OptionalAttributes {
  segment: boolean;
  datum: boolean;
  totalDepthRow: boolean;
}

interface OptionalAttributeType {
  optionalAttributes: OptionalAttributes;
  setOptionalAttributes: Dispatch<SetStateAction<OptionalAttributes>>;
}

export const OptionalAttributeContext = createContext<
  OptionalAttributeType | undefined
>(undefined);

interface OptionalAttributeProviderProps {
  children: ReactNode;
}

export const OptionalAttributeProvider: FC<OptionalAttributeProviderProps> = ({
  children,
}) => {
  const [optionalAttributes, setOptionalAttributes] =
    useState<OptionalAttributes>({
      segment: true,
      datum: false,
      totalDepthRow: false,
    });

  return (
    <OptionalAttributeContext.Provider
      value={{
        optionalAttributes,
        setOptionalAttributes,
      }}
    >
      {children}
    </OptionalAttributeContext.Provider>
  );
};
