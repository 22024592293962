import { FC, useRef, useState } from 'react';

import { colors, Icon, Menu } from '@equinor/amplify-component-lib';
import {
  arrow_drop_down,
  arrow_drop_up,
  calendar_date_range,
} from '@equinor/eds-icons';

import { DateRangeMenu } from 'components/DateRangeMenu/DateRangeMenu';

import { StyledButton } from 'src/pages/MapView/components/Footer/Footer.styles';

export const DateRangeSelection: FC = () => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  return (
    <>
      <StyledButton
        variant="ghost"
        ref={anchorRef}
        onClick={() => setOpen((o) => !o)}
      >
        <Icon
          data={calendar_date_range}
          color={colors.text.static_icons__default.rgba}
        />
        <Icon
          data={open ? arrow_drop_down : arrow_drop_up}
          color={colors.text.static_icons__default.rgba}
        />
      </StyledButton>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom-start"
      >
        <Menu.Section index={0} title="Timespan">
          <DateRangeMenu />
        </Menu.Section>
      </Menu>
    </>
  );
};
