import { FC } from 'react';

import {
  colors,
  convertLatLngToUtm,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { useMapOptions } from 'src/pages/MapView/hooks';
import { roundTo } from 'src/utils';

import { round } from 'lodash';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${spacings.xx_small} ${spacings.small};
  background: ${colors.ui.background__light.rgba};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${spacings.medium_small};
`;

const Container = styled.div`
  display: flex;
  gap: ${spacings.small};
`;

export const HoverCoordinates: FC = () => {
  const { hoverPoint } = useMapOptions();

  const [longitude, latitude] = hoverPoint ?? [0, 0];
  const utmCoordinates = convertLatLngToUtm({
    lng: hoverPoint?.[0] ?? 0,
    lat: hoverPoint?.[1] ?? 0,
  });
  return (
    <Wrapper>
      <Container>
        <Typography variant="meta">
          Lat: {roundTo(latitude, 7) ?? ''}
        </Typography>
        <Typography variant="meta">
          Lng: {roundTo(longitude, 7) ?? ''}
        </Typography>
      </Container>
      <Container>
        <Typography variant="meta">
          X: {Math.round(utmCoordinates[0])}m
        </Typography>
        <Typography variant="meta">
          Y: {Math.round(utmCoordinates[1])}m
        </Typography>
        {hoverPoint && !isNaN(hoverPoint[2]) && (
          <Typography variant="meta">
            Z: {round(hoverPoint[2], 2)} bar
          </Typography>
        )}
      </Container>
    </Wrapper>
  );
};
