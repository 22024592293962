import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { useInitializeEditProgram } from '../hooks/useInitializeEditProgram';
import { useOptionalAttributes } from '../hooks/useOptionalAttributes';

export interface EditPrognosisContextType {
  isInitializing: boolean;
  imageFiles: File[];
  selectedTableRows: number[] | null;
  setSelectedTableRows: Dispatch<SetStateAction<number[]>>;
  addSelectedTableRow: (id: number, isEditing: boolean) => void;
  removeSelectedTableRow: (id: number) => void;
  addImageFile: (file: File) => void;
  hasUpdatedCell: boolean;
  setHasUpdatedCell: Dispatch<SetStateAction<boolean>>;
}

export const EditPrognosisContext = createContext<
  EditPrognosisContextType | undefined
>(undefined);

interface EditPrognosisProviderProps {
  children: ReactNode;
}

export const EditPrognosisProvider: FC<EditPrognosisProviderProps> = ({
  children,
}) => {
  const { setOptionalAttributes } = useOptionalAttributes();
  const { isInitializing } = useInitializeEditProgram({
    setOptionalAttributes,
  });

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [selectedTableRows, setSelectedTableRows] = useState<number[]>([]);
  const [hasUpdatedCell, setHasUpdatedCell] = useState(false);

  const handleAddSelectedTableRow = (id: number, isEditing: boolean) => {
    if (!isEditing) {
      setSelectedTableRows([id]);
      return;
    }
    setSelectedTableRows((prev) => [...prev, id]);
  };

  const handleRemoveSelectedTableRow = (id: number) => {
    setSelectedTableRows((prev) => prev.filter((row) => row !== id));
  };

  const handleAddImageFile = (file: File) => {
    const newFiles = [...imageFiles, file];
    setImageFiles(newFiles);
  };

  return (
    <EditPrognosisContext.Provider
      value={{
        isInitializing,
        imageFiles,
        selectedTableRows,
        setSelectedTableRows,
        addSelectedTableRow: handleAddSelectedTableRow,
        removeSelectedTableRow: handleRemoveSelectedTableRow,
        addImageFile: handleAddImageFile,
        hasUpdatedCell,
        setHasUpdatedCell,
      }}
    >
      {children}
    </EditPrognosisContext.Provider>
  );
};
