import { FC, useMemo } from 'react';

import { colors } from '@equinor/amplify-component-lib';

import { HighlightShape } from 'src/pages/MapView/components/MeasureArea/MeasureArea';
import { ShapeType } from 'src/utils/measureArea';

import styled from 'styled-components';

const ShapePoint = styled.circle`
  :hover {
    fill: ${colors.interactive.primary__resting.rgba};
  }
`;

const ShapeLine = styled.polyline`
  transition: 500ms;
`;

interface ShapeProps {
  shapePixel: number[][];
  index: number;
  highlightShape?: HighlightShape;
  canComplete?: boolean;
}

export const Shape: FC<ShapeProps> = ({
  shapePixel,
  canComplete,
  index,
  highlightShape,
}) => {
  const highlightThisShape: ShapeType | undefined = useMemo(() => {
    if (index !== highlightShape?.shapeIndex) return;
    return highlightShape?.type === ShapeType.CIRCUMFERENCE
      ? ShapeType.CIRCUMFERENCE
      : ShapeType.AREA;
  }, [highlightShape?.shapeIndex, highlightShape?.type, index]);

  return (
    <>
      <ShapeLine
        points={shapePixel.flatMap((line) => line).join(' ')}
        fill={colors.interactive.primary__hover_alt.rgba}
        fillOpacity={highlightThisShape === ShapeType.AREA ? `50%` : 0}
        stroke={
          highlightThisShape === ShapeType.CIRCUMFERENCE
            ? colors.interactive.primary__resting.rgba
            : colors.interactive.disabled__text.rgba
        }
        strokeWidth="2"
        strokeDasharray="6"
      />
      {[...shapePixel].slice(0, shapePixel.length - 1).map((line, index) => {
        const isCompleting = index === 0 && canComplete;
        return (
          <ShapePoint
            key={`${line[0]}//${line[1]}//${index}`}
            cx={`${line[0]}px`}
            cy={`${line[1]}px`}
            r="5"
            fill={
              isCompleting ? colors.interactive.primary__hover.rgba : 'black'
            }
            strokeWidth="10"
            stroke="transparent"
          />
        );
      })}
    </>
  );
};
