import { FC } from 'react';

import { ColumnHeader } from '../ColumnHeader/ColumnHeader';
import { DetailsCell } from './DetailsCell/DetailsCell';
import { Container } from './DetailsColumn.styles';
import { PrognosisDetailDto, PrognosisDto } from 'src/api';
import { useGetPreviousPrognosisDetails } from 'src/hooks/useGetPreviousPrognosisDetails';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { HeaderDescriptionType } from 'src/types/prognosisDetails';
import { StatusIdEnum } from 'src/types/status';
import { findPreviousDetail } from 'src/utils/previousDetail';

interface DetailsColumnProps {
  columnSpan: number;
  columnStart: number;
  header: string;
  headerDescriptions: HeaderDescriptionType[];
  isEditing: boolean;
  prognosisData?: PrognosisDto;
  prognosisDetailData: PrognosisDetailDto[];
  prognosisDetailKeys: (keyof PrognosisDetailDto)[];
  rowSpan: number;
}

export const DetailsColumn: FC<DetailsColumnProps> = ({
  columnSpan,
  columnStart,
  header,
  headerDescriptions,
  isEditing,
  prognosisData,
  prognosisDetailData,
  prognosisDetailKeys,
  rowSpan,
}) => {
  const { data: previousData } = useGetPreviousPrognosisDetails();

  const { selectedTableRows, setHasUpdatedCell } = useEditPrognosis();

  return (
    <Container
      $columnStart={columnStart}
      $columnSpan={columnSpan}
      $isEditing={isEditing}
      $rowSpan={rowSpan}
    >
      <ColumnHeader
        columnSpan={columnSpan}
        header={header}
        headerDescriptions={headerDescriptions}
        prognosisDetailKeys={prognosisDetailKeys}
      />
      {prognosisDetailData?.flatMap((prognosisDetail, index) =>
        prognosisDetailKeys.map((prognosisDetailKey) => {
          const isSelected = selectedTableRows?.includes(prognosisDetail.id);

          if (isEditing) {
            return (
              <DetailsCell
                key={`prognosis-detail-cell-${prognosisDetail.id}-${prognosisDetailKey}`}
                detailValue={prognosisDetail[prognosisDetailKey]?.toString()}
                isSelected={isSelected}
                detailKey={prognosisDetailKey}
                index={index}
              />
            );
          } else {
            const previous = findPreviousDetail(
              prognosisDetailKey,
              prognosisDetail.id,
              previousData ?? []
            );

            const current = prognosisDetail[prognosisDetailKey]?.toString();

            // if previous is null or undefined, it is not updated
            const isUpdated =
              previous != null &&
              current !== previous &&
              prognosisData?.statusId === StatusIdEnum.SUBMITTED;

            if (isUpdated) {
              setHasUpdatedCell(true);
            }

            return (
              <DetailsCell
                key={`prognosis-detail-cell-${prognosisDetail.id}-${prognosisDetailKey}`}
                isUpdated={isUpdated}
                detailValue={current}
                previous={previous}
                isSelected={isSelected}
                detailKey={prognosisDetailKey}
                index={index}
              />
            );
          }
        })
      )}
    </Container>
  );
};
