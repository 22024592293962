import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import { CircularProgress, Typography } from '@equinor/amplify-component-lib';

import { Section } from '../../EditPrognosis.styles';
import { useEditPrognosis } from '../../hooks/useEditPrognosis';
import { BorderedSection } from './BorderedSection/BorderedSection';
import { OptionalAttributes } from './OptionalAttributes/OptionalAttributes';
import { PrognosisComments } from './PrognosisComments/PrognosisComments';
import { SelectWellbore } from './SelectWellbore/SelectWellbore';
import { ZoneSelector } from './ZoneSelector/ZoneSelector';
import { InitializingContainer, MainContent } from './FormData.styles';
import { PrognosisDetailsTable } from 'src/components/PrognosisDetailsTable/PrognosisDetailsTable';
import Sparky from 'src/resources/premo-new-sparky.svg';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const FormData: FC = () => {
  const { watch } = useFormContext<EditPrognosisFormValues>();
  const { isInitializing } = useEditPrognosis();
  const { prognosisId } = useParams();
  const { operation } = watch();

  if (isInitializing) {
    return (
      <MainContent>
        <InitializingContainer>
          <Typography variant="h2">Loading existing prognosis...</Typography>
          <CircularProgress color="primary" />
        </InitializingContainer>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <SelectWellbore />
      {!prognosisId ? (
        <>
          <img src={Sparky} />
          <Typography group="paragraph" variant="body_long">
            {!operation
              ? 'Select an operation first'
              : 'Select a wellbore to start'}
          </Typography>
        </>
      ) : (
        <>
          <OptionalAttributes />
          <BorderedSection title="Zone selection">
            <ZoneSelector />
          </BorderedSection>
          <Section>
            <PrognosisDetailsTable isEditing={true} />
          </Section>
          <PrognosisComments />
        </>
      )}
    </MainContent>
  );
};
