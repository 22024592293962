import { Column } from '../../components/Column';
import { useHelplines } from '../../ManageHelpLines';
import { Flow } from './Flow';
import { Gradient } from './Gradient';
import { Pressure } from './Pressure';

export const SelectData = () => {
  const { selectedWellbore, source } = useHelplines();

  if (selectedWellbore === undefined || source === undefined) return null;

  const renderSource = () => {
    switch (source) {
      case 'Dhpg1':
        return <Pressure />;
      case 'Dhpg2':
        return <Pressure />;
      case 'GRADIENT':
        return <Gradient />;
      case 'FLOW':
        return <Flow />;
    }
  };

  return <Column title="Pressure helplines">{renderSource()}</Column>;
};
