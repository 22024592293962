import { FC, Fragment, useMemo } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';
import { TooltipWithBounds } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import {
  TooltipColor,
  TooltipColorWrapper,
  TooltipDate,
  TooltipName,
  TooltipValue,
} from '../LineChart.styles';
import { LinePlotMargin, TooltipData } from '../LineChart.types';
import { getDataTypeShortName, tooltipStyles } from '../LineChart.utils';
import { DataType } from 'src/api';
import { roundTo } from 'src/utils';

interface TooltipProps extends Partial<WithTooltipProvidedProps<TooltipData>> {
  MARGIN: LinePlotMargin;
}

export const LineChartToolTip: FC<TooltipProps> = ({
  tooltipTop,
  tooltipLeft,
  tooltipData,
  MARGIN,
}) => {
  const sortedTooltipData = useMemo(
    () =>
      tooltipData?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      ),
    [tooltipData]
  );

  const tooltipDataWithOnlyOnePIValue = useMemo(() => {
    if (
      !sortedTooltipData?.some(
        (data) => data.helplineDataType === DataType.PRODUCTIVITY_INDEX
      )
    )
      return sortedTooltipData;
    const wellNamesWithMeasuredPI = sortedTooltipData
      .filter((data) => data.helplineDataType === DataType.PRODUCTIVITY_INDEX)
      .map((data) => data.name);
    return sortedTooltipData.filter((data) => {
      if (data.helplineDataType === DataType.PRODUCTIVITY_INDEX_INTERPOLATED) {
        return !wellNamesWithMeasuredPI.some(
          (wellboreName) => data.name === wellboreName
        );
      }
      return true;
    });
  }, [sortedTooltipData]);

  if (!tooltipDataWithOnlyOnePIValue) return null;

  return (
    <div>
      <TooltipWithBounds
        top={(tooltipTop ?? 0) + MARGIN.top}
        left={(tooltipLeft ?? 0) + MARGIN.left}
        style={tooltipStyles}
      >
        <TooltipDate group="ui" variant="chip__badge">
          {formatDate(tooltipDataWithOnlyOnePIValue[0].date)}
        </TooltipDate>
        {tooltipDataWithOnlyOnePIValue.map((wellbore) => {
          return (
            <Fragment
              key={
                wellbore.name +
                wellbore.color +
                wellbore.value +
                wellbore.helplineDataType
              }
            >
              <TooltipColorWrapper>
                <TooltipColor $color={wellbore.color} />
              </TooltipColorWrapper>
              <TooltipName group="ui" variant="chip__badge">
                {`${wellbore.name}
                ${
                  wellbore.helplineDataType
                    ? ' - ' + getDataTypeShortName(wellbore.helplineDataType)
                    : ''
                }
                `}
              </TooltipName>
              <TooltipValue group="ui" variant="chip__badge">
                {wellbore.value > 0 ||
                wellbore.helplineDataType === DataType.ON_STREAM_HOURS
                  ? roundTo(wellbore.value, 2)
                  : 'Missing'}
              </TooltipValue>
            </Fragment>
          );
        })}
      </TooltipWithBounds>
    </div>
  );
};
