import { FC } from 'react';
import { ChangeEvent } from 'react';

import { Search as AmplifySearch } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

const StyledAmplifySearch = styled(AmplifySearch)`
  width: 240px;

  input {
    background-color: ${colors.ui.background__default.rgba};
    border-bottom: 1px solid ${colors.text.static_icons__tertiary.rgba};
  }
`;

interface SearchProps {
  value: string | null | undefined;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<SearchProps> = ({ value, handleOnChange }) => {
  return (
    <StyledAmplifySearch
      value={value ?? undefined}
      placeholder="Search for a title"
      onChange={handleOnChange}
    />
  );
};
