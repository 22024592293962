import { spacings, Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const HeaderContent = styled.div`
  display: grid;
  align-items: center;
  gap: ${spacings.small};
  width: 100%;
  grid-template-columns: minmax(0, 1fr) auto auto;
`;

export const MainContent = styled.div`
  display: flex;
  max-width: 1280px;
  width: calc(100% - (${spacings.large} * 2));
  padding: ${spacings.large};
  flex-direction: column;
  justify-self: center;
  gap: ${spacings.large};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
