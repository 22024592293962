import { FC, useMemo } from 'react';

import { Chip, colors } from '@equinor/amplify-component-lib';
import { SelectOption } from '@equinor/amplify-component-lib/dist/molecules/Select/Select.types';

import { usePressureData } from 'src/pages/PlotView/hooks/usePressureData';
import { Measurement } from 'src/types';
import { getLineChartLineColor } from 'src/utils';

import styled from 'styled-components';

interface ComboBoxChipWithColoredDotProps {
  item: SelectOption<Measurement>;
  onDelete: () => void;
  tryingToRemove: boolean;
}

interface StyledChipProps {
  $tryingToRemove: boolean;
}

const StyledChip = styled(Chip)<StyledChipProps>`
  padding: 0;
  min-height: 24px;
  align-items: center;
  ${({ $tryingToRemove }) =>
    $tryingToRemove &&
    `background-color: ${colors.interactive.primary__hover_alt.rgba};`}
`;

interface ColoredDotProps {
  $color: string;
}

const ColoredDot = styled.div<ColoredDotProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

export const ComboBoxChipWithColoredDot: FC<
  ComboBoxChipWithColoredDotProps
> = ({ tryingToRemove, onDelete, item }) => {
  const { pressureData } = usePressureData();
  const isMissingData = useMemo(() => {
    const pressureDataForItem = pressureData?.find(
      (data) =>
        data[0].wellboreUuid === item.wellboreUuid && data[0].dhpg === item.dhpg
    );

    return !pressureDataForItem?.every(
      (data) => data.pressureFinal === 0 || !data.pressureFinal
    );
  }, [item.dhpg, item.wellboreUuid, pressureData]);

  return (
    <StyledChip
      variant={isMissingData ? 'white' : 'error'}
      $tryingToRemove={tryingToRemove}
      onDelete={onDelete}
    >
      <ColoredDot $color={getLineChartLineColor(item)} />
      {item.label}
    </StyledChip>
  );
};
