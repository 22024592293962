import { useQuery } from '@tanstack/react-query';

import { FieldService } from 'src/api';
import {
  FIELD_OUTLINE_QUERY_KEY,
  MEASURED_DATA_MAP_KEY,
} from 'src/constants/queryKeys';
import { useApp } from 'src/hooks';

export function useGetFieldOutlineFeatureCollection() {
  const { field } = useApp();

  const useQueryResult = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, FIELD_OUTLINE_QUERY_KEY, field?.uuid],
    queryFn: async () => {
      const cases = await FieldService.getCases(field?.uuid ?? '');
      let caseId;
      for (const object of cases) {
        if (object.defaultFlag === true) {
          caseId = object.caseId;
          break;
        }
      }
      const outlineTypes = await FieldService.getFieldOutlineTypesByCaseId(
        field?.uuid ?? '',
        caseId ?? ''
      );
      const fileId = outlineTypes[0].fileId;
      return FieldService.getFieldOutlineFeaturesByFileId(
        field?.uuid ?? '',
        caseId ?? '',
        fileId ?? ''
      );
    },
    staleTime: Infinity,
  });

  const featureLengthsArray = useQueryResult.data?.features?.map(
    (feature) => feature.geometry?.coordinates.at(0)?.length ?? 0
  );

  const mainFieldOutlineIndex =
    featureLengthsArray?.indexOf(Math.max(...featureLengthsArray)) ?? -1;

  const mainFieldOutline = useQueryResult.data?.features?.at(
    mainFieldOutlineIndex
  );

  return { ...useQueryResult, mainFieldOutline, mainFieldOutlineIndex };
}
