import { colors } from '@equinor/amplify-component-lib';
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { Group } from '@visx/group';

const PATH_HEIGHT = 40;

export const HorizontalBrushHandle = ({
  x,
  height,
  isBrushActive,
}: BrushHandleRenderProps) => {
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x} top={(height - PATH_HEIGHT) / 2}>
      <path
        fill="#f2f2f2"
        d={`M 4 0 l 0 ${PATH_HEIGHT}`}
        stroke={colors.interactive.primary__resting.rgba}
        strokeWidth="8"
        strokeLinecap="round"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  );
};
