import { colors } from '@equinor/amplify-component-lib';
import { check, file, IconData, time } from '@equinor/eds-icons';

import { StatusIdEnum } from 'src/types/status';

type VariantStyles = {
  [variant in StatusIdEnum]: {
    background: string;
    label: string;
    iconData?: IconData;
  };
};

export const variantStyles: VariantStyles = {
  [StatusIdEnum.DRAFT]: {
    background: colors.ui.background__light.rgba,
    label: 'Draft',
  },
  [StatusIdEnum.SUBMITTED]: {
    background: colors.ui.background__info.rgba,
    label: 'Submitted',
  },
  [StatusIdEnum.REVISION_REQUIRED]: {
    background: colors.ui.background__danger.rgba,
    label: 'Revision required',
  },
  [StatusIdEnum.OUTDATED]: {
    background: colors.ui.background__warning.rgba,
    label: 'Outdated',
  },
  [StatusIdEnum.HISTORICAL]: {
    background: colors.ui.background__medium.rgba,
    label: 'Historical',
    iconData: time,
  },
  [StatusIdEnum.APPROVED]: {
    background: colors.interactive.primary__selected_highlight.rgba,
    label: 'Approved',
    iconData: check,
  },
  [StatusIdEnum.PREVIEW]: {
    background: colors.dataviz.lightgray.lighter,
    label: 'Preview',
    iconData: file,
  },
};
