import { ChangeEvent, FC, useState } from 'react';

import { Icon } from '@equinor/amplify-component-lib';
import { infinity } from '@equinor/eds-icons';

import {
  DatePickerWrapper,
  IndefiniteButton,
} from './AdvancedFilterDialog.styles';
import { MeasuredDataFilter } from 'src/api';
import { FilterRule } from 'src/api';
import { CustomDatePicker } from 'src/components/CustomDatePicker/CustomDatePicker';
import { IndefiniteTimeStringType } from 'src/types';
import { getIndefiniteTimeString, isDateIndefinite } from 'src/utils';

export enum DateSelectionType {
  FROM = 'from',
  TO = 'to',
}

interface DateSelectionProps {
  type: DateSelectionType;
  filter: MeasuredDataFilter;
  updateRule: (id: string, value: Partial<FilterRule>) => void;
}

export const DateSelection: FC<DateSelectionProps> = ({
  type,
  filter,
  updateRule,
}) => {
  const isFrom = type === DateSelectionType.FROM;

  const [date, setDate] = useState(
    isFrom ? filter.rule?.fromDate : filter.rule?.toDate
  );
  const [isIndefinite, setIsIndefinite] = useState(
    isDateIndefinite(isFrom ? filter.rule?.fromDate : filter.rule?.toDate)
  );
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const dateString = new Date(event.target.value).toISOString();
    setDate(dateString);
    updateRule(
      filter.id ?? '',
      isFrom ? { fromDate: dateString } : { toDate: dateString }
    );
  };

  const handleIsIndefiniteOnClick = () => {
    if (isIndefinite) {
      updateRule(
        filter.id ?? '',
        isFrom ? { fromDate: date } : { toDate: date }
      );
    } else {
      updateRule(
        filter.id ?? '',
        isFrom
          ? {
              fromDate: getIndefiniteTimeString(IndefiniteTimeStringType.PAST),
            }
          : {
              toDate: getIndefiniteTimeString(IndefiniteTimeStringType.FUTURE),
            }
      );
    }
    setIsIndefinite((prev) => !prev);
  };

  return (
    <DatePickerWrapper>
      <CustomDatePicker
        handleOnBlur={handleChange}
        date={date}
        label={isFrom ? 'From' : 'To'}
        disabled={isIndefinite}
      />
      <IndefiniteButton
        variant="ghost_icon"
        aria-label="indefinite"
        onClick={handleIsIndefiniteOnClick}
        $isIndefinite={isIndefinite}
      >
        <Icon
          data={infinity}
          title="Indefinite"
          color={isIndefinite ? 'primary' : 'secondary'}
        />
      </IndefiniteButton>
    </DatePickerWrapper>
  );
};
