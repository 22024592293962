import { useQuery } from '@tanstack/react-query';

import { WellPlanningPhaseService } from 'src/api';
import { WELL_PLANNING_PHASES_KEY } from 'src/constants';

export const useGetWellPlanningPhases = () => {
  return useQuery({
    queryKey: [WELL_PLANNING_PHASES_KEY],
    queryFn: () =>
      WellPlanningPhaseService.getApiV1PrognosesWellPlanningPhases(),
  });
};
