import { useParams } from 'react-router-dom';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { MeasuredDataFilter, StringGuidKeyValuePair } from 'src/api';
import {
  ALL_MEASURED_DATA_FILTERS_KEY,
  MEASURED_DATA_FILTERS_KEY,
  PRES_DATA_FOR_WELLBORES_FLAT_KEY,
} from 'src/constants';
import { DEFAULT_ADVANCED_FILTER_OPTIONS } from 'src/pages/EditMeasuredData/EditMeasuredData.const';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

export const useUpdateQueryData = () => {
  const { wellId, dhpg } = useParams();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { advancedFilterDialogOptions, setAdvancedFilterDialogOptions } =
    useEditMeasuredData();

  const updateFilterQueryData = (
    data: MeasuredDataFilter,
    isUpdating: boolean
  ) => {
    const currentFilters: MeasuredDataFilter[] | undefined =
      queryClient.getQueryData([MEASURED_DATA_FILTERS_KEY, wellId, dhpg]);
    if (currentFilters) {
      const targetIndex = currentFilters?.findIndex(
        (currFilter) => currFilter.id === data.id
      );
      if (isUpdating) {
        currentFilters[targetIndex] = data;
      } else {
        currentFilters.push(data);
      }

      queryClient.setQueryData(
        [MEASURED_DATA_FILTERS_KEY, wellId, dhpg],
        currentFilters
      );
    }
  };

  const updateGlobalFilterQueryData = (
    data: MeasuredDataFilter,
    isUpdating: boolean
  ) => {
    const currentGlobalFilters: MeasuredDataFilter[] | undefined =
      queryClient.getQueryData([ALL_MEASURED_DATA_FILTERS_KEY]);

    if (currentGlobalFilters) {
      const targetIndex = currentGlobalFilters?.findIndex(
        (currFilter) => currFilter.id === data.id
      );
      if (isUpdating) {
        currentGlobalFilters[targetIndex] = data;
      } else {
        currentGlobalFilters.push(data);
      }
      queryClient.setQueryData(
        [ALL_MEASURED_DATA_FILTERS_KEY],
        currentGlobalFilters
      );
    }
  };

  const updateBulkFilterQueryData = (data: MeasuredDataFilter[]) => {
    const currentFilters: MeasuredDataFilter[] | undefined =
      queryClient.getQueryData([MEASURED_DATA_FILTERS_KEY, wellId, dhpg]);
    if (currentFilters) {
      const newFilters = currentFilters.map((filter) => {
        const hasBeenUpdatedIndex = data.findIndex((d) => d.id === filter.id);
        if (hasBeenUpdatedIndex !== -1) {
          return data[hasBeenUpdatedIndex];
        } else {
          return filter;
        }
      });
      queryClient.setQueryData(
        [MEASURED_DATA_FILTERS_KEY, wellId, dhpg],
        newFilters
      );
    }
  };

  const deleteFilterQueryData = (ids: StringGuidKeyValuePair[]) => {
    const currentFilters: MeasuredDataFilter[] | undefined =
      queryClient.getQueryData([MEASURED_DATA_FILTERS_KEY, wellId, dhpg]);
    if (currentFilters) {
      ids.forEach((id) => {
        const targetIndex = currentFilters?.findIndex(
          (currFilter) => currFilter.id === id.value
        );
        if (targetIndex !== -1) {
          currentFilters.splice(targetIndex, 1);
        }
      });
      queryClient.setQueryData(
        [MEASURED_DATA_FILTERS_KEY, wellId, dhpg],
        currentFilters
      );
    }
  };

  const invalidateEditModeQueries = () => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return (
          query.queryKey.includes(PRES_DATA_FOR_WELLBORES_FLAT_KEY) ||
          query.queryKey.includes(ALL_MEASURED_DATA_FILTERS_KEY)
        );
      },
    });
  };

  const handleOnSuccess = () => {
    invalidateEditModeQueries();
    if (advancedFilterDialogOptions) {
      setAdvancedFilterDialogOptions(DEFAULT_ADVANCED_FILTER_OPTIONS);
    }
  };

  const handleOnError = (error: Error) => {
    invalidateEditModeQueries();
    showSnackbar(
      `There was a problem updating the active edits. Error: ${error.message})`
    );
  };

  return {
    updateFilterQueryData,
    updateGlobalFilterQueryData,
    updateBulkFilterQueryData,
    deleteFilterQueryData,
    handleOnSuccess,
    handleOnError,
  };
};
