import { FC, useMemo, useState } from 'react';

import { ParentSize } from '@visx/responsive';

import {
  ChartDataCategory,
  ChartDataPoint,
  ChartSelect,
} from 'components/Chart/Chart.types';
import { ChartHeader } from 'components/Chart/Header/ChartHeader';
import { SkeletonChart } from 'components/Chart/Skeleton/SkeletonChart';

import { Card, ChartContainer } from './PrognosisChart.styles';
import { Scales } from './Scales';
import { usePrognosisDetailsFromId } from 'src/hooks/usePrognosisDetailsFromId';
import { Unit } from 'src/types/prognosisDetails';
import { ConvertPrognosisToSG } from 'src/utils/unitConversion';

import { useApp } from 'hooks/useApp';

export const PrognosisChart: FC = () => {
  const { data, isFetching } = usePrognosisDetailsFromId();
  const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);
  const [selectedData, setSelectedData] = useState<ChartSelect>({
    id: ChartDataCategory.REFERENCE_ID as number,
    name: ChartDataCategory.REFERENCE as string,
  });
  const { unit, airGap } = useApp();

  const dataCopy = useMemo(() => {
    return data?.map((prognosis) => {
      if (unit === Unit.SG && airGap) {
        return ConvertPrognosisToSG(prognosis);
      }
      return prognosis;
    });
  }, [unit, airGap, data]);

  const elements = [
    {
      id: ChartDataCategory.REFERENCE_ID as number,
      name: ChartDataCategory.REFERENCE as string,
    },
    {
      id: ChartDataCategory.DATUM_ID as number,
      name: ChartDataCategory.DATUM as string,
    },
  ];

  const usingData = useMemo(() => {
    if (selectedData.name === ChartDataCategory.REFERENCE.toString()) {
      return (
        dataCopy
          ?.filter((item) => item.referenceDepth)
          .map((item) => ({
            zone: item.lithostratSubzone ?? '',
            depth: item.referenceDepth ?? 0,
            low: item.pressureLow ?? 0,
            high: item.pressureHigh ?? 0,
            initial: item.pressureInitial,
            expected: item.pressureExpected ?? 0,
            color: item.colorHtml,
          })) ?? []
      );
    }
    return (
      dataCopy
        ?.filter((item) => item.datumDepth)
        .map((item) => ({
          zone: item.lithostratSubzone ?? '',
          depth: item.datumDepth ?? 0,
          low: item.pressureDatumLow ?? 0,
          high: item.pressureDatumHigh ?? 0,
          expected: item.pressureDatumExpected ?? 0,
          color: item.colorHtml,
        })) ?? []
    );
  }, [dataCopy, selectedData]);

  return (
    <Card ref={setCardElement}>
      <ChartHeader
        cardElement={cardElement}
        selected={selectedData}
        setSelected={setSelectedData}
        elements={elements}
        title="Overview"
      />
      <ChartContainer>
        <ParentSize ignoreDimensions="height">
          {({ width, height }) =>
            isFetching ? (
              <SkeletonChart
                width={width}
                height={height}
                orientation="horizontal"
              />
            ) : (
              <Scales
                selected={selectedData.name as ChartDataCategory}
                data={usingData as ChartDataPoint[]}
                width={width}
                height={height}
              />
            )
          }
        </ParentSize>
      </ChartContainer>
    </Card>
  );
};
