import { useQuery } from '@tanstack/react-query';

import { FilterService } from 'src/api';
import { FILTER_KEY } from 'src/constants/queryKeys';
import { useApp } from 'src/hooks';

export const useGetFilterViews = () => {
  const { field } = useApp();
  return useQuery({
    queryKey: [FILTER_KEY, field?.uuid],
    queryFn: () => FilterService.getApiV1Filter(field?.uuid ?? ''),
  });
};
