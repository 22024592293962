import { PrognosisDetailDto } from '../api';

export enum TopRounded {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum HeaderDescriptionContent {
  DEPTH = 'M TVD MSL',
  REFERENCE_PRESSURE = 'Pressure at reference depth',
  GRADIENT_TYPE = 'Fluid',
  DATUM_PRESSURE = 'Pressure at datum depth',
  PRESSURE_VALUE = 'bar/m',
  SEGMENT_DESCRIPTION = 'Name / Segment / Comment',
}

export interface HeaderDescriptionType {
  content: HeaderDescriptionContent;
  columnSpan: number;
}

export interface PrognosisDetailColumnData {
  header: string;
  topRounded?: TopRounded;
  columnSpan: number;
  headerDescriptions: HeaderDescriptionType[];
  prognosisDetailKeys: (keyof PrognosisDetailDto)[];
}

export enum Unit {
  BAR = 'BAR',
  SG = 'SG',
  AIR_GAP = 'airGap',
  CURRENT = 'currentUnit',
}
