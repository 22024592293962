import { FC } from 'react';

import { DetailedView } from '../DetailedView/DetailedView';
import { EditPrognosisProvider } from '../EditPrognosis/providers/EditPrognosisProvider';
import { PreviewFooter } from './components/PreviewFooter';

export const PreviewPrognosis: FC = () => {
  return (
    <EditPrognosisProvider>
      <DetailedView showPreviewChip={true} />
      <PreviewFooter />
    </EditPrognosisProvider>
  );
};
