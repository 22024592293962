import { FC, useRef, useState } from 'react';

import { Button, Icon, Menu } from '@equinor/amplify-component-lib';
import { delete_forever, file_copy, more_vertical } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

import { useShouldShowEditActions } from 'src/pages/DetailedView/hooks/useShouldShowEditActions';
import { useCopyPrognosis } from 'src/pages/EditPrognosis/hooks/useCopyPrognosis';

const { colors } = tokens;

interface ActionMenuProps {
  handleDelete: () => void;
}

export const ActionsMenu: FC<ActionMenuProps> = ({ handleDelete }) => {
  const { mutate: createCopy } = useCopyPrognosis();
  const showDeleteAction = useShouldShowEditActions();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  const handleCopyPrognosis = () => {
    createCopy();
  };

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        onClick={handleOnClick}
        aria-label="Options"
      >
        <Icon data={more_vertical} />
      </Button>
      {open && (
        <Menu
          open
          anchorEl={anchorRef.current}
          placement="bottom-start"
          onClose={handleOnClose}
        >
          <Menu.Section>
            <Menu.Item onClick={handleCopyPrognosis}>
              <Icon
                data={file_copy}
                color={colors.interactive.primary__resting.rgba}
              />
              Copy to a new draft
            </Menu.Item>
            {showDeleteAction ? (
              <Menu.Item onClick={handleDelete}>
                <Icon
                  data={delete_forever}
                  color={colors.interactive.primary__resting.rgba}
                />
                Delete prognosis
              </Menu.Item>
            ) : (
              <></>
            )}
          </Menu.Section>
        </Menu>
      )}
    </>
  );
};
