/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MeasurementType {
  DHPG1 = 'Dhpg1',
  DHPG2 = 'Dhpg2',
  FLOW = 'Flow',
  INJECTION_FLOW = 'InjectionFlow',
  PRODUCTION_FLOW = 'ProductionFlow',
  CUMULATIVE_INJECTION = 'CumulativeInjection',
  CUMULATIVE_PRODUCTION = 'CumulativeProduction',
  INSTANT_VOIDAGE = 'InstantVoidage',
  CUMULATIVE_VOIDAGE = 'CumulativeVoidage',
  PRODUCTIVITY_INDEX = 'ProductivityIndex',
  PRODUCTIVITY_INDEX_INTERPOLATED = 'ProductivityIndexInterpolated',
}
