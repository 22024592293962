import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useHelplines } from '../../ManageHelpLines';
import { FullWidthCheckBox } from './SelectData.styles';
import { DataType } from 'src/api';
import { MeasurementType } from 'src/api';
import { SourceTypes } from 'src/api';

export const Pressure = () => {
  const {
    selectedWellbore,
    selectedHelpLines,
    addHelpLine,
    removeHelpLine,
    source,
  } = useHelplines();

  const { wellId, dhpg } = useParams();

  const isChecked = (dataType: DataType) => {
    return selectedHelpLines.some(
      (hl) =>
        hl.id === selectedWellbore?.wellboreUuid &&
        hl.type === SourceTypes.WELLBORE &&
        hl.dataTypes?.includes(dataType) &&
        hl.source === source
    );
  };

  const isEditModeSelectedWell = useMemo(() => {
    return (
      wellId === selectedWellbore?.wellboreUuid && source?.includes(dhpg ?? '1')
    );
  }, [dhpg, selectedWellbore?.wellboreUuid, source, wellId]);

  const handleClick = (dataType: DataType) => {
    if (isChecked(dataType)) {
      removeHelpLine({
        id: selectedWellbore?.wellboreUuid,
        type: SourceTypes.WELLBORE,
        dataTypes: [dataType],
        formations: [],
        source: source,
        measureType:
          source === 'Dhpg1' ? MeasurementType.DHPG1 : MeasurementType.DHPG2,
        name: selectedWellbore?.wellName ?? '',
      });
    } else {
      addHelpLine({
        id: selectedWellbore?.wellboreUuid,
        type: SourceTypes.WELLBORE,
        dataTypes: [dataType],
        formations: [],
        source: source,
        measureType:
          source === 'Dhpg1' ? MeasurementType.DHPG1 : MeasurementType.DHPG2,
        name: selectedWellbore?.wellName ?? '',
      });
    }
  };

  return (
    <>
      <FullWidthCheckBox
        label="Raw Pressure"
        checked={isChecked(DataType.RAW)}
        onClick={() => handleClick(DataType.RAW)}
      />
      <FullWidthCheckBox
        label="Datum pressure"
        checked={isChecked(DataType.DEPTH_CORRECTED)}
        onClick={() => handleClick(DataType.DEPTH_CORRECTED)}
      />
      <FullWidthCheckBox
        label="QC Pressure"
        disabled={isEditModeSelectedWell}
        checked={
          isEditModeSelectedWell
            ? isEditModeSelectedWell
            : isChecked(DataType.FINAL)
        }
        onClick={() => handleClick(DataType.FINAL)}
      />
    </>
  );
};
