import { Button, colors, Icon, spacings } from '@equinor/amplify-component-lib';
import { last_page } from '@equinor/eds-icons';

import { DeleteFilterButton } from 'src/pages/EditMeasuredData/components/FilterPanel/ActionBar/Buttons/DeleteFilterButton';
import { DisableFilterButton } from 'src/pages/EditMeasuredData/components/FilterPanel/ActionBar/Buttons/DisableFilterButton';
import { EditFilterButton } from 'src/pages/EditMeasuredData/components/FilterPanel/ActionBar/Buttons/EditFilterButton';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

import styled from 'styled-components';

const ActionBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  justify-content: space-between;
  align-content: center;
  position: absolute;
  bottom: 0;
  background-color: ${colors.ui.background__default.rgba};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
  padding: 0 ${spacings.small};
  > :last-child {
    width: 110px;
    display: flex;
    justify-content: space-between;
  }
`;

export const ActionBar = () => {
  const { toggleFilterPanelOpen } = useEditMeasuredData();

  return (
    <ActionBarWrapper>
      <Button
        variant="ghost_icon"
        color="secondary"
        onClick={toggleFilterPanelOpen}
      >
        <Icon data={last_page} />
      </Button>

      <div>
        <DisableFilterButton />
        <EditFilterButton />
        <DeleteFilterButton />
      </div>
    </ActionBarWrapper>
  );
};
