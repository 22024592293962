import { spacings, Typography } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { motion } from 'framer-motion';
import { styled } from 'styled-components';

const { colors } = tokens;

export const Wrapper = styled(motion.div)`
  border-right: 1px solid ${colors.ui.background__medium.rgba};
  height: 100%;
  position: sticky;
  top: 0;
`;

export const Filters = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacings.large};
  align-self: stretch;
  position: sticky;
  top: 0;
`;

export const Header = styled.div`
  display: flex;
  /* prettier wraps the line below which leads to a formatting error */
  /* prettier-ignore */
  padding: ${spacings.medium} ${spacings.large} ${spacings.small} ${spacings.large};
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
`;

export const Section = styled.div`
  display: flex;
  padding: 0 ${spacings.large} ${spacings.large} ${spacings.large};
  flex-direction: column;
  gap: ${spacings.medium};
  align-self: stretch;
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  > button {
    white-space: nowrap;
  }
`;

export const StyledTypography = styled(Typography)`
  display: flex;
  flex: 1 0 0;
`;

export const ModifiedRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
  div:has(> input) {
    background: ${colors.ui.background__default.rgba};
  }
`;
