import { useParams } from 'react-router';

import { useMutation } from '@tanstack/react-query';

import { BlobService } from 'src/api';

export const usePostBlob = () => {
  const { prognosisId } = useParams();

  return useMutation({
    mutationFn: async (image: File) => {
      return await BlobService.postApiV1Blob(Number(prognosisId), {
        file: image,
      });
    },
  });
};
