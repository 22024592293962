import { FC, useCallback } from 'react';

import { Label } from '@equinor/eds-core-react';

import {
  GradientElement,
  MainTick,
  SmallTick,
  Tick,
  TickContainer,
} from 'src/pages/MapView/components/Legend/Gradient.styles';
import { RODRIGO_HEATMAP_COLOR_DOMAIN } from 'src/pages/MapView/MapView.constants';

interface GradientProps {
  ticks: number[];
}

export const Gradient: FC<GradientProps> = ({ ticks }) => {
  const tickElements = useCallback(
    (index: number) => {
      if (index === 0)
        return [
          <MainTick key="main" />,
          <SmallTick key="small-1" />,
          <SmallTick key="small-2" />,
        ];

      if (index === ticks.length - 1)
        return [
          <SmallTick key="small-1" />,
          <SmallTick key="small-2" />,
          <MainTick key="main" />,
        ];
      return [
        <SmallTick key="small-1" />,
        <SmallTick key="small-2" />,
        <MainTick key="main" />,
        <SmallTick key="small-3" />,
        <SmallTick key="small-4" />,
      ];
    },
    [ticks.length]
  );

  return (
    <GradientElement
      $colors={RODRIGO_HEATMAP_COLOR_DOMAIN}
      $isVelocityMap={true}
    >
      <TickContainer $tickAmount={ticks.length}>
        {ticks?.map((tick, index) => (
          <Tick
            key={`tick-${index}`}
            $gridColumn={index + 1}
            $tickAmount={ticks.length}
          >
            <Label label={tick.toString()} />
            {tickElements(index)}
          </Tick>
        ))}
      </TickContainer>
    </GradientElement>
  );
};
