import { FC, useState } from 'react';

import {
  Button,
  Dialog,
  Icon,
  OptionalTooltip,
  RichTextDisplay,
} from '@equinor/amplify-component-lib';
import { comment, comment_solid } from '@equinor/eds-icons';

interface CommentCellProps {
  detailComment: string | null | undefined;
}

export const CommentCell: FC<CommentCellProps> = ({ detailComment }) => {
  const [viewComment, setViewComment] = useState(false);

  const handleOnOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setViewComment(true);
  };

  const handleOnClose = () => setViewComment(false);

  return (
    <>
      <OptionalTooltip title={!detailComment ? 'No comment found' : undefined}>
        <Button
          variant="ghost_icon"
          disabled={!detailComment}
          onClick={handleOnOpen}
        >
          <Icon data={detailComment ? comment_solid : comment} />
        </Button>
      </OptionalTooltip>
      {viewComment && (
        <Dialog
          title="Detail comment"
          onClose={handleOnClose}
          open={viewComment}
          isDismissable
          width={400}
        >
          <RichTextDisplay value={detailComment} />
        </Dialog>
      )}
    </>
  );
};
