import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';

import {
  Button,
  colors,
  Icon,
  Menu,
  Typography,
} from '@equinor/amplify-component-lib';
import { arrow_drop_down, arrow_drop_up } from '@equinor/eds-icons';

import { Actions } from '../Actions/Actions';
import { ChartSelect } from '../Chart.types';
import { Header, HeaderSection } from './ChartHeader.styles';

import styled from 'styled-components';

interface ChartHeaderProps {
  cardElement: HTMLDivElement | null;
  selected: ChartSelect;
  setSelected: Dispatch<SetStateAction<ChartSelect>>;
  elements: ChartSelect[];
  title: string;
}
interface MenuItemProps {
  $selected: boolean;
}

const MenuItem = styled(Menu.Item)<MenuItemProps>`
  background: ${({ $selected }) =>
    $selected ? colors.interactive.primary__selected_highlight.rgba : 'none'};
`;

export const ChartHeader: FC<ChartHeaderProps> = ({
  cardElement,
  selected,
  setSelected,
  elements,
  title,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  return (
    <Header>
      <HeaderSection>
        <Button
          ref={anchorRef}
          variant="outlined"
          onClick={handleOnClick}
          data-testid="chartMenuButton"
        >
          {selected?.name || 'Select zone'}
          <Icon data={open ? arrow_drop_up : arrow_drop_down} />
        </Button>
        {open && (
          <Menu
            open
            anchorEl={anchorRef.current}
            placement="bottom-start"
            onClose={handleOnClose}
          >
            {elements?.map((value, index) => (
              <MenuItem
                key={value.id}
                $selected={value?.id === selected?.id}
                onClick={() => setSelected(value)}
                data-testid={`dataSelectorItem-${index}`}
              >
                {value.name}
              </MenuItem>
            ))}
          </Menu>
        )}
        <Typography variant="h4">{title}</Typography>
      </HeaderSection>
      <Actions cardElement={cardElement} />
    </Header>
  );
};
