import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@equinor/amplify-component-lib';
import { chevron_right } from '@equinor/eds-icons';

import { StyledBreadcrumbs } from './Breadcrumbs.styles';

export const Breadcrumbs: FC = () => {
  return (
    <StyledBreadcrumbs separator={<Icon data={chevron_right} />}>
      <StyledBreadcrumbs.Breadcrumb as={Link} to="/dashboard">
        Dashboard
      </StyledBreadcrumbs.Breadcrumb>
      <StyledBreadcrumbs.Breadcrumb disabled>
        Prognosis editor
      </StyledBreadcrumbs.Breadcrumb>
    </StyledBreadcrumbs>
  );
};
