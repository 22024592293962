import { createContext, ReactNode, useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';

import { PressureDataSimple } from 'src/api';
import { PressureService } from 'src/api';
import { PRES_DATA_FOR_WELLBORES_FLAT_KEY } from 'src/constants';
import { useMeasuredData } from 'src/hooks';
import { useApp } from 'src/hooks/useApp';

export interface PressureDataState {
  pressureData: PressureDataSimple[][];
  loading: boolean;
  errors: string[];
}

export const PressureDataStateContext = createContext<PressureDataState>({
  pressureData: [],
  loading: false,
  errors: [],
});

export const PressureDataProvider = ({ children }: { children: ReactNode }) => {
  const { field } = useApp();
  const { from, to, selectedMeasurements } = useMeasuredData();
  const wellboreUuids = selectedMeasurements
    .map((items) => items.wellboreUuid)
    .filter((item) => item !== undefined);
  const uniqueWellboreUuids = wellboreUuids.filter(
    (item, pos) => wellboreUuids.indexOf(item) === pos
  );

  const results = useQueries({
    queries: uniqueWellboreUuids.map((wellboreUuid) => ({
      queryKey: [
        PRES_DATA_FOR_WELLBORES_FLAT_KEY,
        wellboreUuid,
        from,
        to,
        field?.uuid,
      ],
      queryFn: () =>
        PressureService.getpressureDataForWellboresFlat(
          field?.uuid ?? '',
          from,
          to,
          [wellboreUuid]
        ),
      staleTime: 1000 * 60 * 60 * 24,
    })),
  });

  const pressureData = useMemo(
    () =>
      results.reduce((pre, curr) => {
        const data1 = curr?.data?.filter((item) => item.dhpg === 1);
        if (
          data1 &&
          data1.length > 0 &&
          selectedMeasurements.find(
            (item) =>
              item.wellboreUuid === data1[0].wellboreUuid && item.dhpg === 1
          )
        ) {
          pre.push(data1);
        }

        const data2 = curr?.data?.filter((item) => item.dhpg === 2);
        if (
          data2 &&
          data2.length > 0 &&
          selectedMeasurements.find(
            (item) =>
              item.wellboreUuid === data2[0].wellboreUuid && item.dhpg === 2
          )
        ) {
          pre.push(data2);
        }
        return pre;
      }, [] as PressureDataSimple[][]),
    [results, selectedMeasurements]
  );

  const errors = results
    .filter((res) => res.isError)
    .map((item) => item?.data?.[0].wellboreUuid)
    .filter((item) => item !== undefined);

  return (
    <PressureDataStateContext.Provider
      value={{
        pressureData,
        loading: results.some((item) => item.isLoading),
        errors,
      }}
    >
      {children}
    </PressureDataStateContext.Provider>
  );
};
