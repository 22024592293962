import { FC } from 'react';

import { SkeletonBase } from '@equinor/amplify-component-lib';

import { LinePlotMargin } from 'src/components/Charts/LineChart/LineChart.types';

import styled from 'styled-components';

interface SkeletonProps {
  $height: number;
  $margin: LinePlotMargin;
}

export const Skeleton = styled(SkeletonBase)<SkeletonProps>`
  position: relative;
  border-radius: 4px;
  height: ${({ $height }) => `${$height}px`};
  width: ${({ $margin }) => {
    return `calc(100% - ${$margin.left}px - ${$margin.right}px)`;
  }};
  left: ${({ $margin }) => {
    return `${$margin.left}px`;
  }};
  margin-bottom: ${({ $margin }) => $margin.bottom};
`;

interface HorizontalBrushSkeletonProps {
  margin: LinePlotMargin;
  height: number;
}

export const HorizontalBrushSkeleton: FC<HorizontalBrushSkeletonProps> = ({
  margin,
  height,
}) => {
  return <Skeleton $margin={margin} $height={height} />;
};
