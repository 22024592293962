import { formatDate } from '@equinor/amplify-component-lib';

import { ONE_DAY_IN_MILLISECONDS } from 'src/constants';
import { ValueLabel } from 'src/types';
import {
  getShortMonth,
  getYear,
  isFirstDayOfMonth,
  isFistMonthOfYear,
} from 'src/utils';

export const generateValueLabel = (value: number, date: string | number) => {
  if (typeof date === 'number') {
    return {
      value,
      label: formatDate(new Date(date), {
        format: 'DD.MM.YY',
      }),
    };
  }

  return {
    value,
    label: date,
  };
};

export const getDayMarks = (from: number, to: number) => {
  const daysMarks: ValueLabel[] = [];

  daysMarks.push(generateValueLabel(from, from));

  for (
    let day = from + ONE_DAY_IN_MILLISECONDS;
    day < to;
    day += ONE_DAY_IN_MILLISECONDS
  ) {
    daysMarks.push(generateValueLabel(day, ''));
  }

  daysMarks.push(generateValueLabel(to, to));

  return daysMarks;
};

export const getFewMonthMarks = (from: number, to: number) => {
  const fewMonthMarks: ValueLabel[] = [];

  fewMonthMarks.push(generateValueLabel(from, from));

  for (
    let day = from + ONE_DAY_IN_MILLISECONDS;
    day < to;
    day += ONE_DAY_IN_MILLISECONDS
  ) {
    if (isFirstDayOfMonth(day)) {
      if (isToCloseToTheEdge(day, from, to)) {
        fewMonthMarks.push(generateValueLabel(day, ''));
        continue;
      }
      fewMonthMarks.push(generateValueLabel(day, getShortMonth(day)));
    }
  }

  fewMonthMarks.push(generateValueLabel(to, to));

  return fewMonthMarks;
};

export const getManyMonthMarks = (from: number, to: number) => {
  const manyMonthMarks: ValueLabel[] = [];

  for (let index = from; index <= to; index += ONE_DAY_IN_MILLISECONDS) {
    if (isFirstDayOfMonth(index)) {
      manyMonthMarks.push(generateValueLabel(index, getShortMonth(index)));
    }
  }

  return manyMonthMarks;
};

export const getYearMarks = (from: number, to: number): ValueLabel[] => {
  const yearMarks: ValueLabel[] = [];

  yearMarks.push(generateValueLabel(from, from));

  for (
    let day = from + ONE_DAY_IN_MILLISECONDS;
    day < to;
    day += ONE_DAY_IN_MILLISECONDS
  ) {
    if (isFirstDayOfMonth(day) && isFistMonthOfYear(day)) {
      if (isToCloseToTheEdge(day, from, to)) {
        yearMarks.push(generateValueLabel(day, ''));
        continue;
      }
      yearMarks.push(generateValueLabel(day, getYear(day)));
    }
  }

  yearMarks.push(generateValueLabel(to, to));

  return yearMarks;
};

const isToCloseToTheEdge = (value: number, from: number, to: number) => {
  const rangeValue = to - from;
  const noLabelRenderRange = Math.round(rangeValue * 0.07);
  const fromToIndexRange = value - from;

  return (
    rangeValue - fromToIndexRange <= noLabelRenderRange ||
    fromToIndexRange <= noLabelRenderRange
  );
};
