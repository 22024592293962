import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Container,
  StyledTypography,
  TypographyContainer,
} from './DetailsCell.styles';
import { PrognosisDetailDto } from 'src/api';
import { EditPrognosisFormValues } from 'src/utils';
import { getCellColor } from 'src/utils/color';
import { getPrognosisDetailError } from 'src/utils/error';

interface DetailsCellProps {
  isUpdated?: boolean;
  detailValue?: string;
  previous?: string;
  isSelected?: boolean;
  detailKey: keyof PrognosisDetailDto;
  index: number;
}

export const DetailsCell: FC<DetailsCellProps> = ({
  detailValue,
  isSelected,
  detailKey,
  index,
  isUpdated,
}) => {
  const {
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();
  const showPlaceholder = detailValue == undefined || detailValue == '';
  // converts the result into a boolean
  const hasError = !!getPrognosisDetailError(errors, index, detailKey);

  let textColor, backgroundColor;

  if (hasError) {
    ({ textColor, backgroundColor } = getCellColor('invalid'));
  } else if (isUpdated) {
    ({ textColor, backgroundColor } = getCellColor('updated'));
  } else if (showPlaceholder) {
    ({ textColor, backgroundColor } = getCellColor('placeholder'));
  } else {
    ({ textColor, backgroundColor } = getCellColor());
  }

  return (
    <Container $isSelected={isSelected}>
      <TypographyContainer $backgroundColor={backgroundColor}>
        <StyledTypography variant="cell_text" group="table" color={textColor}>
          {showPlaceholder ? '0.0' : detailValue}
        </StyledTypography>
      </TypographyContainer>
    </Container>
  );
};
