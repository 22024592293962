import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { OverlappingPrognosisService } from 'src/api';
import { OVERLAPPING_PROGNOSES } from 'src/constants';

export const useOverlappingPrognoses = () => {
  const { prognosisId } = useParams();
  const usingId = Number(prognosisId);

  const query = useQuery({
    queryKey: [OVERLAPPING_PROGNOSES, prognosisId],
    queryFn: () =>
      OverlappingPrognosisService.getApiV1PrognosesOverlappingPrognoses(
        usingId
      ),
    enabled: !Number.isNaN(usingId),
    retry: 2,
  });

  return { ...query };
};
