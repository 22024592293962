import { SelectOptionRequired } from '@equinor/amplify-component-lib';

import { DataType } from 'src/api';
import { FilterCondition } from 'src/api';
import { FilterDataTypes } from 'src/api';
import { PressureLabels } from 'src/constants';

export const DataTypeOptions: SelectOptionRequired[] = [
  { value: FilterDataTypes.PRESSURE, label: 'Pressure' },
  {
    value: FilterDataTypes.CUMULATIVE_PRODUCTION,
    label: 'Cumulative volume',
  },
  {
    value: FilterDataTypes.INSTANT_PRODUCTION,
    label: 'Instant rate',
  },
  {
    value: FilterDataTypes.ON_STREAM_HOURS,
    label: PressureLabels[DataType.ON_STREAM_HOURS],
  },
];
export const ConditionOptions: SelectOptionRequired[] = [
  { value: FilterCondition.EQUAL, label: 'Equals' },
  { value: FilterCondition.NOTEQUAL, label: 'Not equals' },
  { value: FilterCondition.GREATERTHAN, label: 'Greater than' },
  { value: FilterCondition.LESSTHAN, label: 'Less than' },
  { value: FilterCondition.GREATERTHANOREQUAL, label: 'Greater than or equal' },
  { value: FilterCondition.LESSTHANOREQUAL, label: 'Less than or equal' },
];
