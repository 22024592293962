import { FC, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Checkbox,
  CircularProgress,
  Typography,
} from '@equinor/amplify-component-lib';

import { MoreOptionsMenu } from 'components/MoreOptionsMenu/MoreOptionsMenu';

import { DetailsActionColumn } from './DetailsActionColumn/DetailsActionColumn';
import { DetailsColumn } from './DetailsColumn/DetailsColumn';
import { DetailsSegmentColumn } from './DetailsSegmentColumn/DetailsSegmentColumn';
import { EditGroup } from './EditGroup/EditGroup';
import { InfoWrapper } from './InfoWrapper/InfoWrapper';
import {
  CheckboxGroup,
  Footer,
  Header,
  InitializeContainer,
  Table,
  TableContainer,
} from './PrognosisDetailsTable.styles';
import {
  PROGNOSIS_DETAILS_SEGMENT_COLUMN,
  PROGNOSIS_DETAILS_TABLE_COLUMNS,
} from 'src/constants/prognosisDetails';
import {
  useApp,
  usePrognosisDetailsFromId,
  usePrognosisFromId,
} from 'src/hooks';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { Unit } from 'src/types/prognosisDetails';
import { sortByTopAge } from 'src/utils';
import { ConvertPrognosisToSG } from 'src/utils/unitConversion';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

interface PrognosisDetailsTableProps {
  isEditing?: boolean;
}
export const PrognosisDetailsTable: FC<PrognosisDetailsTableProps> = ({
  isEditing = false,
}) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();
  const { data: apiPrognosisDetails } = usePrognosisDetailsFromId();
  const { data: prognosisData } = usePrognosisFromId();
  const { optionalAttributes, setOptionalAttributes } = useOptionalAttributes();
  const { isInitializing } = useEditPrognosis();
  const { unit, airGap } = useApp();
  const { hasUpdatedCell } = useEditPrognosis();

  const cardRef = useRef<HTMLDivElement | null>(null);

  const [referenceChecked, setReferenceChecked] = useState(true);
  const [gradientsChecked, setGradientsChecked] = useState(true);

  const formPrognosisDetails = getValues('prognosisDetails');

  const apiPrognosisDetailCopy = useMemo(() => {
    return apiPrognosisDetails?.map((prognosis) => {
      if (unit === Unit.SG && airGap) {
        return ConvertPrognosisToSG(prognosis);
      }
      return prognosis;
    });
  }, [unit, airGap, apiPrognosisDetails]);

  const hasErrors =
    errors.prognosisDetails && Object.keys(errors.prognosisDetails).length > 0;

  const details = isEditing ? formPrognosisDetails : apiPrognosisDetailCopy;
  const dataToUse = sortByTopAge(details ?? []);

  const numColumns = useMemo(() => {
    let count = 0;
    if (referenceChecked) count += 5;
    if (gradientsChecked) count += 2;
    if (optionalAttributes.datum) count += 5;
    return count;
  }, [referenceChecked, gradientsChecked, optionalAttributes.datum]);

  const numRows = useMemo(() => (dataToUse?.length ?? 0) + 3, [dataToUse]);

  const renderColumns = useMemo(() => {
    const columns = [
      <DetailsSegmentColumn
        key={PROGNOSIS_DETAILS_SEGMENT_COLUMN.header}
        header={PROGNOSIS_DETAILS_SEGMENT_COLUMN.header}
        headerDescriptions={PROGNOSIS_DETAILS_SEGMENT_COLUMN.headerDescriptions}
        isEditing={isEditing}
        prognosisDetailKeys={
          PROGNOSIS_DETAILS_SEGMENT_COLUMN.prognosisDetailKeys
        }
        rowSpan={numRows}
      />,
    ];

    let currentStart = 2;

    PROGNOSIS_DETAILS_TABLE_COLUMNS.filter((column) => {
      return (
        (column.header === 'Reference' && referenceChecked) ||
        (column.header === 'Gradients' && gradientsChecked) ||
        (column.header === 'Datum' && optionalAttributes.datum)
      );
    }).forEach((column) => {
      columns.push(
        <DetailsColumn
          key={column.header}
          columnSpan={column.columnSpan}
          columnStart={currentStart}
          header={column.header}
          headerDescriptions={column.headerDescriptions}
          isEditing={isEditing}
          prognosisData={prognosisData}
          prognosisDetailData={dataToUse ?? []}
          prognosisDetailKeys={column.prognosisDetailKeys}
          rowSpan={numRows}
        />
      );
      currentStart += column.columnSpan;
    });

    if (isEditing) {
      columns.push(
        <DetailsActionColumn
          key="actionColumn"
          columnStart={currentStart}
          rowSpan={numRows}
        />
      );
    }

    return columns;
  }, [
    dataToUse,
    gradientsChecked,
    isEditing,
    numRows,
    optionalAttributes.datum,
    prognosisData,
    referenceChecked,
  ]);

  const handleCheckboxChange =
    (setter: React.Dispatch<React.SetStateAction<boolean>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.checked);
    };

  if (isInitializing) {
    return (
      <InitializeContainer>
        <CircularProgress color="primary" />
      </InitializeContainer>
    );
  }

  return (
    <TableContainer ref={cardRef}>
      <Header>
        <Typography variant="h4">
          {isEditing ? 'Fill in the prognosis data' : 'Details'}
        </Typography>
        {isEditing ? (
          <EditGroup />
        ) : (
          <CheckboxGroup>
            <Checkbox
              label="Reference"
              checked={referenceChecked}
              onChange={handleCheckboxChange(setReferenceChecked)}
            />
            <Checkbox
              label="Gradients"
              checked={gradientsChecked}
              onChange={handleCheckboxChange(setGradientsChecked)}
            />
            <Checkbox
              label="Datum"
              checked={optionalAttributes.datum}
              onChange={() =>
                setOptionalAttributes((prev) => ({
                  segment: prev.segment,
                  datum: !prev.datum,
                  totalDepthRow: prev.totalDepthRow,
                }))
              }
            />
            <MoreOptionsMenu cardElement={cardRef.current} />
          </CheckboxGroup>
        )}
      </Header>
      <Table $rows={numRows} $columns={numColumns} $isEditing={isEditing}>
        {renderColumns}
      </Table>
      <Footer>
        <InfoWrapper
          hasErrors={hasErrors ?? false}
          hasUpdatedCell={hasUpdatedCell}
        />
      </Footer>
    </TableContainer>
  );
};
