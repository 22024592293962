/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FilterDataTypes {
  PRESSURE = 'Pressure',
  INSTANT_PRODUCTION = 'InstantProduction',
  CUMULATIVE_PRODUCTION = 'CumulativeProduction',
  ON_STREAM_HOURS = 'OnStreamHours',
}
