import { useEffect, useMemo, useState } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { ParentSize } from '@visx/responsive';

import { ChartDataPoint, ChartSelect } from 'components/Chart/Chart.types';
import { ChartHeader } from 'components/Chart/Header/ChartHeader';
import { SkeletonChart } from 'components/Chart/Skeleton/SkeletonChart';

import { Scales } from './Scales';
import { Card, ChartContainer } from './TimeChart.styles';
import { PrognosisDetailReservoirZoneDto } from 'src/api';
import { useApp, usePrognosisDetailsFromId } from 'src/hooks';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { Unit } from 'src/types/prognosisDetails';
import { ConvertPrognosisToSG } from 'src/utils/unitConversion';

import { useGetReservoirZoneDetails } from 'hooks/useGetReservoirZoneDetails';
import styled from 'styled-components';

const Info = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TimeChart = () => {
  const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);
  const [selectedData, setSelectedData] = useState<ChartSelect>();

  const { data: apiPrognosisDetails } = usePrognosisDetailsFromId();

  const { data: reservoirZone, isFetching } = useGetReservoirZoneDetails(
    selectedData?.name || ''
  );

  const { unit, airGap } = useApp();

  const reservoirZoneDetails: PrognosisDetailReservoirZoneDto[] =
    useMemo(() => {
      return (reservoirZone?.map((prognosis) => {
        if (unit === Unit.SG && airGap) {
          return ConvertPrognosisToSG(prognosis);
        }
        return prognosis;
      }) || []) as PrognosisDetailReservoirZoneDto[];
    }, [unit, airGap, reservoirZone]);

  const { selectedTableRows, addSelectedTableRow } = useEditPrognosis();

  const selectedZoneInDetails = apiPrognosisDetails?.find(
    (item) =>
      selectedTableRows &&
      item.id === selectedTableRows[selectedTableRows.length - 1]
  );

  const usingData = useMemo(() => {
    if (!reservoirZoneDetails) return [];
    const latestFive = reservoirZoneDetails?.slice(-5);
    return latestFive
      ?.map((item, index) => ({
        zone: item.reservoirZone,
        depth: item.referenceDepth,
        low: item.pressureLow,
        high: item.pressureHigh,
        initial: item.pressureInitial,
        expected: item.pressureExpected,
        index: index,
        date: item.approveDate ? new Date(item.approveDate) : new Date(),
        color: item.colorHtml,
      }))
      .reverse();
  }, [reservoirZoneDetails]);

  useEffect(() => {
    if (selectedZoneInDetails) {
      setSelectedData({
        id: selectedZoneInDetails.id,
        name: selectedZoneInDetails.reservoirZone,
      });
    } else {
      setSelectedData(undefined);
    }
  }, [selectedZoneInDetails]);

  const handleSetSelectedData = (value: ChartSelect) => {
    setSelectedData(value);
    addSelectedTableRow(value.id, false);
  };

  return (
    <Card ref={setCardElement}>
      <ChartHeader
        cardElement={cardElement}
        selected={selectedData!}
        setSelected={(e) => handleSetSelectedData(e as ChartSelect)}
        elements={
          apiPrognosisDetails?.map((item) => ({
            id: item.id,
            name: item.reservoirZone,
          })) as ChartSelect[]
        }
        title="Zones over time"
      />
      <ChartContainer>
        {selectedData ? (
          <ParentSize ignoreDimensions="height">
            {({ width, height }) => {
              if (isFetching) {
                return (
                  <SkeletonChart
                    width={width}
                    height={height}
                    orientation="vertical"
                  />
                );
              }

              if (usingData.length > 0) {
                return (
                  <Scales
                    width={width}
                    height={height}
                    data={usingData as ChartDataPoint[]}
                  />
                );
              }

              return <Info>No data available for this zone</Info>;
            }}
          </ParentSize>
        ) : (
          <Info>
            Select a zone to display data, either in the select above or in the
            table below
          </Info>
        )}
      </ChartContainer>
    </Card>
  );
};
