import { MouseEvent, useMemo } from 'react';

import { scaleBand, scaleLinear } from '@visx/scale';
import { withTooltip } from '@visx/tooltip';

import Chart from 'components/Chart/Chart';
import { MARGIN, TICK_LABEL_PROPS } from 'components/Chart/Chart.constants';
import { ChartDataPoint } from 'components/Chart/Chart.types';
import {
  getPressureDomain,
  isNow,
  localPoint,
} from 'components/Chart/Chart.utils';

import { Elements } from './Elements';
import { Unit } from 'src/types';

import { useApp } from 'hooks/useApp';

interface ScalesProps {
  width: number;
  height: number;
  data: ChartDataPoint[];
}

export const Scales = withTooltip<ScalesProps>(
  ({
    data,
    width,
    height,
    showTooltip,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipData,
  }) => {
    const { unit } = useApp();
    const xMax = useMemo(() => width - MARGIN.RIGHT, [width]);
    const yMax = useMemo(() => height - MARGIN.BOTTOM, [height]);

    const dateScale = useMemo(
      () =>
        scaleBand<Date>({
          range: [0, xMax],
          domain: data?.map((d) => d.date),
          padding: 0.2,
        }),
      [xMax, data]
    );

    const pressureScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax - MARGIN.BOTTOM - TICK_LABEL_PROPS.fontSize, 0],
          domain: getPressureDomain(data),
          round: true,
        }),
      [yMax, data]
    );

    const formatTick = (date: Date) => {
      if (isNow(date)) return 'Now';

      return date.toLocaleDateString('en-US', {
        month: 'short',
        year: '2-digit',
      });
    };

    const handleOnMouseMove = (
      value: ChartDataPoint,
      event: MouseEvent<Element>
    ) => {
      const { x, y } = localPoint(event) ?? { x: 0 };

      showTooltip({
        tooltipData: value,
        tooltipTop: y,
        tooltipLeft: x,
      });
    };

    return (
      <Chart
        width={width}
        height={height}
        data={data}
        // @ts-expect-error formatTick is not a required prop
        formatTick={formatTick}
        xScale={dateScale}
        yScale={pressureScale}
        yMax={yMax}
        xMax={xMax}
        xLabel="Date"
        yLabel={unit === Unit.BAR ? 'Pressure' : 'SG'}
        tooltipLeft={tooltipLeft}
        tooltipTop={tooltipTop}
        tooltipData={tooltipData}
        rangeColor={data.at(0)?.color}
      >
        <Elements
          dateScale={dateScale}
          pressureScale={pressureScale}
          dateScaleBandwidth={dateScale.bandwidth()}
          data={data}
          onMouseExit={hideTooltip}
          onMouseMove={handleOnMouseMove}
        />
      </Chart>
    );
  }
);
