import { useContext } from 'react';

import { MeasuredDataContext } from 'src/providers/MeasuredDataProvider';

export const useMeasuredData = () => {
  const context = useContext(MeasuredDataContext);
  if (context === undefined) {
    throw new Error(
      'useMeasuredData must be used within a MeasuredDataProvider'
    );
  }
  return context;
};
