import { useLocation, useNavigate } from 'react-router';

import { environment, Field, TopBar } from '@equinor/amplify-component-lib';
import { useIsFetching } from '@tanstack/react-query';

import { Account } from './Content/Account';
import { Guidelines } from './Content/Guidelines';
import { Settings } from './Content/Settings';
import { useApp } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';

const { getAppName, getEnvironmentName } = environment;

export const ApplicationTopBar = () => {
  const { field, setField } = useApp();
  const navigate = useNavigate();
  const location = useLocation();
  const isFetching = useIsFetching() > 0;
  const { fields } = useGetUserAccess();
  const handleHeaderClick = () => {
    if (location.pathname !== 'dashboard') navigate('/dashboard');
  };

  const handleSetField = (newField: Field) => {
    setField(newField);
    navigate('/dashboard');
  };

  return (
    <TopBar
      onHeaderClick={handleHeaderClick}
      applicationIcon="premo"
      applicationName={getAppName(import.meta.env.VITE_NAME)}
      isFetching={isFetching}
      environment={getEnvironmentName(import.meta.env.VITE_ENVIRONMENT_NAME)}
      currentField={field}
      onSelectField={handleSetField}
      availableFields={fields}
    >
      <TopBar.Actions>
        <Account key="topbar-account" />
        <Settings key="topbar-settings" />
        <Guidelines />
        <TopBar.Resources hideLearnMore />
      </TopBar.Actions>
    </TopBar>
  );
};
