import { useMemo } from 'react';

import { PolygonLayer } from '@deck.gl/layers';
import { useQuery } from '@tanstack/react-query';

import { PolygonFeature } from 'src/api';
import { FieldService } from 'src/api';
import { AREA_FEATURES_QUERY_KEY, MEASURED_DATA_MAP_KEY } from 'src/constants';
import { useApp } from 'src/hooks/useApp';
import { MapLayer } from 'src/pages/MapView/MapView.types';

export function useAreaOutlines() {
  const { field } = useApp();

  const { data } = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, AREA_FEATURES_QUERY_KEY, field?.uuid],
    queryFn: () => FieldService.getAreas(field?.uuid ?? ''),
    staleTime: Infinity,
  });

  const areas = useMemo(() => {
    if (!data) return;
    return data
      .map((area) => area.polygonCollection)
      .flatMap((polygonCollection) => polygonCollection?.features);
  }, [data]);

  return useMemo(() => {
    return new PolygonLayer({
      id: MapLayer.AREAS_OUTLINE,
      filled: false,
      data: areas,
      getPolygon: (d: PolygonFeature) => d.geometry?.coordinates,
      getLineColor: [190, 190, 190], // Equinor interactive disabled text
      getLineWidth: 1,
      lineWidthMinPixels: 2,
      positionFormat: 'XY',
    });
  }, [areas]);
}
