import { FC, useState } from 'react';

import { Button, Icon } from '@equinor/amplify-component-lib';
import {
  check_circle_outlined,
  close_circle_outlined,
  edit,
  share,
} from '@equinor/eds-icons';

import { StatusChip } from 'components/StatusChip/StatusChip';

import { useShouldShowApproverActions } from '../../hooks/useShouldShowApproverActions';
import { useShouldShowEditActions } from '../../hooks/useShouldShowEditActions';
import { ActionsMenu } from './ActionsMenu/ActionsMenu';
import { ApproveDialog } from './ApproveDialog/ApproveDialog';
import { EditDialog } from './EditDialog/EditDialog';
import { RejectDialog } from './RejectDialog/RejectDialog';
import { Container } from './Actions.styles';
import { DeleteDialog } from 'src/components/DeleteDialog/DeleteDialog';
import { usePrognosisFromId } from 'src/hooks';
import { StatusIdEnum } from 'src/types';

interface ActionProps {
  showPreviewChip?: boolean;
}
export const Actions: FC<ActionProps> = ({ showPreviewChip = false }) => {
  const { data } = usePrognosisFromId();
  const showApproverActions = useShouldShowApproverActions();
  const showEditButton = useShouldShowEditActions();

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOnOpenApproveDialog = () => setOpenApproveDialog(true);
  const handleOnCloseApproveDialog = () => setOpenApproveDialog(false);

  const handleOnOpenRejectDialog = () => setOpenRejectDialog(true);
  const handleOnCloseRejectDialog = () => setOpenRejectDialog(false);

  const handleOnOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleOnCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleOnOpenEditDialog = () => setOpenEditDialog(true);
  const handleOnCloseEditDialog = () => setOpenEditDialog(false);

  return (
    <>
      <Container>
        {showPreviewChip ? (
          <StatusChip statusId={StatusIdEnum.PREVIEW} />
        ) : (
          <StatusChip statusId={data?.statusId ?? 0} />
        )}
        {showApproverActions ? (
          <>
            <Button onClick={handleOnOpenApproveDialog}>
              <Icon data={check_circle_outlined} /> Approve
            </Button>
            <Button onClick={handleOnOpenRejectDialog} color="danger">
              <Icon data={close_circle_outlined} />
              Return for revision
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" disabled>
              <Icon data={share} /> Share
            </Button>
            {showEditButton && (
              <Button
                variant="outlined"
                onClick={handleOnOpenEditDialog}
                title="Edit"
              >
                <Icon data={edit} /> Edit
              </Button>
            )}
          </>
        )}
        <ActionsMenu handleDelete={handleOnOpenDeleteDialog} />
      </Container>
      <ApproveDialog
        open={openApproveDialog}
        title={data?.title ?? 'N/A'}
        onClose={handleOnCloseApproveDialog}
      />
      <RejectDialog
        open={openRejectDialog}
        title={data?.title ?? 'N/A'}
        onClose={handleOnCloseRejectDialog}
      />
      <DeleteDialog
        open={openDeleteDialog}
        title={data?.title ?? 'N/A'}
        onClose={handleOnCloseDeleteDialog}
      />
      <EditDialog
        open={openEditDialog}
        title={data?.title ?? 'N/A'}
        onClose={handleOnCloseEditDialog}
      />
    </>
  );
};
