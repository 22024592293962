import { Field } from '@equinor/amplify-component-lib';

import { HelpLine, MeasuredDataFilter } from 'src/api';

export enum RightAxisEnum {
  INSTANT = 'Instant',
  CUMULATIVE = 'Cumulative',
  GRADIENT = 'Gradient',
  ON_STREAM_HOURS = 'On stream hours',
  PRODUCTIVITY_INDEX = 'Productivity index',
}

export interface FlowDataType {
  instantProduction: HelpLine | undefined;
  cumulativeProduction: HelpLine | undefined;
}

export interface WellInfoProps {
  field: Field;
  wellId: string | undefined;
  dhpg: string | undefined;
}

export interface AdvancedFilterDialogOptions {
  open: boolean;
  initialFilters: MeasuredDataFilter[];
  isUpdatingFilter: boolean;
}
