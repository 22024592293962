import { FC } from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';

import {
  SingleSelect,
  spacings,
  TextField,
  Typography,
} from '@equinor/amplify-component-lib';

import { TextFieldContainer } from '../DataInputDialog.styles';
import { getPrognosisDetailError } from 'src/utils/error';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

interface GradientsProps {
  label: string;
  index: number;
}

const items = [
  {
    label: 'Oil',
    value: 'Oil',
  },
  {
    label: 'Water',
    value: 'Water',
  },
  {
    label: 'Gas',
    value: 'Gas',
  },
];

export const Gradients: FC<GradientsProps> = ({ label, index }) => {
  const {
    register,
    trigger,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();

  const fluid: Path<EditPrognosisFormValues> = `prognosisDetails.${index}.fluidType`;
  const pressureGradientDepth: Path<EditPrognosisFormValues> = `prognosisDetails.${index}.pressureGradientDepth`;

  const fluidError = getPrognosisDetailError(errors, index, 'fluidType');
  const pressureGradientDepthError = getPrognosisDetailError(
    errors,
    index,
    'pressureGradientDepth'
  );

  const fluidValidationVariant = fluidError && 'error';
  const pressureGradientDepthValidationVariant =
    pressureGradientDepthError && 'error';

  const handleBlur = (fieldPath: Path<EditPrognosisFormValues>) => {
    const value = getValues(fieldPath);

    if (value) {
      trigger(fieldPath);
    } else {
      clearErrors(fieldPath);
    }
  };

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        <Controller
          name={fluid}
          render={({ field }) => {
            return (
              <SingleSelect
                items={items}
                label="Fluid Type"
                helperText={fluidError?.message || 'Select fluid type'}
                value={{
                  label: field.value as string,
                  value: field.value as string,
                }}
                clearable={false}
                data-testid={fluid}
                variant={fluidValidationVariant}
                onSelect={(value) => {
                  field.onChange(value?.value);
                }}
              />
            );
          }}
        />

        <TextField
          {...register(pressureGradientDepth)}
          id={'PressureGradientDepth'}
          placeholder="0.0"
          label="Pressure Gradient Depth"
          helperText={pressureGradientDepthError?.message || 'bar/m'}
          variant={pressureGradientDepthValidationVariant}
          data-testid={pressureGradientDepth}
          onBlur={() => handleBlur(pressureGradientDepth)}
        />
      </TextFieldContainer>
    </Container>
  );
};
