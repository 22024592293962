import { useAuth, useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Filter, FilterService } from 'src/api';
import { CREATE_FILTER_KEY, FILTER_KEY } from 'src/constants/queryKeys';
import { useApp } from 'src/hooks/useApp';
import { useDashboard } from 'src/pages/Dashboard/hooks/useDashboard';

export const useCreateFilterView = (title: string) => {
  const { filterValue } = useDashboard();
  const { field } = useApp();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { account } = useAuth();

  const body = {
    id: '', // Placeholder for the response ID
    title: title,
    createdByEmail: account?.username ?? 'N/A',
    fieldIdentifier: field?.uuid ?? 'N/A',
    status: filterValue.status ?? null,
    operation: filterValue.operation ?? null,
    wellbore: filterValue.wellbore ?? null,
    zone: filterValue.reservoirZone ?? null,
    createdBy: filterValue.createdBy ?? null,
    approver: filterValue.approver ?? null,
    lastModified: filterValue.lastModifiedFrom ?? null,
  };

  return useMutation({
    mutationKey: [CREATE_FILTER_KEY],
    mutationFn: async () => {
      const response = await FilterService.postApiV1Filter(body);
      body.id = response as unknown as string; // the response the Id as a string
      return body;
    },
    onSuccess: (updatedBody) => {
      showSnackbar('Filter saved');
      queryClient.setQueryData([FILTER_KEY, field?.uuid], (data: Filter[]) => {
        return [...(data || []), updatedBody];
      });
    },
  });
};
