import { FC } from 'react';

import { formatDate, Typography } from '@equinor/amplify-component-lib';

import { StatusIdEnum } from 'src/types/status';
import { getStatusColor } from 'src/utils';

interface ValidityDateProps {
  isValid?: boolean;
  validFromDate: string | null | undefined;
  validToDate: string | null | undefined;
  statusId?: StatusIdEnum;
}

export const ValidityDate: FC<ValidityDateProps> = ({
  isValid,
  validFromDate,
  validToDate,
  statusId,
}) => {
  const isHistorical = statusId === StatusIdEnum.HISTORICAL;

  return (
    <>
      {isHistorical ? (
        <Typography group="table" variant="cell_text">
          {formatDate(validToDate ?? 'N/A', {
            format: 'DD. month YYYY',
            month: 'short',
          })}
        </Typography>
      ) : (
        <Typography
          color={getStatusColor(statusId, isValid)}
          group="table"
          variant="cell_text"
        >
          {formatDate(validFromDate ?? 'N/A', {
            format: 'DD. month YYYY',
            month: 'short',
          })}
          {' - '}
          {formatDate(validToDate ?? 'N/A', {
            format: 'DD. month YYYY',
            month: 'short',
          })}
        </Typography>
      )}
    </>
  );
};
