import { FC, useState } from 'react';

import { focus_center, measure, well } from '@equinor/eds-icons';

import { MapOptionsMenuItem } from 'src/pages/MapView/components/MapSettings/components/MapOptionsMenuItem';
import { SettingsMenu } from 'src/pages/MapView/components/MapSettings/components/SettingsMenu';
import { useMapOptions } from 'src/pages/MapView/hooks';

export enum MapTool {
  DEFAULT = 'default',
  MEASURE_AREA = 'measureArea',
  SELECT_AREA = 'selectArea',
}

export const MapTools: FC = () => {
  const { setMapTool } = useMapOptions();

  const [open, setOpen] = useState(false);
  const handleOnClick = (tool: MapTool) => {
    setMapTool(tool);
    setOpen(false);
  };

  return (
    <SettingsMenu
      open={open}
      setOpen={setOpen}
      title={'Selection tools'}
      icon={focus_center}
    >
      <MapOptionsMenuItem
        text="Select several wells"
        icon={well}
        onClick={() => handleOnClick(MapTool.SELECT_AREA)}
      />
      <MapOptionsMenuItem
        text="Measure area"
        icon={measure}
        onClick={() => handleOnClick(MapTool.MEASURE_AREA)}
      />
    </SettingsMenu>
  );
};
