import { SkeletonBase } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { LinePlotMargin } from 'src/components/Charts/LineChart/LineChart.types';

import styled from 'styled-components';

const { shape } = tokens;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

interface LinePlotSkeletonOuterProps {
  $height: number;
}

export const LinePlotSkeletonOuter = styled.div<LinePlotSkeletonOuterProps>`
  position: relative;
  height: ${({ $height }) => $height};
`;

interface AxesProps {
  $margin: LinePlotMargin;
  $itemWidth: number;
}
export const BottomAxis = styled.div<AxesProps>`
  position: absolute;
  width: ${({ $margin }) => {
    return `calc(100% - ${$margin.left}px - ${$margin.right}px)`;
  }};
  left: ${({ $margin }) => {
    return `${$margin.left}px`;
  }};
  bottom: ${({ $margin }) => {
    return `calc(${$margin.bottom}px - 16px)`;
  }};
  height: 12px;
  display: flex;
  justify-content: space-around;
  > div {
    width: ${({ $itemWidth }) => {
      return `${$itemWidth}px`;
    }};
  }
`;

export const LeftAxis = styled.div<AxesProps>`
  position: absolute;
  width: ${({ $itemWidth }) => {
    return `${$itemWidth}px`;
  }};
  left: ${({ $margin, $itemWidth }) => {
    return `calc(${$margin.left}px - ${$itemWidth + 8}px)`;
  }};
  top: ${({ $margin }) => {
    return `${$margin.top}px`;
  }};
  height: ${({ $margin }) => {
    return `calc(100% - ${$margin.top}px - ${$margin.bottom}px)`;
  }};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const AxisItem = styled(SkeletonBase)`
  width: 100%;
  height: 12px;
  border-radius: ${shape.rounded.borderRadius};
`;

interface SkeletonProps {
  $margin: LinePlotMargin;
}
export const VerticalBrushSkeleton = styled(SkeletonBase)<SkeletonProps>`
  position: absolute;
  border-radius: 4px;
  height: ${({ $margin }) => {
    return `calc(100% - ${$margin.top}px - ${$margin.bottom}px)`;
  }};
  width: 22px;
  top: ${({ $margin }) => {
    return `${$margin.top}px`;
  }};
  left: 0;
`;
