import { useContext } from 'react';

import { EditPrognosisContext } from '../providers/EditPrognosisProvider';

export function useEditPrognosis() {
  const context = useContext(EditPrognosisContext);

  if (context === undefined) {
    throw new Error(
      'useEditPrognosis must be used within a EditPrognosisProvider'
    );
  }

  return context;
}
