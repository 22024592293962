import { PrognosisDetailDto } from 'src/api';
import {
  HeaderDescriptionContent,
  PrognosisDetailColumnData,
} from 'src/types/prognosisDetails';

export const ZONE_AND_SEGMENT_KEYS: (keyof PrognosisDetailDto)[] = [
  'lithostratSubzone',
  'segment',
];
export const PROGNOSIS_DETAILS_SEGMENT_COLUMN: PrognosisDetailColumnData = {
  header: '',
  columnSpan: 1,
  headerDescriptions: [
    { content: HeaderDescriptionContent.SEGMENT_DESCRIPTION, columnSpan: 1 },
  ],
  prognosisDetailKeys: ['lithostratSubzone'],
};

export const PROGNOSIS_DETAILS_TABLE_COLUMNS: PrognosisDetailColumnData[] = [
  {
    header: 'Reference',
    columnSpan: 5,
    headerDescriptions: [
      { content: HeaderDescriptionContent.DEPTH, columnSpan: 1 },
      {
        content: HeaderDescriptionContent.REFERENCE_PRESSURE,
        columnSpan: 4,
      },
    ],
    prognosisDetailKeys: [
      'referenceDepth',
      'pressureInitial',
      'pressureExpected',
      'pressureLow',
      'pressureHigh',
    ],
  },
  {
    header: 'Gradients',
    columnSpan: 2,
    headerDescriptions: [
      { content: HeaderDescriptionContent.GRADIENT_TYPE, columnSpan: 1 },
      {
        content: HeaderDescriptionContent.PRESSURE_VALUE,
        columnSpan: 1,
      },
    ],
    prognosisDetailKeys: ['fluidType', 'pressureGradientDepth'],
  },
  {
    header: 'Datum',
    columnSpan: 5,
    headerDescriptions: [
      { content: HeaderDescriptionContent.DEPTH, columnSpan: 1 },
      {
        content: HeaderDescriptionContent.DATUM_PRESSURE,
        columnSpan: 3,
      },
      {
        content: HeaderDescriptionContent.PRESSURE_VALUE,
        columnSpan: 1,
      },
    ],
    prognosisDetailKeys: [
      'datumDepth',
      'pressureDatumExpected',
      'pressureDatumLow',
      'pressureDatumHigh',
      'pressureGradientToDatumDepth',
    ],
  },
];

export const NUM_GRADIENT_COLUMNS = 2; // Constant for Container's $amount

export const headerTitles = [
  'Title',
  'Submitter',
  'Approver',
  'Status',
  'Operation',
  'Validity',
];

export const EMPTY_DETAIL: Partial<PrognosisDetailDto> = {
  reservoirZoneNickname: '',
  comment: '',
  referenceDepth: undefined,
  pressureInitial: undefined,
  pressureExpected: undefined,
  pressureLow: undefined,
  pressureHigh: undefined,
  fluidType: 'Oil',
  pressureGradientDepth: undefined,
  datumDepth: undefined,
  pressureDatumExpected: undefined,
  pressureDatumLow: undefined,
  pressureDatumHigh: undefined,
  pressureGradientToDatumDepth: undefined,
};
