import { Button, spacings, TextField } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { ICON_PADDING } from 'src/constants/checkboxButton';

import styled from 'styled-components';

const { colors } = tokens;

export const StyledContainer = styled.div`
  display: flex;
`;

interface StyledButtonProps {
  $isSelected?: boolean;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  && {
    background-color: ${({ $isSelected }) =>
      $isSelected && colors.interactive.primary__hover_alt.rgba};
  }
  min-height: 40px; // to match height of TextField
`;

export const SwitchContainer = styled.div`
  display: flex;
  gap: ${spacings.small};
  align-items: center;
  justify-content: right;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacings.small};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
  padding-left: ${ICON_PADDING}px;
`;

export const StyledTextField = styled(TextField)`
  max-width: 230px; // largest width that doesn't cause Icon to scale down
`;
