import { Dispatch, SetStateAction } from 'react';

import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { ChangeFlags, CompositeLayer } from '@deck.gl/core';

import { PolygonFeature, PressureDataSimple } from 'src/api';
import {
  HEATMAP_LAYER_RADIUS_PIXELS,
  RODRIGO_HEATMAP_COLOR_DOMAIN,
  WEIGHTS_TEXTURE_SIZE,
} from 'src/pages/MapView/MapView.constants';
import { DataSet, LegendRange } from 'src/pages/MapView/MapView.types';
import { getDataGridInPolygon } from 'src/pages/MapView/MapView.utils';
import { mapToRange } from 'src/utils';

interface InterpolatedMapLayerState {
  data: DataSet | undefined;
  range: [number, number] | undefined;
  valueDomain: [number, number] | undefined;
  [key: string]: unknown;
}

interface InterpolatedMapLayerProps {
  id: string;
  showHeatmap: boolean;
  colors: DataSet;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setLegendRange: Dispatch<SetStateAction<LegendRange>>;
  pressurePoints?: PressureDataSimple[];
  mainFieldOutline?: PolygonFeature;
}

export class InterpolatedMapLayer extends CompositeLayer<InterpolatedMapLayerProps> {
  state: InterpolatedMapLayerState = {
    data: undefined,
    range: undefined,
    valueDomain: undefined,
  };

  private async getData() {
    this.props.setIsLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 100));
    const { data, min, max } = getDataGridInPolygon(
      this.props.pressurePoints,
      this.props.mainFieldOutline
    );
    if (data.length === 0) return;

    this.props.setIsLoading(false);
    this.props.setLegendRange({ min, max });
    this.setState({ data, valueDomain: [min, max] });
  }

  updateState({ changeFlags }: { changeFlags: ChangeFlags }) {
    if (
      (changeFlags.propsChanged.toString().includes('pressurePoints') ||
        this.state.data === undefined) &&
      this.props.pressurePoints &&
      this.props.pressurePoints.length > 0 &&
      this.props.showHeatmap
    ) {
      this.getData();
    }
  }

  weightAccessor(
    colorDomain: [number, number]
  ): (d: [number, number, number]) => number {
    return (d: [number, number, number]) => {
      const stepSize =
        (colorDomain[1] - colorDomain[0]) / RODRIGO_HEATMAP_COLOR_DOMAIN.length;

      const weight = Math.max(
        Math.floor(d[2] / stepSize) * stepSize,
        colorDomain[0]
      );

      return Math.max(
        0.001,
        mapToRange(
          weight,
          { start: colorDomain[0], end: colorDomain[1] },
          { start: 0, end: 1 }
        )
      );
    };
  }

  // Removed ContourLayer for now, but keeping this a CompositeLayer in case of future needs of additional layers
  renderLayers() {
    return [
      new HeatmapLayer({
        id: `${this.props.id}/heatmap`,
        data: this.state.data ?? [],
        getWeight: this.weightAccessor(this.state.valueDomain ?? [0, 1]),
        getPosition: (d: [number, number, number]) => [d[1], d[0]],
        radiusPixels: HEATMAP_LAYER_RADIUS_PIXELS,
        weightsTextureSize: WEIGHTS_TEXTURE_SIZE,
        colorRange: this.props.colors,
        colorDomain: [0, 1],
        aggregation: 'MEAN',
        pickable: true,
        visible: this.props.showHeatmap,
        updateTriggers: {
          getWeight: [this.state.valueDomain],
          colorDomain: [this.state.valueDomain],
        },
      }),
    ];
  }
}

InterpolatedMapLayer.layerName = 'InterpolatedMapLayer';
InterpolatedMapLayer.defaultProps = {
  ...HeatmapLayer.defaultProps,
};
