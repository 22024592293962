import { SelectOptionRequired } from '@equinor/amplify-component-lib';

import { capitalize } from 'lodash';

/**
 * Converts a given option string to a SelectOptionRequired object.
 * If the option is undefined, it returns a default object with label 'N/A' and value 'n/a'.
 * Optionally formats the label based on the formatLabels parameter.
 *
 * @param option - The option string to be converted. Can be undefined.
 * @param formatLabels - A boolean indicating whether to format the label. Defaults to false.
 * @returns An object of type SelectOptionRequired with the formatted label and original value.
 */
export function lookupValueToComboBoxRequired(
  option: string | undefined | null,
  formatLabels = false
): SelectOptionRequired {
  if (!option) {
    return {
      label: 'N/A',
      value: 'n/a',
    };
  }

  let haveAtSign = false;
  if (typeof option === 'string') {
    haveAtSign = option.includes('@');
  }

  return {
    label:
      formatLabels && !haveAtSign
        ? capitalize(option).replaceAll('_', ' ')
        : formatLabels
          ? option.toUpperCase()
          : option,
    value: option,
  };
}

/**
 * Converts an array of lookup values to an array of SelectOptionRequired objects.
 *
 * @param lookupValues - An array of lookup values to be converted. If undefined, an empty array is returned.
 * @param formatLabels - A boolean indicating whether to format the labels of the SelectOptionRequired objects. Defaults to false.
 * @returns An array of SelectOptionRequired objects.
 */
export function lookupValuesToComboBoxRequired(
  lookupValues: string[] | undefined,
  formatLabels = false
): SelectOptionRequired[] {
  if (!lookupValues) {
    return [];
  }
  return lookupValues.map((value) =>
    lookupValueToComboBoxRequired(value, formatLabels)
  );
}
