import { useHelplines } from '../../ManageHelpLines';
import { FullWidthCheckBox } from './SelectData.styles';
import { DataType } from 'src/api';
import { MeasurementType } from 'src/api';
import { SourceTypes } from 'src/api';
import { PressureLabels } from 'src/constants';
import { WellborePurpose } from 'src/types';

export const Flow = () => {
  const {
    selectedWellbore,
    selectedHelpLines,
    addHelpLine,
    removeHelpLine,
    source,
  } = useHelplines();

  const isChecked = (dataType: DataType) => {
    return selectedHelpLines.some(
      (hl) =>
        hl.id === selectedWellbore?.wellboreUuid &&
        hl.type === SourceTypes.WELLBORE &&
        hl.dataTypes?.includes(dataType)
    );
  };

  const handleClick = (dataType: DataType) => {
    const measureType =
      dataType === DataType.ON_STREAM_HOURS
        ? MeasurementType.DHPG1
        : MeasurementType.FLOW;
    if (isChecked(dataType)) {
      removeHelpLine({
        id: selectedWellbore?.wellboreUuid,
        type: SourceTypes.WELLBORE,
        dataTypes: [dataType],
        formations: [],
        source: source,
        measureType: measureType,
        name: selectedWellbore?.wellName ?? '',
      });
    } else {
      addHelpLine({
        id: selectedWellbore?.wellboreUuid,
        type: SourceTypes.WELLBORE,
        dataTypes: [dataType],
        formations: [],
        source: source,
        measureType: measureType,
        name: selectedWellbore?.wellName ?? '',
      });
    }
  };

  return (
    <>
      <FullWidthCheckBox
        label={
          selectedWellbore?.purpose === WellborePurpose.INJECTION
            ? PressureLabels[DataType.INSTANT_INJECTION]
            : PressureLabels[DataType.INSTANT_PRODUCTION]
        }
        checked={isChecked(
          selectedWellbore?.purpose === WellborePurpose.INJECTION
            ? DataType.INSTANT_INJECTION
            : DataType.INSTANT_PRODUCTION
        )}
        onChange={() =>
          handleClick(
            selectedWellbore?.purpose === WellborePurpose.INJECTION
              ? DataType.INSTANT_INJECTION
              : DataType.INSTANT_PRODUCTION
          )
        }
      />
      <FullWidthCheckBox
        label={
          selectedWellbore?.purpose === WellborePurpose.INJECTION
            ? PressureLabels[DataType.CUMULATIVE_INJECTION]
            : PressureLabels[DataType.CUMULATIVE_PRODUCTION]
        }
        checked={isChecked(
          selectedWellbore?.purpose === WellborePurpose.INJECTION
            ? DataType.CUMULATIVE_INJECTION
            : DataType.CUMULATIVE_PRODUCTION
        )}
        onChange={() =>
          handleClick(
            selectedWellbore?.purpose === WellborePurpose.INJECTION
              ? DataType.CUMULATIVE_INJECTION
              : DataType.CUMULATIVE_PRODUCTION
          )
        }
      />
      <FullWidthCheckBox
        label="On stream hours"
        checked={isChecked(DataType.ON_STREAM_HOURS)}
        onChange={() => handleClick(DataType.ON_STREAM_HOURS)}
      />
      <FullWidthCheckBox
        label="Productivity index"
        checked={isChecked(DataType.PRODUCTIVITY_INDEX)}
        onChange={() => handleClick(DataType.PRODUCTIVITY_INDEX)}
      />
    </>
  );
};
