import { colors, Icon, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

interface StyledDatepickerProps {
  disabled?: boolean;
}
export const StyledDatePicker = styled.input<StyledDatepickerProps>`
  height: 36px;
  padding: 4px 6px;
  border: none;
  background-color: ${colors.ui.background__light.rgba};
  border-bottom: 1px solid ${colors.text.static_icons__tertiary.rgba};
  color: ${(props) =>
    props.disabled
      ? colors.ui.background__light.rgba
      : colors.text.static_icons__default.rgba};
  font-weight: 400;
  font-size: 16px;
  font-family: 'Equinor', sans-serif;
  min-width: 150px;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  &:focus {
    outline: none;
    border-bottom: 1px solid transparent;
    padding-left: 6px;
    box-shadow: inset 0 0 0 2px #007079;
  }
`;

export const DisabledText = styled.div`
  position: absolute;
  top: 22px;
  left: ${spacings.small};
  font-weight: 400;
  z-index: 500;
  color: ${colors.text.static_icons__secondary.rgba};
`;

export const StyledCalendarIcon = styled(Icon)<StyledDatepickerProps>`
  position: absolute;
  right: 6px;
  bottom: 6px;
  height: 24px;
  width: 24px;
  color: ${(props) =>
    props.disabled
      ? colors.ui.background__medium.rgba
      : colors.text.static_icons__default.rgba};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledDateWrapper = styled.div`
  position: relative;
  width: 100%;

  div {
    background-color: ${colors.ui.background__light.rgba};
  }
`;
