import {
  spacings,
  Typography,
  TypographyProps,
} from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { styled } from 'styled-components';

const { shape, colors, elevation } = tokens;

interface CardWrapperProps {
  $statusColor?: string;
}

export const CardWrapper = styled.div<CardWrapperProps>`
  display: grid;
  grid-template-columns: minmax(0, 3fr) 2fr repeat(3, 1fr) 50px;
  background-color: ${colors.ui.background__default.rgba};
  border-radius: ${shape.corners.borderRadius};
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  box-shadow: ${elevation.raised};
  transition: box-shadow 400ms;
  &:not([role='progressbar']) {
    cursor: pointer;
    &:hover {
      box-shadow: ${elevation.sticky};
    }
  }
  border-left: 4px solid ${({ $statusColor }) => $statusColor};
`;

interface CardContentProps {
  $approvedTab?: boolean;
}
export const CardContent = styled.div<CardContentProps>`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / span 6;
  align-items: center;
  width: 100%;
  gap: ${spacings.large};
  padding: ${spacings.medium};
  ${({ $approvedTab }) =>
    $approvedTab &&
    `
    &:hover {
      background-color: ${colors.interactive.primary__hover_alt.rgba};
    }
  `}
`;

export const SubCardContent = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / span 6;
  align-items: center;
  width: 100%;
  gap: ${spacings.large};
  background-color: ${colors.ui.background__light.rgba};
  padding: 0 ${spacings.medium};
  height: 64px;
  border-top: 1px solid ${colors.ui.background__medium.rgba};
  &:hover {
    background-color: ${colors.interactive.primary__hover_alt.rgba};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacings.small};
  align-items: center;
`;

export const ValueContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: ${spacings.large};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

export const StyledTypography = styled(Typography)<TypographyProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
