import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@equinor/amplify-component-lib';
import { chevron_right } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';
import { useQueryClient } from '@tanstack/react-query';

import { IconChip } from './IconChip/IconChip';
import { RelatedPrognosis } from './RelatedPrognosis/RelatedPrognosis';
import { ValidityDate } from './ValidityDate/ValidityDate';
import {
  CardContent,
  CardWrapper,
  StyledTypography,
  TitleContainer,
  TitleRow,
} from './PrognosisCard.styles';
import { PrognosisValues } from './PrognosisValues';
import {
  PrognosisDetailService,
  PrognosisDto,
  PrognosisService,
} from 'src/api';
import { GET_IMAGE, PROGNOSIS_DETAIL, PROGNOSIS_KEY } from 'src/constants';
import { TabType } from 'src/constants/tab';
import { useGetBlob } from 'src/pages/EditPrognosis/hooks/useGetBlob';
import { StatusIdEnum } from 'src/types/status';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';
import { OperationValues, selectStrokeColor } from 'src/utils/prognosis';

const { colors } = tokens;

interface PrognosisCardProps extends PrognosisDto {
  currentTab: TabType;
  relatedPrognoses?: PrognosisDto[];
}

export const PrognosisCard: FC<PrognosisCardProps> = ({
  currentTab,
  approveUser,
  createUser,
  id,
  operation,
  operationId,
  wellPlanningPhase,
  statusId,
  relatedPrognoses,
  title,
  wellboreIdentifier,
  validFromDate,
  validToDate,
  comment,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { insertImages } = useGetBlob();

  const strokeColor = selectStrokeColor(statusId, validFromDate ?? '');

  const formattedOperation = lookupValueToComboBoxRequired(
    operation,
    true
  ).label;

  const isValid: boolean | undefined =
    validFromDate &&
    validToDate &&
    statusId === Number(StatusIdEnum.APPROVED) &&
    new Date().getTime() >= new Date(validFromDate).getTime() &&
    new Date().getTime() <= new Date(validToDate).getTime()
      ? true
      : undefined;

  const handleOnClick = () => {
    if (id != undefined) {
      const path =
        statusId === Number(StatusIdEnum.DRAFT)
          ? `/dashboard/edit-prognosis/${id}`
          : `/detailed-view/${id}`;
      navigate(path);
    }
  };

  const prefetch = () => {
    // prefetch data

    if (id == undefined) return;
    queryClient.prefetchQuery({
      queryKey: [PROGNOSIS_KEY, id.toString()],
      queryFn: () => PrognosisService.getApiV1Prognoses1(id),
      // Prefetch only fires when data is older than the staleTime,
      // so in a case like this you definitely want to set one
      staleTime: 60000,
    });

    // prefetch details
    queryClient.prefetchQuery({
      queryKey: [PROGNOSIS_DETAIL, id.toString()],
      queryFn: () => PrognosisDetailService.getApiV1PrognosesDetails1(id),
      staleTime: 60000,
    });

    // prefetch blob
    queryClient.prefetchQuery({
      queryKey: [GET_IMAGE, id.toString()],
      queryFn: () => insertImages(comment ?? '', id),
      staleTime: 60000,
    });
  };

  return (
    <CardWrapper $statusColor={strokeColor}>
      <CardContent
        onClick={handleOnClick}
        $approvedTab={currentTab === TabType.Approved}
        onMouseEnter={prefetch}
      >
        <TitleContainer>
          <StyledTypography variant="h4">
            {wellboreIdentifier ?? 'N/A'}
          </StyledTypography>
          <TitleRow>
            <IconChip statusId={statusId} from_date={validFromDate} />
            <StyledTypography variant="body_long_bold">
              {title ?? 'N/A'}
            </StyledTypography>
          </TitleRow>
        </TitleContainer>
        <ValidityDate
          isValid={isValid}
          statusId={statusId}
          validFromDate={validFromDate}
          validToDate={validToDate}
        />
        <div>
          <PrognosisValues label={formattedOperation} />
          {operationId?.toString() === OperationValues.NEW_WELL.toString() && (
            <>
              <br />
              <PrognosisValues label={'/ ' + wellPlanningPhase} />
            </>
          )}
        </div>

        <PrognosisValues label={createUser} isShortName />
        <PrognosisValues label={approveUser} isShortName />
        <Icon
          data={chevron_right}
          color={colors.interactive.primary__resting.rgba}
        />
      </CardContent>
      {currentTab === TabType.Approved &&
        relatedPrognoses?.map((relatedPrognoses) => (
          <RelatedPrognosis
            key={relatedPrognoses.id}
            currentTab={currentTab}
            {...relatedPrognoses}
          />
        ))}
    </CardWrapper>
  );
};
