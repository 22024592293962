import { useNavigate } from 'react-router';

import { useAuth, useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePostDetail } from './usePostDetail';
import { PrognosisCreateDto, PrognosisService } from 'src/api';
import { COPY_PROGNOSIS, DRAFT_PROGNOSIS_KEY } from 'src/constants';
import { StatusIdEnum } from 'src/types';

import { usePrognosisDetailsFromId } from 'hooks/usePrognosisDetailsFromId';
import { usePrognosisFromId } from 'hooks/usePrognosisFromId';

export const useCopyPrognosis = () => {
  const { data: prognosisDetailsData } = usePrognosisDetailsFromId();
  const { data: prognosisData } = usePrognosisFromId();
  const { account } = useAuth();
  const username = account?.username;
  const createUserShortName = username ? username.split('@')[0] : '';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: postDetail } = usePostDetail();

  const { showSnackbar } = useSnackbar();

  const body: PrognosisCreateDto = {
    ...prognosisData,
    statusId: StatusIdEnum.DRAFT,
    createUser: createUserShortName,
    title: `${prognosisData?.title} - Copy`,
  };

  return useMutation({
    mutationKey: [COPY_PROGNOSIS],
    mutationFn: async () => {
      if (!prognosisDetailsData || !prognosisData) {
        throw new Error('Prognosis data is not available');
      }

      const newId = await PrognosisService.postApiV1Prognoses({
        ...body,
      });

      await Promise.all(
        prognosisDetailsData.map((detail) =>
          postDetail({
            detail: { ...detail, prognosisId: newId },
            newPrognosisId: newId,
          })
        )
      );

      await queryClient.setQueryData(
        [DRAFT_PROGNOSIS_KEY, newId.toString()],
        body
      );

      return newId;
    },
    onSuccess: (id) => {
      navigate(`/dashboard/edit-prognosis/${id}`);
    },
    onError: (err) => {
      showSnackbar('Failed to copy prognosis' + err.message);
    },
  });
};
