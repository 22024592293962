import { Card, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { elevation } = tokens;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
  box-shadow: ${elevation.raised};
  padding: ${spacings.large};
  max-width: 762px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
`;
