import { Map } from './components/Map/Map';
import { MapOptionsProvider } from './providers/MapOptionsProvider';
import { PressurePointsProvider } from './providers/PressurePointsProvider';

export const MapView = () => {
  return (
    <PressurePointsProvider>
      <MapOptionsProvider>
        <Map />
      </MapOptionsProvider>
    </PressurePointsProvider>
  );
};
