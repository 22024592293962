export const TOP_BAR_HEIGHT = '65px';
export const SIDE_BAR_WIDTH = '73px';
export const SIDE_BAR_WIDTH_OPEN = '255px';

/*Map view variables */
export const MAP_FOOTER_HEIGHT = '117px';

/*Line chart variables*/
export const LINE_CHART_BRUSH_HEIGHT = '3rem';
export const LINE_CHART_HEIGHT = '30rem';
export const LINE_CHART_BOTTOM_PADDING = '2rem';
export const LINE_PLOT_FOOTER_HEIGHT = '24px';

/*Edit mode variables*/
export const FILTER_PANEL_WIDTH = '300px';
export const ACTIONS_AND_TITLE_HEIGHT = '40px';
export const TOOLBAR_FOOTER_HEIGHT = '65px';
