import { FC } from 'react';

import { colors } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const CountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  background: ${colors.interactive.primary__resting.rgba};
  color: ${colors.text.static_icons__primary_white.rgba};
  font-size: 12px;
  border-radius: 50%;
`;

interface CountProps {
  value: number;
}

export const Count: FC<CountProps> = ({ value }) => {
  return <CountWrapper>{value}</CountWrapper>;
};
