import { FC, useMemo } from 'react';

import { ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale';

import { DataType } from 'src/api';
import {
  LineChartLine,
  ManualEditData,
} from 'src/components/Charts/LineChart/LineChart.types';

import { isNaN } from 'lodash';

interface ManualEditCirclesProps {
  lineChartLines: LineChartLine[];
  valueScale: ScaleLinear<number, number, never>;
  dateScale: ScaleTime<number, number, never>;
}

export const DataPointCircle: FC<ManualEditCirclesProps> = ({
  lineChartLines,
  valueScale,
  dateScale,
}) => {
  const dataPoints: ManualEditData[] = useMemo(() => {
    return lineChartLines.flatMap(
      (line) =>
        line.data
          .map((dataPoint) => {
            const point = {
              value: dataPoint.value,
              date: dataPoint.date,
              color: line.color,
            };
            if (
              dataPoint.isManual ||
              line.helplineDataType === DataType.PRODUCTIVITY_INDEX
            ) {
              return point;
            }
            return undefined;
          })
          .filter((data) => data !== undefined) as ManualEditData[]
    );
  }, [lineChartLines]);

  return (
    <g>
      {dataPoints.map((point) => {
        const value = valueScale(point.value);

        if (isNaN(value) || !value) return null;
        return (
          <circle
            key={point.value + point.date.toDateString()}
            cx={dateScale(point.date.setHours(0, 0, 0, 0))} // Set hours to 00:00 to make circle appear exactly on start of date in chart
            cy={valueScale(point.value)}
            r={3}
            fill={point.color}
            strokeWidth={2}
            pointerEvents="none"
          />
        );
      })}
    </g>
  );
};
