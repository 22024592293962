import { CSSProperties, FC, useMemo } from 'react';

import { colors, Typography } from '@equinor/amplify-component-lib';
import { useQuery } from '@tanstack/react-query';

import { Area } from 'src/api';
import { FieldService } from 'src/api';
import { AREA_FEATURES_QUERY_KEY, MEASURED_DATA_MAP_KEY } from 'src/constants';
import { useApp } from 'src/hooks/useApp';
import { useMapInner } from 'src/pages/MapView/components/Map/hooks/useMapInner';

import styled from 'styled-components';

type AreaType = Area & {
  position: CSSProperties;
};

export const AreaName = styled(Typography)`
  position: absolute;
  background: none;
  border: none;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color: ${colors.text.static_icons__secondary.rgba};
  text-transform: capitalize;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const AreaNames: FC = () => {
  const { field } = useApp();
  const { viewport } = useMapInner();
  const typedViewport = viewport;

  const { data: areas } = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, AREA_FEATURES_QUERY_KEY, field?.uuid],
    queryFn: () => FieldService.getAreas(field?.uuid ?? ''),
    staleTime: Infinity,
  });

  const areasWithPosition: AreaType[] = useMemo(() => {
    if (!areas) return [];
    return areas.map((d) => {
      const [lng, lat] = d.center?.coordinates ?? [0, 0];
      const [left, top] = typedViewport.project([lng, lat]);
      return {
        ...d,
        position: {
          top: top,
          left: left,
        },
      } as AreaType;
    });
  }, [areas, typedViewport]);

  return (
    <>
      {areasWithPosition.map((area) => (
        <AreaName
          key={`area-${area.id}`}
          variant="body_short_bold"
          style={area.position}
        >
          {area.name?.toLowerCase()}
        </AreaName>
      ))}
    </>
  );
};
