import { FC, MouseEvent, ReactNode, useRef } from 'react';

import { MeasuredDataFilter } from 'src/api';
import { CollapsedFilterItem } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/CollapsedFilterItem';
import {
  selectingClickHandler,
  toggleSingleSelectingFilter,
} from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/utils';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { FilterSelect } from 'src/pages/EditMeasuredData/providers/EditMeasuredDataProvider';

import styled from 'styled-components';

interface WrapperProps {
  $selected: boolean;
  $dragging: boolean;
  $disabled: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  height: 64px;
  padding: 0 16px;
  flex-shrink: 0;
  background-color: ${({ $selected }) => $selected && '#E6FAEC'};
  background-color: ${({ $dragging }) => $dragging && '#ffffff'};
  opacity: ${({ $disabled }) => $disabled && 0.5};
`;

interface HistoryItemProps {
  children: ReactNode;
  filter: MeasuredDataFilter;
  selected?: boolean;
  dragging?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export const FilterItemWrapper: FC<HistoryItemProps> = ({
  children,
  selected,
  filter,
  dragging,
  onClick,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { filterPanelOpen, selectedFilters, setSelectedFilters } =
    useEditMeasuredData();

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    let newSelectedFilters: FilterSelect[] = [];
    if (e.metaKey || e.ctrlKey) {
      newSelectedFilters = selectingClickHandler(
        e,
        filter,
        filtersForWell,
        selectedFilters
      );
    } else if (onClick !== undefined) {
      onClick(e);
    } else {
      newSelectedFilters = toggleSingleSelectingFilter(filter, selectedFilters);
    }
    setSelectedFilters(newSelectedFilters);
  };

  if (!filterPanelOpen) {
    return <CollapsedFilterItem filter={filter} />;
  }

  return (
    <Wrapper
      ref={scrollRef}
      onClick={handleClick}
      $selected={selected ?? false}
      $dragging={dragging ?? false}
      $disabled={filter.disabled ?? false}
    >
      {children}
    </Wrapper>
  );
};
