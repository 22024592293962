import { FC, useState } from 'react';

import { TextField, Typography } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { MainDialogContent } from '../Actions.styles';
import { usePrognosisApproval } from 'src/pages/Dashboard/hooks/usePrognosisApproval';

interface ApproveDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const ApproveDialog: FC<ApproveDialogProps> = ({
  open,
  title,
  onClose,
}) => {
  const [optionalComment, setOptionalComment] = useState<string>('');
  const { approvePrognosis } = usePrognosisApproval();
  const { mutateAsync: approveMutate, isPending: isApprovePending } =
    approvePrognosis;

  const handleOnSubmit = async () => {
    if (isApprovePending) return;
    await approveMutate(optionalComment);
    onClose();
  };

  const handleOnTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionalComment(e.target.value);
  };

  if (!open) return null;

  return (
    <Dialog
      title={`Approve prognosis - ${title}`}
      open={open}
      onClose={onClose}
      width={500}
      withContentPadding
      actions={[
        {
          position: 'right',
          onClick: onClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleOnSubmit,
          text: 'Approve',
          color: 'primary',
          disabled: isApprovePending,
          isLoading: isApprovePending,
        },
      ]}
    >
      <MainDialogContent>
        <TextField
          id="approve"
          label="Add comment"
          value={optionalComment}
          onChange={handleOnTextFieldChange}
          multiline
          rows={3}
          placeholder="Add a comment based on the decision taken"
        />
        <Typography variant="body_short">
          Approving this prognosis will make it public to all viewers and
          supersede the current approved prognosis on this well/wellbore.
          <strong> This action cannot be undone.</strong>
        </Typography>
      </MainDialogContent>
    </Dialog>
  );
};
