import { FC } from 'react';

import {
  ComboBox,
  SelectOption,
  Typography,
} from '@equinor/amplify-component-lib';

import { SelectOptionRequiredWithColor } from '../ZoneSelector';
import { ComboSelectContainer, Container } from './ZoneNavigator.styles';
import { PrognosisDetailDto, StratigraphicUnit, Stratigraphy } from 'src/api';

interface ZoneNavigatorProps {
  column: string;
  data: Stratigraphy[];
  handleAddPrognosisDetails: (
    subzones: SelectOption<SelectOptionRequiredWithColor>[]
  ) => void;
  values: Partial<PrognosisDetailDto>[];
}

interface MappedStratigraphicUnit extends StratigraphicUnit {
  label: string;
  value: string;
  children?: MappedStratigraphicUnit[];
}

export const ZoneNavigator: FC<ZoneNavigatorProps> = ({
  column,
  data,
  handleAddPrognosisDetails,
  values,
}) => {
  const { stratUnits } =
    data?.find(
      ({ stratColumnIdentifier }) => stratColumnIdentifier === column
    ) ?? {};

  const mapStratigraphicUnit = (
    unit: StratigraphicUnit
  ): MappedStratigraphicUnit => {
    const children = unit.stratigraphicUnits?.map(mapStratigraphicUnit) || [];
    return {
      label: unit.stratUnitIdentifier || '',
      value: unit.stratUnitIdentifier || '',
      ...unit,
      ...(children.length > 0 && { children }),
    };
  };

  const processIntermediateUnits = () => {
    return stratUnits?.map(mapStratigraphicUnit) ?? [];
  };

  const remapValues = values.map((value) => ({
    label: value.lithostratSubzone || '',
    value: value.lithostratSubzone || '',
    ...value,
  }));

  return (
    <Container>
      {stratUnits && stratUnits.length > 0 ? (
        <ComboSelectContainer>
          <ComboBox
            values={
              remapValues as SelectOption<SelectOptionRequiredWithColor>[]
            }
            onSelect={handleAddPrognosisDetails}
            placeholder="Select subzone"
            clearable
            syncParentChildSelection={false}
            id={`subzone-input`}
            items={
              processIntermediateUnits() as SelectOption<SelectOptionRequiredWithColor>[]
            }
          />
        </ComboSelectContainer>
      ) : (
        <Typography>
          No stratigraphic units found for column <b>{column}</b>
        </Typography>
      )}
    </Container>
  );
};
