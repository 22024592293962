import { Field } from '@equinor/amplify-component-lib';

import { MeasuredDataFilter } from 'src/api';
import { FilterCondition } from 'src/api';
import { FilterDataTypes } from 'src/api';
import { FilterType } from 'src/api';
import { createTemporaryId } from 'src/utils';

interface CreateEmptyRuleProps {
  field: Field | undefined;
  wellId?: string | undefined;
  dhpg?: string | undefined;
  fromDate: Date;
  toDate: Date;
}

export const createEmptyRule = ({
  field,
  wellId,
  dhpg,
  fromDate,
  toDate,
}: CreateEmptyRuleProps) => {
  const emptyRule: MeasuredDataFilter = {
    id: createTemporaryId(),
    type: FilterType.RULE,
    fieldGuid: field?.uuid ?? '',
    wellId: wellId,
    disabled: false,
    changes: [],
    filterOrder: 0,
    dhpg: parseInt(dhpg ?? '1'),
    rule: {
      ruleId: createTemporaryId(),
      fromDate: new Date(fromDate).toISOString(),
      toDate: new Date(toDate).toISOString(),
      criteria: [
        {
          criteriaId: createTemporaryId(),
          dataType: FilterDataTypes.PRESSURE,
          condition: FilterCondition.EQUAL,
          criteria: 0,
          filterCriteriaOrder: 0,
        },
      ],
    },
  };

  return emptyRule;
};
