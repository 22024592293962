import { FC } from 'react';

import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useGetFiltersGlobal } from 'src/pages/EditMeasuredData/hooks/useGetFiltersGlobal';

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: ${spacings.xxx_large};
  flex-shrink: 0;
`;

interface TabProps {
  $active: boolean;
}

const Tab = styled.button<TabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms;
  &:hover {
    background-color: ${colors.interactive.primary__hover_alt.rgba};
  }
  color: ${({ $active }) =>
    $active
      ? colors.interactive.primary__resting.rgba
      : colors.text.static_icons__tertiary.rgba};
  border-bottom: 2px solid
    ${({ $active }) =>
      $active
        ? colors.interactive.primary__resting.rgba
        : colors.ui.background__medium.rgba};
`;

export enum GlobalFilterTab {
  THIS_WELL = 'this-well',
  GLOBAL = 'global',
}

interface GlobalFilterTabsProps {
  activeTab: GlobalFilterTab;
  setActiveTab: (activeTab: GlobalFilterTab) => void;
}

export const GlobalFilterTabs: FC<GlobalFilterTabsProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const { filterPanelOpen } = useEditMeasuredData();
  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { data: globalFilters = [] } = useGetFiltersGlobal();
  const handleThisWellClick = () => {
    setActiveTab(GlobalFilterTab.THIS_WELL);
  };

  const handleGlobalOnCLick = () => {
    setActiveTab(GlobalFilterTab.GLOBAL);
  };

  if (!filterPanelOpen || globalFilters.length === 0) return null;

  return (
    <Wrapper>
      <Tab
        $active={activeTab === GlobalFilterTab.THIS_WELL}
        onClick={handleThisWellClick}
      >
        <Typography group="navigation" variant="menu_tabs">
          For this well ({filtersForWell.length})
        </Typography>
      </Tab>
      <Tab
        $active={activeTab === GlobalFilterTab.GLOBAL}
        onClick={handleGlobalOnCLick}
      >
        <Typography group="navigation" variant="menu_tabs">
          Global ({globalFilters.length})
        </Typography>
      </Tab>
    </Wrapper>
  );
};
