/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeasuredDataFilter } from '../models/MeasuredDataFilter';
import type { StringGuidKeyValuePair } from '../models/StringGuidKeyValuePair';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MeasuredDataFilterService {
  /**
   * Saves a measuredDataFilter
   * @param requestBody
   * @returns MeasuredDataFilter OK
   * @throws ApiError
   */
  public static saveFilter(
    requestBody?: MeasuredDataFilter
  ): CancelablePromise<MeasuredDataFilter> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/MeasuredDataFilter',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Updates a measuredDataFilter
   * @param requestBody
   * @returns MeasuredDataFilter OK
   * @throws ApiError
   */
  public static updateFilter(
    requestBody?: MeasuredDataFilter
  ): CancelablePromise<MeasuredDataFilter> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/MeasuredDataFilter',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * @param wellId
   * @param dhpg
   * @returns MeasuredDataFilter OK
   * @throws ApiError
   */
  public static getFilters(
    wellId?: string,
    dhpg?: number
  ): CancelablePromise<Array<MeasuredDataFilter>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/MeasuredDataFilter',
      query: {
        wellId: wellId,
        dhpg: dhpg,
      },
    });
  }
  /**
   * Bulk updates filters
   * @param requestBody
   * @returns MeasuredDataFilter OK
   * @throws ApiError
   */
  public static bulkUpdateFilter(
    requestBody?: Array<MeasuredDataFilter>
  ): CancelablePromise<Array<MeasuredDataFilter>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/MeasuredDataFilter/bulkupdate',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Gets a filter by id
   * @param id
   * @returns MeasuredDataFilter OK
   * @throws ApiError
   */
  public static getFilterById(
    id: string
  ): CancelablePromise<MeasuredDataFilter> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/MeasuredDataFilter/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * Deletes a filter by id
   * @param id
   * @returns StringGuidKeyValuePair OK
   * @throws ApiError
   */
  public static deletefilterbyId(
    id: string
  ): CancelablePromise<StringGuidKeyValuePair> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/MeasuredDataFilter/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * Bulk Deletes  filters by id
   * @param requestBody
   * @returns StringGuidKeyValuePair OK
   * @throws ApiError
   */
  public static bulkDeletefilterbyId(
    requestBody?: Array<string>
  ): CancelablePromise<Array<StringGuidKeyValuePair>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/MeasuredDataFilter/bulkDelete',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
