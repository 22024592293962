import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Position } from '@turf/helpers';

import { PressureDataSimple } from 'src/api';
import { PressureService } from 'src/api';
import {
  MEASURED_DATA_MAP_KEY,
  PRES_DATA_BY_FIELD_FLAT_KEY,
} from 'src/constants';
import { useApp } from 'src/hooks';
import { getNearestPoint } from 'src/pages/MapView/MapView.utils';
import { getDateForDaysIntoPast } from 'src/utils';

export interface PressurePointsState {
  pressurePoints: PressureDataSimple[];
  pressurePointsWithValue: PressureDataSimple[];
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  nearestNeighbor: Map<string, Position>;
  loading: boolean;
}

export const PressurePointsStateContext = createContext<PressurePointsState>(
  {} as PressurePointsState
);

export const PressurePointsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { field } = useApp();
  const [selectedDate, setSelectedDate] = useState<Date>(
    getDateForDaysIntoPast(1)
  );
  const [nearestPointMap, setNearestPointMap] = useState<Map<string, Position>>(
    new Map<string, Position>()
  );

  const { data: pressurePoints = [], isLoading } = useQuery({
    queryKey: [
      MEASURED_DATA_MAP_KEY,
      PRES_DATA_BY_FIELD_FLAT_KEY,
      field?.uuid,
      selectedDate,
    ],
    queryFn: () =>
      PressureService.getpressureDataByFieldFlat(
        field?.uuid ?? '',
        formatDate(selectedDate, { format: 'YYYY-MM-DD' })
      ),
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: 1000 * 60 * 60 * 24,
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (nearestPointMap.size < pressurePoints.length) {
      const tempMap = new Map<string, Position>();
      pressurePoints.forEach((item) => {
        const nearest = getNearestPoint(item, pressurePoints);
        tempMap.set(`${item.wellboreUuid}-${item.dhpg}`, nearest);
      });

      setNearestPointMap(tempMap);
    }
  }, [nearestPointMap.size, pressurePoints, selectedDate]);

  const pressurePointsWithValue = useMemo(
    () => pressurePoints.filter((point) => point.pressureFinal > 0),
    [pressurePoints]
  );

  return (
    <PressurePointsStateContext.Provider
      value={{
        pressurePoints,
        pressurePointsWithValue,
        selectedDate,
        setSelectedDate,
        loading: isLoading,
        nearestNeighbor: nearestPointMap,
      }}
    >
      {children}
    </PressurePointsStateContext.Provider>
  );
};
