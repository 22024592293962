import { FC } from 'react';

import { MapMarker } from 'src/pages/MapView/components/MapElements/MapMarker/MapMarker';
import { MapMarkersForArea } from 'src/pages/MapView/components/MapElements/MapMarkersForArea/MapMarkersForArea';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MAP_ZOOM_THRESHOLD_FOR_GROUPING_POINTS } from 'src/pages/MapView/MapView.constants';

export const MapElements: FC = () => {
  const { viewState, filteredPressurePoints } = useMapOptions();
  if (viewState.zoom < MAP_ZOOM_THRESHOLD_FOR_GROUPING_POINTS) {
    return <MapMarkersForArea />;
  }
  return filteredPressurePoints.map((pressurePoint) => {
    return (
      <MapMarker
        key={`${pressurePoint.wellboreUuid}//${pressurePoint.dhpg}`}
        {...pressurePoint}
      />
    );
  });
};
