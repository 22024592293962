import { FC, useState } from 'react';

import { Button, Icon } from '@equinor/amplify-component-lib';
import { delete_forever, view_list } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

const { colors } = tokens;

import {
  Container,
  StyledTextField,
  Wrapper,
} from '../FilterViewEditDialog.styles';
import { Filter } from 'src/api';

interface EditViewProps {
  item: Filter;
  isDisabled: boolean;
  handleOnDelete: (itemId: string) => void;
  handleOnPatch: (id: string, value: string) => void;
}

export const EditView: FC<EditViewProps> = ({
  item,
  isDisabled,
  handleOnDelete,
  handleOnPatch,
}) => {
  const [value, setValue] = useState<string>(item.title);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (item.id != undefined) {
      handleOnPatch(item.id, e.target.value);
    } else {
      console.error('Item id is undefined');
    }
  };

  const handleOnClick = () => {
    if (item.id != undefined) {
      handleOnDelete(item.id);
    } else {
      console.error('Item id is undefined');
    }
  };

  return (
    <Wrapper>
      <Container>
        <Icon
          data={view_list}
          color={
            isDisabled
              ? colors.interactive.disabled__fill.rgba
              : colors.interactive.primary__resting.rgba
          }
        />
        <StyledTextField
          id={item.id ?? 'N/A'}
          value={value}
          disabled={isDisabled}
          onChange={handleOnChange}
        />
      </Container>
      <Button
        aria-label="delete"
        variant="ghost_icon"
        onClick={handleOnClick}
        disabled={isDisabled}
      >
        <Icon
          data={delete_forever}
          color={
            isDisabled
              ? colors.interactive.disabled__fill.rgba
              : colors.interactive.danger__resting.rgba
          }
        />
      </Button>
    </Wrapper>
  );
};
