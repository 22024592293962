import { FC, MouseEvent, TouchEvent, useCallback, useState } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { localPoint } from '@visx/event';
import { Bar, Line } from '@visx/shape';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale';

import {
  LineChartLine,
  LinePlotMargin,
  TooltipData,
  WellboreTooltip,
} from '../LineChart.types';
import { DraggableDataPoint } from './DraggableDataPoint';
import { RightAxisEnum } from 'src/types';

interface LineChartToolTipLineProps
  extends Partial<WithTooltipProvidedProps<TooltipData>> {
  wellbores: LineChartLine[];
  MARGIN: LinePlotMargin;
  yMax: number;
  xMax: number;
  width: number;
  height: number;
  valueScale: ScaleLinear<number, number, never>;
  axisRightScale: ScaleLinear<number, number, never>;
  dateScale: ScaleTime<number, number, never>;
  bottomAxisNumTicks: number;
  isDragActive?: boolean;
  helplines?: LineChartLine[];
  selectedRightAxis?: RightAxisEnum;
  backgroundColor?: string;
}

export const LineChartToolTipLine: FC<LineChartToolTipLineProps> = ({
  dateScale,
  MARGIN,
  wellbores,
  helplines,
  axisRightScale,
  valueScale,
  isDragActive,
  xMax,
  yMax,
  tooltipLeft,
  tooltipData,
  showTooltip,
  hideTooltip,
}) => {
  const [isDraggingDate, setIsDraggingDate] = useState<Date | undefined>(
    undefined
  );

  const handleTooltip = useCallback(
    (
      event:
        | TouchEvent<SVGRectElement | SVGCircleElement>
        | MouseEvent<SVGRectElement | SVGCircleElement>,
      manualY?: number
    ) => {
      if (!showTooltip) return null;
      const { x, y } = localPoint(event) ?? { x: 0 };
      const allLineChartLines: LineChartLine[] = [];
      allLineChartLines.push(...wellbores);
      if (helplines) {
        allLineChartLines.push(...helplines);
      }

      const tooltipData: (WellboreTooltip | undefined)[] =
        allLineChartLines.map((chartline) => {
          const dateFromX = dateScale.invert(x - MARGIN.left);

          const dateFromXAdjusted = new Date(
            dateFromX.getTime() + 1000 * 60 * 60 * 12 // 12 hours
          );

          const dateFromXDay = dateFromXAdjusted.getDate();
          const dateFromXMonth = dateFromXAdjusted.getMonth();
          const dateFromXYear = dateFromXAdjusted.getFullYear();

          const dataPoint = chartline.data.find((dataPoint) => {
            const dataPointDay = dataPoint.date.getDate();
            const dataPointMonth = dataPoint.date.getMonth();
            const dataPointYear = dataPoint.date.getFullYear();

            return (
              dataPointDay === dateFromXDay &&
              dataPointMonth === dateFromXMonth &&
              dateFromXYear === dataPointYear
            );
          });

          if (!dataPoint) return;

          return {
            circleTop:
              chartline.rightAxisType !== undefined
                ? axisRightScale(dataPoint.value)
                : valueScale(dataPoint.value),
            name: chartline.name,
            date: isDraggingDate ?? new Date(dataPoint.date.toDateString()),
            color: chartline.color,
            helplineDataType: chartline.helplineDataType,
            value: !chartline.helplineDataType
              ? manualY
                ? valueScale.invert(manualY)
                : dataPoint.value
              : dataPoint.value,
          };
        });

      const cleanedTooltipData = tooltipData.filter(
        (item) => item
      ) as WellboreTooltip[];

      showTooltip({
        tooltipData: cleanedTooltipData,
        tooltipLeft: dateScale(cleanedTooltipData[0].date),
        tooltipTop: y,
      });
    },
    [
      showTooltip,
      wellbores,
      helplines,
      dateScale,
      MARGIN.left,
      axisRightScale,
      valueScale,
      isDraggingDate,
    ]
  );

  if (!hideTooltip || !showTooltip) return null;
  return (
    <>
      {/*This Bar is an invisible "base" for the tooltip hover effect etc*/}
      <Bar
        x={0}
        y={0}
        width={xMax}
        height={yMax}
        fill="transparent"
        rx={14}
        onTouchStart={handleTooltip}
        onTouchMove={handleTooltip}
        onTouchEnd={() => hideTooltip()}
        onMouseMove={handleTooltip}
        onMouseLeave={() => hideTooltip()}
      />
      {tooltipData && (
        <g>
          <Line
            from={{ x: tooltipLeft, y: 0 }}
            to={{ x: tooltipLeft, y: yMax }}
            stroke={colors.ui.background__medium.rgba}
            strokeWidth={2}
            pointerEvents="none"
            strokeDasharray="2,2"
          />

          {tooltipData.map((data) => {
            const key = `${data.name}`;
            if (isDragActive && !data.helplineDataType) {
              return (
                <DraggableDataPoint
                  key={key}
                  tooltipData={data}
                  handleTooltip={handleTooltip}
                  tooltipLeft={tooltipLeft}
                  width={xMax}
                  valueScale={valueScale}
                  height={yMax}
                  isDraggingDate={isDraggingDate}
                  setIsDraggingDate={setIsDraggingDate}
                />
              );
            }
          })}
        </g>
      )}
    </>
  );
};
