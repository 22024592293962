import { FC, useMemo } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';
import { scaleOrdinal } from '@visx/scale';

import { ChartDataPointCategory } from '../Chart.types';
import { dataPointColor } from '../Chart.utils';

import { styled } from 'styled-components';

const { colors } = tokens;

const Container = styled.div`
  position: absolute;
  display: flex;
  gap: ${spacings.small};
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Item = styled.div`
  display: flex;
  gap: ${spacings.x_small};
  align-items: center;
  > span {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 2px solid transparent;
  }
`;

interface LegendProps {
  color?: string;
  graphColors?: string[];
}

export const Legend: FC<LegendProps> = ({ graphColors, color }) => {
  const legendScale = useMemo(
    () =>
      scaleOrdinal({
        range: [
          dataPointColor(ChartDataPointCategory.INITIAL),
          dataPointColor(ChartDataPointCategory.EXPECTED),
          dataPointColor(ChartDataPointCategory.LOW_HIGH, color, graphColors),
        ],
        domain: [
          ChartDataPointCategory.INITIAL,
          ChartDataPointCategory.EXPECTED,
          ChartDataPointCategory.LOW_HIGH,
        ],
      }),
    [graphColors, color]
  );

  const customColor = (category: ChartDataPointCategory) =>
    graphColors && category === ChartDataPointCategory.LOW_HIGH;

  return (
    <Container>
      {legendScale.domain().map((category) => (
        <Item key={category}>
          <span
            style={{
              background: legendScale(category)?.fill,
              borderColor: legendScale(category)?.stroke,
              borderWidth: customColor(category) ? 0 : 4,
              width: customColor(category) ? 18 : 11,
              height: customColor(category) ? 18 : 11,
              opacity: category === ChartDataPointCategory.EXPECTED ? 0.4 : 1,
            }}
          />
          <Typography
            variant="label"
            group="input"
            color={colors.text.static_icons__tertiary.rgba}
          >
            {category}
            {customColor(category) && ' / Stratigraphic color'}
          </Typography>
        </Item>
      ))}
    </Container>
  );
};
