/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Filter } from '../models/Filter';
import type { FilterCreateDto } from '../models/FilterCreateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FilterService {
  /**
   * Get user filters by field
   * @param fieldIdentifier
   * @returns Filter OK
   * @throws ApiError
   */
  public static getApiV1Filter(
    fieldIdentifier: string
  ): CancelablePromise<Array<Filter>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Filter/{fieldIdentifier}',
      path: {
        fieldIdentifier: fieldIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Create a new filter
   * @param requestBody
   * @returns string Created
   * @throws ApiError
   */
  public static postApiV1Filter(
    requestBody?: FilterCreateDto
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Filter',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Update filter title
   * @param id
   * @param title
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1Filter(
    id: string,
    title: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Filter/{id}',
      path: {
        id: id,
      },
      query: {
        title: title,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Delete a filter
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteApiV1Filter(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Filter/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
