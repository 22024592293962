import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, useSnackbar } from '@equinor/amplify-component-lib';

import {
  ButtonContainer,
  Container,
} from 'src/pages/EditPrognosis/components/ActionFooter/ActionFooter.styles';
import { SubmitDialog } from 'src/pages/EditPrognosis/components/ActionFooter/SubmitDialog';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const PreviewFooter: FC = () => {
  const navigate = useNavigate();
  const { prognosisId } = useParams();
  const { showSnackbar } = useSnackbar();
  const { trigger, watch } = useFormContext<EditPrognosisFormValues>();
  const { title, prognosisDetails } = watch();

  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  const handleOnOpenSubmitDialog = async () => {
    const isValid = await trigger();
    if (prognosisDetails.length <= 0) {
      showSnackbar('Please fill in the prognosis data.');
    } else if (isValid) {
      setOpenSubmitDialog(true);
    }
  };

  const handleOnCloseSubmitDialog = () => setOpenSubmitDialog(false);

  return (
    <>
      <Container>
        <Button variant="ghost" onClick={() => navigate('/dashboard')}>
          Return to dashboard
        </Button>
        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={() => navigate(`/dashboard/edit-prognosis/${prognosisId}`)}
          >
            Return to edit mode
          </Button>
          <Button onClick={handleOnOpenSubmitDialog}>
            Submit for approval
          </Button>
        </ButtonContainer>
      </Container>
      <SubmitDialog
        open={openSubmitDialog}
        title={title ?? 'N/A'}
        onClose={handleOnCloseSubmitDialog}
      />
    </>
  );
};
